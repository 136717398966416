import { createSlice } from "@reduxjs/toolkit";
import { IReduxState } from "../../interfaces/IReduxState";
import { IListStockDetails } from "../../../Pages/StockDetails/IStockDetails";

export const ListStockDetails = createSlice({
  name: "ListStockDetails",
  initialState: {
    isLoading: false,
    data: {},
  } as IReduxState<IListStockDetails[]>,
  reducers: {
    ListStockDetailsStart: (state) => {
      state.isLoading = true;
    },
    successListStockDetails: (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    },
    failureListStockDetails: (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    },
  },
});

export const {
  ListStockDetailsStart,
  failureListStockDetails,
  successListStockDetails,
} = ListStockDetails.actions;

export default ListStockDetails.reducer;
