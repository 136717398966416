import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IReduxState } from "../../interfaces/IReduxState";
import { IListManualStockAdjustment } from "../../../Pages/StockAdjustment/IStockAdjustment";
import { Filter } from "../../../Pages/StockRecieve/IStockRecieve";

export const ListStockAdjustment = createSlice({
  name: "ListStockAdjustment",
  initialState: {} as IReduxState<IListManualStockAdjustment[]>,
  reducers: {
    listStockAdjustment: (state, _: PayloadAction<Filter>) => {
      state.isLoading = true;
    },
    successListStockAdjustment: (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    },
    failureListStockAdjustment: (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    },
  },
});

export const {
  successListStockAdjustment,
  failureListStockAdjustment,
  listStockAdjustment,
} = ListStockAdjustment.actions;

export default ListStockAdjustment.reducer;
