import { createSlice } from "@reduxjs/toolkit";
import { IReduxState } from "../../interfaces/IReduxState";

export const GetSequenceList = createSlice({
  name: "GetSequenceList",
  initialState: {
    isLoading: false,
    data: {},
  } as IReduxState<{}>,
  reducers: {
    getSequenceListStart: (state) => {
      state.isLoading = true;
    },
    getSequenceListSuccess: (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    },
    getSequenceListFailure: (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    },
  },
});
export const {
  getSequenceListFailure,
  getSequenceListStart,
  getSequenceListSuccess,
} = GetSequenceList.actions;
export default GetSequenceList.reducer;
