import { AxiosResponse } from "axios";
import { call, put } from "redux-saga/effects";
import api from "../../Axios/middleware";
import {
  successLocationMaster,
  // failureLocationMaster,
  // dataGetted,
} from "./ListLocationMasterSlice";
import {
  failureaddLocationMaster,
  successaddLocationMaster,
} from "./AddLocationMasterSlice";
import { PayloadAction } from "@reduxjs/toolkit";
import { ILocationMaster } from "../../../Pages/LocationMaster/ILocationMaster";
import {
  failureeditLocationMaster,
  successeditLocationMaster,
} from "./EditLocationMasterSlice";
import {
  failuredeleteLocationMaster,
  successdeleteLocationMaster,
} from "./DeleteLocationMasterSlice";

// list
const ListLocationMasterApi = () => {
  return api.get("/location-master/filter");
};

export function* ListLocationMasterCall() {
  try {
    const response: AxiosResponse = yield call(ListLocationMasterApi);
    yield put(successLocationMaster(response.data));
  } catch (error) {
    yield put(successLocationMaster(error));
  }
}

// add
const AddLocationMasterApi = (action: PayloadAction<ILocationMaster>) => {
  return api.post("/location-master/create", action.payload);
};

export function* AddLocationMasterCall(action: PayloadAction<ILocationMaster>) {
  try {
    const response: AxiosResponse = yield call(AddLocationMasterApi, action);
    yield put(successaddLocationMaster(response.data));
  } catch (error) {
    yield put(failureaddLocationMaster(error));
  }
}

// edit
const EditLocationMasterApi = (action: PayloadAction<ILocationMaster>) => {
  return api.patch("/location-master/update", action.payload, {
    params: { id: action.payload.id },
  });
};

export function* EditLocationMasterCall(
  action: PayloadAction<ILocationMaster>
) {
  try {
    const response: AxiosResponse = yield call(EditLocationMasterApi, action);
    yield put(successeditLocationMaster(response.data));
  } catch (error) {
    yield put(failureeditLocationMaster(error));
  }
}

// delete
const DeleteLocationMasterApi = (action: PayloadAction<number>) => {
  return api.delete("/location-master/delete", {
    params: { id: action.payload },
  });
};

export function* DeleteLocationMasterCall(action: PayloadAction<number>) {
  try {
    const response: AxiosResponse = yield call(DeleteLocationMasterApi, action);
    yield put(successdeleteLocationMaster(response.data));
  } catch (error) {
    yield put(failuredeleteLocationMaster(error));
  }
}
