import { CardContent } from "@mui/material";
import { FunctionComponent } from "react";
import { CardContentsProps } from "./ICardContent";

const CardContents: FunctionComponent<CardContentsProps> = (props) => {
  const { sx, className, children } = props;
  return (
    <CardContent sx={sx} className={className}>
      {children}
    </CardContent>
  );
};

export default CardContents;
