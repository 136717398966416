import { FunctionComponent, memo, useEffect } from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { ILocationMaster } from "./ILocationMaster";
import { useAppDispatch, useAppSelector } from "../../Redux/Store/Hooks";
import { listLocationType } from "../../Redux/Slices/LocationType/ListLocationTypeSlice";
import TextInputFieldC from "../../Components/TextInput/TextInputFieldC";
import Select from "../../Components/Select/AutoComplete";
import { AutocompleteOption } from "../../Components/Select/ISelectProps";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { addLocationMaster } from "../../Redux/Slices/LocationMaster/AddLocationMasterSlice";
import { editLocationMaster } from "../../Redux/Slices/LocationMaster/EditLocationMasterSlice";
import { listDivisionMaster } from "../../Redux/Slices/DivisionMaster/ListDivisionMasterSlice";

interface LocationMasterFormProps {
  formRef: React.RefObject<HTMLFormElement>;
  initialValues: ILocationMaster;
  setInitialValues: React.Dispatch<React.SetStateAction<ILocationMaster>>;
}

const LocationMasterForm: FunctionComponent<LocationMasterFormProps> = (
  props
) => {
  const locationSchema = z.object({
    id: z.number().optional(),
    name: z
      .string({ message: "Please enter Name" })
      .min(1, { message: "Please enter Name" }),
    location_type_id: z.number({ message: "Please select Location type" }),
    division_id: z.number({ message: "Please select Location type" }),
    code: z
      .string({ message: "Please enter Code" })
      .min(1, { message: "Please enter Code" }),
    location_type: z
      .string({ message: "Please enter Location" })
      .min(1, { message: "Please enter Location" }),
  });
  const methods = useForm<ILocationMaster>({
    defaultValues: props.initialValues,
    resolver: zodResolver(locationSchema),
  });

  const dispatch = useAppDispatch();

  const onSubmit: SubmitHandler<ILocationMaster> = (val) => {
    console.log(val);
    if (val.id) {
      dispatch(editLocationMaster(val));
    } else {
      dispatch(addLocationMaster(val));
    }
  };

  useEffect(() => {
    dispatch(listLocationType());
    dispatch(listDivisionMaster());
    return () => {
      props.setInitialValues({});
    };
  }, [props, dispatch]);

  const { data: locationType, isLoading: locationTypeLoading } = useAppSelector(
    (state) => state.ListLocationType
  );
  const { data: division, isLoading: divisionLoading } = useAppSelector(
    (state) => state.ListDivisionMaster
  );
  const locationTypeList = Array.isArray(locationType?.data)
    ? locationType?.data
        .filter((type) => type.name !== "Supplier") // Filter first
        .map((type) => ({ label: type.name, value: type.id })) // Then map
    : [];

  console.log(locationTypeList);

  const divisionList = Array.isArray(division?.data)
    ? division?.data.map((type) => ({ label: type.name!, value: type.id }))
    : [];

  return (
    <FormProvider {...methods}>
      <form
        ref={props.formRef}
        onSubmit={methods.handleSubmit(onSubmit)}
        className="form_content"
      >
        <TextInputFieldC name="name" label="Name" type="text" />

        <Select
          name="division_id"
          label="Division"
          loading={divisionLoading}
          options={divisionList}
          value={
            divisionList?.find(
              (opt) => opt.value === methods.getValues("division_id")
            ) ?? null
          }
          onChange={(_, value) => {
            const val = value as AutocompleteOption;
            methods.setValue("division_id", val?.value ?? null, {
              shouldValidate: true,
            });
          }}
        />

        <Select
          name="location_type_id"
          label="Location Type"
          loading={locationTypeLoading}
          options={locationTypeList}
          value={
            locationTypeList?.find(
              (opt) => opt?.value === methods.getValues("location_type_id")
            ) ?? null
          }
          onChange={(_, value) => {
            const val = value as AutocompleteOption;
            methods.setValue("location_type_id", val?.value ?? null, {
              shouldValidate: true,
            });
            methods.setValue("location_type", val?.label ?? null);
          }}
        />

        <TextInputFieldC name="code" label="Code" type="text" />
      </form>
    </FormProvider>
  );
};

export default memo(LocationMasterForm);
