import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IReduxState } from "../../interfaces/IReduxState";
import { IColorCode } from "../../../Pages/ColorCode/IColorCode";

export const AddColorCodeSlice = createSlice({
  name: "AddColorCode",
  initialState: {} as IReduxState<IColorCode>,
  reducers: {
    addColorCode: (state, _: PayloadAction<IColorCode>) => {
      state.isLoading = true;
    },
    successaddColorCode: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
    failureaddColorCode: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
    clearAddColorCodeResponse: (state) => {
      state.data = {};
    },
  },
});

export const {
  addColorCode,
  failureaddColorCode,
  successaddColorCode,
  clearAddColorCodeResponse,
} = AddColorCodeSlice.actions;

export default AddColorCodeSlice.reducer;
