import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IReduxState } from "../../interfaces/IReduxState";
import { IDivisionMaster } from "../../../Pages/DivisionMaster/IDivisionMaster";

export const EditDivisionMasterSlice = createSlice({
  name: "EditDivisionMaster",
  initialState: {} as IReduxState<IDivisionMaster>,
  reducers: {
    editDivisionMaster: (state, _: PayloadAction<IDivisionMaster>) => {
      state.isLoading = true;
    },
    successeditDivisionMaster: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
    failureeditDivisionMaster: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
    clearEditDivisionMasterResponse: (state) => {
      state.data = {};
    },
  },
});

export const {
  editDivisionMaster,
  failureeditDivisionMaster,
  successeditDivisionMaster,
  clearEditDivisionMasterResponse,
} = EditDivisionMasterSlice.actions;

export default EditDivisionMasterSlice.reducer;
