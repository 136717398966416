import { CardHeader } from "@mui/material";
import { FunctionComponent } from "react";
import { CardHeadersProps } from "./ICardHeader";

const CardHeaders: FunctionComponent<CardHeadersProps> = (props) => {
  const { avatar, action, title, subheader, sx } = props;
  return (
    <CardHeader
      avatar={
        avatar
        // <Avatar sx={{ bgcolor: red[500] }} aria-label="recipe">
        //   R
        // </Avatar>
      }
      action={
        action
        // <IconButton aria-label="settings">
        //   <MoreVertIcon />
        // </IconButton>
      }
      title={title}
      subheader={subheader}
      sx={sx}
      titleTypographyProps={{ variant: "body1" }}
    />
  );
};

export default CardHeaders;
