import { createSlice } from "@reduxjs/toolkit";
import { IReduxState } from "../../interfaces/IReduxState";
import { IColorCode } from "../../../Pages/ColorCode/IColorCode";

export const ColorCodeSlice = createSlice({
  name: "ListColorCode",
  initialState: {} as IReduxState<IColorCode[]>,
  reducers: {
    listColorCode: (state) => {
      state.isLoading = true;
    },
    successColorCode: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
    failureColorCode: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
  },
});

export const { failureColorCode, listColorCode, successColorCode } =
  ColorCodeSlice.actions;

export default ColorCodeSlice.reducer;
