import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IReduxState } from "../../interfaces/IReduxState";
import { ILocationMaster } from "../../../Pages/LocationMaster/ILocationMaster";

export const DeleteLocationMasterSlice = createSlice({
  name: "DeleteLocationMaster",
  initialState: {} as IReduxState<ILocationMaster>,
  reducers: {
    deleteLocationMaster: (state, _) => {
      state.isLoading = true;
    },
    successdeleteLocationMaster: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
    failuredeleteLocationMaster: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
    clearDeleteLocationMasterResponse: (state) => {
      state.data = {};
    },
  },
});

export const {
  deleteLocationMaster,
  failuredeleteLocationMaster,
  successdeleteLocationMaster,
  clearDeleteLocationMasterResponse,
} = DeleteLocationMasterSlice.actions;

export default DeleteLocationMasterSlice.reducer;
