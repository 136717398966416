import { Dispatch, FunctionComponent, SetStateAction, useEffect } from "react";
import { SubmitHandler, useFieldArray, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { IGetOnePick } from "./IStockTransfer";
import Table from "../../Components/Table/Table";
import CustomToolbar from "../../Components/Table/components/CustomToolbar";
import TableSearch from "../../Components/Table/components/TableSearch";
import { Columns } from "../../Components/Table/ITable";
import { IListStockDetails } from "../StockDetails/IStockDetails";
import { GridCellParams, GridRenderCellParams } from "@mui/x-data-grid";
import CustomFooter from "../../Components/Table/components/CustomFooter";
import { useAppDispatch, useAppSelector } from "../../Redux/Store/Hooks";
import ButtonField from "../../Components/Button/ButtonField";
import { FormHelperText } from "@mui/material";
import { clearGetOnePickListResponse } from "../../Redux/Slices/PickList/GetOnePickItemSlice";
import {
  clearEditPickItemResponse,
  editPickItem,
} from "../../Redux/Slices/PickList/EditPickItemSlice";
import ResponseDialog from "../../Components/ResponseDialog/ResponseDialog";
import { getoneStockTransfer } from "../../Redux/Slices/StockTransfer/GetoneStockTransferSlice";
import { dateFormatter } from "../../Utils/dateFormatter";

interface PickQuantityFormProps {
  list?: IGetOnePick;
  setPickQuantityDialogOpen: Dispatch<SetStateAction<boolean>>;
  update_id: number;
}

const PickQuantityForm: FunctionComponent<PickQuantityFormProps> = (props) => {
  const dispatch = useAppDispatch();
  const { data: updatePickItem } = useAppSelector(
    (state) => state.EditPickItem
  );

  const columns: Columns = [
    {
      field: "area_code",
      renderHeader: () => <b>Area Code</b>,
      flex: 1,
      renderCell: (params: GridRenderCellParams<IListStockDetails>) => (
        <div
          style={{
            backgroundColor: params.row.color,
            height: "100%",
            width: "100%",
            color: "white",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {params.value}
        </div>
      ),
      align: "center",
      headerAlign: "center",
    },
    {
      field: "item_barcode",
      renderHeader: () => <b>Item Barcode</b>,
      flex: 1,
    },
    {
      field: "entry_date",
      renderHeader: () => <b>Entry Date</b>,
      flex: 1,
      type: "date",
      valueFormatter: (params) => dateFormatter(params.value),
      headerAlign: "center",
      align: "center",
    },
    {
      field: "onhand_qnty",
      renderHeader: () => <b>Onhand Quantity</b>,
      cellClassName: (params: GridCellParams<IListStockDetails>) =>
        params.row.fifo_check ? "" : "disable_table_text",
      flex: 1,
      type: "number",
      align: "right",
      headerAlign: "right",
    },
    {
      field: "picked_qnty",
      renderHeader: () => <b>Pick Item Quantity</b>,
      flex: 1,
      editable: true,
      cellClassName: (params: GridCellParams<IListStockDetails>) =>
        params.row.fifo_check ? "inputCell" : "disable_table_text",
      type: "number",
      align: "right",
      headerAlign: "right",
    },
  ];

  const arraySchema = z.object({
    area_code: z.string(),
    item_barcode: z.string(),
    // onhand_qnty: z.number(),
    picked_qnty: z.number().optional().nullable(),
    // check: z.boolean(),
    // check_box_enable: z.boolean(),
  });

  const schema = z
    .object({
      picklist: z.object({
        id: z.number().optional(),
        need_quantity: z.number().optional(),
      }),
      stockDetails: z.array(arraySchema).min(1),
    })
    .refine(
      (data) =>
        (data.picklist.need_quantity ?? 0) >=
        (data.stockDetails
          .map((val) => val.picked_qnty)
          .reduce((pre, cur) => (pre ?? 0) + (cur ?? 0)) ?? 0),
      { message: "gg", path: ["stockDetails"] }
    );

  const methods = useForm<IGetOnePick>({
    // defaultValues: tempData,
    resolver: zodResolver(schema),
  });

  const { update } = useFieldArray({
    control: methods.control,
    name: "stockDetails",
  });

  // submit form
  const onSubmit: SubmitHandler<IGetOnePick> = (val) => {
    const filterdValue = val.stockDetails
      .filter((value) => !!value.picked_qnty)
      .map((value) => ({ ...value, item_quantity: value.picked_qnty }));

    console.log({ ...val, stockDetails: filterdValue });
    dispatch(editPickItem({ ...val, stockDetails: filterdValue }));
  };

  // update table rows
  const updateRow = (newRow: IListStockDetails) => {
    const updatedRow = { ...newRow };
    const findIndex = tableRows.findIndex((val) => {
      return newRow.id === val.id;
    });
    update(findIndex!, newRow);
    return updatedRow;
  };

  // table rows
  const tableRows = methods
    .getValues("stockDetails")
    ?.map((row, index) => ({ ...row, index: index + 1, id: index }));

  useEffect(() => {
    methods.reset(props.list);
  }, [methods, props.list]);

  useEffect(() => {
    return () => {
      dispatch(clearGetOnePickListResponse());
      dispatch(clearEditPickItemResponse());
    };
  }, [dispatch]);

  return (
    <>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <Table
          rows={tableRows ?? []}
          columns={columns}
          isCellEditable={(params: GridCellParams<IListStockDetails>) =>
            params.row.fifo_check
          }
          processRowUpdate={updateRow}
          initialPageSize={100}
          editMode="cell"
        >
          <CustomToolbar>
            <b>{methods.getValues("picklist.items.item_name")}</b>
            <TableSearch className="view_table_left_align_search" />
          </CustomToolbar>
          <CustomFooter sx={{ gap: "8px", padding: "0 8px" }}>
            <div>
              Requested Quantity : {methods.getValues("picklist.need_quantity")}
            </div>
            <div
              style={{
                color: !!methods.formState.errors.stockDetails
                  ? "#bd0000"
                  : "black",
              }}
            >
              Picked Quantity :{" "}
              {methods
                .getValues("stockDetails")
                ?.map((val) => val.picked_qnty)
                ?.reduce((pre, cur) => pre + cur)}
              {!!methods.formState.errors.stockDetails && (
                <FormHelperText error>
                  Picked Quantity exceeds the maximum limit of Requested
                  Quantity.
                </FormHelperText>
              )}
            </div>
            <div style={{ display: "flex", gap: "inherit" }}>
              <ButtonField
                className="form_cancel_button"
                onClick={() => props.setPickQuantityDialogOpen(false)}
              >
                Cancel
              </ButtonField>

              <ButtonField
                className="form_save_button"
                type="submit"
                onClick={() => {
                  // formRef.current?.requestSubmit();
                }}
              >
                Save
              </ButtonField>
            </div>
          </CustomFooter>
        </Table>
      </form>

      <ResponseDialog
        response={updatePickItem}
        parentDialog={props.setPickQuantityDialogOpen}
        okFunction={() => {
          props.update_id && dispatch(getoneStockTransfer(props.update_id));
        }}
      />
    </>
  );
};

export default PickQuantityForm;
