import { createSlice } from "@reduxjs/toolkit";
import { IReduxState } from "../../interfaces/IReduxState";
import { IDivisionMaster } from "../../../Pages/DivisionMaster/IDivisionMaster";

export const DivisionMasterSlice = createSlice({
  name: "ListDivisionMaster",
  initialState: {} as IReduxState<IDivisionMaster[]>,
  reducers: {
    listDivisionMaster: (state) => {
      state.isLoading = true;
    },
    successDivisionMaster: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
    failureDivisionMaster: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
  },
});

export const {
  failureDivisionMaster,
  listDivisionMaster,
  successDivisionMaster,
} = DivisionMasterSlice.actions;

export default DivisionMasterSlice.reducer;
