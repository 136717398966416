import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IReduxState } from "../../interfaces/IReduxState";
import { IZoneConfig } from "../../../Pages/ZoneConfig/IZoneConfig";

export const EditZoneConfigSlice = createSlice({
  name: "EditZoneConfig",
  initialState: {} as IReduxState<IZoneConfig>,
  reducers: {
    editZoneConfig: (state, _: PayloadAction<IZoneConfig>) => {
      state.isLoading = true;
    },
    successeditZoneConfig: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
    failureeditZoneConfig: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
    clearEditZoneConfigResponse: (state) => {
      state.data = {};
    },
  },
});

export const {
  editZoneConfig,
  failureeditZoneConfig,
  successeditZoneConfig,
  clearEditZoneConfigResponse,
} = EditZoneConfigSlice.actions;

export default EditZoneConfigSlice.reducer;
