import { AxiosResponse } from "axios";
import { call, put } from "redux-saga/effects";
import api from "../../Axios/middleware";
import { responseReportsStockReceive } from "./ListReportsStockReceiveSlice";
import { PayloadAction } from "@reduxjs/toolkit";
import { IReportFilter } from "../../../Pages/ReportsStockReceive/IReportsStockReceive";

const ReportsStockReceiveApi = (action: PayloadAction<IReportFilter>) => {
  console.log(action.payload);

  return api.get("/transa-stock-receive/report", { params: action.payload });
};

export function* ReportsStockReceiveCall(action: PayloadAction<IReportFilter>) {
  try {
    const respose: AxiosResponse = yield call(ReportsStockReceiveApi, action);
    yield put(responseReportsStockReceive(respose.data));
  } catch (error) {
    yield put(responseReportsStockReceive({}));
  }
}
