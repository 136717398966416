import { FunctionComponent, useEffect } from "react";
import "./StorageArea.scss";
import { StorageAreaProps } from "./IStorageArea";
import Cards from "../../Components/Card/cards";
import {
  clearStorageArea,
  listStorageArea,
} from "../../Redux/Slices/StorageArea/ListStorageAreaSlice";
import { useAppDispatch, useAppSelector } from "../../Redux/Store/Hooks";
import { useLocation, useNavigate } from "react-router-dom";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import IconButtonField from "../../Components/Button/IconButtonField";
import CardHeaders from "../../Components/Card/Components/CardHeader";
import CardContents from "../../Components/Card/Components/CardContent";
import { SvgIcon } from "@mui/material";
import { ReactComponent as Stock } from "../../Assets/Images/Stock.svg";
import StorageContainerComponent from "./StorageContainerComponent";

const StorageArea: FunctionComponent<StorageAreaProps> = () => {
  const dispatch = useAppDispatch();
  const { state: id } = useLocation();
  const nav = useNavigate();
  const { data } = useAppSelector((state) => state.ListStorageArea);

  useEffect(() => {
    dispatch(listStorageArea(id));
  }, [dispatch, id]);

  useEffect(() => {
    return () => {
      dispatch(clearStorageArea());
    };
  }, [dispatch]);

  return (
    <>
      <div style={{ display: "flex", gap: 10, alignItems: "center" }}>
        <IconButtonField onClick={() => nav(-1)}>
          <NavigateBeforeIcon />
        </IconButtonField>
        <div className="title_name">Storage Area</div>
      </div>
      <div style={{ display: "flex", gap: 10, alignItems: "center" }}>
        <p>
          Zone Name : <b>{data?.data?.zone?.name}</b>
        </p>
        <p>
          Zone Code : <b>{data?.data?.zone?.code}</b>
        </p>
      </div>
      <StorageContainerComponent data={data} />
    </>
  );
};

export default StorageArea;
