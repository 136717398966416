import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IReduxState } from "../../interfaces/IReduxState";
// import { ILocationReceive } from "../../../Pages/LocationReceive/ILocationReceive";

export const AddLocationReceiveSlice = createSlice({
  name: "AddLocationReceive",
  initialState: {} as IReduxState<{}>,
  reducers: {
    addLocationReceive: (state, _: PayloadAction<{}>) => {
      state.isLoading = true;
    },
    successaddLocationReceive: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
    failureaddLocationReceive: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
    clearAddLocationReceiveResponse: (state) => {
      state.data = {};
    },
  },
});

export const {
  addLocationReceive,
  failureaddLocationReceive,
  successaddLocationReceive,
  clearAddLocationReceiveResponse,
} = AddLocationReceiveSlice.actions;

export default AddLocationReceiveSlice.reducer;
