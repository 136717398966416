import React, { FunctionComponent, useEffect } from "react";
import {
  FormProvider,
  SubmitHandler,
  UseFieldArrayAppend,
  UseFieldArrayUpdate,
  useForm,
} from "react-hook-form";
import { z, ZodNumber } from "zod";
import { IAdjustItems, IListManualStockAdjustment } from "./IStockAdjustment";
import { zodResolver } from "@hookform/resolvers/zod";
import NumberInputField from "../../Components/NumberInput/NumberInputField";
import TextInputFieldC from "../../Components/TextInput/TextInputFieldC";
import { useAppDispatch, useAppSelector } from "../../Redux/Store/Hooks";
import { listScanItemsStart } from "../../Redux/Slices/StockAdjustment/ListScanItems";
interface ItemFormProps {
  formRef: React.RefObject<HTMLFormElement>;
  initialValues: IAdjustItems;
  setInitialValues: React.Dispatch<React.SetStateAction<IAdjustItems>>;
  itemSchema: z.ZodObject<{
    id: z.ZodOptional<z.ZodNumber>;
    item_barcode: z.ZodString;
    // adjust_qty: z.ZodNumber;
  }>;
  append: UseFieldArrayAppend<IListManualStockAdjustment, "item_details">;
  update: UseFieldArrayUpdate<IListManualStockAdjustment, "item_details">;
  setDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
}
const StockAdjustmentItemForm: FunctionComponent<ItemFormProps> = (props) => {
  const dispatch = useAppDispatch();
  const { data: ListScanItems } = useAppSelector(
    (state) => state.ListScanItems
  );

  const methods = useForm<IAdjustItems>({
    defaultValues: props.initialValues,
    resolver: zodResolver(props.itemSchema),
  });
  const onSubmit: SubmitHandler<IAdjustItems> = (val) => {
    console.log(val);

    // if (val.id || val.id === 0) {
    //   console.log("update");

    //   props.update(val.id!, val);
    // } else {
    //   console.log("add");
    //   props.append(val);
    // }
  };

  const handleEnterKeyPress = (event: React.KeyboardEvent) => {
    if (
      event.key === "Enter" &&
      methods.getValues("item_barcode")?.length! > 0
    ) {
      // Call your function here

      dispatch(
        listScanItemsStart({ item_barcode: methods.getValues("item_barcode") })
      );
      props.setDialogOpen(false);
      console.log("Enter key pressed!", methods.getValues("item_barcode"));
    }
  };

  useEffect(() => {
    return () => {
      props.setInitialValues({});
    };
  }, [props]);
  useEffect(() => {
    if (ListScanItems?.status) methods.setValue("item_barcode", "");
    if (props.formRef.current) {
      const inputElement =
        props.formRef.current.querySelector<HTMLInputElement>(
          "[name='item_barcode']"
        );
      inputElement?.focus();
    }
  }, [ListScanItems]);

  return (
    <FormProvider {...methods}>
      <form
        ref={props.formRef}
        onSubmit={methods.handleSubmit(onSubmit)}
        className="form_content"
      >
        <TextInputFieldC
          name="item_barcode"
          label="Item Barcode"
          InputProps={{ onKeyDown: handleEnterKeyPress, autoFocus: true }}
        />
      </form>
    </FormProvider>
  );
};

export default StockAdjustmentItemForm;
