import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IReduxState } from "../../interfaces/IReduxState";
import { IItemMaster } from "../../../Pages/ItemMaster/IItemMaster";
import { IListItemParams } from "./ApiItemMaster";

export const ItemMasterSlice = createSlice({
  name: "ListItemMaster",
  initialState: {} as IReduxState<IItemMaster[]>,
  reducers: {
    listItemMaster: (state, _: PayloadAction<IListItemParams>) => {
      state.isLoading = true;
    },
    successItemMaster: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
    failureItemMaster: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
    clearItemMaster: (state) => {
      state.data = {};
    },
  },
});

export const {
  failureItemMaster,
  listItemMaster,
  successItemMaster,
  clearItemMaster,
} = ItemMasterSlice.actions;

export default ItemMasterSlice.reducer;
