import { FunctionComponent, useEffect, useRef, useState } from "react";
import "./UserMaster.scss";
import Table from "../../Components/Table/Table";
import CustomToolbar from "../../Components/Table/components/CustomToolbar";
import ButtonField from "../../Components/Button/ButtonField";
import { Columns } from "../../Components/Table/ITable";
import {
  GridRenderCellParams,
  GridValueFormatterParams,
} from "@mui/x-data-grid";
import { IRole, IUserMaster } from "./IUserMaster";
import { useAppDispatch, useAppSelector } from "../../Redux/Store/Hooks";
import { listUserMaster } from "../../Redux/Slices/UserMaster/ListUserMasterSlice";
import Dialog from "../../Components/Dialog/Dialog";
import UserForm from "./UserForm";
import ResponseDialog from "../../Components/ResponseDialog/ResponseDialog";
import TableSearch from "../../Components/Table/components/TableSearch";
import { clearAddUserMasterResponse } from "../../Redux/Slices/UserMaster/AddUserMasterSlice";
import { ReactComponent as Delete } from "../../Assets/Images/delete.svg";
import { ReactComponent as Edit } from "../../Assets/Images/edit.svg";
import { IDeleteDialog } from "../../Components/DeleteDialog/IDeleteDialog";
import IconButtonField from "../../Components/Button/IconButtonField";
import { SvgIcon } from "@mui/material";
import { clearEditUserMasterResponse } from "../../Redux/Slices/UserMaster/EditUserMasterSlice";
import {
  clearDeleteUserMasterResponse,
  deleteUserMaster,
} from "../../Redux/Slices/UserMaster/DeleteUserMasterSlice";
import DeleteDialog from "../../Components/DeleteDialog/DeleteDialog";

interface UserMasterProps {}

const UserMaster: FunctionComponent<UserMasterProps> = () => {
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState<boolean>(false);
  const [deleteDialogData, setDeleteData] = useState<IDeleteDialog>({});
  const [initialValues, setInitialValues] = useState<IUserMaster | {}>({});
  const dispatch = useAppDispatch();
  const { data: userMasterList } = useAppSelector(
    (state) => state.ListUserMaster
  );
  const { data: addUserMaster } = useAppSelector(
    (state) => state.AddUserMasterSlice
  );
  const { data: editUser } = useAppSelector((state) => state.EditUserMaster);
  const { data: deleteUser } = useAppSelector(
    (state) => state.DeleteUserMaster
  );
  const formRef = useRef<HTMLFormElement>(null);
  const columns: Columns = [
    {
      field: "index",
      renderHeader: () => <b>S.No</b>,
      width: 90,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "name",
      renderHeader: () => <b>Name</b>,
      flex: 1,
      type: "string",
      headerAlign: "center",
    },
    {
      field: "email",
      renderHeader: () => <b>Email</b>,
      flex: 1,
      type: "string",
      headerAlign: "center",
    },
    {
      field: "mob_no",
      renderHeader: () => <b>Mobile Number</b>,
      flex: 1,
      type: "string",
      headerAlign: "center",
      align: "center",
    },
    {
      field: "role",
      renderHeader: () => <b>Role</b>,
      flex: 1,
      type: "string",
      valueFormatter: (params: GridValueFormatterParams<IRole>) =>
        params.value.role_name,
      headerAlign: "left",
      align: "left",
    },
    {
      field: "action",
      renderHeader: () => <b>Action</b>,
      flex: 1,
      type: "actions",
      renderCell: (params: GridRenderCellParams<IUserMaster>) => {
        return (
          <>
            <IconButtonField
              onClick={() => {
                setInitialValues({ ...params.row });
                setDialogOpen(true);
              }}
            >
              <SvgIcon sx={{ fill: "none" }} component={Edit} inheritViewBox />
            </IconButtonField>
            <IconButtonField
              onClick={() => {
                setDeleteData({ id: params.id, name: params.row.name });
                setDeleteDialogOpen(true);
              }}
            >
              <SvgIcon component={Delete} inheritViewBox />
            </IconButtonField>
          </>
        );
      },
    },
  ];

  const userMaster = Array.isArray(userMasterList?.data)
    ? userMasterList?.data.map((user, index) => ({ ...user, index: index + 1 }))
    : [];

  useEffect(() => {
    dispatch(listUserMaster());
  }, [addUserMaster, editUser, deleteUser, dispatch]);
  useEffect(() => {
    return () => {
      dispatch(clearAddUserMasterResponse());
      dispatch(clearEditUserMasterResponse());
      dispatch(clearDeleteUserMasterResponse());
    };
  }, [dispatch]);
  return (
    <>
      <Table rows={userMaster ?? []} columns={columns}>
        <CustomToolbar>
          <h6>User Master</h6>
          <TableSearch />
          <ButtonField
            className="tabel_add_new_button"
            onClick={() => setDialogOpen(true)}
          >
            Add new
          </ButtonField>
        </CustomToolbar>
      </Table>

      <Dialog
        open={dialogOpen}
        dialogClassName="dialog"
        Dialog_Title={`${
          (initialValues as IUserMaster).id ? "Update" : "Add"
        } User`}
        dialogContentClassName="dialog_content"
        Dialog_Content={
          <UserForm
            formRef={formRef}
            initialValues={initialValues}
            setInitialValues={setInitialValues}
          />
        }
        Dialog_Actions={
          <>
            <ButtonField
              className="form_cancel_button"
              onClick={() => setDialogOpen(false)}
            >
              Cancel
            </ButtonField>
            <ButtonField
              className="form_save_button"
              onClick={() => formRef.current?.requestSubmit()}
            >
              Save
            </ButtonField>
          </>
        }
      />
      <ResponseDialog response={addUserMaster} parentDialog={setDialogOpen} />
      <ResponseDialog response={editUser} parentDialog={setDialogOpen} />
      <DeleteDialog
        deleteableName={deleteDialogData.name ?? ""}
        open={deleteDialogOpen}
        setOpen={setDeleteDialogOpen}
        deleteFunction={() => {
          dispatch(deleteUserMaster(deleteDialogData.id));
        }}
        response={deleteUser}
      />
    </>
  );
};

export default UserMaster;
