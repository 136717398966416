import {
  FunctionComponent,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import "./StockRecieve.scss";
import {
  FormProvider,
  SubmitHandler,
  useFieldArray,
  useForm,
} from "react-hook-form";
import { IcreateStock, TStatus } from "./IStockRecieve";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Cancel";
import { z } from "zod";
import DatePickers from "../../Components/DatePicker/DatePicker";
import dayjs from "dayjs";
import TextInputFieldC from "../../Components/TextInput/TextInputFieldC";
import { AutocompleteOption } from "../../Components/Select/ISelectProps";
import Select from "../../Components/Select/AutoComplete";
import SelectWC from "../../Components/withoutControllerComponents/Select/Select";
import { useAppDispatch, useAppSelector } from "../../Redux/Store/Hooks";
import { zodResolver } from "@hookform/resolvers/zod";
import { FormHelperText, SvgIcon } from "@mui/material";
import ButtonField from "../../Components/Button/ButtonField";
import IconButtonField from "../../Components/Button/IconButtonField";
import {
  clearItemMaster,
  listItemMaster,
} from "../../Redux/Slices/ItemMaster/ListItemMasterSlice";
import {
  clearCreateStock,
  createStockStart,
} from "../../Redux/Slices/StockRecieve/CreateStockRecieve";
import { listLocationMaster } from "../../Redux/Slices/LocationMaster/ListLocationMasterSlice";
import Table from "../../Components/Table/Table";
import CustomToolbar from "../../Components/Table/components/CustomToolbar";
import { useLocation, useNavigate } from "react-router-dom";
import Dialog from "../../Components/Dialog/Dialog";
import ResponseDialog from "../../Components/ResponseDialog/ResponseDialog";
import CustomFooter from "../../Components/Table/components/CustomFooter";
import {
  GridActionsCellItem,
  GridCellParams,
  GridColDef,
  GridEventListener,
  GridRenderEditCellParams,
  GridRowEditStopReasons,
  GridRowId,
  GridRowModel,
  GridRowModes,
  GridRowModesModel,
  GridRowsProp,
} from "@mui/x-data-grid";
import { IDeleteDialog } from "../../Components/DeleteDialog/IDeleteDialog";
import { listLocationType } from "../../Redux/Slices/LocationType/ListLocationTypeSlice";
import { getItemTypeStart } from "../../Redux/Slices/GeneralSetting/ItemType/GetItemType";
import { getCodeStart } from "../../Redux/Slices/SequenceSlicer/GetCode";
import { getGenerateSerialNoStart } from "../../Redux/Slices/GeneralSetting/GenerateSerialNo/GetGenerateSerialNo";
import TableAggregation from "../../Components/Table/components/TableAggregation ";
import { totalItemQuantity } from "../../Utils/TableAggregations";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import React from "react";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import debounce from "lodash.debounce";
import api from "../../Redux/Axios/middleware";
import { generateUniqueId } from "../../Utils/UniqIDGenerater";
import Loading from "../../Components/Loading/Loading";
import {
  clearFindOneStockReceive,
  findOneStockReceiveStart,
} from "../../Redux/Slices/StockRecieve/FindOneStockReceive";
import {
  clearUpdateStock,
  UpdateStockReceiveStart,
} from "../../Redux/Slices/StockRecieve/UpadateStockRecieve";

const StockRecieveForm: FunctionComponent = () => {
  const dispatch = useAppDispatch();
  const [fieldOpen, setFieldOpen] = useState<boolean>(false);
  const [confirmationDialog, setConfirmationDialog] = useState<boolean>(false);
  const nav = useNavigate();
  const requestFormRef = useRef<HTMLFormElement>(null);
  const [receiveFromLocations, SetReceiveFromLocations] =
    useState<AutocompleteOption[]>();

  const { state: editId } = useLocation();
  console.log("UpdateId", editId);
  useEffect(() => {
    if (editId) dispatch(findOneStockReceiveStart(editId));
  }, [dispatch, editId]);

  // set custom serial no status
  const { data: customSerialNo } = useAppSelector(
    (state) => state.GetGenerateSerialNo
  );
  // for handle loading
  const customSerialNoStatus = useAppSelector(
    (state) => state.GetGenerateSerialNo
  );

  const { data: editStockReceiveValues } = useAppSelector(
    (state) => state.FindOneStockReceive
  );

  const [IsItemSerialNo, SetIsItemSerialNo] = useState<boolean>(false);

  const { data: createStockRecieve } = useAppSelector(
    (state) => state.CreateStockRecieve
  );
  const { data: updateStockReceive } = useAppSelector(
    (state) => state.UpdateStockReceive
  );

  const { data: Location } = useAppSelector(
    (state) => state.ListLocationMaster
  );

  const { data: locationTypeList } = useAppSelector(
    (state) => state.ListLocationType
  );
  const { data: ItemTypeList } = useAppSelector((state) => state.GetItemType);

  const { data: GetCode } = useAppSelector((state) => state.GetCode);

  const ItemTypeLists = Array.isArray(ItemTypeList?.data)
    ? ItemTypeList?.data.map((type) => ({
        label: type.name!,
        value: type.id!,
      }))
    : [];

  const LocationTypeLists = Array.isArray(locationTypeList?.data)
    ? locationTypeList?.data.map((type) => ({
        label: type.name!,
        value: type.id!,
      }))
    : [];

  const itemSchema = (IsItemSerialNo: boolean) =>
    z.object({
      id: z.number().optional(),
      item_id: z.number({ message: "Please Select Item" }),
      item_qnty: z
        .number({ message: "Please Enter Quantity" })
        .nonnegative({ message: "Quantity Must be Positive Number" }),
      base_uom: z
        .string({ message: "Please Enter Custom Serial No" })
        .nonempty({ message: "Please Enter Custom Serial No" }),
      cus_serial_no: IsItemSerialNo
        ? z
            .string({ message: "Please Enter Custom Serial No" })
            .nonempty({ message: "Please Enter Custom Serial No" })
        : z.string().optional(),
      item_code: z.string().optional(),
      item_name: z.string().optional(),
    });

  const stockRecieveSchema = (fieldOpen: boolean) =>
    z.object({
      entry_date: z.custom((val) => dayjs(val).isValid(), {
        message: "Please select the date",
      }),
      entry_no: z
        .string({ message: "Please Enter Entery Number" })
        .nonempty("Please Enter Entery Number"),
      grn_no: z
        .string({ message: "Please Enter GRN Number" })
        .nonempty("Please Enter GRN Number"),
      receive_type: z.number({ message: "Please Select Recieve Type" }),
      item_type: z.number({ message: "Please Select Item Type" }),
      receive_from: !fieldOpen
        ? z.number({ message: "Please Select Receive From " })
        : z.number().optional().nullable(),
      supplier_name: fieldOpen
        ? z
            .string({ message: "Please Enter Supplier Name" })
            .nonempty({ message: "Please Enter Supplier Name" })
        : z.string().optional(),
      item_details: z
        .array(itemSchema(IsItemSerialNo))
        .min(1, "Please Add The Items"),
    });

  const methods = useForm<IcreateStock>({
    defaultValues: {
      entry_date: dayjs(new Date()),
      entry_no: null,
      grn_no: null,
      receive_type: null,
      receive_from: null,
      location_id: null,
      item_type: null,
      item_details: [],
    },
    resolver: zodResolver(stockRecieveSchema(fieldOpen)),
  });

  // form submit
  const onSubmit: SubmitHandler<IcreateStock> = (val) => {
    if (editId) {
      const modifyVal = {
        ...methods.getValues(),
        item_details: methods.getValues("item_details")?.map((items) => ({
          item_id: items.item_id,
          item_code: items.item_code,
          item_qnty: items.item_qnty,
          base_uom: items.base_uom,
          cus_serial_no: items.cus_serial_no,
        })),
      };
      const updateData = {
        id: editId,
        data: { ...modifyVal, status: "BNG" },
      };
      console.log("UpdateData>>", updateData);
      dispatch(UpdateStockReceiveStart(updateData));
    } else {
      setConfirmationDialog(true);
      console.log(">>>>>>>>Val", val);
    }
  };

  const submitWithStatus = (status: TStatus) => {
    const val = {
      ...methods.getValues(),
      item_details: methods.getValues("item_details")?.map((items) => ({
        item_id: items.item_id,
        item_code: items.item_code,
        item_qnty: items.item_qnty,
        base_uom: items.base_uom,
        cus_serial_no: items.cus_serial_no,
      })),
      cus_status: IsItemSerialNo,
      status: status,
    };
    dispatch(createStockStart(val));
  };

  const { append, remove, update, prepend, replace } = useFieldArray({
    control: methods.control,
    name: "item_details",
  });

  const stockRecieveItemRow = methods
    .getValues("item_details")
    ?.map((row, index) => ({ ...row, index: index + 1, id: index }));

  // Inline Edit
  const [enterKey, setEnterKey] = useState<boolean>(true);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  // Disable cell editing unless explicitly enabled by the edit action
  const isCellEditable = (params: GridCellParams) => {
    return rowModesModel[params.id]?.mode === GridRowModes.Edit;
  };
  const fetchOptions = async (query: string): Promise<AutocompleteOption[]> => {
    const response = await api.get("/item-master", {
      params: {
        searchkey: query, // Include the search query in the API request
        item_type: methods.getValues("item_type") === 1 ? "RM" : "FG",
      },
    });
    const data = await response?.data?.data?.listitem_master;
    setEnterKey(true);

    return data?.map((item: any) => ({
      label: item.item_name,
      value: item.id,
    }));
  };
  const [options, setOptions] = useState<AutocompleteOption[]>([]);
  const [inputValue, setInputValue] = useState("");

  const handleInputChange = (event: React.ChangeEvent<{}>, value: string) => {
    setEnterKey(true);
    setInputValue(value);
  };
  const debouncedFetchOptions = debounce(async (query: string) => {
    if (query.length >= 3) {
      // Optional: Set a minimum character length for the search
      const results = await fetchOptions(query);
      setOptions(results);
    }
  }, 100); // Adjust the debounce time as needed

  useEffect(() => {
    if (inputValue && enterKey) {
      debouncedFetchOptions(inputValue);
    } else {
      setOptions([]);
    }
  }, [inputValue]);
  const { data: items } = useAppSelector((state) => state.ListItemMaster);
  const item_list = Array.isArray(items?.data)
    ? items?.data.map((item) => ({
        label: item.item_name!,
        value: item.id!,
      }))
    : [];
  const [rows, setRows] = React.useState<GridRowsProp>([]);
  const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>(
    {}
  );

  const handleRowEditStop: GridEventListener<"rowEditStop"> = (
    params,
    event
  ) => {
    if (
      params.reason === GridRowEditStopReasons.rowFocusOut ||
      params.reason === GridRowEditStopReasons.enterKeyDown
    ) {
      setEnterKey(false);

      event.defaultMuiPrevented = true;
    }
  };
  const handleEditClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
    setIsEdit(true);
  };

  const handleSaveClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });

    // replace([...rows]);
    if (!isEdit) {
      handleAddClick();
    }
    setIsEdit(false);
  };

  const handleDeleteClick = (id: GridRowId) => () => {
    setRows(rows.filter((row) => row.id !== id));
  };
  const handleCancelClick = (id: GridRowId) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = rows.find((row) => row.id === id);
    if (editedRow!.isNew) {
      setRows(rows.filter((row) => row.id !== id));
    }
  };
  const processRowUpdate = (newRow: GridRowModel) => {
    const updatedRow = { ...newRow, isNew: false };
    setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
    return updatedRow;
  };

  const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };
  const handleAddClick = () => {
    const id = generateUniqueId();
    setRows((oldRows) => [
      ...oldRows,
      {
        id,
        item_id: "",
        item_code: "",
        cus_serial_no: "",
        base_uom: "",
        item_qnty: null,
        isNew: true,
      },
    ]);
    setRowModesModel((oldModel) => ({
      ...oldModel,
      [id]: { mode: GridRowModes.Edit, fieldToFocus: "item_id" },
    }));
  };
  const list_items_row = rows.map((item, i) => ({
    ...item,
    index: i + 1,
  }));
  useEffect(() => {
    replace([]);
    if (rows.length > 0) {
      const checkValidArray = rows.filter(
        (items) =>
          items.item_id !== null &&
          items.item_qnty !== null &&
          items.cus_serial_no !== null &&
          items.cus_serial_no !== ""
      );
      console.log("Valid Arrays", checkValidArray);

      replace([...checkValidArray]);
    }
  }, [rows]);
  useLayoutEffect(() => {
    dispatch(listLocationMaster());
    dispatch(listLocationType());
    dispatch(getItemTypeStart("IT"));
    dispatch(clearItemMaster());
    if (!editId) {
      dispatch(getCodeStart("Receive"));
      dispatch(getGenerateSerialNoStart());
    }
    // Unmount functions
    return () => {
      // props.setInitialValues({});
      if (editId) {
        dispatch(clearUpdateStock());
        dispatch(clearFindOneStockReceive());
      } else {
        dispatch(clearCreateStock());
      }
    };
  }, [dispatch]);

  const [initialItemField, setInitialItemField] = useState<boolean>(false);
  useEffect(() => {
    if (methods.watch("grn_no") === "" && !IsItemSerialNo && rows.length > 0) {
      setRows([]);
    }
    const statusAddItem = () => {
      if (!IsItemSerialNo) {
        if (!!methods.getValues("grn_no") && !!methods.getValues("item_type")) {
          return true;
        }
      } else if (!!methods.getValues("item_type")) {
        return true;
      }
      return false;
    };
    setInitialItemField(statusAddItem);
  }, [
    methods.getValues("item_type"),
    methods.getValues("grn_no"),
    methods.watch("grn_no"),
    IsItemSerialNo,
  ]);

  useEffect(() => {
    if (initialItemField && methods.getValues("item_type")) {
      handleAddClick();
    }
  }, [initialItemField, methods.getValues("item_type")]);
  useLayoutEffect(() => {
    if (editId) {
      SetIsItemSerialNo(editStockReceiveValues?.data?.cus_status!);
    } else {
      SetIsItemSerialNo(customSerialNo?.data?.cus_serial_no!);
    }
  }, [customSerialNo, editId, editStockReceiveValues]);

  useEffect(() => {
    methods.setValue("entry_no", GetCode?.data);
  }, [GetCode?.data, methods]);
  useEffect(() => {
    if (editId && methods.getValues("item_type")) {
      dispatch(
        listItemMaster({
          item_type: methods.getValues("item_type") === 1 ? "RM" : "FG",
        })
      );
    }
    if (editId && methods.getValues("receive_type")) {
      const recieveFromOptionsFilter = Location?.data?.filter(
        (locations) =>
          locations.location_type_id === methods.getValues("receive_type")
      );
      const locationList = Array.isArray(recieveFromOptionsFilter)
        ? recieveFromOptionsFilter?.map((type) => ({
            label: type.name!,
            value: type.id!,
          }))
        : [];
      SetReceiveFromLocations(locationList);
    }
  }, [editId, methods.getValues("item_type")]);
  useEffect(() => {
    if (editId) {
      methods.reset({ ...editStockReceiveValues?.data, entry_date: dayjs() });
      const updateItemList = editStockReceiveValues?.data?.item_details?.map(
        (item, index) => ({
          ...item,
          id: index,
          s_no: index + 1,
          // base_uom:item?.items?.base_uom
        })
      );
      if (Array.isArray(updateItemList)) {
        setRows([...updateItemList]);
        replace(updateItemList);
      }
    }
  }, [editStockReceiveValues, editId]);
  console.log("Form Errors", methods.formState.errors);

  const columnsb: GridColDef[] = [
    {
      field: "index",
      headerName: "S.No",
      renderHeader: () => <b>S.No</b>,
      type: "number",
      flex: 0.1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "item_id",
      renderHeader: () => <b>Item Name</b>,
      flex: 0.8,
      editable: true,
      type: "singleSelect",
      valueOptions: item_list,
      renderEditCell: (params: GridRenderEditCellParams) => {
        return (
          <SelectWC
            placeholder="Search Items"
            name="item_id"
            filterOptions={(options) => options}
            sx={{ width: "100%" }}
            options={options}
            onInputChange={handleInputChange}
            value={
              item_list?.find((item) => item.value === params.value)?.label
            }
            onChange={(event, newValue) => {
              if (newValue && typeof newValue !== "string") {
                const getValue: AutocompleteOption =
                  newValue as AutocompleteOption;
                params.api.setEditCellValue({
                  id: params.id,
                  field: params.field,
                  value: getValue.value,
                });
                // Set the item code based on the selected item
                const itemDetail = items?.data?.find(
                  (item) => item.id === getValue.value
                )?.item_code;
                if (itemDetail) {
                  // Update the item_code field

                  setRows((prevRows) =>
                    prevRows.map((row) =>
                      row.id === params.id
                        ? {
                            ...row,
                            item_id: getValue.value,
                            item_code:
                              items?.data?.find(
                                (item) => item.id === getValue.value
                              )?.item_code || "",
                            cus_serial_no: `${methods.getValues("grn_no")}-${
                              items?.data?.find(
                                (item) => item.id === getValue.value
                              )?.item_code
                            }`,
                            base_uom: items?.data?.find(
                              (item) => item.id === getValue.value
                            )?.base_uom,
                          }
                        : row
                    )
                  );
                }
              } else {
                setRows((prevRows) =>
                  prevRows.map((row) =>
                    row.id === params.id
                      ? {
                          ...row,
                          item_code: "",
                          cus_serial_no: "",
                        }
                      : row
                  )
                );
              }
            }}
          />
        );
      },
    },
    {
      field: "item_code",
      renderHeader: () => <b>Item Code</b>,
      type: "string",
      flex: 0.7,
      editable: false,
    },
    {
      field: "cus_serial_no",
      renderHeader: () => <b>Custom S.No</b>,
      type: "string",
      flex: 0.8,
      align: "left",
      headerAlign: "left",
      editable: IsItemSerialNo,
    },
    {
      field: "base_uom",
      renderHeader: () => <b>UOM</b>,
      type: "string",
      flex: 0.4,
      align: "left",
      headerAlign: "left",
      editable: false,
    },
    {
      field: "item_qnty",
      renderHeader: () => <b>Item Quantity</b>,
      type: "number",
      flex: 0.4,
      align: "right",
      headerAlign: "right",
      editable: true,
    },

    {
      field: "actions",
      type: "actions",
      renderHeader: () => <b>Actions</b>,
      width: 100,
      cellClassName: "actions",
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"
              sx={{ color: "primary.main" }}
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
              disabled={rows.length === 1 ? true : false}
            />,
          ];
        }

        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(id)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            onClick={handleDeleteClick(id)}
            color="inherit"
          />,
        ];
      },
    },
  ];
  if (customSerialNoStatus.isLoading && !editId) {
    return (
      <>
        <Loading isLoading />
      </>
    );
  } else {
    return (
      <>
        <FormProvider {...methods}>
          <Table
            columns={columnsb ?? []}
            rows={list_items_row ?? []}
            rowModesModel={rowModesModel}
            onRowModesModelChange={handleRowModesModelChange}
            onRowEditStop={handleRowEditStop}
            processRowUpdate={processRowUpdate}
            editMode="row"
            initialPageSize={100}
            isCellEditable={isCellEditable}
          >
            <CustomToolbar className="stock_recieve_toolbar">
              <h6 style={{ width: "100%" }}>
                {editId ? "Edit Stock Recieve" : "Add Stock Recieve"}
              </h6>
              <form
                autoComplete="off"
                ref={requestFormRef}
                onSubmit={methods.handleSubmit(onSubmit)}
                className="Stock_receive_form"
              >
                <div className="start_fields">
                  <Select
                    tabIndex={1}
                    size="small"
                    // sx={{ width: "100%" }}
                    name="item_type"
                    label="Item Type"
                    options={ItemTypeLists}
                    value={
                      ItemTypeLists?.find(
                        (items) =>
                          items.value === methods.getValues("item_type")
                      ) ?? null
                    }
                    onChange={(_, value) => {
                      const val = value as AutocompleteOption;
                      remove();
                      setRows([]);
                      if (val) {
                        dispatch(
                          listItemMaster({
                            item_type: val.value === 1 ? "RM" : "FG",
                          })
                        );
                      } else {
                      }
                      methods.setValue("item_type", val?.value, {
                        shouldValidate: true,
                      });
                    }}
                  />
                  <TextInputFieldC
                    tabIndex={2}
                    size="small"
                    disabled={
                      (!IsItemSerialNo && rows.length > 1) ||
                      (!IsItemSerialNo && rows[0]?.cus_serial_no?.length! > 1)
                    }
                    // sx={{ width: "100%" }}
                    name="grn_no"
                    label="GRN No"
                  />

                  <Select
                    tabIndex={3}
                    size="small"
                    // sx={{ width: "100%" }}
                    name="receive_type"
                    label="Receive Type"
                    options={LocationTypeLists}
                    value={
                      LocationTypeLists?.find(
                        (RType) =>
                          RType.value === methods.getValues("receive_type")
                      ) ?? null
                    }
                    onChange={(_, value) => {
                      const val = value as AutocompleteOption;
                      if (val) {
                        const recieveFromOptionsFilter = Location?.data?.filter(
                          (locations) =>
                            locations.location_type_id === val.value
                        );
                        const locationList = Array.isArray(
                          recieveFromOptionsFilter
                        )
                          ? recieveFromOptionsFilter?.map((type) => ({
                              label: type.name!,
                              value: type.id!,
                            }))
                          : [];
                        SetReceiveFromLocations(locationList);
                        methods.setValue("receive_from", null, {
                          shouldValidate: true,
                        });
                        if (val.label === "Supplier") {
                          setFieldOpen(true);
                          methods.setValue("receive_from", null, {
                            shouldValidate: true,
                          });
                        } else {
                          setFieldOpen(false);
                          methods.setValue("supplier_name", "");
                        }
                      }
                      methods.setValue("receive_type", val?.value, {
                        shouldValidate: true,
                      });
                    }}
                  />
                  <Select
                    tabIndex={fieldOpen ? -1 : 4}
                    size="small"
                    sx={{
                      // width: "100%",
                      display: `${fieldOpen ? "none" : "flex"}`,
                    }}
                    name="receive_from"
                    label="Receive From"
                    disabled={methods.watch("receive_type") ? false : true}
                    options={receiveFromLocations!}
                    value={
                      receiveFromLocations?.find(
                        (RFrom) =>
                          RFrom.value === methods.getValues("receive_from")
                      ) ?? null
                    }
                    onChange={(_, value) => {
                      const val = value as AutocompleteOption;
                      methods.setValue("receive_from", val?.value, {
                        shouldValidate: true,
                      });
                    }}
                  />
                  <TextInputFieldC
                    tabIndex={fieldOpen ? 4 : -1}
                    size="small"
                    sx={{
                      // width: "100%",
                      display: `${fieldOpen ? "flex" : "none"}`,
                    }}
                    name="supplier_name"
                    label="Supplier Name"
                  />
                </div>
                <div className="end_fields">
                  <DatePickers
                    size="small"
                    // sx={{ width: "100%" }}
                    disabled
                    name="entry_date"
                    label="Entry Date"
                    // maxDate={methods.getValues().entry_date}
                    onChange={() => methods.trigger("entry_date")}
                  />
                  <TextInputFieldC
                    size="small"
                    disabled
                    // sx={{ width: "100%" }}
                    name="entry_no"
                    label="Entry No"
                  />
                </div>
              </form>
              <div
                className={`${
                  !initialItemField
                    ? "add_new_disabled"
                    : "add_new_stock_item_btn"
                }`}
              >
                <ButtonField
                  disabled={!initialItemField}
                  className="tabel_add_new_button"
                  onClick={handleAddClick}
                >
                  Add Item
                </ButtonField>

                {methods.formState.errors.item_details && (
                  <FormHelperText error className="add_item_error">
                    {methods.formState.errors.item_details.message}
                  </FormHelperText>
                )}
              </div>
            </CustomToolbar>
            <CustomFooter className="item_footer">
              <TableAggregation
                name="Total Item Quantities :"
                value={totalItemQuantity(stockRecieveItemRow!)}
              />
              <div className="button_navigations">
                <ButtonField
                  className="form_cancel_button"
                  onClick={() => nav(-1)}
                >
                  Cancel
                </ButtonField>
                <ButtonField
                  className="form_save_button"
                  onClick={() => requestFormRef.current?.requestSubmit()}
                >
                  {editId ? "Binning" : "Save"}
                </ButtonField>
              </div>
              {/* <TablePaginations /> */}
            </CustomFooter>
          </Table>
        </FormProvider>
        <>
          {/* confirmation dialog */}
          <Dialog
            open={confirmationDialog}
            Dialog_Title={
              <>
                Confirm
                <IconButtonField
                  onClick={() => {
                    setConfirmationDialog(false);
                  }}
                >
                  <SvgIcon
                    component={CloseOutlinedIcon}
                    inheritViewBox
                    sx={{ height: "20px", width: "20px" }}
                  />
                </IconButtonField>
              </>
            }
            Dialog_Content={
              <>
                Are you sure you want to proceed? <br /> Please confirm if you
                want to <b>Binning</b> or <b>Save as Draft</b> the items.
              </>
            }
            Dialog_Actions={
              <>
                <ButtonField
                  className="form_cancel_button"
                  onClick={() => {
                    setConfirmationDialog(false);
                    submitWithStatus("PND");
                  }}
                >
                  Draft
                </ButtonField>
                <ButtonField
                  className="form_save_button"
                  onClick={() => {
                    setConfirmationDialog(false);
                    submitWithStatus("BNG");
                  }}
                >
                  Binning
                </ButtonField>
              </>
            }
          />
          {/* Create Stock Response */}
          <ResponseDialog
            response={createStockRecieve}
            okFunction={() => nav(-1)}
          />
          {/* Update Stock Response */}
          <ResponseDialog
            response={updateStockReceive}
            okFunction={() => {
              nav(-1);
            }}
          />
        </>
      </>
    );
  }
};

export default StockRecieveForm;
