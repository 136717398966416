import { createSlice } from "@reduxjs/toolkit";
import { IReduxState } from "../../../interfaces/IReduxState";
import { IItemTypeList } from "./IItemType";

export const GetItemType = createSlice({
  name: "GetItemType",
  initialState: {} as IReduxState<IItemTypeList[]>,
  reducers: {
    getItemTypeStart: (state, _) => {
      state.isLoading = true;
    },
    successGetItemType: (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    },
    failureGetItemType: (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    },
  },
});

export const { failureGetItemType, getItemTypeStart, successGetItemType } =
  GetItemType.actions;
export default GetItemType.reducer;
