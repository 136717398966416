import React, { useEffect } from "react";
import UserPermission from "../../Components/UserPermission/UserPermission";
import "./UserPermissions.scss";

import { useAppDispatch, useAppSelector } from "../../Redux/Store/Hooks";
import { userPermissionRowsList } from "../../Redux/Slices/UserPermission/UserPermissionRowsSlicer";
import { listRoleSlice } from "../../Redux/Slices/Role/ListRoleSlice";
import { useSearchParams } from "react-router-dom";

const UserPermissions: React.FC = () => {
  const dispatch = useAppDispatch();
  const permissionData = useAppSelector((state) => state.RoleSlice);
  const userPermissionRowsData = useAppSelector(
    (state) => state.UserPermissionRows
  );
  const [searchParams] = useSearchParams();

  useEffect(() => {
    dispatch(listRoleSlice());
    dispatch(userPermissionRowsList(searchParams.get("id")));
  }, [dispatch, searchParams]);

  const filteredRole = permissionData.data?.data?.map((role) => ({
    value: role.id,
    label: role.role_name,
  }));

  const rows = userPermissionRowsData.data?.data?.map((row) => ({
    ...row,
    add: row.add ?? false,
    edit: row.edit ?? false,
    delete: row.delete ?? false,
    view: row.view ?? false,
  }));

  return (
    <>
      {/* <Snackbar response={userPermissionRowsData.data} /> */}
      <UserPermission
        rows={rows ?? []}
        RoleOptions={filteredRole ?? []}
        getRoleValues={(val) => console.log(val)}
        getRoleOption={(val) => {
          dispatch(userPermissionRowsList(val?.value));
        }}
      />
    </>
  );
};

export default UserPermissions;
