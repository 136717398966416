import { createSlice } from "@reduxjs/toolkit";
import { IReduxState } from "../../interfaces/IReduxState";
import { IGRNNo } from "../../../Pages/StorageAreaDashboard/IStorageAreaDashboard";

export const GRNNoSlice = createSlice({
  name: "ListGRNNo",
  initialState: {} as IReduxState<IGRNNo[]>,
  reducers: {
    listGRNNo: (state) => {
      state.isLoading = true;
    },
    successGRNNo: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
    failureGRNNo: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
    clearGRNNo: (state) => {
      state.data = {};
    },
  },
});

export const { failureGRNNo, listGRNNo, successGRNNo, clearGRNNo } =
  GRNNoSlice.actions;

export default GRNNoSlice.reducer;
