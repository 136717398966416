import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IReduxState } from "../../interfaces/IReduxState";
import { ILocationReceive } from "../../../Pages/LocationReceive/ILocationReceive";

export const EditLocationReceiveSlice = createSlice({
  name: "EditLocationReceive",
  initialState: {} as IReduxState<ILocationReceive>,
  reducers: {
    editLocationReceive: (state, _: PayloadAction<ILocationReceive>) => {
      state.isLoading = true;
    },
    successeditLocationReceive: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
    failureeditLocationReceive: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
    clearEditLocationReceiveResponse: (state) => {
      state.data = {};
    },
  },
});

export const {
  editLocationReceive,
  failureeditLocationReceive,
  successeditLocationReceive,
  clearEditLocationReceiveResponse,
} = EditLocationReceiveSlice.actions;

export default EditLocationReceiveSlice.reducer;
