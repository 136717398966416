import "./TransactionRequest.scss";
import {
  FunctionComponent,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { IItemDetails, ITransactionRequest } from "./ITransactionRequest";
import Table from "../../Components/Table/Table";
import CustomToolbar from "../../Components/Table/components/CustomToolbar";
import CustomFooter from "../../Components/Table/components/CustomFooter";
import ButtonField from "../../Components/Button/ButtonField";
import { IDeleteDialog } from "../../Components/DeleteDialog/IDeleteDialog";
import { useAppDispatch, useAppSelector } from "../../Redux/Store/Hooks";
import Dialog from "../../Components/Dialog/Dialog";
import ResponseDialog from "../../Components/ResponseDialog/ResponseDialog";
import { useLocation, useNavigate } from "react-router-dom";
import { Columns } from "../../Components/Table/ITable";
import {
  GridActionsCellItem,
  GridCellParams,
  GridEventListener,
  GridRenderEditCellParams,
  GridRowEditStopReasons,
  GridRowId,
  GridRowModel,
  GridRowModes,
  GridRowModesModel,
  GridRowsProp,
} from "@mui/x-data-grid";
import { FormHelperText } from "@mui/material";
import ItemForm from "./ItemForm";
import {
  FormProvider,
  SubmitHandler,
  useFieldArray,
  useForm,
} from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import TextInputFieldC from "../../Components/TextInput/TextInputFieldC";
import DeleteDialog from "../../Components/DeleteDialog/DeleteDialog";
import DatePickers from "../../Components/DatePicker/DatePicker";
import { listLocationMaster } from "../../Redux/Slices/LocationMaster/ListLocationMasterSlice";
import Select from "../../Components/Select/AutoComplete";
import { AutocompleteOption } from "../../Components/Select/ISelectProps";
import dayjs from "dayjs";
import {
  addTransactionRequest,
  clearAddTransactionRequestResponse,
} from "../../Redux/Slices/TransactionRequest/AddTransactionRequestSlice";
import {
  clearGetoneTransactionRequest,
  getoneTransactionRequest,
} from "../../Redux/Slices/TransactionRequest/GetOneTransactionRequestSlice";
import {
  clearEditTransactionRequestResponse,
  editTransactionRequest,
} from "../../Redux/Slices/TransactionRequest/EditTransactionRequestSlice";
import {
  clearGetCode,
  getCodeStart,
} from "../../Redux/Slices/SequenceSlicer/GetCode";
import TableAggregation from "../../Components/Table/components/TableAggregation ";
import { totalItemQuantity } from "../../Utils/TableAggregations";
import SelectWC from "../../Components/withoutControllerComponents/Select/Select";
import debounce from "lodash.debounce";
import api from "../../Redux/Axios/middleware";
import { generateUniqueId } from "../../Utils/UniqIDGenerater";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Cancel";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { listItemMaster } from "../../Redux/Slices/ItemMaster/ListItemMasterSlice";

interface TransactionRequestFormProps {}

const TransactionRequestForm: FunctionComponent<
  TransactionRequestFormProps
> = () => {
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState<boolean>(false);
  const [deleteDialogData, setDeleteData] = useState<IDeleteDialog>({});
  const [itemValue, setItemValue] = useState<IItemDetails>({});

  const dispatch = useAppDispatch();
  const { state: requestUpdateId, pathname } = useLocation();
  const nav = useNavigate();
  const formRef = useRef<HTMLFormElement>(null);
  const requestFormRef = useRef<HTMLFormElement>(null);

  const { data: locationsList } = useAppSelector(
    (state) => state.ListLocationMaster
  );
  const { data: transaction } = useAppSelector(
    (state) => state.GetoneTransactionRequest
  );
  const { data: addTransaction } = useAppSelector(
    (state) => state.AddTransactionRequest
  );
  const { data: editTransaction } = useAppSelector(
    (state) => state.EditTransactionRequest
  );
  const { data: entryCode } = useAppSelector((state) => state.GetCode);

  // Inline Edit
  const [enterKey, setEnterKey] = useState<boolean>(true);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const isCellEditable = (params: GridCellParams) => {
    return rowModesModel[params.id]?.mode === GridRowModes.Edit;
  };
  const fetchOptions = async (query: string): Promise<AutocompleteOption[]> => {
    const response = await api.get("/item-master", {
      params: {
        searchkey: query, // Include the search query in the API request
      },
    });
    const data = await response?.data?.data?.listitem_master;
    setEnterKey(true);

    return data?.map((item: any) => ({
      label: item.item_name,
      value: item.id,
    }));
  };
  const [options, setOptions] = useState<AutocompleteOption[]>([]);
  const [inputValue, setInputValue] = useState("");

  const { data: items } = useAppSelector((state) => state.ListItemMaster);

  const item_list = Array.isArray(items?.data)
    ? items?.data.map((item) => ({
        label: item.item_name!,
        value: item.id!,
      }))
    : [];

  const handleInputChange = (event: React.ChangeEvent<{}>, value: string) => {
    setEnterKey(true);
    setInputValue(value);
  };
  const debouncedFetchOptions = debounce(async (query: string) => {
    if (query.length >= 3) {
      // Optional: Set a minimum character length for the search
      const results = await fetchOptions(query);
      setOptions(results);
    }
  }, 100); // Adjust the debounce time as needed
  useEffect(() => {
    if (inputValue && enterKey) {
      debouncedFetchOptions(inputValue);
    } else {
      setOptions([]);
    }
  }, [inputValue]);
  const [rows, setRows] = useState<GridRowsProp>([]);
  const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});
  // Inline Edit Functions
  const handleRowEditStop: GridEventListener<"rowEditStop"> = (
    params,
    event
  ) => {
    if (
      params.reason === GridRowEditStopReasons.rowFocusOut ||
      params.reason === GridRowEditStopReasons.enterKeyDown
    ) {
      setEnterKey(false);

      event.defaultMuiPrevented = true;
    }
  };
  const handleEditClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
    setIsEdit(true);
  };
  const handleSaveClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });

    // replace([...rows]);
    if (!isEdit) {
      handleAddClick();
    }
    setIsEdit(false);
  };
  const handleDeleteClick = (id: GridRowId) => () => {
    setRows(rows.filter((row) => row.id !== id));
  };
  const handleCancelClick = (id: GridRowId) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = rows.find((row) => row.id === id);
    if (editedRow!.isNew) {
      setRows(rows.filter((row) => row.id !== id));
    }
  };
  const processRowUpdate = (newRow: GridRowModel) => {
    const updatedRow = { ...newRow, isNew: false };
    setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
    return updatedRow;
  };
  const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };
  const handleAddClick = () => {
    const id = generateUniqueId();
    setRows((oldRows) => [
      ...oldRows,
      {
        id,
        item_id: "",
        item_code: "",
        item_qnty: null,
        isNew: true,
      },
    ]);
    setRowModesModel((oldModel) => ({
      ...oldModel,
      [id]: { mode: GridRowModes.Edit, fieldToFocus: "item_id" },
    }));
  };
  useEffect(() => {
    replace([]);
    if (rows.length > 0) {
      const checkValidArray = rows.filter(
        (items) =>
          items.item_id !== null &&
          items.item_qnty !== null &&
          items.cus_serial_no !== null &&
          items.cus_serial_no !== ""
      );
      console.log("Valid Arrays", checkValidArray);

      replace([...checkValidArray]);
    }
  }, [rows]);

  // item schema
  const itemSchema = z.object({
    id: z.number().optional(),
    item_id: z.number({ message: "Please Select Item" }),
    item_qnty: z.number({ message: "Please enter Quantity" }),
    item_code: z.string().optional(),
    items: z.any().optional(),
  });

  // form schema
  const transactionRequestSchema = z.object({
    entry_no: z
      .string({ message: "Please enter the Entry No" })
      .min(1, "Please enter the Entry No"),
    entry_date: z.custom((val) => val && dayjs(val).isValid(), {
      message: "Please select the date",
    }),
    request_from: z.number({
      message: "Please select the Request from location",
    }),
    request_to: z.number({ message: "Please select the Request to location" }),

    item_details: z.array(itemSchema).min(1, "Please Add the items"),
  });

  // form init
  const methods = useForm<ITransactionRequest>({
    defaultValues: {
      entry_date: dayjs(),
      request_from: Number(localStorage.getItem("location_id")),
    },
    resolver: zodResolver(transactionRequestSchema),
  });

  // form submit
  const onSubmit: SubmitHandler<ITransactionRequest> = (val) => {
    const processedValue = {
      ...methods.getValues(),
      item_details: methods.getValues("item_details")?.map((items) => ({
        item_id: items.item_id,
        item_code: items.item_code,
        item_qnty: items.item_qnty,
      })),
    };
    if (requestUpdateId) {
      dispatch(
        editTransactionRequest({ ...processedValue, id: requestUpdateId })
      );
    } else {
      dispatch(addTransactionRequest({ ...processedValue, status: "PND" }));
    }
  };

  // array field in the from
  const { append, remove, update, replace } = useFieldArray({
    control: methods.control,
    name: "item_details",
  });

  // table rows
  const tableRows = methods
    .getValues("item_details")
    ?.map((row, index) => ({ ...row, index: index + 1, id: index }));

  useEffect(() => {
    dispatch(listItemMaster({}));
    dispatch(listLocationMaster());

    if (requestUpdateId) {
      dispatch(getoneTransactionRequest(requestUpdateId));
    } else {
      handleAddClick();
    }
    !requestUpdateId && dispatch(getCodeStart("Request"));
    return () => {
      methods.reset({});
      dispatch(clearGetCode());
      dispatch(clearGetoneTransactionRequest());
      dispatch(clearAddTransactionRequestResponse());
      dispatch(clearEditTransactionRequestResponse());
    };
  }, [requestUpdateId, dispatch, methods]);

  useLayoutEffect(() => {
    if (requestUpdateId) {
      methods.reset({
        entry_date: dayjs(),
        request_from: transaction?.data?.request_from,
        request_to: transaction?.data?.request_to,
        entry_no: transaction?.data?.entry_no,
        // entry_date: dayjs(transaction?.data?.entry_date),
      });
      const updateItemList = transaction?.data?.item_details?.map(
        (item, index) => ({
          ...item,
          id: index,
          s_no: index + 1,
          // base_uom:item?.items?.base_uom
        })
      );
      if (Array.isArray(updateItemList)) {
        setRows([...updateItemList]);
        replace(updateItemList);
      }
      handleAddClick();
    }
    if (entryCode?.data && !requestUpdateId) {
      methods.setValue("entry_no", entryCode.data);
    }
  }, [transaction?.data, requestUpdateId, entryCode?.data, methods]);

  // location for dropdown
  const locations = Array.isArray(locationsList?.data)
    ? locationsList?.data.map((item) => ({ label: item.name!, value: item.id }))
    : [];

  // only show warehouse for requesting
  const warehouseLocation =
    locationsList?.data
      ?.filter((warehouse) => warehouse.location_type === "Warehouse")
      .map((item) => ({ label: item.name!, value: item.id })) ?? [];

  const list_items_row = rows.map((item, i) => ({
    ...item,
    index: i + 1,
  }));

  const columns: Columns = [
    {
      field: "index",
      renderHeader: () => <b>S.No</b>,
      align: "center",
      headerAlign: "center",
      width: 90,
    },
    {
      field: "item_id",
      renderHeader: () => <b>Item Name</b>,
      flex: 0.8,
      editable: true,
      type: "singleSelect",
      valueOptions: item_list,
      renderEditCell: (params: GridRenderEditCellParams) => {
        return (
          <SelectWC
            placeholder="Search Items"
            name="item_id"
            filterOptions={(options) => options}
            sx={{ width: "100%" }}
            options={options}
            onInputChange={handleInputChange}
            value={
              item_list?.find((item) => item.value === params.value)?.label
            }
            onChange={(event, newValue) => {
              if (newValue && typeof newValue !== "string") {
                const getValue: AutocompleteOption =
                  newValue as AutocompleteOption;
                params.api.setEditCellValue({
                  id: params.id,
                  field: params.field,
                  value: getValue.value,
                });
                // Set the item code based on the selected item
                const itemDetail = items?.data?.find(
                  (item) => item.id === getValue.value
                )?.item_code;
                if (itemDetail) {
                  // Update the item_code field
                  setRows((prevRows) =>
                    prevRows.map((row) =>
                      row.id === params.id
                        ? {
                            ...row,
                            item_id: getValue.value,
                            item_code:
                              items?.data?.find(
                                (item) => item.id === getValue.value
                              )?.item_code || "",
                            base_uom: items?.data?.find(
                              (item) => item.id === getValue.value
                            )?.base_uom,
                          }
                        : row
                    )
                  );
                }
              } else {
                setRows((prevRows) =>
                  prevRows.map((row) =>
                    row.id === params.id
                      ? {
                          ...row,
                          item_code: "",
                          cus_serial_no: "",
                        }
                      : row
                  )
                );
              }
            }}
          />
        );
      },
    },
    {
      field: "item_code",
      renderHeader: () => <b>Item Code</b>,
      flex: 1,
      align: "left",
      headerAlign: "left",
      type: "string",
      editable: false,
    },
    {
      field: "item_qnty",
      renderHeader: () => <b>Item Quantity</b>,
      flex: 1,
      headerAlign: "right",
      align: "right",
      type: "number",
      editable: true,
    },
    {
      field: "actions",
      type: "actions",
      renderHeader: () => <b>Actions</b>,
      width: 100,
      cellClassName: "actions",
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"
              sx={{ color: "primary.main" }}
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
              disabled={rows.length === 1 ? true : false}
            />,
          ];
        }

        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(id)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            onClick={handleDeleteClick(id)}
            color="inherit"
          />,
        ];
      },
    },
  ];

  return (
    <>
      <FormProvider {...methods}>
        <Table
          rows={list_items_row ?? []}
          columns={columns}
          initialPageSize={100}
          rowModesModel={rowModesModel}
          onRowModesModelChange={handleRowModesModelChange}
          onRowEditStop={handleRowEditStop}
          processRowUpdate={processRowUpdate}
          editMode="row"
          isCellEditable={isCellEditable}
        >
          <CustomToolbar>
            <h6>
              {pathname === "/transaction/request/add"
                ? "Add Stock Request"
                : "Edit Stock Request"}
            </h6>
            <form
              ref={requestFormRef}
              onSubmit={methods.handleSubmit(onSubmit)}
              className="new_ui_form_single"
            >
              <div className="form_field_first_line">
                <div className="form_start_fields">
                  <Select
                    size="small"
                    sx={{ width: "100%" }}
                    options={locations ?? []}
                    name="request_from"
                    label="Request From"
                    disabled
                    value={
                      locations?.find(
                        (location) =>
                          location.value === methods.getValues("request_from")
                      ) ?? null
                    }
                    onChange={(_, val) => {
                      methods.setValue(
                        "request_from",
                        (val as AutocompleteOption)?.value ?? null,
                        { shouldValidate: true }
                      );
                    }}
                  />
                </div>
                <div className="form_end_fields">
                  <DatePickers
                    size="small"
                    sx={{ width: "100%" }}
                    name="entry_date"
                    label="Entry Date"
                    disabled
                  />
                </div>
              </div>
              <div className="form_field_second_line">
                <div className="form_start_fields">
                  <Select
                    size="small"
                    sx={{ width: "100%" }}
                    options={warehouseLocation ?? []}
                    name="request_to"
                    label="Request To"
                    value={
                      warehouseLocation?.find(
                        (location) =>
                          location.value === methods.getValues("request_to")
                      ) ?? null
                    }
                    onChange={(_, val) => {
                      methods.setValue(
                        "request_to",
                        (val as AutocompleteOption)?.value ?? null,
                        { shouldValidate: true }
                      );
                    }}
                  />
                </div>
                <div className="form_end_fields">
                  <TextInputFieldC
                    sx={{ width: "100%" }}
                    size="small"
                    name="entry_no"
                    label="Entry No"
                    disabled
                  />
                </div>
              </div>
            </form>
            <div className="add_new_with_error">
              <ButtonField
                className="tabel_add_new_button"
                // onClick={() => setDialogOpen(true)}
                onClick={() => handleAddClick()}
              >
                Add new
              </ButtonField>
              {methods.formState.errors.item_details && (
                <FormHelperText error style={{ textAlign: "end" }}>
                  {methods.formState.errors.item_details.message}
                </FormHelperText>
              )}
            </div>
          </CustomToolbar>

          <CustomFooter className="item_footer">
            <TableAggregation
              name="Total Item Quantities :"
              value={totalItemQuantity(tableRows!)}
            />
            <div className="button_navigations">
              <ButtonField
                className="form_cancel_button"
                //   onClick={() => setDialogOpen(false)}
                onClick={() => nav(-1)}
              >
                Cancel
              </ButtonField>

              <ButtonField
                className="form_save_button"
                onClick={() => requestFormRef.current?.requestSubmit()}
              >
                Save
              </ButtonField>
            </div>
          </CustomFooter>
        </Table>
      </FormProvider>

      <Dialog
        open={dialogOpen}
        dialogClassName="dialog"
        Dialog_Title={`${
          Number.isInteger(itemValue.id) ? "Update" : "Add"
        } Item`}
        Dialog_Content={
          <ItemForm
            formRef={formRef}
            initialValues={itemValue}
            setInitialValues={setItemValue}
            itemSchema={itemSchema}
            append={append}
            update={update}
            setDialogOpen={setDialogOpen}
          />
        }
        Dialog_Actions={
          <>
            <ButtonField
              className="form_cancel_button"
              onClick={() => setDialogOpen(false)}
            >
              Cancel
            </ButtonField>

            <ButtonField
              className="form_save_button"
              onClick={() => formRef.current?.requestSubmit()}
            >
              Save
            </ButtonField>
          </>
        }
      />

      <DeleteDialog
        deleteableName={deleteDialogData.name ?? ""}
        open={deleteDialogOpen}
        setOpen={setDeleteDialogOpen}
        deleteFunction={() => {
          remove(deleteDialogData.id as number);
          setDeleteDialogOpen(false);
        }}
      />

      <ResponseDialog
        response={addTransaction}
        parentDialog={setDialogOpen}
        okFunction={() => nav(-1)}
      />
      <ResponseDialog
        response={editTransaction}
        parentDialog={setDialogOpen}
        okFunction={() => nav(-1)}
      />
    </>
  );
};

export default TransactionRequestForm;
