import { useEffect, useLayoutEffect, useRef, useState } from "react";
import Table from "../../Components/Table/Table";
import CustomToolbar from "../../Components/Table/components/CustomToolbar";
import CustomFooter from "../../Components/Table/components/CustomFooter";
import ButtonField from "../../Components/Button/ButtonField";
import { useAppDispatch, useAppSelector } from "../../Redux/Store/Hooks";
import { useNavigate } from "react-router-dom";
import { Columns } from "../../Components/Table/ITable";
import {
  GridPreProcessEditCellProps,
  GridRenderCellParams,
  GridValueGetterParams,
} from "@mui/x-data-grid";
import {
  FormProvider,
  SubmitHandler,
  useFieldArray,
  useForm,
} from "react-hook-form";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import DatePickers from "../../Components/DatePicker/DatePicker";
import dayjs from "dayjs";
import TextInputFieldC from "../../Components/TextInput/TextInputFieldC";
import { DevTool } from "@hookform/devtools";
import Dialog from "../../Components/Dialog/Dialog";
import ItemForm from "../TransactionRequest/ItemForm";
import { IDeleteDialog } from "../../Components/DeleteDialog/IDeleteDialog";
import { IItemDetails } from "../TransactionRequest/ITransactionRequest";
import IconButtonField from "../../Components/Button/IconButtonField";
import { FormHelperText, SvgIcon } from "@mui/material";
import DeleteDialog from "../../Components/DeleteDialog/DeleteDialog";
import { ReactComponent as Delete } from "../../Assets/Images/delete.svg";
import { ReactComponent as Edit } from "../../Assets/Images/edit.svg";
import TableAggregation from "../../Components/Table/components/TableAggregation ";
import {
  TotalAdjustedQuantity,
  totalItemQuantity,
} from "../../Utils/TableAggregations";
import { IStockTransferItemDetails } from "../StockTransfer/IStockTransfer";
import {
  IAdjustItems,
  IItemAdjustList,
  IListManualStockAdjustment,
} from "./IStockAdjustment";
import StockAdjustmentItemForm from "./StockAdjustmentItemForm";
import { getCodeStart } from "../../Redux/Slices/SequenceSlicer/GetCode";
import ResponseDialog from "../../Components/ResponseDialog/ResponseDialog";
import { clearScanItem } from "../../Redux/Slices/StockAdjustment/ListScanItems";
import {
  clearCreateStockAdjustment,
  createStockAdjustmentStart,
} from "../../Redux/Slices/StockAdjustment/CreateStockAdjustment";
import TextAreaInputField from "../../Components/TextAreaInput/TextAreaInputField";
import Snackbar from "../../Components/Snackbar/Snackbar";

const ManualStockAdjustmentForm = () => {
  const dispatch = useAppDispatch();
  // const location = useLocation();
  const nav = useNavigate();
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState<boolean>(false);
  const [deleteDialogData, setDeleteData] = useState<IDeleteDialog>({});
  const [itemValue, setItemValue] = useState<IItemDetails>({});
  const formRef = useRef<HTMLFormElement>(null);
  const requestFormRef = useRef<HTMLFormElement>(null);

  const { data: GetCode } = useAppSelector((state) => state.GetCode);
  console.log(GetCode);

  const { data: ListScanItems } = useAppSelector(
    (state) => state.ListScanItems
  );
  console.log(ListScanItems);
  const { data: createResponse } = useAppSelector(
    (state) => state.CreateStockAdjustment
  );

  useEffect(() => {
    const ListScanedItemsRows = ListScanItems?.data?.map((items, i) => ({
      ...items,
      item_name: items.item_details?.item_name,
      adjust_qty: 0,
    }));

    if (ListScanedItemsRows?.length! > 0) {
      if (methods.getValues("item_details")?.length! > 0) {
        replace([
          ...methods.getValues("item_details")!,
          ...ListScanedItemsRows!,
        ]);
        setDialogOpen(true);
      } else {
        replace([...ListScanedItemsRows!]);
      }
    }
  }, [ListScanItems]);

  // Columns
  const columns: Columns = [
    {
      field: "index",
      renderHeader: () => <b>S.No</b>,
      align: "center",
      headerAlign: "center",
      width: 90,
    },
    {
      field: "item_name",
      renderHeader: () => <b>Item Name</b>,
      flex: 0.7,
      align: "left",
      headerAlign: "left",
      type: "string",
    },
    {
      field: "item_barcode",
      renderHeader: () => <b>Item Barcode</b>,
      flex: 1,
      align: "left",
      headerAlign: "left",
      type: "string",
    },
    {
      field: "area_code",
      renderHeader: () => <b>Area Code</b>,
      flex: 0.5,
      align: "left",
      headerAlign: "left",
      type: "string",
    },
    {
      field: "item_qnty",
      renderHeader: () => <b>Available Quantity</b>,
      flex: 0.5,
      align: "right",
      headerAlign: "right",
      type: "number",
    },
    {
      field: "adjust_qty",
      renderHeader: () => <b>Adjust Item Quantity</b>,
      flex: 0.8,
      align: "right",
      headerAlign: "right",
      type: "number",
      editable: true,
      cellClassName: "inputCell",
      preProcessEditCellProps: (params: GridPreProcessEditCellProps) => {
        const hasError = params.props.value! > params.row.item_qnty!;
        if (hasError) {
          alert("Adjust Item Quantity is more than On Hand Quantity");
          // throw new Error("Item Quantity is more than Requested Quantity");
        }
        return { ...params.props, error: hasError };
      },
    },
    {
      field: "actions",
      renderHeader: () => <b>Action</b>,
      type: "actions",
      renderCell: (params: GridRenderCellParams<IAdjustItems>) => {
        return (
          <>
            {/* <IconButtonField
              onClick={() => {
                // setInitialValues({ ...params.row });
                setDialogOpen(true);
                setItemValue({ ...params.row });
              }}
            >
              <SvgIcon sx={{ fill: "none" }} component={Edit} inheritViewBox />
            </IconButtonField> */}
            <IconButtonField
              onClick={() => {
                setDeleteData({ id: params.id, name: params.row.item_barcode });
                setDeleteDialogOpen(true);
              }}
            >
              <SvgIcon component={Delete} inheritViewBox />
            </IconButtonField>
          </>
        );
      },
    },
  ];

  // update table rows
  const updateRow = (newRow: IItemAdjustList) => {
    const updatedRow = { ...newRow };
    update(newRow.id!, newRow);
    return updatedRow;
  };

  // item schema
  const itemSchema = z.object({
    id: z.number().optional(),
    item_barcode: z.string({ message: "Please Enter Item Barcode" }),
    adjust_qty: z.number({ message: "Please Enter Item Quantity" }),
  });

  // Adjusted Item Schema

  // form schema
  const stockTransferSchema = z
    .object({
      entry_no: z
        .string({ message: "Please enter the Entry No" })
        .min(1, { message: "Please enter the Entry No" }),
      entry_date: z.custom((val) => val && dayjs(val).isValid(), {
        message: "Please select the date",
      }),
      remarks: z.string({ message: "Please Enter Remarks" }),
      item_details: z.array(itemSchema).min(1, "Please Add the items"),
    })
    .refine(
      (data) => data.item_details.filter((item) => !!item.adjust_qty).length,
      {
        message: "enter minimum one item quantity",
        path: ["item_details"],
      }
    );

  useLayoutEffect(() => {
    if (GetCode?.data?.length! > 0) {
      methods.reset((val) => ({
        ...val,
        entry_no: GetCode?.data,
      }));
    }
  }, [GetCode]);

  // react hook form init
  const methods = useForm<IListManualStockAdjustment>({
    defaultValues: {
      entry_date: dayjs(),
    },
    resolver: zodResolver(stockTransferSchema),
  });

  const { replace, update, append, remove } = useFieldArray({
    control: methods.control,
    name: "item_details",
  });
  // form submit
  const onSubmit: SubmitHandler<any> = (val) => {
    console.log(val);
    dispatch(createStockAdjustmentStart(val));
  };

  const tableRows = methods
    .getValues("item_details")
    ?.map((row: any, index: any) => ({
      ...row,
      index: index + 1,
      id: index,
    }));

  useEffect(() => {
    dispatch(getCodeStart("Adjustment"));
    return () => {
      dispatch(clearScanItem());
      dispatch(clearCreateStockAdjustment());
    };
  }, [dispatch]);

  return (
    <>
      <Snackbar response={ListScanItems} autoHideDuration={2000} />
      <FormProvider {...methods}>
        <Table
          processRowUpdate={updateRow}
          rows={tableRows ?? []}
          columns={columns}
          editMode="cell"
        >
          <CustomToolbar>
            <h6>Add Stock Adjustment</h6>
            <form
              ref={requestFormRef}
              onSubmit={methods.handleSubmit(onSubmit)}
              className="toolbar_form"
            >
              <div className="right_div">
                <TextInputFieldC
                  size="small"
                  name="entry_no"
                  label="Entry No"
                  disabled
                />

                <DatePickers
                  size="small"
                  name="entry_date"
                  label="Entry Date"
                  disabled
                />

                <div className="add_new_with_error">
                  <ButtonField
                    className="tabel_add_new_button"
                    onClick={() => setDialogOpen(true)}
                  >
                    Add new
                  </ButtonField>
                  {methods.formState.errors.item_details && (
                    <FormHelperText error style={{ textAlign: "end" }}>
                      {methods.formState.errors.item_details.message}
                    </FormHelperText>
                  )}
                </div>
              </div>
            </form>
          </CustomToolbar>

          <CustomFooter className="item_footer">
            {/* <div className="left_div">
              <TextInputFieldC size="small" name="remarks" label="Remarks" />
            </div> */}
            <TextAreaInputField
              name="remarks"
              size="small"
              label="Remarks"
              rows={2}
              sx={{ width: "55%" }}
            />
            <TableAggregation
              name="Total Adjusted Quantities :"
              value={TotalAdjustedQuantity(tableRows!)}
            />
            <div
              style={{
                display: "flex",
                border: "1px solid gray",
                width: "20%",
                gap: "1.5rem",
                padding: "5px",
                alignItems: "center",
                justifyContent: "center",
                borderRadius: "8px",
              }}
            >
              <ButtonField
                className="form_cancel_button"
                //   onClick={() => setDialogOpen(false)}
                onClick={() => nav(-1)}
              >
                Cancel
              </ButtonField>

              <ButtonField
                className="form_save_button"
                onClick={() => requestFormRef.current?.requestSubmit()}
              >
                Save
              </ButtonField>
            </div>
          </CustomFooter>
        </Table>
      </FormProvider>

      <Dialog
        open={dialogOpen}
        dialogClassName="dialog"
        setOpen={setDialogOpen}
        Dialog_Title={
          <>
            Enter/Scan Barcode
            <IconButtonField
              onClick={() => {
                setDialogOpen(false);
              }}
            >
              <SvgIcon
                component={CloseOutlinedIcon}
                inheritViewBox
                sx={{ height: "20px", width: "20px" }}
              />
            </IconButtonField>
          </>
        }
        Dialog_Content={
          <StockAdjustmentItemForm
            formRef={formRef}
            initialValues={itemValue}
            setInitialValues={setItemValue}
            itemSchema={itemSchema}
            append={append}
            update={update}
            setDialogOpen={setDialogOpen}
          />
        }
        // Dialog_Actions={
        //   <>
        //     <ButtonField
        //       className="form_cancel_button"
        //       onClick={() => setDialogOpen(false)}
        //     >
        //       Cancel
        //     </ButtonField>

        //     <ButtonField
        //       className="form_save_button"
        //       onClick={() => formRef.current?.requestSubmit()}
        //     >
        //       Save
        //     </ButtonField>
        //   </>
        // }
      />

      <DevTool control={methods.control} />

      <DeleteDialog
        deleteableName={deleteDialogData.name ?? ""}
        open={deleteDialogOpen}
        setOpen={setDeleteDialogOpen}
        deleteFunction={() => {
          remove(deleteDialogData.id as number);
          setDeleteDialogOpen(false);
        }}
      />

      {/* <ResponseDialog
        response={ListScanItems}
        okFunction={() => setDialogOpen(false)}
      /> */}
      <ResponseDialog response={createResponse} okFunction={() => nav(-1)} />
    </>
  );
};

export default ManualStockAdjustmentForm;
