import { AxiosResponse } from "axios";
import { call, put } from "redux-saga/effects";
import api from "../../Axios/middleware";
import { PayloadAction } from "@reduxjs/toolkit";
import { IReportFilter } from "../../../Pages/ReportsStockReceive/IReportsStockReceive";
import { responseReportsStockDetails } from "./ListReportsStockDetailsSlice";

const ReportsStockDetailsApi = (action: PayloadAction<IReportFilter>) => {
  console.log(action.payload);

  return api.get("/item-master/report", {
    params: action.payload,
  });
};

export function* ReportsStockDetailsCall(action: PayloadAction<IReportFilter>) {
  try {
    const respose: AxiosResponse = yield call(ReportsStockDetailsApi, action);
    yield put(responseReportsStockDetails(respose.data));
  } catch (error) {
    yield put(responseReportsStockDetails({}));
  }
}
