import { AxiosResponse } from "axios";
import { call, put } from "redux-saga/effects";
import api from "../../Axios/middleware";
import { failureItemMaster, successItemMaster } from "./ListItemMasterSlice";
import {
  IItemMaster,
  ImportItemProps,
} from "../../../Pages/ItemMaster/IItemMaster";
import { PayloadAction } from "@reduxjs/toolkit";
import {
  failureaddItemMaster,
  successaddItemMaster,
} from "./AddItemMasterSlice";
import {
  failureeditItemMaster,
  successeditItemMaster,
} from "./EditItemMasterSlice";
import {
  failuredeleteItemMaster,
  successdeleteItemMaster,
} from "./DeleteItemMasterSlice";

import {
  successImportItemMaster,
  failureImportItemMaster,
} from "./ImportItemSlicer";

export interface IListItemParams {
  item_type?: string;
}

// list
const ListItemMasterApi = (action: PayloadAction<IListItemParams>) => {
  return api.get("/item-master/getitems", { params: action.payload });
};

export function* ListItemMasterCall(action: PayloadAction<IListItemParams>) {
  try {
    const response: AxiosResponse = yield call(ListItemMasterApi, action);

    yield put(successItemMaster(response.data));
  } catch (error) {
    yield put(failureItemMaster(error));
  }
}

// add
const AddItemMasterApi = (action: PayloadAction<IItemMaster>) => {
  return api.post("/item-master/create", action.payload);
};

export function* AddItemMasterCall(action: PayloadAction<IItemMaster>) {
  try {
    const response: AxiosResponse = yield call(AddItemMasterApi, action);
    yield put(successaddItemMaster(response.data));
  } catch (error) {
    yield put(failureaddItemMaster(error));
  }
}

// import Items
const ImportItemAPI = (action: PayloadAction<ImportItemProps>) => {
  console.log(action.payload);

  return api.post("/item-master/item_import", action.payload, {
    headers: { "Content-Type": "multipart/form-data" },
  });
};
export function* ImportItemsCall(action: PayloadAction<ImportItemProps>) {
  try {
    const response: AxiosResponse = yield call(ImportItemAPI, action);
    yield put(successImportItemMaster(response.data));
  } catch (error) {
    yield put(failureImportItemMaster(error));
  }
}

// edit
const EditItemMasterApi = (action: PayloadAction<IItemMaster>) => {
  return api.patch("/item-master/update", action.payload, {
    params: { id: action.payload.id },
  });
};

export function* EditItemMasterCall(action: PayloadAction<IItemMaster>) {
  try {
    const response: AxiosResponse = yield call(EditItemMasterApi, action);
    yield put(successeditItemMaster(response.data));
  } catch (error) {
    yield put(failureeditItemMaster(error));
  }
}

// delete
const DeleteItemMasterApi = (action: PayloadAction<number>) => {
  return api.delete("/item-master/delete", {
    params: { id: action.payload },
  });
};

export function* DeleteItemMasterCall(action: PayloadAction<number>) {
  try {
    const response: AxiosResponse = yield call(DeleteItemMasterApi, action);
    yield put(successdeleteItemMaster(response.data));
  } catch (error) {
    yield put(failuredeleteItemMaster(error));
  }
}
