import "./Dashboard.scss";
import { FunctionComponent, useEffect } from "react";
import Cards from "../../Components/Card/cards";
import { darken } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../Redux/Store/Hooks";
import { dashboardCard } from "../../Redux/Slices/Dashboard/DashboardCard";

const Dashboard: FunctionComponent = () => {
  const dispatch = useAppDispatch();
  const { data: dashboardCards } = useAppSelector(
    (state) => state.DashboardCard
  );

  const cards = [
    {
      name: "Stock Receive",
      counts: dashboardCards?.data?.receive,
      bgcolor: "rgb(255, 244, 222)",
    },
    {
      name: "Stock Requests",
      counts: dashboardCards?.data?.request,
      bgcolor: "rgb(220, 252, 231)",
    },
    {
      name: "Stock Transfer",
      counts: dashboardCards?.data?.transfer,
      bgcolor: "rgb(220, 225, 252)",
    },
    {
      name: "Location Receive",
      counts: dashboardCards?.data?.location_receive,
      bgcolor: "rgb(255, 226, 229)",
    },
  ];

  useEffect(() => {
    // dispatch(listZoneConfig(""));
    dispatch(dashboardCard());
    return () => {
      // dispatch(clearDashboardStorageArea());
    };
  }, [dispatch]);

  return (
    <div className="dashboard_container">
      <Cards className="dashboard_cards_container">
        {cards.map((card) => (
          <Cards
            className="dashboard_cards"
            sx={{ bgcolor: card.bgcolor, color: darken(card.bgcolor, 0.7) }}
          >
            <div className="heading_name">{card.name}</div>
            <div className="count_no">{card.counts}</div>
          </Cards>
        ))}
      </Cards>

      {/* <ReactECharts option={} /> */}

      {/* <div className="storage_area_filter">
        <Select
          fullWidth
          sx={{ maxWidth: 250 }}
          size="small"
          placeholder="select zone"
          options={zoneOption ?? []}
          onChange={(_, val) => {
            const value = val as AutocompleteOption;
            dispatch(listDashboardStorageArea(value?.value ?? null));
          }}
        />
      </div> */}

      {/* <div style={{ height: "615px" }}>
        <StorageContainerComponent data={data} />
      </div> */}
    </div>
  );
};

export default Dashboard;
