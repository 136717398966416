import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, Tab } from "@mui/material";
import React, { FunctionComponent, useEffect } from "react";
import "./TabList.scss";
import { ITabList } from "./ITabProps";

const TabLists: FunctionComponent<ITabList> = (props) => {
  const {
    ScrollButtonComponent,
    variant,
    scrollButtons,
    tabs,
    tabPannels,
    tabClassName,
    tabContainerClassName,
    tabPanelClassName,
    orientation,
    action,
    allowScrollButtonsMobile,
    selectionFollowsFocus,
    sx,
    textColor,
    visibleScrollbar,
    centered,
    onChange,
    tabValue,
  } = props;

  return (
    <div
      className={
        orientation === "horizontal"
          ? `tablist_main_component_container`
          : "tablist_main_component_container_vertical"
      }
    >
      <TabContext value={tabValue ?? ""}>
        <div className="tablist_header_component_container">
          <TabList
            {...props}
            selectionFollowsFocus={selectionFollowsFocus}
            sx={sx}
            allowScrollButtonsMobile={allowScrollButtonsMobile}
            onChange={onChange}
            ScrollButtonComponent={ScrollButtonComponent}
            variant={variant}
            scrollButtons={scrollButtons}
            orientation={orientation}
            visibleScrollbar={visibleScrollbar}
            centered={centered}
            className={`tab_container_style ${tabContainerClassName}`}
          >
            {tabs &&
              tabs.map((tab, i) => (
                <Tab
                  className={`tab_style ${tabClassName}`}
                  disabled={tab.disabled}
                  label={tab.label}
                  icon={tab.icon && tab.icon}
                  value={tab.value ? tab.value : `${i + 1}`}
                />
              ))}
          </TabList>
        </div>
        {tabPannels &&
          tabPannels.map((tabPanel, i) => (
            <TabPanel
              className={tabPanelClassName}
              value={tabPanel.value ? tabPanel.value : `${i + 1}`}
              children={tabPanel.children}
            />
          ))}
      </TabContext>
    </div>
  );
};

export default TabLists;
