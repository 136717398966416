import "./Switchs.scss";

import { Switch } from "@mui/material";
import { FC } from "react";
import { SwitchsProps } from "./ISwitchs";

const Switchs: FC<SwitchsProps> = (props) => {
  const { onChange, value, defaultChecked, readOnly, disabled, checked } =
    props;
  return (
    <Switch
      {...props}
      checked={checked}
      defaultChecked={defaultChecked}
      onChange={onChange}
      value={value}
      disabled={disabled}
      readOnly={readOnly}
    />
  );
};
export default Switchs;
