import { createSlice } from "@reduxjs/toolkit";
import { IReduxState } from "../../interfaces/IReduxState";
import { IZoneStorageArea } from "../../../Pages/StorageArea/IStorageArea";

export const DashboardStorageAreaSlice = createSlice({
  name: "ListDashboardStorageArea",
  initialState: {} as IReduxState<IZoneStorageArea>,
  reducers: {
    listDashboardStorageArea: (state, _) => {
      state.isLoading = true;
    },
    successDashboardStorageArea: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
    failureDashboardStorageArea: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
    clearDashboardStorageArea: (state) => {
      state.data = {};
    },
  },
});

export const {
  failureDashboardStorageArea,
  listDashboardStorageArea,
  successDashboardStorageArea,
  clearDashboardStorageArea,
} = DashboardStorageAreaSlice.actions;

export default DashboardStorageAreaSlice.reducer;
