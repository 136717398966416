import React, { useEffect } from "react";
import "../Login/Login.scss";
import TextInputField from "../../Components/TextInput/TextInputField";
import {
  CircularProgress,
  Divider,
  InputAdornment,
  SvgIcon,
} from "@mui/material";
import { Mail } from "@mui/icons-material";
import SendIcon from "@mui/icons-material/Send";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import ButtonField from "../../Components/Button/ButtonField";
import { Link, useNavigate } from "react-router-dom";

import { useFormik } from "formik";
import * as Yup from "yup";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { IForgotPassword } from "./IForgotPassword";
import { z } from "zod";
import TextInputFieldC from "../../Components/TextInput/TextInputFieldC";
import { useAppDispatch, useAppSelector } from "../../Redux/Store/Hooks";
import {
  ClearForgotPassword,
  ForgotPasswordStart,
} from "../../Redux/Slices/LoginSlicer/ForgotPassword";
import ResponseDialog from "../../Components/ResponseDialog/ResponseDialog";

interface ForgotProps {
  userName: string;
  email: string;
}
const ForgotPassword = () => {
  const nav = useNavigate();
  const dispatch = useAppDispatch();
  const ForGotPasswordRes = useAppSelector((state) => state.ForgotPassword);
  const { data: forgotPassRes } = useAppSelector(
    (state) => state.ForgotPassword
  );
  console.log(forgotPassRes);

  const loginSchema = z.object({
    email: z
      .string({
        required_error: "Email is required",
        message: "Email is required",
      })
      .email({
        message: "Invalid email",
      }),
  });
  const methods = useForm<IForgotPassword>({
    defaultValues: {
      email: null,
    },
    resolver: zodResolver(loginSchema),
  });
  const onSubmit: SubmitHandler<IForgotPassword> = (val) => {
    console.log(val);

    dispatch(ForgotPasswordStart(val.email));
    // localStorage.setItem("token", JSON.stringify(val));
    // nav("/dashboard");
  };
  useEffect(() => {
    return () => {
      dispatch(ClearForgotPassword());
    };
  }, []);
  return (
    <>
      <div className="backGround">
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <div className="main">
              <div>
                <div className="heading">Forgot Password</div>
                <Divider />
              </div>

              <div className="textFields">
                <TextInputFieldC
                  className="textField"
                  name="email"
                  type="text"
                  placeholder="Email"
                  variant="outlined"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SvgIcon
                          sx={{
                            width: "28px",
                            height: "28px",
                          }}
                          component={AccountCircleIcon}
                          inheritViewBox
                        />
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
              <div className="textFields">
                <ButtonField
                  disabled={ForGotPasswordRes.isLoading}
                  className="buttonfield"
                  type="submit"
                >
                  Submit
                  {ForGotPasswordRes.isLoading && (
                    <CircularProgress
                      size={24}
                      sx={{
                        color: "white",
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        marginTop: "-12px",
                        marginLeft: "-12px",
                      }}
                    />
                  )}
                </ButtonField>
                <Link className="login_page" to={"/login"}>
                  <ButtonField className="buttonfield">Login</ButtonField>
                </Link>
              </div>
            </div>
          </form>
        </FormProvider>
        <ResponseDialog
          response={forgotPassRes}
          okFunction={() => nav(`/login`)}
        />
      </div>
    </>
  );
};

export default ForgotPassword;
