import { AxiosResponse } from "axios";
import { call, put } from "redux-saga/effects";
import api from "../../Axios/middleware";
import {
  failureLocationType,
  successLocationType,
} from "./ListLocationTypeSlice";

// list
const ListLocationTypeApi = () => {
  return api.get("/location-type");
};

export function* ListLocationTypeCall() {
  try {
    const response: AxiosResponse = yield call(ListLocationTypeApi);
    yield put(successLocationType(response.data));
  } catch (error) {
    yield put(failureLocationType(error));
  }
}
