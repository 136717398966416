import { createSlice } from "@reduxjs/toolkit";
import { IReduxState } from "../../interfaces/IReduxState";
import { ILocationReceive } from "../../../Pages/LocationReceive/ILocationReceive";

export const GetoneLocationReceiveSlice = createSlice({
  name: "GetoneLocationReceive",
  initialState: {} as IReduxState<ILocationReceive>,
  reducers: {
    getoneLocationReceive: (state, _) => {
      state.isLoading = true;
    },
    successGetoneLocationReceive: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
    failureGetoneLocationReceive: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
    clearGetoneLocationReceiveResponse: (state) => {
      state.data = {};
    },
  },
});

export const {
  getoneLocationReceive,
  failureGetoneLocationReceive,
  successGetoneLocationReceive,
  clearGetoneLocationReceiveResponse,
} = GetoneLocationReceiveSlice.actions;

export default GetoneLocationReceiveSlice.reducer;
