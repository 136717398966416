import { FunctionComponent, useEffect, useState } from "react";
import "./StockRecieve.scss";
import Table from "../../Components/Table/Table";
import CustomToolbar from "../../Components/Table/components/CustomToolbar";
import ButtonField from "../../Components/Button/ButtonField";
import { useAppDispatch, useAppSelector } from "../../Redux/Store/Hooks";
import TableSearch from "../../Components/Table/components/TableSearch";
import Dialog from "../../Components/Dialog/Dialog";
import ResponseDialog from "../../Components/ResponseDialog/ResponseDialog";
import { clearCreateStock } from "../../Redux/Slices/StockRecieve/CreateStockRecieve";
import { listStockRecieveStart } from "../../Redux/Slices/StockRecieve/ListStockRecieve";
import { Columns } from "../../Components/Table/ITable";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import {
  GridRenderCellParams,
  GridValueFormatterParams,
} from "@mui/x-data-grid";
import { IListStockRecieve, ILocation, statusOptionsFD } from "./IStockRecieve";
import { dateFormatter } from "../../Utils/dateFormatter";
import IconButtonField from "../../Components/Button/IconButtonField";
import { SvgIcon } from "@mui/material";
import { ReactComponent as ViewIcon } from "../../Assets/Images/solar_eye-linear.svg";
import { ReactComponent as Delete } from "../../Assets/Images/delete.svg";
import { ReactComponent as Edit } from "../../Assets/Images/edit.svg";
import ViewStockItems from "./ViewStockItems";
import { useNavigate } from "react-router-dom";
import { listLocationMaster } from "../../Redux/Slices/LocationMaster/ListLocationMasterSlice";
import {
  cleareDeleteStockReceive,
  deleteStockReceiveStart,
} from "../../Redux/Slices/StockRecieve/DeleteStockReceive";
import DeleteDialog from "../../Components/DeleteDialog/DeleteDialog";
import { getCodeStart } from "../../Redux/Slices/SequenceSlicer/GetCode";
import {
  clearGetGenerateSerialNo,
  getGenerateSerialNoStart,
} from "../../Redux/Slices/GeneralSetting/GenerateSerialNo/GetGenerateSerialNo";
import { getItemTypeStart } from "../../Redux/Slices/GeneralSetting/ItemType/GetItemType";
import { listItemMaster } from "../../Redux/Slices/ItemMaster/ListItemMasterSlice";
import { listLocationType } from "../../Redux/Slices/LocationType/ListLocationTypeSlice";
import Loading from "../../Components/Loading/Loading";

const StockRecieve: FunctionComponent = () => {
  const [viewDialogOpen, setViewDialogOpen] = useState<boolean>(false);
  const [deleteDialouge, setDeleteDialouge] = useState<boolean>(false);
  const [deleteData, setDeleteData] = useState<{ id: number; name: string }>();
  const [addNewBtn, SetAddNewBtn] = useState<boolean>(false);

  const dispatch = useAppDispatch();

  const nav = useNavigate();
  const { data: customSerialNo } = useAppSelector(
    (state) => state.GetGenerateSerialNo
  );
  const { data: Location } = useAppSelector(
    (state) => state.ListLocationMaster
  );
  const { data: statusUpdateRes } = useAppSelector(
    (state) => state.StockRecieveStatusUpdate
  );
  console.log(statusUpdateRes);

  const { data: getItemType } = useAppSelector((state) => state.GetItemType);
  const statusOptions = Array.isArray(getItemType?.data)
    ? getItemType?.data.map((status) => ({
        label: status.name!,
        value: status.id!,
      }))
    : [];

  const { data: DeleteResponse } = useAppSelector(
    (state) => state.DeleteStockRecieve
  );
  const LocationList = Array.isArray(Location?.data)
    ? Location?.data.map((type) => ({ label: type.name!, value: type.id! }))
    : [];
  const { data: createStockRecieve } = useAppSelector(
    (state) => state.CreateStockRecieve
  );
  const { data: stockRecieveList } = useAppSelector(
    (state) => state.ListStockRecieve
  );
  // console.log(stockRecieveList);
  const stockRecieveListRow = Array.isArray(stockRecieveList?.data)
    ? stockRecieveList?.data.map((stock, index) => ({
        ...stock,
        from_name_supplier:
          stock.from_name?.length! > 0 ? stock.from_name : stock.supplier_name,
        index: index + 1,
      }))
    : [];
  console.log(stockRecieveListRow);

  const columns: Columns = [
    {
      field: "index",
      renderHeader: () => <b>S.No</b>,
      width: 90,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "entry_no",
      renderHeader: () => <b>Entry No</b>,
      flex: 0.6,
      type: "string",
      headerAlign: "left",
      align: "left",
    },
    {
      field: "entry_date",
      renderHeader: () => <b>Entry Date</b>,
      flex: 0.6,
      type: "date",
      valueFormatter: (params) => dateFormatter(params.value),
      headerAlign: "center",
      align: "center",
    },
    {
      field: "from_name_supplier",
      renderHeader: () => <b>Recieve From / Supplier Name</b>,
      flex: 1,
      type: "string",
      headerAlign: "left",
      align: "left",
    },
    {
      field: "item_details",
      renderHeader: () => <b>Total Items</b>,
      flex: 1,
      type: "string",
      valueGetter: (params) => params.value?.length,
      headerAlign: "right",
      align: "right",
    },
    {
      field: "status",
      renderHeader: () => <b>Status</b>,
      flex: 0.6,
      headerAlign: "left",
      align: "left",
      type: "singleSelect",
      valueOptions: statusOptionsFD,
    },
    {
      field: "action",
      renderHeader: () => <b>Action</b>,
      flex: 1,
      type: "actions",
      renderCell: (params: GridRenderCellParams<IListStockRecieve>) => {
        return (
          <>
            <IconButtonField
              onClick={() => {
                // console.log(params.row);

                localStorage.setItem("viewStock", String(params.row.id));
                setViewDialogOpen(true);
              }}
            >
              <SvgIcon
                sx={{ fill: "none" }}
                component={ViewIcon}
                inheritViewBox
              />
            </IconButtonField>
            <IconButtonField
              disabled={params?.row?.status !== "PND"}
              onClick={() => {
                // setInitialValues(params.row);
                // dispatch(setEditStockRecieveData(params.row));
                // localStorage.setItem(
                //   "stock_receive_edit_id",
                //   String(params.row.id)
                // );
                // console.log(params.row);

                // setDialogOpen(true);
                nav("edit", { state: params.id });
              }}
            >
              <SvgIcon sx={{ fill: "none" }} component={Edit} inheritViewBox />
            </IconButtonField>
            <IconButtonField
              disabled={params?.row?.status !== "PND"}
              onClick={() => {
                setDeleteDialouge(true);
                setDeleteData({
                  id: params.row.id!,
                  name: "Stock Receive Data",
                });
              }}
            >
              <SvgIcon component={Delete} inheritViewBox />
            </IconButtonField>
          </>
        );
      },
    },
  ];
  console.log(getItemType);

  useEffect(() => {
    // if (getItemType?.status) {
    dispatch(listStockRecieveStart({ key: "ST" }));
    // }
  }, [createStockRecieve, DeleteResponse]);
  useEffect(() => {
    if (addNewBtn) {
      nav("add");
    }
  }, [customSerialNo]);

  useEffect(() => {
    dispatch(listLocationMaster());
    dispatch(getItemTypeStart("RES"));
    dispatch(listLocationType());
    dispatch(getItemTypeStart("IT"));
    return () => {
      dispatch(clearCreateStock());
      dispatch(cleareDeleteStockReceive());
      dispatch(clearGetGenerateSerialNo());
    };
  }, []);

  return (
    <>
      <Table
        columns={columns ?? []}
        rows={stockRecieveListRow ?? []}
        disableColumnMenu={false}
      >
        <CustomToolbar className="custom_toolbar">
          <h6>Stock Receive</h6>
          {/* <Select
            label="Recieve From"
            size="small"
            options={LocationList}
            onChange={(_, val) => {
              const value = val as AutocompleteOption;
              if (value) {
                dispatch(listStockRecieveStart(value.value));
              } else {
                dispatch(listStockRecieveStart(""));
              }
            }}
          /> */}
          <div className="custom_toolbar_search_add_new">
            <TableSearch />
            <ButtonField
              className="tabel_add_new_button"
              onClick={() => {
                SetAddNewBtn(true);
                dispatch(getGenerateSerialNoStart());
                dispatch(getCodeStart("Receive"));
              }}
            >
              Add new
            </ButtonField>
          </div>
        </CustomToolbar>
      </Table>
      <>
        {/* View Dialog */}
        <Dialog
          open={viewDialogOpen}
          Dialog_Content={<ViewStockItems />}
          dialogClassName="dialog_double_input"
          Dialog_Title={
            <>
              View Stock Items Recieved
              <IconButtonField
                onClick={() => {
                  setViewDialogOpen(false);
                }}
              >
                <SvgIcon
                  component={CloseOutlinedIcon}
                  inheritViewBox
                  sx={{ height: "20px", width: "20px" }}
                />
              </IconButtonField>
            </>
          }
        />
      </>
      <DeleteDialog
        deleteableName={deleteData?.name}
        response={DeleteResponse}
        open={deleteDialouge}
        setOpen={setDeleteDialouge}
        deleteFunction={() => dispatch(deleteStockReceiveStart(deleteData?.id))}
      />
      <ResponseDialog
        response={statusUpdateRes}
        okFunction={() => {
          dispatch(listStockRecieveStart({ key: "ST" }));
        }}
      />
    </>
  );
};

export default StockRecieve;
