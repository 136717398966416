import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../Redux/Store/Hooks";
import { dateFormatter } from "../../Utils/dateFormatter";
import { ColumnMeta } from "../../Components/Table/ITableRP";
import { listStockAdjustment } from "../../Redux/Slices/StockAdjustment/ListStockAdjustment";
import TableRP from "../../Components/Table/TableRP";
import { FormControlLabel, Radio, RadioGroup } from "@mui/material";
import DateRangePicker from "../../Components/DateRangePicker/DateRangePicker";
import { Range, RangeKeyDict } from "react-date-range";
import { addTimeToDate } from "../../Utils/AddTimeToDate";

const ReportStockAdjustment = () => {
  const dispatch = useAppDispatch();
  const [dateRange, setDateRange] = useState<Range | undefined>();
  const [detailedReport, setDetailedReport] = useState<boolean>(false);
  const { data: stockAdjustmentList } = useAppSelector(
    (state) => state.ListStockAdjustment
  );
  const AdjustStockListRow = Array.isArray(stockAdjustmentList?.data)
    ? stockAdjustmentList?.data.map((stock, index) => ({
        index: index + 1,
        entry_no: stock.entry_no,
        entry_date: dateFormatter(stock.entry_date),
        remarks: stock.remarks,
        total_items: stock.item_details?.length,
        total_adjusted_items: stock?.item_details?.reduce((sum, item) => {
          return sum + (item.adjust_qty ? Number(item.adjust_qty) : 0);
        }, 0),
      }))
    : [];

  const detailedStockAdjustListRow = Array.isArray(stockAdjustmentList?.data)
    ? stockAdjustmentList?.data.flatMap((stock) => {
        return stock.item_details?.map((items, i) => ({
          entry_no: stock.entry_no,
          entry_date: dateFormatter(stock.entry_date),
          item_name: items.items?.item_name,
          item_bar_code: items.item_barcode,
          adjust_qty: items.adjust_qty,
          remarks: stock.remarks,
        }));
      }) || []
    : [];
  console.log(stockAdjustmentList?.data);
  const DetailedList = detailedStockAdjustListRow?.map((stock, i) => ({
    s_no: i + 1,
    ...stock,
  }));

  const columns: ColumnMeta[] =
    detailedReport === false
      ? [
          {
            field: "index",
            header: "S.No",
            align: "center",
            alignHeader: "center",
          },
          {
            field: "entry_no",
            header: "Entry No",
            align: "left",
            alignHeader: "left",
          },
          {
            field: "entry_date",
            header: "Entry Date",
            align: "center",
            alignHeader: "center",
          },
          {
            field: "total_items",
            header: "Total Items",
            align: "right",
            alignHeader: "right",
          },
          {
            field: "total_adjusted_items",
            header: "Total Adjusted Items",
            align: "right",
            alignHeader: "right",
          },
          {
            field: "remarks",
            header: "Remarks",
            align: "left",
            alignHeader: "left",
          },
        ]
      : [
          {
            field: "s_no",
            header: "S.No",
            align: "center",
            alignHeader: "center",
          },
          {
            field: "entry_no",
            header: "Entry No",
            align: "left",
            alignHeader: "left",
          },
          {
            field: "entry_date",
            header: "Entry Date",
            align: "center",
            alignHeader: "center",
          },
          {
            field: "item_name",
            header: "Item Name",
            align: "left",
            alignHeader: "left",
          },
          {
            field: "item_bar_code",
            header: "Item Barcode",
            align: "left",
            alignHeader: "left",
          },
          {
            field: "adjust_qty",
            header: "Adjusted Quantities",
            align: "right",
            alignHeader: "right",
          },
          {
            field: "remarks",
            header: "Remarks",
            align: "left",
            alignHeader: "left",
          },
        ];
  const [value, setValue] = useState("summary");

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue((event.target as HTMLInputElement).value);
    setDetailedReport(
      (event.target as HTMLInputElement).value === "detail" ? true : false
    );
  };
  useEffect(() => {
    dispatch(listStockAdjustment({ key: "SRE" }));
  }, [dispatch]);
  console.log(DetailedList);

  return (
    <>
      <TableRP
        sortMode="multiple"
        columns={columns}
        size="small"
        rows={detailedReport === false ? AdjustStockListRow! : DetailedList!}
        // viewRowData={(data: IListStockRecieve) => {
        //   localStorage.setItem("viewStock", String(data.id));
        //   setViewDialogOpen(true);
        // }}
        headerWithSearch
        headerTitle="Stock Adjustment Report"
        // actionColumn
        headerStartComponent={
          <div className="report_start_component">
            <fieldset className="report_fieldset">
              <legend className="reports_label">Report Type</legend>
              <RadioGroup
                name="controlled-radio-buttons-group"
                value={value}
                onChange={handleChange}
                sx={{
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <FormControlLabel
                  value="summary"
                  control={<Radio size="small" />}
                  label="Summary"
                />
                <FormControlLabel
                  value="detail"
                  control={<Radio size="small" />}
                  label="Detail"
                />
              </RadioGroup>
            </fieldset>
            <div className="report_filters_container">
              <DateRangePicker
                setRanges={setDateRange}
                showMonthAndYearPickers={false}
                size="small"
                placeholder="Choose Report Period"
                ranges={dateRange}
                direction="horizontal"
                handleOkBtn={() =>
                  dispatch(
                    listStockAdjustment({
                      key: "SRE",
                      startDate: addTimeToDate(dateRange?.startDate!, 5, 30),
                      endDate: addTimeToDate(dateRange?.endDate!, 5, 30),
                    })
                  )
                }
                onChange={(item: RangeKeyDict) => {
                  setDateRange(item.selection);
                }}
                handleRangeClear={() =>
                  dispatch(
                    listStockAdjustment({
                      key: "SRE",
                    })
                  )
                }
                value={dateRange}
              />
            </div>
          </div>
        }
        CSVExport
        ExcelExport
        PdfExport
        ExportName="stock_adjustment_report"
      />
    </>
  );
};

export default ReportStockAdjustment;
