import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IReduxState } from "../../interfaces/IReduxState";
import {
  IStockTransfer,
  ITransferApiCall,
} from "../../../Pages/StockTransfer/IStockTransfer";

export const StockTransferSlice = createSlice({
  name: "ListStockTransfer",
  initialState: {} as IReduxState<IStockTransfer[]>,
  reducers: {
    listStockTransfer: (state, _: PayloadAction<ITransferApiCall>) => {
      state.isLoading = true;
    },
    successStockTransfer: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
    failureStockTransfer: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
    clearStockTransfer: (state) => {
      state.data = {};
    },
  },
});

export const {
  failureStockTransfer,
  listStockTransfer,
  successStockTransfer,
  clearStockTransfer,
} = StockTransferSlice.actions;

export default StockTransferSlice.reducer;
