export interface ItemMasterProps {}

export interface IItemMaster {
  id?: number;
  division_id?: number | null;
  item_type?: string | null;
  item_name?: string | null;
  item_image?: string | File | null;
  item_code?: string | null;
  description?: string | null;
  stock_uom?: string | null;
  base_uom?: string | null;
  created_at?: string | null;
  updated_at?: string | null;
  created_by?: string | null;
  updated_by?: string | null;
  is_deleted?: boolean;
}
export interface ImportItemProps {
  division_id: number | null;
  csvfile: File | null;
}

export const ItemTypeOptions = [
  {
    label: "Raw Material",
    value: "RM",
  },
  {
    label: "Finished Goods",
    value: "FG",
  },
];
