import { createSlice } from "@reduxjs/toolkit";
import { IReduxState } from "../../interfaces/IReduxState";

export const GenerateSequence = createSlice({
  name: "GenerateSequence",
  initialState: {} as IReduxState<string>,
  reducers: {
    GenerateSequenceStart: (state, _) => {
      state.isLoading = true;
    },
    successGenerateSequence: (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    },
    failureGenerateSequence: (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    },
  },
});

export const {
  GenerateSequenceStart,
  successGenerateSequence,
  failureGenerateSequence,
} = GenerateSequence.actions;

export default GenerateSequence.reducer;
