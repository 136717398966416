import { AxiosResponse } from "axios";
import { call, put } from "redux-saga/effects";
import api from "../../Axios/middleware";
import { failureUserMaster, successUserMaster } from "./ListUserMasterSlice";
import {
  failureaddUserMaster,
  successaddUserMaster,
} from "./AddUserMasterSlice";
import { PayloadAction } from "@reduxjs/toolkit";
import { IUserMaster } from "../../../Pages/UserMaster/IUserMaster";
import {
  failureeditUserMaster,
  successeditUserMaster,
} from "./EditUserMasterSlice";
import {
  failuredeleteUserMaster,
  successdeleteUserMaster,
} from "./DeleteUserMasterSlice";

// list
const ListUserMasterApi = () => {
  return api.get("/user/findAll");
};

export function* ListUserMasterCall() {
  try {
    const response: AxiosResponse = yield call(ListUserMasterApi);
    yield put(successUserMaster(response.data));
  } catch (error) {
    yield put(failureUserMaster(error));
  }
}

// add
const AddUserMasterApi = (action: PayloadAction<IUserMaster>) => {
  return api.post("/user/create", action.payload);
};

export function* AddUserMasterCall(action: PayloadAction<IUserMaster>) {
  try {
    const response: AxiosResponse = yield call(AddUserMasterApi, action);
    yield put(successaddUserMaster(response.data));
  } catch (error) {
    yield put(failureaddUserMaster(error));
  }
}

// edit
const EditUserMasterApi = (action: PayloadAction<IUserMaster>) => {
  return api.patch("/user/update", action.payload, {
    params: { id: action.payload.id },
  });
};

export function* EditUserMasterCall(action: PayloadAction<IUserMaster>) {
  try {
    const response: AxiosResponse = yield call(EditUserMasterApi, action);
    yield put(successeditUserMaster(response.data));
  } catch (error) {
    yield put(failureeditUserMaster(error));
  }
}

// delete
const DeleteUserMasterApi = (action: PayloadAction<number>) => {
  return api.delete("/user/delete", {
    params: { id: action.payload },
  });
};

export function* DeleteUserMasterCall(action: PayloadAction<number>) {
  try {
    const response: AxiosResponse = yield call(DeleteUserMasterApi, action);
    yield put(successdeleteUserMaster(response.data));
  } catch (error) {
    yield put(failuredeleteUserMaster(error));
  }
}
