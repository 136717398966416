import { createSlice } from "@reduxjs/toolkit";
import { IReduxState } from "../../interfaces/IReduxState";
import { IReportStockReceive } from "../../../Pages/ReportsStockReceive/IReportsStockReceive";

export const ListReportsStockReceive = createSlice({
  name: "ListReportsStockReceive",
  initialState: {} as IReduxState<IReportStockReceive[]>,
  reducers: {
    listReportsStockReceive: (state, _) => {
      state.isLoading = true;
    },
    responseReportsStockReceive: (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    },
  },
});

export const { listReportsStockReceive, responseReportsStockReceive } =
  ListReportsStockReceive.actions;
export default ListReportsStockReceive.reducer;
