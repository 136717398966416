import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IReduxState } from "../../interfaces/IReduxState";
import { IItemMaster } from "../../../Pages/ItemMaster/IItemMaster";

export const AddItemMasterSlice = createSlice({
  name: "AddItemMaster",
  initialState: {} as IReduxState<IItemMaster>,
  reducers: {
    addItemMaster: (state, _: PayloadAction<IItemMaster>) => {
      state.isLoading = true;
    },
    importItemMaster: (state, _) => {
      state.isLoading = true;
    },
    successaddItemMaster: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
    failureaddItemMaster: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
    clearAddItemMasterResponse: (state) => {
      state.data = {};
    },
  },
});

export const {
  addItemMaster,
  importItemMaster,
  failureaddItemMaster,
  successaddItemMaster,
  clearAddItemMasterResponse,
} = AddItemMasterSlice.actions;

export default AddItemMasterSlice.reducer;
