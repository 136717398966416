import { FunctionComponent, useEffect, useState } from "react";
import "./LocationReceive.scss";
import {
  ILocationReceiveItems,
  LocationReceiveProps,
} from "./ILocationReceive";
import { useLocation, useNavigate } from "react-router-dom";
import { ILocationReceive } from "./ILocationReceive";
import { useAppDispatch, useAppSelector } from "../../Redux/Store/Hooks";
import { Columns } from "../../Components/Table/ITable";
import { dateFormatter } from "../../Utils/dateFormatter";
import {
  GridPreProcessEditCellProps,
  GridRenderCellParams,
  GridValueFormatterParams,
} from "@mui/x-data-grid";
import { ILocationMaster } from "../LocationMaster/ILocationMaster";
import IconButtonField from "../../Components/Button/IconButtonField";
import { SvgIcon } from "@mui/material";
import Table from "../../Components/Table/Table";
import CustomToolbar from "../../Components/Table/components/CustomToolbar";
import TableSearch from "../../Components/Table/components/TableSearch";
import Dialog from "../../Components/Dialog/Dialog";
import { ReactComponent as Edit } from "../../Assets/Images/edit.svg";
import { ReactComponent as ViewIcon } from "../../Assets/Images/solar_eye-linear.svg";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { statusOptionsFD } from "../StockRecieve/IStockRecieve";
import LocationReceiveView from "./LocationReceiveView";
import {
  clearLocationReceive,
  listLocationReceive,
} from "../../Redux/Slices/LocationReceive/ListLocationReceiveSlice";

const LocationReceive: FunctionComponent<LocationReceiveProps> = () => {
  const location = useLocation();
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [viewInitialValues, setViewInitialValues] = useState<
    ILocationReceiveItems[]
  >([]);

  const dispatch = useAppDispatch();
  const nav = useNavigate();
  const { data: LocationReceive } = useAppSelector(
    (state) => state.ListLocationReceive
  );

  const column: Columns = [
    {
      field: "index",
      renderHeader: () => <b>S.No</b>,
      align: "center",
      headerAlign: "center",
      width: 90,
    },
    {
      field: "entry_no",
      renderHeader: () => <b>Entry No</b>,
      flex: 0.6,
      align: "left",
      headerAlign: "left",
      type: "string",
    },
    {
      field: "entry_date",
      renderHeader: () => <b>Entry Date</b>,
      flex: 0.6,
      align: "left",
      headerAlign: "left",
      type: "dateTime",
      valueFormatter: (params) => dateFormatter(params.value),
    },
    {
      field: "receivefrom",
      renderHeader: () => <b>Receive From</b>,
      flex: 1,
      align: "left",
      headerAlign: "left",
      type: "string",
      valueFormatter: (params: GridValueFormatterParams<ILocationMaster>) => {
        return params.value?.name;
      },
    },
    {
      field: "receiveto",
      renderHeader: () => <b>Receive To</b>,
      flex: 1,
      align: "left",
      headerAlign: "left",
      type: "string",
      valueFormatter: (params: GridValueFormatterParams<ILocationMaster>) => {
        return params.value?.name;
      },
    },
    {
      field: "status",
      renderHeader: () => <b>Status</b>,
      flex: 0.6,
      align: "left",
      headerAlign: "left",
      type: "singleSelect",
      valueOptions: statusOptionsFD,
      // editable: true,
      preProcessEditCellProps: (params: GridPreProcessEditCellProps) => {
        // dispatch()
        return { ...params.props };
      },
    },
    {
      field: "actions",
      renderHeader: () => <b>Action</b>,
      type: "actions",
      renderCell: (params: GridRenderCellParams<ILocationReceive>) => {
        return (
          <>
            <IconButtonField
              onClick={() => {
                const itemDetails = params.row?.item_details?.map(
                  (items, index) => ({ ...items, index: index + 1 })
                );
                setDialogOpen(true);
                setViewInitialValues(itemDetails ?? []);
              }}
            >
              <SvgIcon
                inheritViewBox
                component={ViewIcon}
                sx={{ fill: "none" }}
              />
            </IconButtonField>

            <IconButtonField
              disabled={params.row.status === "DLVD"}
              onClick={() => {
                nav("edit", { state: { id: params.row.id } });
              }}
            >
              <SvgIcon sx={{ fill: "none" }} component={Edit} inheritViewBox />
            </IconButtonField>
          </>
        );
      },
    },
  ];

  useEffect(() => {
    dispatch(listLocationReceive({ key: "ST" }));
    return () => {
      dispatch(clearLocationReceive());
    };
  }, [dispatch, location]);

  const LocationReceiveList = LocationReceive?.data?.map((stock, index) => ({
    ...stock,
    index: index + 1,
  }));
  return (
    <>
      <Table rows={LocationReceiveList ?? []} columns={column}>
        <CustomToolbar>
          <h6>Location Receive</h6>
          <TableSearch />
        </CustomToolbar>
      </Table>

      <Dialog
        open={dialogOpen}
        dialogClassName="dialog_double_input"
        Dialog_Title={
          <>
            Items
            <IconButtonField
              onClick={() => {
                setDialogOpen(false);
              }}
            >
              <SvgIcon component={CloseOutlinedIcon} inheritViewBox />
            </IconButtonField>
          </>
        }
        Dialog_Content={
          <>
            <LocationReceiveView tableRows={viewInitialValues} />
          </>
        }
      />
    </>
  );
};

export default LocationReceive;
