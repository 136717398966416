import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IReduxState } from "../../interfaces/IReduxState";
import { IItemMaster } from "../../../Pages/ItemMaster/IItemMaster";

export const EditItemMasterSlice = createSlice({
  name: "EditItemMaster",
  initialState: {} as IReduxState<IItemMaster>,
  reducers: {
    editItemMaster: (state, _: PayloadAction<IItemMaster>) => {
      state.isLoading = true;
    },
    successeditItemMaster: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
    failureeditItemMaster: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
    clearEditItemMasterResponse: (state) => {
      state.data = {};
    },
  },
});

export const {
  editItemMaster,
  failureeditItemMaster,
  successeditItemMaster,
  clearEditItemMasterResponse,
} = EditItemMasterSlice.actions;

export default EditItemMasterSlice.reducer;
