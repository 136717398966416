import { createSlice } from "@reduxjs/toolkit";
import { IListStockRecieve } from "../../../Pages/StockRecieve/IStockRecieve";

export const EditStockRecieve = createSlice({
  name: "EditStockRecieve",
  initialState: {} as { data?: IListStockRecieve },
  reducers: {
    setEditStockRecieveData: (state, action) => {
      state.data = action.payload;
    },
    clearEditStockRecieveData: (state) => {
      state.data = {};
    },
  },
});

export const { clearEditStockRecieveData, setEditStockRecieveData } =
  EditStockRecieve.actions;
export default EditStockRecieve.reducer;
