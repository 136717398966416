import { AxiosResponse } from "axios";
import { call, put } from "redux-saga/effects";
import api from "../../Axios/middleware";
import { failureZoneConfig, successZoneConfig } from "./ListZoneConfigSlice";
import {
  failureaddZoneConfig,
  successaddZoneConfig,
} from "./AddZoneConfigSlice";
import { PayloadAction } from "@reduxjs/toolkit";
import { IZoneConfig } from "../../../Pages/ZoneConfig/IZoneConfig";
import {
  failureeditZoneConfig,
  successeditZoneConfig,
} from "./EditZoneConfigSlice";
import {
  failuredeleteZoneConfig,
  successdeleteZoneConfig,
} from "./DeleteZoneConfigSlice";

// list
const ListZoneConfigApi = (action: PayloadAction<number | null>) => {
  return api.get(`/zone-config/filter`);
};

export function* ListZoneConfigCall(action: PayloadAction<number | null>) {
  try {
    const response: AxiosResponse = yield call(ListZoneConfigApi, action);
    yield put(successZoneConfig(response.data));
  } catch (error) {
    yield put(failureZoneConfig(error));
  }
}

// add
const AddZoneConfigApi = (action: PayloadAction<IZoneConfig>) => {
  return api.post("/zone-config/create", action.payload);
};

export function* AddZoneConfigCall(action: PayloadAction<IZoneConfig>) {
  try {
    const response: AxiosResponse = yield call(AddZoneConfigApi, action);
    yield put(successaddZoneConfig(response.data));
  } catch (error) {
    yield put(failureaddZoneConfig(error));
  }
}

// edit
const EditZoneConfigApi = (action: PayloadAction<IZoneConfig>) => {
  return api.patch("/zone-config/update", action.payload, {
    params: { id: action.payload.id },
  });
};

export function* EditZoneConfigCall(action: PayloadAction<IZoneConfig>) {
  try {
    const response: AxiosResponse = yield call(EditZoneConfigApi, action);
    yield put(successeditZoneConfig(response.data));
  } catch (error) {
    yield put(failureeditZoneConfig(error));
  }
}

// delete
const DeleteZoneConfigApi = (action: PayloadAction<number>) => {
  return api.delete("/zone-config/delete", {
    params: { id: action.payload },
  });
};

export function* DeleteZoneConfigCall(action: PayloadAction<number>) {
  try {
    const response: AxiosResponse = yield call(DeleteZoneConfigApi, action);
    yield put(successdeleteZoneConfig(response.data));
  } catch (error) {
    yield put(failuredeleteZoneConfig(error));
  }
}
