import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IReduxState } from "../../interfaces/IReduxState";
import { IColorCode } from "../../../Pages/ColorCode/IColorCode";

export const EditColorCodeSlice = createSlice({
  name: "EditColorCode",
  initialState: {} as IReduxState<IColorCode>,
  reducers: {
    editColorCode: (state, _: PayloadAction<IColorCode>) => {
      state.isLoading = true;
    },
    successeditColorCode: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
    failureeditColorCode: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
    clearEditColorCodeResponse: (state) => {
      state.data = {};
    },
  },
});

export const {
  editColorCode,
  failureeditColorCode,
  successeditColorCode,
  clearEditColorCodeResponse,
} = EditColorCodeSlice.actions;

export default EditColorCodeSlice.reducer;
