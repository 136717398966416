import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IReduxState } from "../../interfaces/IReduxState";
import { IItemMaster } from "../../../Pages/ItemMaster/IItemMaster";

export const DeleteItemMasterSlice = createSlice({
  name: "DeleteItemMaster",
  initialState: {} as IReduxState<IItemMaster>,
  reducers: {
    deleteItemMaster: (state, _) => {
      state.isLoading = true;
    },
    successdeleteItemMaster: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
    failuredeleteItemMaster: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
    clearDeleteItemMasterResponse: (state) => {
      state.data = {};
    },
  },
});

export const {
  deleteItemMaster,
  failuredeleteItemMaster,
  successdeleteItemMaster,
  clearDeleteItemMasterResponse,
} = DeleteItemMasterSlice.actions;

export default DeleteItemMasterSlice.reducer;
