import { FunctionComponent, useEffect, useRef } from "react";
import Table from "../../Components/Table/Table";
import CustomToolbar from "../../Components/Table/components/CustomToolbar";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { ILocationReceive } from "./ILocationReceive";
import Select from "../../Components/Select/AutoComplete";
import { useAppDispatch, useAppSelector } from "../../Redux/Store/Hooks";
import { AutocompleteOption } from "../../Components/Select/ISelectProps";
import DatePickers from "../../Components/DatePicker/DatePicker";
import TextInputFieldC from "../../Components/TextInput/TextInputFieldC";
import { listLocationMaster } from "../../Redux/Slices/LocationMaster/ListLocationMasterSlice";
import { listTransactionRequest } from "../../Redux/Slices/TransactionRequest/ListTransactionRequestSlice";
import {
  clearGetoneLocationReceiveResponse,
  getoneLocationReceive,
} from "../../Redux/Slices/LocationReceive/GetoneLocationReceiveSlice";
import { useLocation, useNavigate } from "react-router-dom";
import { Columns } from "../../Components/Table/ITable";
import CustomFooter from "../../Components/Table/components/CustomFooter";
import ButtonField from "../../Components/Button/ButtonField";
import {
  clearEditLocationReceiveResponse,
  editLocationReceive,
} from "../../Redux/Slices/LocationReceive/EditLocationReceiveSlice";
import ResponseDialog from "../../Components/ResponseDialog/ResponseDialog";
import dayjs from "dayjs";

interface LocationReceiveFormProps {}

const LocationReceiveForm: FunctionComponent<LocationReceiveFormProps> = () => {
  const dispatch = useAppDispatch();
  const nav = useNavigate();
  const requestFormRef = useRef<HTMLFormElement>(null);
  const location = useLocation();

  const { data: locationsList } = useAppSelector(
    (state) => state.ListLocationMaster
  );
  const { data: requestList } = useAppSelector(
    (state) => state.ListTransactionRequest
  );
  const { data: locationReceive } = useAppSelector(
    (state) => state.GetoneLocationReceive
  );
  const { data: editLocation } = useAppSelector(
    (state) => state.editLocationReceive
  );

  // react hook form init
  const methods = useForm<ILocationReceive>();

  // form submit
  const onSubmit: SubmitHandler<ILocationReceive> = (val) => {
    dispatch(editLocationReceive({ ...val, status: "DLVD" }));
  };

  // dropdown lists
  const locations = Array.isArray(locationsList?.data)
    ? locationsList?.data.map((item) => ({ label: item.name!, value: item.id }))
    : [];
  const requests = Array.isArray(requestList?.data)
    ? requestList?.data.map((item) => ({
        label: item.entry_no!,
        value: item.id,
      }))
    : [];

  // init
  useEffect(() => {
    dispatch(listLocationMaster());
    dispatch(listTransactionRequest(null));
    dispatch(getoneLocationReceive(location.state.id));
    return () => {
      dispatch(clearEditLocationReceiveResponse());
      dispatch(clearGetoneLocationReceiveResponse());
    };
  }, [dispatch, location]);

  useEffect(() => {
    methods.reset({
      ...locationReceive?.data,
      receive_to: locationReceive?.data?.location_id,
      entry_date: dayjs(locationReceive?.data?.entry_date),
    });
  }, [locationReceive?.data, methods]);

  const columns: Columns = [
    {
      field: "index",
      renderHeader: () => <b>S.No</b>,
      align: "center",
      headerAlign: "center",
      width: 90,
    },
    {
      field: "item_name",
      renderHeader: () => <b>Item</b>,
      flex: 1,
      align: "left",
      headerAlign: "left",
      type: "string",
    },
    {
      field: "transfer_qnty",
      renderHeader: () => <b>Transferd Quantity</b>,
      flex: 1,
      align: "right",
      headerAlign: "right",
      type: "number",
    },
    {
      field: "item_qnty",
      renderHeader: () => <b>Received Quantity</b>,
      flex: 1,
      align: "right",
      headerAlign: "right",
      type: "number",
      // renderEditCell: (params) => (
      //   <GridEditInputCell
      //     {...params}
      //     error={true}
      //     value={10}
      //     inputProps={{
      //       max: 10,
      //       min: 0,
      //     }}
      //   />
      // ),
    },
  ];

  // table rows
  const tableRows = methods.getValues("item_details")?.map((row, index) => ({
    ...row,
    index: index + 1,
    id: row.id ?? index,
    transfer_qnty:
      (locationReceive?.data?.transfer.item_details ?? [])[index].item_qnty ??
      null,
  }));

  return (
    <>
      <Table rows={tableRows ?? []} columns={columns}>
        <CustomToolbar>
          <h6>Edit Location Receive</h6>
          <FormProvider {...methods}>
            <form
              ref={requestFormRef}
              onSubmit={methods.handleSubmit(onSubmit)}
              className="new_ui_form_single"
            >
              <div className="form_field_first_line">
                <div className="form_start_fields">
                  <Select
                    size="small"
                    options={locations ?? []}
                    sx={{ width: "100%" }}
                    name="receive_from"
                    label="Receive From"
                    disabled
                    value={
                      locations?.find(
                        (location) =>
                          location.value === methods.getValues("receive_from")
                      ) ?? null
                    }
                    onChange={(_, val) => {
                      methods.setValue(
                        "receive_from",
                        (val as AutocompleteOption)?.value ?? null,
                        { shouldValidate: true }
                      );
                    }}
                  />
                </div>
                <div className="form_end_fields">
                  <DatePickers
                    size="small"
                    sx={{ width: "100%" }}
                    name="entry_date"
                    label="Entry Date"
                    disabled
                  />
                </div>
              </div>
              <div className="form_field_second_line">
                <div className="form_start_fields">
                  <Select
                    size="small"
                    sx={{ width: "100%" }}
                    options={locations ?? []}
                    name="receive_to"
                    label="Receive To"
                    disabled
                    value={
                      locations?.find(
                        (location) =>
                          location.value === methods.getValues("receive_to")
                      ) ?? null
                    }
                    onChange={(_, val) => {
                      methods.setValue(
                        "receive_to",
                        (val as AutocompleteOption)?.value ?? null,
                        { shouldValidate: true }
                      );
                    }}
                  />
                  {/* <Select
                  size="small"
                  sx={{ width: "100%" }}
                  options={locations ?? []}
                  name="receive_to"
                  label="Transfer To"
                  disabled
                  value={
                    locations?.find(
                      (location) =>
                        location.value === methods.getValues("receive_to")
                    ) ?? null
                  }
                  onChange={(_, val) => {
                    methods.setValue(
                      "receive_to",
                      (val as AutocompleteOption)?.value ?? null,
                      { shouldValidate: true }
                    );
                  }}
                /> */}
                </div>
                <div className="form_end_fields">
                  <TextInputFieldC
                    sx={{ width: "100%" }}
                    size="small"
                    name="entry_no"
                    label="Entry No"
                    disabled
                  />
                </div>
              </div>
              <div className="form_field_third_line">
                <div className="form_start_fields">
                  <Select
                    size="small"
                    sx={{ width: "100%" }}
                    options={requests ?? []}
                    name="request_id"
                    label="Request No"
                    disabled
                    value={
                      requests?.find(
                        (request) =>
                          request.value === methods.getValues("request_id")
                      ) ?? null
                    }
                    onChange={(_, val) => {
                      const value = val as AutocompleteOption;
                      methods.setValue("request_id", value?.value ?? null, {
                        shouldValidate: true,
                      });
                    }}
                  />
                </div>
              </div>
            </form>
          </FormProvider>
        </CustomToolbar>
        <CustomFooter className="item_footer">
          <ButtonField
            className="form_cancel_button"
            //   onClick={() => setDialogOpen(false)}
            onClick={() => nav(-1)}
          >
            Cancel
          </ButtonField>

          <ButtonField
            className="form_save_button"
            onClick={() => requestFormRef.current?.requestSubmit()}
          >
            Save
          </ButtonField>
        </CustomFooter>
      </Table>
      <ResponseDialog response={editLocation} okFunction={() => nav(-1)} />
    </>
  );
};

export default LocationReceiveForm;
