import { createSlice } from "@reduxjs/toolkit";
import { IReduxState } from "../../interfaces/IReduxState";
import { IRole } from "../../../Pages/UserMaster/IUserMaster";

export const RoleSlice = createSlice({
  name: "RoleSlice",
  initialState: {} as IReduxState<IRole[]>,
  reducers: {
    listRoleSlice: (state) => {
      state.isLoading = true;
    },
    successRoleSlice: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
    failureRoleSlice: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
  },
});

export const { failureRoleSlice, listRoleSlice, successRoleSlice } =
  RoleSlice.actions;

export default RoleSlice.reducer;
