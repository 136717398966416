import { FunctionComponent, useEffect, useRef, useState } from "react";
import "./ZoneConfig.scss";
import Table from "../../Components/Table/Table";
import { IZoneConfig, ZoneConfigProps } from "./IZoneConfig";
import { Columns } from "../../Components/Table/ITable";
import CustomToolbar from "../../Components/Table/components/CustomToolbar";
import TableSearch from "../../Components/Table/components/TableSearch";
import { useAppDispatch, useAppSelector } from "../../Redux/Store/Hooks";
import { listZoneConfig } from "../../Redux/Slices/ZoneConfig/ListZoneConfigSlice";
import ButtonField from "../../Components/Button/ButtonField";
import Dialog from "../../Components/Dialog/Dialog";
import ZoneConfigForm from "./ZoneConfigForm";
import ResponseDialog from "../../Components/ResponseDialog/ResponseDialog";
import { clearAddZoneConfigResponse } from "../../Redux/Slices/ZoneConfig/AddZoneConfigSlice";
import IconButtonField from "../../Components/Button/IconButtonField";
import { SvgIcon } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { GridRenderCellParams } from "@mui/x-data-grid";
import { IDeleteDialog } from "../../Components/DeleteDialog/IDeleteDialog";
import DeleteDialog from "../../Components/DeleteDialog/DeleteDialog";
import { ReactComponent as Delete } from "../../Assets/Images/delete.svg";
import { ReactComponent as Edit } from "../../Assets/Images/edit.svg";
import { clearEditZoneConfigResponse } from "../../Redux/Slices/ZoneConfig/EditZoneConfigSlice";
import {
  clearDeleteZoneConfigResponse,
  deleteZoneConfig,
} from "../../Redux/Slices/ZoneConfig/DeleteZoneConfigSlice";
import { ReactComponent as ViewIcon } from "../../Assets/Images/solar_eye-linear.svg";
import { listLocationMaster } from "../../Redux/Slices/LocationMaster/ListLocationMasterSlice";
import Select from "../../Components/withoutControllerComponents/Select/Select";
import { AutocompleteOption } from "../../Components/Select/ISelectProps";

const ZoneConfig: FunctionComponent<ZoneConfigProps> = () => {
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState<boolean>(false);
  const [deleteDialogData, setDeleteData] = useState<IDeleteDialog>({});
  const [initialValues, setInitialValues] = useState<IZoneConfig | {}>({});
  const nav = useNavigate();
  const dispatch = useAppDispatch();
  const { data: zoneConfig } = useAppSelector((state) => state.ListZoneConfig);
  const { data: addZone } = useAppSelector((state) => state.AddZoneConfig);
  const { data: editZone } = useAppSelector((state) => state.EditZoneConfig);
  const { data: deleteZone } = useAppSelector(
    (state) => state.DeleteZoneConfig
  );
  const formRef = useRef<HTMLFormElement>(null);

  const { data: Location } = useAppSelector(
    (state) => state.ListLocationMaster
  );
  const LocationList = Array.isArray(Location?.data)
    ? Location?.data.map((type) => ({ label: type.name!, value: type.id! }))
    : [];

  const columns: Columns = [
    {
      field: "index",
      renderHeader: () => <b>S.No</b>,
      width: 90,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "name",
      renderHeader: () => <b>Name</b>,
      flex: 1,
      align: "left",
      headerAlign: "left",
      type: "string",
    },
    {
      field: "code",
      renderHeader: () => <b>Code</b>,
      flex: 1,
      align: "left",
      headerAlign: "left",
      type: "string",
    },
    {
      field: "row_prefix",
      renderHeader: () => <b>Row Prefix</b>,
      flex: 1,
      align: "left",
      headerAlign: "left",
      type: "string",
    },
    {
      field: "row_len",
      renderHeader: () => <b>Row Count</b>,
      flex: 1,
      align: "right",
      headerAlign: "right",
      type: "string",
    },
    {
      field: "col_prefix",
      renderHeader: () => <b>Column Prefix</b>,
      flex: 1,
      align: "left",
      headerAlign: "left",
      type: "string",
    },
    {
      field: "col_len",
      renderHeader: () => <b>Column Count</b>,
      flex: 1,
      align: "right",
      headerAlign: "right",
      type: "string",
    },
    {
      field: "pallete_prefix",
      renderHeader: () => <b>Pallete Prefix</b>,
      flex: 1,
      align: "left",
      headerAlign: "left",
      type: "string",
    },
    {
      field: "pallete_len",
      renderHeader: () => <b>Pallete Count</b>,
      flex: 1,
      align: "right",
      headerAlign: "right",
      type: "string",
    },
    {
      field: "action",
      renderHeader: () => <b>Action</b>,
      flex: 1,
      type: "actions",
      renderCell: (params: GridRenderCellParams<IZoneConfig>) => {
        return (
          <>
            <IconButtonField
              onClick={() => nav(`storage-area`, { state: params.id })}
            >
              <SvgIcon
                inheritViewBox
                component={ViewIcon}
                sx={{ fill: "none" }}
              />
            </IconButtonField>
            {/* <IconButtonField
              onClick={() => {
                setInitialValues({ ...params.row });
                setDialogOpen(true);
              }}
            >
              <SvgIcon sx={{ fill: "none" }} component={Edit} inheritViewBox />
            </IconButtonField> */}
            <IconButtonField
              onClick={() => {
                setDeleteData({ id: params.id, name: params.row.name });
                setDeleteDialogOpen(true);
              }}
            >
              <SvgIcon component={Delete} inheritViewBox />
            </IconButtonField>
          </>
        );
      },
    },
  ];
  useEffect(() => {
    dispatch(listZoneConfig(""));
  }, [addZone, editZone, deleteZone, dispatch]);
  useEffect(() => {
    dispatch(listLocationMaster());
    return () => {
      dispatch(clearAddZoneConfigResponse());
      dispatch(clearEditZoneConfigResponse());
      dispatch(clearDeleteZoneConfigResponse());
    };
  }, [dispatch]);
  const zoneConfigList = Array.isArray(zoneConfig?.data)
    ? zoneConfig?.data.map((zone, index) => ({ ...zone, index: index + 1 }))
    : [];
  return (
    <>
      <Table rows={zoneConfigList ?? []} columns={columns}>
        <CustomToolbar className="custom_toolbar">
          <h6>Zone</h6>
          {/* <Select
            size="small"
            label="Location Filter"
            options={LocationList}
            onChange={(_, val) => {
              const value = val as AutocompleteOption;
              if (value) {
                dispatch(listZoneConfig(value.value));
              } else {
                dispatch(listZoneConfig(""));
              }
            }}
          /> */}
          <div className="custom_toolbar_search_add_new">
            <TableSearch />
            <ButtonField
              className="tabel_add_new_button"
              onClick={() => setDialogOpen(true)}
            >
              Add new
            </ButtonField>
          </div>
        </CustomToolbar>
      </Table>

      <Dialog
        open={dialogOpen}
        dialogClassName="dialog_double_input"
        Dialog_Title={`${
          (initialValues as IZoneConfig).id ? "Update" : "Add"
        } Zone Config`}
        Dialog_Content={
          <ZoneConfigForm
            formRef={formRef}
            initialValues={initialValues}
            setInitialValues={setInitialValues}
          />
        }
        Dialog_Actions={
          <>
            <ButtonField
              className="form_cancel_button"
              onClick={() => setDialogOpen(false)}
            >
              Cancel
            </ButtonField>
            <ButtonField
              className="form_save_button"
              onClick={() => formRef.current?.requestSubmit()}
            >
              Save
            </ButtonField>
          </>
        }
      />

      <ResponseDialog response={addZone} parentDialog={setDialogOpen} />
      <ResponseDialog response={editZone} parentDialog={setDialogOpen} />
      <DeleteDialog
        deleteableName={deleteDialogData.name ?? ""}
        open={deleteDialogOpen}
        setOpen={setDeleteDialogOpen}
        deleteFunction={() => {
          dispatch(deleteZoneConfig(deleteDialogData.id));
        }}
        response={deleteZone}
      />
    </>
  );
};

export default ZoneConfig;
