import { FilterMatchMode } from "primereact/api";
import { DataTable, DataTableFilterMeta } from "primereact/datatable";
import React, { FunctionComponent, useState } from "react";
import { ITableRPProps } from "./ITableRP";
import { Column } from "primereact/column";
import { IconField } from "primereact/iconfield";
import { InputIcon } from "primereact/inputicon";
import { InputText } from "primereact/inputtext";
import { IReportsStockDetails } from "../../Pages/ReportsStockDetails/IReportsStockDetails";
import IconButtonField from "../Button/IconButtonField";
import { FormControl, FormLabel, SvgIcon } from "@mui/material";
import { ReactComponent as ViewIcon } from "../../Assets/Images/solar_eye-linear.svg";
import "./TableRP.scss";
import Select from "../withoutControllerComponents/Select/Select";
import { ReactComponent as NoData } from "../../Assets/Images/NoData.svg";
import { exportToExcel } from "../../Utils/exportToExcel";
import { exportToCSV } from "../../Utils/exportToCSV";
import { exportToPDF } from "../../Utils/exportToPDF";
import { ReactComponent as ExcelExportIcon } from "../../Assets/Images/export-excel-2.svg";
import { ReactComponent as PdfExportIcon } from "../../Assets/Images/pdf-32.svg";
import { ReactComponent as CSVExportIcon } from "../../Assets/Images/csv-icon.svg";
import TextInputField from "../TextInput/TextInputField";
import SearchIcon from "@mui/icons-material/Search";
const TableRP: FunctionComponent<ITableRPProps> = (props) => {
  const {
    columns,
    rows,
    footer,
    headerWithSearch,
    headerTitle,
    size,
    scrollHeight,
    viewRowData,
    sortMode,
    actionColumn,
    headerEndComponent,
    headerStartComponent,
    CSVExport,
    ExcelExport,
    ExportName = "TableExport",
    PdfExport,
  } = props;

  const [globalFilterValue, setGlobalFilterValue] = useState<string>("");
  const [filters, setFilters] = useState<DataTableFilterMeta>({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });
  const onGlobalFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    let _filters = { ...filters };

    // @ts-ignore
    _filters["global"].value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
  };
  const header = (
    <>
      <div className="flex flex-wrap align-items-center justify-content-between gap-3">
        <span
          style={{ fontSize: "1.3rem", padding: "10px 5px" }}
          className=" text-900 font-bold"
        >
          {headerTitle}
        </span>
        <div className="flex flex-wrap align-items-center gap-3">
          <div className="flex align-items-center justify-content-start gap-3">
            <div>{headerStartComponent}</div>
            {/* <fieldset className="report_fieldset">
              <legend className="reports_label">Search</legend> */}
            <div className="flex justify-content-end ">
              <IconField iconPosition="left" className="rounded">
                <InputIcon className="pi pi-search" />
                <InputText
                  value={globalFilterValue}
                  onChange={onGlobalFilterChange}
                  placeholder="Keyword Search"
                />
              </IconField>
              {/* <TextInputField
                size="small"
                value={globalFilterValue}
                onChange={onGlobalFilterChange}
                placeholder="Keyword Search"
                InputProps={{
                  startAdornment: <SearchIcon />,
                }}
              /> */}
            </div>
            {/* </fieldset> */}
          </div>
          <div className="search_export_container">
            <div
              style={{ width: "100%", display: "flex", alignItems: "center" }}
            >
              <fieldset className="report_fieldset">
                <legend className="reports_label">Export Types</legend>
                <div className="report_export_btn_container">
                  <IconButtonField
                    sx={{
                      display: ExcelExport ? "flex" : "none",
                    }}
                    className="report_export_btn"
                    toolTip="Export To Excel"
                    onClick={() => exportToExcel(columns, rows, ExportName)}
                  >
                    <SvgIcon
                      component={ExcelExportIcon}
                      inheritViewBox
                      sx={{ height: "17px", width: "17px" }}
                    />
                  </IconButtonField>
                  <IconButtonField
                    sx={{ display: CSVExport ? "flex" : "none" }}
                    className="report_export_btn"
                    toolTip="Export To CSV"
                    onClick={() => exportToCSV(columns, rows, ExportName)}
                  >
                    <SvgIcon
                      component={CSVExportIcon}
                      inheritViewBox
                      sx={{ height: "19px", width: "19px" }}
                    />
                  </IconButtonField>
                  <IconButtonField
                    sx={{ display: PdfExport ? "flex" : "none" }}
                    className="report_export_btn"
                    toolTip="Export To PDF"
                    onClick={() => exportToPDF(columns, rows, ExportName)}
                  >
                    <SvgIcon
                      component={PdfExportIcon}
                      inheritViewBox
                      sx={{ height: "17px", width: "17px" }}
                    />
                  </IconButtonField>
                </div>
              </fieldset>
              {headerEndComponent}
            </div>
          </div>
        </div>
      </div>
    </>
  );
  const viewTemplate = (rowData: IReportsStockDetails) => {
    return (
      <IconButtonField
        onClick={() => {
          if (viewRowData) viewRowData(rowData);
        }}
      >
        <SvgIcon sx={{ fill: "none" }} component={ViewIcon} inheritViewBox />
      </IconButtonField>
    );
  };

  return (
    <>
      <DataTable
        value={rows}
        header={headerWithSearch ? header : <></>}
        footer={footer}
        size={size}
        filters={filters}
        // showGridlines
        scrollable
        scrollHeight={scrollHeight}
        sortMode={sortMode}
        emptyMessage={
          <>
            <div className="custom_no_rows">
              <SvgIcon
                className="table_svg_icon"
                component={NoData}
                inheritViewBox
              />
              No Data Found
            </div>
          </>
        }
        removableSort
      >
        {columns.map((col, i) => (
          <Column
            key={col.field}
            field={col.field}
            header={col.header}
            body={col.body}
            align={col.align}
            alignHeader={col.alignHeader}
            sortable={col.sortable}
          />
        ))}
        {actionColumn ? (
          <Column
            header="Actions"
            body={viewTemplate}
            alignHeader="center"
            style={{ textAlign: "center", width: "4em" }}
          />
        ) : null}
      </DataTable>
    </>
  );
};

export default TableRP;
