import { AxiosResponse } from "axios";
import { call, put } from "redux-saga/effects";
import api from "../../Axios/middleware";
import {
  failureDivisionMaster,
  successDivisionMaster,
} from "./ListDivisionMasterSlice";
import { IDivisionMaster } from "../../../Pages/DivisionMaster/IDivisionMaster";
import { PayloadAction } from "@reduxjs/toolkit";
import {
  failureaddDivisionMaster,
  successaddDivisionMaster,
} from "./AddDivisionMasterSlice";
import {
  failureeditDivisionMaster,
  successeditDivisionMaster,
} from "./EditDivisionMasterSlice";
import {
  failuredeleteDivisionMaster,
  successdeleteDivisionMaster,
} from "./DeleteDivisionMasterSlice";

// list
const ListDivisionMasterApi = () => {
  return api.get("/division");
};

export function* ListDivisionMasterCall() {
  try {
    const response: AxiosResponse = yield call(ListDivisionMasterApi);
    yield put(successDivisionMaster(response.data));
  } catch (error) {
    yield put(failureDivisionMaster(error));
  }
}

// add
const AddDivisionMasterApi = (action: PayloadAction<IDivisionMaster>) => {
  return api.post("/division/create", action.payload);
};

export function* AddDivisionMasterCall(action: PayloadAction<IDivisionMaster>) {
  try {
    const response: AxiosResponse = yield call(AddDivisionMasterApi, action);
    yield put(successaddDivisionMaster(response.data));
  } catch (error) {
    yield put(failureaddDivisionMaster(error));
  }
}

// edit
const EditDivisionMasterApi = (action: PayloadAction<IDivisionMaster>) => {
  return api.patch("/division/update", action.payload, {
    params: { id: action.payload.id },
  });
};

export function* EditDivisionMasterCall(
  action: PayloadAction<IDivisionMaster>
) {
  try {
    const response: AxiosResponse = yield call(EditDivisionMasterApi, action);
    yield put(successeditDivisionMaster(response.data));
  } catch (error) {
    yield put(failureeditDivisionMaster(error));
  }
}

// delete
const DeleteDivisionMasterApi = (action: PayloadAction<number>) => {
  return api.delete("/division/delete", {
    params: { id: action.payload },
  });
};

export function* DeleteDivisionMasterCall(action: PayloadAction<number>) {
  try {
    const response: AxiosResponse = yield call(DeleteDivisionMasterApi, action);
    yield put(successdeleteDivisionMaster(response.data));
  } catch (error) {
    yield put(failuredeleteDivisionMaster(error));
  }
}
