import { FunctionComponent, useEffect, useState } from "react";
import "./ReportsStockTransfer.scss";
import { ReportsStockTransferProps } from "./IReportsStockTransfer";
import { dateFormatter } from "../../Utils/dateFormatter";
import Select from "../../Components/withoutControllerComponents/Select/Select";
import { useAppDispatch, useAppSelector } from "../../Redux/Store/Hooks";
import { listReportsStockTransfer } from "../../Redux/Slices/ReportStockTransfer/ListReportsStockTransferSlice";
import { listLocationMaster } from "../../Redux/Slices/LocationMaster/ListLocationMasterSlice";
import { AutocompleteOption } from "../../Components/Select/ISelectProps";
import TableRP from "../../Components/Table/TableRP";
import { ColumnMeta } from "../../Components/Table/ITableRP";
import { IStockTransfer } from "../StockTransfer/IStockTransfer";
import StockTransferItemsView from "../StockTransfer/StockTransferItemsView";
import IconButtonField from "../../Components/Button/IconButtonField";
import { FormControlLabel, Radio, RadioGroup, SvgIcon } from "@mui/material";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import Dialog from "../../Components/Dialog/Dialog";
import { IItemDetails } from "../TransactionRequest/ITransactionRequest";
import { statusOptionsFD } from "../StockRecieve/IStockRecieve";
import DateRangePicker from "../../Components/DateRangePicker/DateRangePicker";
import { Range, RangeKeyDict } from "react-date-range";
import { addTimeToDate } from "../../Utils/AddTimeToDate";

const ReportsStockTransfer: FunctionComponent<
  ReportsStockTransferProps
> = () => {
  const dispatch = useAppDispatch();
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [viewInitialValues, setViewInitialValues] = useState<IItemDetails[]>(
    []
  );
  const [dateRange, setDateRange] = useState<Range | undefined>();
  const [locationFilterId, SetLocationFilterId] = useState<number>();
  const [detailedReport, setDetailedReport] = useState<boolean>(false);

  const { data: stockTransfer } = useAppSelector(
    (state) => state.ListReportsStockTransfer
  );

  const { data: location, isLoading: locationLoading } = useAppSelector(
    (state) => state.ListLocationMaster
  );
  const stockTransferList = stockTransfer?.data?.map((stock, index) => ({
    index: index + 1,
    entry_no: stock.entry_no,
    entry_date: dateFormatter(stock.entry_date),
    from_name: stock.from_name,
    to_name: stock.to_name,
    total_items: stock.item_details?.length,
    status: statusOptionsFD.find((status) => status.value === stock.status)
      ?.label,
  }));
  console.log(stockTransfer?.data);
  const detailedStockTransfer = stockTransfer?.data?.flatMap((stock) => {
    return stock.item_details?.flatMap((items) => ({
      entry_no: stock.entry_no,
      entry_date: dateFormatter(stock.entry_date),
      from_name: stock.from_name,
      to_name: stock.to_name,
      item_name: items.items?.item_name,
      item_code: items.item_code,
      item_qnty: items.item_qnty,
      status: statusOptionsFD.find((status) => status.value === stock.status)
        ?.label,
    }));
  });
  const DetailedReportList = detailedStockTransfer?.map((stocks, i) => ({
    index: i + 1,
    ...stocks,
  }));

  // console.log(stockTransferList);
  const [value, setValue] = useState("summary");

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue((event.target as HTMLInputElement).value);
    setDetailedReport(
      (event.target as HTMLInputElement).value === "detail" ? true : false
    );
  };
  const columns: ColumnMeta[] = detailedReport
    ? [
        {
          field: "index",
          header: "S.No",
          align: "center",
          alignHeader: "center",
        },
        {
          field: "entry_no",
          header: "Entry No",
          align: "left",
          alignHeader: "left",
        },
        {
          field: "entry_date",
          header: "Entry Date",
          align: "left",
          alignHeader: "left",
        },
        {
          field: "from_name",
          header: "Transfer From",
          align: "left",
          alignHeader: "left",
        },
        {
          field: "to_name",
          header: "Transfer To",
          align: "left",
          alignHeader: "left",
        },
        {
          field: "item_name",
          header: "Item Name",
          align: "left",
          alignHeader: "left",
        },
        {
          field: "item_code",
          header: "Item Code",
          align: "left",
          alignHeader: "left",
        },
        {
          field: "item_qnty",
          header: "Item Quantity",
          align: "right",
          alignHeader: "right",
        },
        {
          field: "status",
          header: "Status",
          align: "left",
          alignHeader: "left",
        },
      ]
    : [
        {
          field: "index",
          header: "S.No",
          align: "center",
          alignHeader: "center",
        },
        {
          field: "entry_no",
          header: "Entry No",
          align: "left",
          alignHeader: "left",
        },
        {
          field: "entry_date",
          header: "Entry Date",
          align: "left",
          alignHeader: "left",
        },
        {
          field: "from_name",
          header: "Transfer From",
          align: "left",
          alignHeader: "left",
        },
        {
          field: "to_name",
          header: "Transfer To",
          align: "left",
          alignHeader: "left",
        },
        {
          field: "total_items",
          header: "Total Items",
          align: "right",
          alignHeader: "right",
        },
        {
          field: "status",
          header: "Status",
          align: "left",
          alignHeader: "left",
        },
      ];

  useEffect(() => {
    dispatch(listReportsStockTransfer({ key: "SRE" }));
    dispatch(listLocationMaster());
  }, [dispatch]);

  // console.log(stockTransferList);

  const locationList = location?.data?.map((location) => ({
    label: location.name!,
    value: location.id,
  }));

  return (
    <>
      <TableRP
        columns={columns}
        size="small"
        rows={
          detailedReport ? DetailedReportList ?? [] : stockTransferList ?? []
        }
        CSVExport
        ExcelExport
        PdfExport
        ExportName="stock_transfer_report"
        headerStartComponent={
          <div className="report_start_component">
            <fieldset className="report_fieldset">
              <legend className="reports_label">Report Type</legend>
              <RadioGroup
                name="controlled-radio-buttons-group"
                value={value}
                onChange={handleChange}
                sx={{
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <FormControlLabel
                  value="summary"
                  control={<Radio size="small" />}
                  label="Summary"
                />
                <FormControlLabel
                  value="detail"
                  control={<Radio size="small" />}
                  label="Detail"
                />
              </RadioGroup>
            </fieldset>
            <div className="report_filters_container">
              <DateRangePicker
                setRanges={setDateRange}
                showMonthAndYearPickers={false}
                size="small"
                placeholder="Choose Report Period"
                ranges={dateRange}
                direction="horizontal"
                handleOkBtn={() =>
                  dispatch(
                    listReportsStockTransfer({
                      key: "SRE",
                      startDate: addTimeToDate(dateRange?.startDate!, 5, 30),
                      endDate: addTimeToDate(dateRange?.endDate!, 5, 30),
                      to: locationFilterId && locationFilterId,
                    })
                  )
                }
                onChange={(item: RangeKeyDict) => {
                  setDateRange(item.selection);
                }}
                handleRangeClear={() =>
                  dispatch(
                    listReportsStockTransfer({
                      key: "SRE",
                      to: locationFilterId && locationFilterId,
                    })
                  )
                }
              />
              <Select
                size="small"
                placeholder="Transfer To"
                options={locationList ?? []}
                loading={locationLoading}
                onChange={(_, val) => {
                  if (val) {
                    const value = val as AutocompleteOption;
                    SetLocationFilterId(value.value);
                    dispatch(
                      listReportsStockTransfer({
                        to: value.value,
                        key: "SRE",
                        startDate:
                          dateRange?.startDate &&
                          addTimeToDate(dateRange?.startDate!, 5, 30),
                        endDate:
                          dateRange?.endDate &&
                          addTimeToDate(dateRange?.endDate!, 5, 30),
                      })
                    );
                  } else {
                    SetLocationFilterId(undefined);
                    dispatch(
                      listReportsStockTransfer({
                        key: "SRE",
                        startDate:
                          dateRange?.startDate &&
                          addTimeToDate(dateRange?.startDate!, 5, 30),
                        endDate:
                          dateRange?.endDate &&
                          addTimeToDate(dateRange?.endDate!, 5, 30),
                      })
                    );
                  }
                }}
              />
            </div>
          </div>
        }
        viewRowData={(data: IStockTransfer) => {
          console.log(data);

          const itemDetails = data.item_details?.map((items, index) => ({
            ...items,
            index: index + 1,
          }));
          console.log(itemDetails);

          setDialogOpen(true);
          setViewInitialValues(itemDetails ?? []);
        }}
        headerWithSearch
        headerTitle="Stock Transfer Report"
        // actionColumn
      />
      <Dialog
        open={dialogOpen}
        setOpen={setDialogOpen}
        dialogClassName="dialog_double_input"
        Dialog_Title={
          <>
            Stock Transfer Items
            <IconButtonField
              onClick={() => {
                setDialogOpen(false);
              }}
            >
              <SvgIcon
                component={CloseOutlinedIcon}
                inheritViewBox
                sx={{ height: "30px", width: "30px" }}
              />
            </IconButtonField>
          </>
        }
        Dialog_Content={
          <StockTransferItemsView tableRows={viewInitialValues} />
        }
      />
    </>
  );
};

export default ReportsStockTransfer;
