import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IReduxState } from "../../interfaces/IReduxState";
import { IReportsStockRequest } from "../../../Pages/ReportsStockRequest/IReportsStockRequest";
import { ITransactionRequest } from "../../../Pages/TransactionRequest/ITransactionRequest";
import { Filter } from "../../../Pages/StockRecieve/IStockRecieve";

export const ListReportsStockRequest = createSlice({
  name: "ListReportsStockRequest",
  initialState: {} as IReduxState<ITransactionRequest[]>,
  reducers: {
    listReportsStockRequest: (state, _:PayloadAction<Filter>) => {
      state.isLoading = true;
    },
    responseReportsStockRequest: (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    },
  },
});

export const { listReportsStockRequest, responseReportsStockRequest } =
  ListReportsStockRequest.actions;
export default ListReportsStockRequest.reducer;
