import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IReduxState } from "../../interfaces/IReduxState";
import { IZoneConfig } from "../../../Pages/ZoneConfig/IZoneConfig";

export const AddZoneConfigSlice = createSlice({
  name: "AddZoneConfig",
  initialState: {} as IReduxState<IZoneConfig>,
  reducers: {
    addZoneConfig: (state, _: PayloadAction<IZoneConfig>) => {
      state.isLoading = true;
    },
    successaddZoneConfig: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
    failureaddZoneConfig: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
    clearAddZoneConfigResponse: (state) => {
      state.data = {};
    },
  },
});

export const {
  addZoneConfig,
  failureaddZoneConfig,
  successaddZoneConfig,
  clearAddZoneConfigResponse,
} = AddZoneConfigSlice.actions;

export default AddZoneConfigSlice.reducer;
