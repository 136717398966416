import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IReduxState } from "../../interfaces/IReduxState";
import { ILocationMaster } from "../../../Pages/LocationMaster/ILocationMaster";

export const AddLocationMasterSlice = createSlice({
  name: "AddLocationMaster",
  initialState: {} as IReduxState<ILocationMaster>,
  reducers: {
    addLocationMaster: (state, _: PayloadAction<ILocationMaster>) => {
      state.isLoading = true;
    },
    successaddLocationMaster: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
    failureaddLocationMaster: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
    clearAddLocationMasterResponse: (state) => {
      state.data = {};
    },
  },
});

export const {
  addLocationMaster,
  failureaddLocationMaster,
  successaddLocationMaster,
  clearAddLocationMasterResponse,
} = AddLocationMasterSlice.actions;

export default AddLocationMasterSlice.reducer;
