import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IReduxState } from "../../interfaces/IReduxState";
import { IUserMaster } from "../../../Pages/UserMaster/IUserMaster";

export const AddUserMasterSlice = createSlice({
  name: "AddUserMasterSlice",
  initialState: {} as IReduxState<IUserMaster>,
  reducers: {
    addUserMaster: (state, _: PayloadAction<IUserMaster>) => {
      state.isLoading = true;
    },
    successaddUserMaster: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
    failureaddUserMaster: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
    clearAddUserMasterResponse: (state) => {
      state.data = {};
    },
  },
});

export const {
  addUserMaster,
  failureaddUserMaster,
  successaddUserMaster,
  clearAddUserMasterResponse,
} = AddUserMasterSlice.actions;

export default AddUserMasterSlice.reducer;
