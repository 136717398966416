import "./ColorCode.scss";
import { FunctionComponent, useEffect } from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import DatePickers from "../../Components/DatePicker/DatePicker";
import { IColorCode } from "./IColorCode";
import { editColorCode } from "../../Redux/Slices/ColorCode/EditColorCodeSlice";
import { addColorCode } from "../../Redux/Slices/ColorCode/AddColorCodeSlice";
import { useAppDispatch } from "../../Redux/Store/Hooks";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import TextInputFieldC from "../../Components/TextInput/TextInputFieldC";
import dayjs from "dayjs";

interface ColorCodeFormProps {
  formRef: React.RefObject<HTMLFormElement>;
  initialValues: IColorCode;
  setInitialValues: React.Dispatch<React.SetStateAction<IColorCode>>;
}

const ColorCodeForm: FunctionComponent<ColorCodeFormProps> = (props) => {
  const dispatch = useAppDispatch();

  // schema
  const colorCodeSchema = z.object({
    id: z.number().optional(),
    color: z.string({ message: "Please Select Color" }),
    // year: z.custom((val) => val && dayjs(val).isValid(), {
    //   message: "Please select the Year",
    // }),
    month: z.custom((val) => val && dayjs(val).isValid(), {
      message: "Please select the Month",
    }),
  });
  // react hook form init
  const methods = useForm<IColorCode>({
    defaultValues: props.initialValues,
    resolver: zodResolver(colorCodeSchema),
  });

  const onSubmit: SubmitHandler<IColorCode> = (val) => {
    if (val.id) {
      dispatch(editColorCode(val));
    } else {
      dispatch(addColorCode(val));
    }
  };

  // clear
  useEffect(() => {
    return () => {
      props.setInitialValues({});
    };
  }, [props]);

  return (
    <>
      <FormProvider {...methods}>
        <form
          ref={props.formRef}
          onSubmit={methods.handleSubmit(onSubmit)}
          className="form_content"
        >
          {/* <DatePickers name="year" label="Select year" views={["year"]} /> */}

          <DatePickers
            name="month"
            label="Select month"
            views={["month"]}
            format="MMMM"
            disabled={!!methods.getValues("id")}
          />

          <TextInputFieldC label="Color" type="color" name="color" />
        </form>
      </FormProvider>
    </>
  );
};

export default ColorCodeForm;
