import { AxiosResponse } from "axios";
import { call, put } from "redux-saga/effects";
import api from "../../Axios/middleware";
import {
  successLocationReceive,
  failureLocationReceive,
} from "./ListLocationReceiveSlice";
import { ILocationReceive } from "../../../Pages/LocationReceive/ILocationReceive";
import { PayloadAction } from "@reduxjs/toolkit";
import {
  failureaddLocationReceive,
  successaddLocationReceive,
} from "./AddLocationReceiveSlice";
import {
  failureStockRequestDetails,
  successStockRequestDetails,
} from "./ListLocationReceiveDetailsSlice";
import {
  failureeditLocationReceive,
  successeditLocationReceive,
} from "./EditLocationReceiveSlice";
import {
  failureGetoneLocationReceive,
  successGetoneLocationReceive,
} from "./GetoneLocationReceiveSlice";
import { GenerateSequenceStart } from "../SequenceSlicer/GenerateSequence";
import { key } from "../../../Pages/TransactionRequest/ITransactionRequest";
import { Filter } from "../../../Pages/StockRecieve/IStockRecieve";

export interface IAPICall {
  key: string | null;
  from?: number | null;
}

// list
const ListLocationReceiveApi = (action: PayloadAction<Filter>) => {
  return api.get("/location-receive/getall",{params:action.payload});
};

export function* ListLocationReceiveCall(action: PayloadAction<Filter>) {
  try {
    const response: AxiosResponse = yield call(ListLocationReceiveApi, action);
    yield put(successLocationReceive(response.data));
  } catch (error) {
    yield put(failureLocationReceive(error));
  }
}

// Stock Request Details list
const ListStockRequestDetailsApi = (action: PayloadAction<number>) => {
  return api.get("/trans-stock-request-det/getid", {
    params: { id: action.payload },
  });
};

export function* ListStockRequestDetailsCall(action: PayloadAction<number>) {
  try {
    const response: AxiosResponse = yield call(
      ListStockRequestDetailsApi,
      action
    );
    yield put(successStockRequestDetails(response.data));
  } catch (error) {
    yield put(failureStockRequestDetails(error));
  }
}

// add
const AddLocationReceiveApi = (action: PayloadAction<ILocationReceive>) => {
  return api.post("/trans-stock-transfer-mas/create", action.payload);
};

export function* AddLocationReceiveCall(
  action: PayloadAction<ILocationReceive>
) {
  try {
    const response: AxiosResponse = yield call(AddLocationReceiveApi, action);
    yield put(successaddLocationReceive(response.data));
    yield put(GenerateSequenceStart("Transfer"));
  } catch (error) {
    yield put(failureaddLocationReceive(error));
  }
}

// edit
const EditLocationReceiveApi = (action: PayloadAction<ILocationReceive>) => {
  return api.patch("/location-receive/update", action.payload, {
    params: { id: action.payload.id },
  });
};

export function* EditLocationReceiveCall(
  action: PayloadAction<ILocationReceive>
) {
  try {
    const response: AxiosResponse = yield call(EditLocationReceiveApi, action);
    yield put(successeditLocationReceive(response.data));
  } catch (error) {
    yield put(failureeditLocationReceive(error));
  }
}

// getone
const GetoneLocationReceiveApi = (action: PayloadAction<number>) => {
  return api.get("/location-receive/getone", {
    params: { id: action.payload },
  });
};

export function* GetoneLocationReceiveCall(action: PayloadAction<number>) {
  try {
    const response: AxiosResponse = yield call(
      GetoneLocationReceiveApi,
      action
    );
    yield put(successGetoneLocationReceive(response.data));
  } catch (error) {
    yield put(failureGetoneLocationReceive(error));
  }
}
