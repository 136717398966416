import { IItemAdjustList } from "../Pages/StockAdjustment/IStockAdjustment";
import { IStockItems } from "../Pages/StockRecieve/IStockRecieve";
import { IStockTransferItemDetails } from "../Pages/StockTransfer/IStockTransfer";

export const totalItemQuantity = (itemsList?: IStockItems[]) => {
  return itemsList?.reduce((sum, item) => {
    return sum + (item.item_qnty ? Number(item.item_qnty) : 0);
  }, 0);
};

export const TotalReqQuantity = (itemsList?: IStockTransferItemDetails[]) => {
  return itemsList?.reduce((sum, item) => {
    return sum + (item.req_item_qnty ? Number(item.req_item_qnty) : 0);
  }, 0);
};

export const TotalAvailQuantity = (itemsList?: IStockTransferItemDetails[]) => {
  return itemsList?.reduce((sum, item) => {
    return (
      sum + (item.available_quantity ? Number(item.available_quantity) : 0)
    );
  }, 0);
};

export const TotalAdjustedQuantity = (itemsList?: IItemAdjustList[]) => {
  console.log(itemsList);

  return itemsList?.reduce((sum, item) => {
    return sum + (item.adjust_qty ? Number(item.adjust_qty) : 0);
  }, 0);
};
