import { AxiosResponse } from "axios";
import { call, put } from "redux-saga/effects";
import api from "../../Axios/middleware";
import { failureStorageArea, successStorageArea } from "./ListStorageAreaSlice";
import { PayloadAction } from "@reduxjs/toolkit";

// list
const ListStorageAreaApi = (action: PayloadAction<number>) => {
  return api.get("/storage-area/filter", {
    params: { zone_id: action.payload },
  });
};

export function* ListStorageAreaCall(action: PayloadAction<number>) {
  try {
    const response: AxiosResponse = yield call(ListStorageAreaApi, action);
    yield put(successStorageArea(response.data));
  } catch (error) {
    yield put(failureStorageArea(error));
  }
}
