import api from "../../Axios/middleware";
import { AxiosResponse } from "axios";
import { call, put } from "redux-saga/effects";
import { failureResetPassword, successResetPassword } from "./ResetPassword";
import { PayloadAction } from "@reduxjs/toolkit";

const ResetPasswordAPI = (action: PayloadAction<{}>) => {
  return api.post("/user/password", action.payload);
};

export function* ResetPasswordCall(action: PayloadAction<{}>) {
  try {
    const response: AxiosResponse = yield call(ResetPasswordAPI, action);
    yield put(successResetPassword(response.data));
  } catch (error) {
    yield put(failureResetPassword(error));
  }
}
