import { GridPagination } from "@mui/x-data-grid";
import { FunctionComponent } from "react";

interface TablePaginationProps {}

const TablePaginations: FunctionComponent<TablePaginationProps> = () => {
  return <GridPagination />;
};

export default TablePaginations;
