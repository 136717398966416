import { FunctionComponent, useEffect } from "react";
import { IUpdateSequence, Sequence } from "./ISequence";
import { FormProvider, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import dayjs from "dayjs";
import DatePickers from "../../Components/DatePicker/DatePicker";
import Select from "../../Components/Select/AutoComplete";
import NumberInputField from "../../Components/NumberInput/NumberInputField";
import TextInputFieldC from "../../Components/TextInput/TextInputFieldC";
import { AutocompleteOption } from "../../Components/Select/ISelectProps";
import { useAppDispatch, useAppSelector } from "../../Redux/Store/Hooks";
import { ListTranscationTypeStart } from "../../Redux/Slices/GeneralSetting/TransactionType/ListTransactionType";
import { createSequenceStart } from "../../Redux/Slices/SequenceSlicer/CreateSequence";
import { UpdateSequenceConfigStart } from "../../Redux/Slices/SequenceSlicer/UpdateSequenceConfig";
import { listDivisionMaster } from "../../Redux/Slices/DivisionMaster/ListDivisionMasterSlice";
// import { createSequenceStart } from "../../Redux/Slicers/Sequence/CreateSequence";

interface SequenceFormProps {
  formRef: React.RefObject<HTMLFormElement>;
  initialValues: Sequence;
  setInitialValues: React.Dispatch<React.SetStateAction<Sequence>>;
}

const SequenceForm: FunctionComponent<SequenceFormProps> = (props) => {
  const dispatch = useAppDispatch();
  const { data: ListTT } = useAppSelector((state) => state.ListTransactionType);
  const { data: division } = useAppSelector(
    (state) => state.ListDivisionMaster
  );

  const divisionOptions = (division?.data ?? []).map((division) => ({
    label: division.name!,
    value: division.id,
  }));

  const ListTTOptions = Array.isArray(ListTT?.data)
    ? ListTT?.data.map((item) => ({
        label: item.name!,
        value: item.id!,
      }))
    : [];
  const statusOptions = [
    { label: "Active", value: true },
    { label: "In-active", value: false },
  ];

  // schema validation
  const schema = z.object({
    division_id: z.number({
      message: "Please select the Division",
    }),
    transaction_type: z.string({ message: "Please select Transaction type" }),
    startDate: z.custom((val) => val && dayjs(val).isValid(), {
      message: "Please select the Start date",
    }),
    endDate: z.custom((val) => val && dayjs(val).isValid(), {
      message: "Please select the End date",
    }),
    num_len: z.number({
      message: "Please enter the number of count",
    }),
    number: z.number({ message: "Please enter start from number" }),
    // .max(5, { message: "safasf" }),
    prefix: z.string({
      message: "Please enter the Prefix",
    }),
    status: z.boolean({
      message: "Please select the status",
    }),
  });
  // .refine(
  //   (data) => {
  //     console.log(data);
  //     return data.number.toString().length === data.num_len;
  //   },
  //   {
  //     message: "adlkjdfkgalkdj",
  //   }
  // );
  // .superRefine((data, ctx) => {
  //   console.log(data.number.toString().length === data.num_len);

  //   if (data.number.toString().length === data.num_len) {
  //     ctx.addIssue({
  //       message: "lsdfj",
  //       path: ["number"],
  //       code: "custom",
  //     });
  //   }
  // });
  // type SequenceFormType = z.infer<typeof schema>;

  const methods = useForm<Sequence>({
    resolver: zodResolver(schema),
    defaultValues: props.initialValues.id
      ? {
          ...props.initialValues,
          startDate: dayjs(props.initialValues.startDate),
          endDate: dayjs(props.initialValues.endDate),
          status: props.initialValues.status === "Active" ? true : false,
        }
      : {
          transaction_type: null,
          prefix: null,
          number: null,
          num_len: null,
          startDate: null,
          endDate: null,
          status: null,
        },
  });

  // submit funtion
  const onSubmit = (val: Sequence) => {
    if (props.initialValues.id! > 0) {
      const updateData: IUpdateSequence = {
        id: props.initialValues.id!,
        data: val,
      };
      // console.log(">>>>Update", updateData);

      dispatch(UpdateSequenceConfigStart(updateData));
    } else {
      // console.log("Create", val);
      dispatch(createSequenceStart(val));
    }
  };
  useEffect(() => {
    return () => {
      props.setInitialValues({});
    };
  }, [props]);
  useEffect(() => {
    dispatch(ListTranscationTypeStart("TT"));
    dispatch(listDivisionMaster());
  }, [dispatch]);

  return (
    <>
      <FormProvider {...methods}>
        <form
          autoComplete="off"
          ref={props.formRef}
          onSubmit={methods.handleSubmit(onSubmit)}
          className="form_double_input"
          id="sequenceForm"
        >
          <button type="submit" hidden />

          <Select
            name="division_id"
            label="Division"
            id="division_id"
            disabled={!!props.initialValues.id}
            options={divisionOptions ?? []}
            value={
              divisionOptions.find(
                (division) =>
                  division.value === methods.getValues("division_id")
              ) ?? null
            }
            onChange={(_, value) => {
              const changedValue = (value as AutocompleteOption) ?? null;

              methods.setValue("division_id", changedValue?.value ?? null, {
                shouldValidate: true,
              });
            }}
          />

          <Select
            name="transaction_type"
            label="Transaction Type"
            id="transaction_type"
            disabled={props.initialValues.id ? true : false}
            options={ListTTOptions ?? []}
            value={
              methods.watch("transaction_type")
                ? ListTTOptions?.find(
                    (opt) => opt.label === methods.watch("transaction_type")
                  )
                : null
            }
            onChange={(_, value) => {
              const changedValue = (value as AutocompleteOption) ?? null;

              methods.setValue(
                "transaction_type",
                changedValue?.label ?? null,
                {
                  shouldValidate: true,
                }
              );
            }}
          />

          <DatePickers
            name="startDate"
            // id="startDate"
            label="Start Date"
            maxDate={methods.getValues().endDate}
            onChange={() => methods.trigger("startDate")}
          />

          <DatePickers
            name="endDate"
            label="End Date"
            // id="endDate"
            minDate={methods.getValues().startDate}
            onChange={() => methods.trigger("endDate")}
          />

          <NumberInputField
            name="num_len"
            label="Number Count"
            id="num_len"
            disabled={props.initialValues.id ? true : false}
          />

          <NumberInputField
            disabled={props.initialValues.id ? true : false}
            name="number"
            id="number"
            label="Start From"
          />

          <TextInputFieldC
            name="prefix"
            id="prefix"
            label="Prefix"
            disabled={props.initialValues.id ? true : false}
          />

          <Select
            name="status"
            id="status"
            label="Status"
            options={statusOptions}
            value={
              statusOptions.find((opt) => opt.value === methods.watch("status"))
                ?.label
            }
            onChange={(_, value) => {
              const changedValue = (value as AutocompleteOption) ?? null;

              methods.setValue("status", changedValue?.value ?? null, {
                shouldValidate: true,
              });
            }}
          />
        </form>
      </FormProvider>
    </>
  );
};

export default SequenceForm;
