import { PayloadAction } from "@reduxjs/toolkit";
import { call, put } from "redux-saga/effects";
import api from "../../Axios/middleware";
import { AxiosResponse } from "axios";
import { successStockCreate, failureCreateStock } from "./CreateStockRecieve";
import {
  failureStockRecieveList,
  successStockRecieveList,
} from "./ListStockRecieve";
import {
  Filter,
  IUpdateStockReceive,
  UpdateStatus,
} from "../../../Pages/StockRecieve/IStockRecieve";
import {
  FailureUpdateStockReceive,
  SuccessUpdateStockReceive,
} from "./UpadateStockRecieve";
import {
  successDeleteStockRecieve,
  failureDeleteStockRecieve,
} from "./DeleteStockReceive";
import {
  failureStockRecieveStatusUpdate,
  successStockRecieveStatusUpdate,
} from "./StockRecieveStatusUpdate";
import { GenerateSequenceStart } from "../SequenceSlicer/GenerateSequence";
import {
  failureFindOneStockReceive,
  successFindOneStockReceive,
} from "./FindOneStockReceive";

// Create Stock Recieve API
const CreateStockAPI = (action: PayloadAction<{}>) => {
  return api.post("/transa-stock-receive/create", action.payload);
};

export function* CreateStockCall(action: PayloadAction<{}>) {
  try {
    const response: AxiosResponse = yield call(CreateStockAPI, action);
    yield put(successStockCreate(response.data));
    yield put(GenerateSequenceStart("Receive"));
  } catch (error) {
    yield put(failureCreateStock(error));
  }
}

// List stock recieve
// const ListStockRecieveAPI = () => {
//   return api.get("/transa-stock-receive");
// };

// export function* ListStockRecieveCall() {
//   try {
//     const response: AxiosResponse = yield call(ListStockRecieveAPI);
//     yield put(successStockRecieveList(response.data));
//   } catch (error) {
//     yield put(failureStockRecieveList(error));
//   }
// }

const FilterStockRecieveAPI = (action: PayloadAction<Filter>) => {
  return api.get("/transa-stock-receive/filter", { params: action.payload });
};

export function* FilterStockRecieveCall(action: PayloadAction<Filter>) {
  try {
    const response: AxiosResponse = yield call(FilterStockRecieveAPI, action);
    yield put(successStockRecieveList(response.data));
  } catch (error) {
    yield put(failureStockRecieveList(error));
  }
}

// Upadte Stock Receive
const UpdateStockReceiveAPI = (action: PayloadAction<IUpdateStockReceive>) => {
  return api.patch(
    `/transa-stock-receive/update?id=${action.payload.id}`,
    action.payload.data
  );
};

export function* UpdateStockReceiveCall(
  action: PayloadAction<IUpdateStockReceive>
) {
  try {
    const response: AxiosResponse = yield call(UpdateStockReceiveAPI, action);
    yield put(SuccessUpdateStockReceive(response.data));
  } catch (error) {
    yield put(FailureUpdateStockReceive(error));
  }
}

// Delete Stock Recieve
const DeleteStockReceiveAPI = (action: PayloadAction<number>) => {
  return api.delete(`/transa-stock-receive/delete?id=${action.payload}`);
};

export function* DeleteStockReceiveCall(action: PayloadAction<number>) {
  try {
    const response: AxiosResponse = yield call(DeleteStockReceiveAPI, action);
    yield put(successDeleteStockRecieve(response.data));
  } catch (error) {
    yield put(failureDeleteStockRecieve(error));
  }
}

// Status Update Stock Recieve
const StockStatusUpdateAPI = async (action: PayloadAction<UpdateStatus>) => {
  return await api.patch(
    `/transa-stock-receive/updatestatus?id=${action.payload.id}`,
    action.payload.update
  );
};

export function* StockRecieveStatusUpdateCall(
  action: PayloadAction<UpdateStatus>
) {
  try {
    const response: AxiosResponse = yield call(StockStatusUpdateAPI, action);
    // console.log(response);

    yield put(successStockRecieveStatusUpdate(response.data));
  } catch (error) {
    yield put(failureStockRecieveStatusUpdate(error));
  }
}

// FindOne
const FindOneAPI = async (action: PayloadAction<number>) =>
  api.get(`/transa-stock-receive/findone?id=${action.payload}`);

export function* FindOneStockReceiveCall(action: PayloadAction<number>) {
  try {
    const response: AxiosResponse = yield call(FindOneAPI, action);
    yield put(successFindOneStockReceive(response.data));
  } catch (error) {
    yield put(failureFindOneStockReceive(error));
  }
}
