import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IReduxState } from "../../interfaces/IReduxState";
import { IStockTransfer } from "../../../Pages/StockTransfer/IStockTransfer";

export const EditStockTransferSlice = createSlice({
  name: "EditStockTransfer",
  initialState: {} as IReduxState<IStockTransfer>,
  reducers: {
    editStockTransfer: (state, _: PayloadAction<IStockTransfer>) => {
      state.isLoading = true;
    },
    successeditStockTransfer: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
    failureeditStockTransfer: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
    clearEditStockTransferResponse: (state) => {
      state.data = {};
    },
  },
});

export const {
  editStockTransfer,
  failureeditStockTransfer,
  successeditStockTransfer,
  clearEditStockTransferResponse,
} = EditStockTransferSlice.actions;

export default EditStockTransferSlice.reducer;
