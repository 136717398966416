import Table from "../../Components/Table/Table";
import { ILocationMaster, LocationMasterProps } from "./ILocationMaster";
import "./LocationMaster.scss";
import { FunctionComponent, useEffect, useRef, useState } from "react";
import ButtonField from "../../Components/Button/ButtonField";
import { useAppDispatch, useAppSelector } from "../../Redux/Store/Hooks";
import Dialog from "../../Components/Dialog/Dialog";
import CustomToolbar from "../../Components/Table/components/CustomToolbar";
import TableSearch from "../../Components/Table/components/TableSearch";
import { listLocationMaster } from "../../Redux/Slices/LocationMaster/ListLocationMasterSlice";
import { clearAddLocationMasterResponse } from "../../Redux/Slices/LocationMaster/AddLocationMasterSlice";
import { Columns } from "../../Components/Table/ITable";
import LocationMasterForm from "./LocationMasterForm";
import ResponseDialog from "../../Components/ResponseDialog/ResponseDialog";
import { GridRenderCellParams, GridValueGetterParams } from "@mui/x-data-grid";
import IconButtonField from "../../Components/Button/IconButtonField";
import { SvgIcon } from "@mui/material";
import { ReactComponent as Delete } from "../../Assets/Images/delete.svg";
import { ReactComponent as Edit } from "../../Assets/Images/edit.svg";
import { IDeleteDialog } from "../../Components/DeleteDialog/IDeleteDialog";
import DeleteDialog from "../../Components/DeleteDialog/DeleteDialog";
import {
  clearDeleteLocationMasterResponse,
  deleteLocationMaster,
} from "../../Redux/Slices/LocationMaster/DeleteLocationMasterSlice";
import { clearEditLocationMasterResponse } from "../../Redux/Slices/LocationMaster/EditLocationMasterSlice";

const LocationMaster: FunctionComponent<LocationMasterProps> = () => {
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState<boolean>(false);
  const [deleteDialogData, setDeleteData] = useState<IDeleteDialog>({});
  const [initialValues, setInitialValues] = useState<ILocationMaster>({});

  const { data: addLocation } = useAppSelector(
    (state) => state.AddLocationMaster
  );
  const { data: editLocation } = useAppSelector(
    (state) => state.EditLocationMaster
  );
  const { data: deleteLocation } = useAppSelector(
    (state) => state.DeleteLocationMaster
  );
  const { data: listLocation } = useAppSelector(
    (state) => state.ListLocationMaster
  );
  const dispatch = useAppDispatch();
  const formRef = useRef<HTMLFormElement>(null);

  useEffect(() => {
    dispatch(listLocationMaster());
  }, [addLocation, editLocation, deleteLocation, dispatch]);
  useEffect(() => {
    return () => {
      dispatch(clearAddLocationMasterResponse());
      dispatch(clearDeleteLocationMasterResponse());
      dispatch(clearEditLocationMasterResponse());
    };
  }, [dispatch]);

  const column: Columns = [
    {
      field: "index",
      renderHeader: () => <b>S.No</b>,
      width: 90,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "name",
      renderHeader: () => <b>Name</b>,
      flex: 1,
      align: "left",
      headerAlign: "left",
      type: "string",
    },
    {
      field: "division",
      renderHeader: () => <b>Division</b>,
      flex: 1,
      align: "left",
      headerAlign: "left",
      type: "string",
      valueGetter: (params: GridValueGetterParams<ILocationMaster>) => {
        return params.row.division?.name;
      },
    },
    {
      field: "location_type",
      renderHeader: () => <b>Location Type</b>,
      flex: 1,
      align: "left",
      headerAlign: "left",
      type: "string",
      valueGetter: (params: GridValueGetterParams<ILocationMaster>) => {
        return params.row.Location_type?.name;
      },
    },
    {
      field: "code",
      renderHeader: () => <b>Code</b>,
      flex: 1,
      align: "left",
      headerAlign: "left",
      type: "string",
    },
    {
      field: "actions",
      renderHeader: () => <b>Action</b>,
      type: "actions",
      renderCell: (params: GridRenderCellParams<ILocationMaster>) => {
        return (
          <>
            <IconButtonField
              onClick={() => {
                setInitialValues({
                  ...params.row,
                  division_id: params.row.division?.id,
                });
                setDialogOpen(true);
              }}
            >
              <SvgIcon sx={{ fill: "none" }} component={Edit} inheritViewBox />
            </IconButtonField>
            <IconButtonField
              onClick={() => {
                setDeleteData({ id: params.id, name: params.row.name });
                setDeleteDialogOpen(true);
              }}
            >
              <SvgIcon component={Delete} inheritViewBox />
            </IconButtonField>
          </>
        );
      },
    },
  ];

  const locationList = Array.isArray(listLocation?.data)
    ? listLocation?.data.map((location, index) => ({
        ...location,
        index: index + 1,
      }))
    : [];

  return (
    <>
      <Table rows={locationList ?? []} columns={column}>
        <CustomToolbar>
          <h6>Location</h6>
          <TableSearch />
          <ButtonField
            className="tabel_add_new_button"
            onClick={() => setDialogOpen(true)}
          >
            Add new
          </ButtonField>
        </CustomToolbar>
      </Table>

      <Dialog
        open={dialogOpen}
        dialogClassName="dialog"
        Dialog_Title={`${initialValues.id ? "Update" : "Add"} Location`}
        Dialog_Content={
          <LocationMasterForm
            formRef={formRef}
            initialValues={initialValues}
            setInitialValues={setInitialValues}
          />
        }
        Dialog_Actions={
          <>
            <ButtonField
              className="form_cancel_button"
              onClick={() => setDialogOpen(false)}
            >
              Cancel
            </ButtonField>
            <ButtonField
              className="form_save_button"
              onClick={() => formRef.current?.requestSubmit()}
            >
              Save
            </ButtonField>
          </>
        }
      />

      <ResponseDialog response={addLocation} parentDialog={setDialogOpen} />
      <ResponseDialog response={editLocation} parentDialog={setDialogOpen} />
      <DeleteDialog
        deleteableName={deleteDialogData.name ?? ""}
        open={deleteDialogOpen}
        setOpen={setDeleteDialogOpen}
        deleteFunction={() => {
          dispatch(deleteLocationMaster(deleteDialogData.id));
        }}
        response={deleteLocation}
      />
    </>
  );
};

export default LocationMaster;
