import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IReduxState } from "../../interfaces/IReduxState";
import {
  ITransactionRequest,
  key,
} from "../../../Pages/TransactionRequest/ITransactionRequest";

export const TransactionRequestSlice = createSlice({
  name: "ListTransactionRequest",
  initialState: {} as IReduxState<ITransactionRequest[]>,
  reducers: {
    listTransactionRequest: (state, _: PayloadAction<key>) => {
      state.isLoading = true;
    },
    successTransactionRequest: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
    failureTransactionRequest: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
  },
});

export const {
  failureTransactionRequest,
  listTransactionRequest,
  successTransactionRequest,
} = TransactionRequestSlice.actions;

export default TransactionRequestSlice.reducer;
