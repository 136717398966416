import { FunctionComponent } from "react";
import { useFormContext, Controller } from "react-hook-form";
import TimePickers from "../TimePicker/TimePickers";
import { ITimePickerProps } from "../TimePicker/ITimePicker";

interface ITextInputProps extends ITimePickerProps {
  name: string;
  label?: string;
}

const TimePickerC: FunctionComponent<ITextInputProps> = (props) => {
  const { label, name, ...rest } = props;
  const {
    control,
    formState: { errors },
  } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <TimePickers
          {...field}
          {...rest}
          label={label}
          error={!!errors[name]}
          helperText={errors[name] ? String(errors[name]?.message) : ""}
          onChange={(val) => {
            field.onChange(val);
          }}
        />
      )}
    />
  );
};

export default TimePickerC;
