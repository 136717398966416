import { AxiosResponse } from "axios";
import { call, put } from "redux-saga/effects";
import api from "../../Axios/middleware";
import {
  successTransactionRequest,
  failureTransactionRequest,
} from "./ListTransactionRequestSlice";
import {
  ITransactionRequest,
  key,
} from "../../../Pages/TransactionRequest/ITransactionRequest";
import { PayloadAction } from "@reduxjs/toolkit";
import {
  failureaddTransactionRequest,
  successaddTransactionRequest,
} from "./AddTransactionRequestSlice";
import {
  failureeditTransactionRequest,
  successeditTransactionRequest,
} from "./EditTransactionRequestSlice";
import {
  failureGetoneTransactionRequest,
  successGetoneTransactionRequest,
} from "./GetOneTransactionRequestSlice";
import {
  failuredeleteTransactionRequest,
  successdeleteTransactionRequest,
} from "./DeleteTransactionRequestSlice";
import { GenerateSequenceStart } from "../SequenceSlicer/GenerateSequence";

// list
const ListTransactionRequestApi = (action: PayloadAction<key>) => {
  return api.get("/trans-stock-request-mas/getall", {
    params: { key: action.payload },
  });
};

export function* ListTransactionRequestCall(action: PayloadAction<key>) {
  try {
    const response: AxiosResponse = yield call(
      ListTransactionRequestApi,
      action
    );
    yield put(successTransactionRequest(response.data));
  } catch (error) {
    yield put(failureTransactionRequest(error));
  }
}

// add
const AddTransactionRequestApi = (
  action: PayloadAction<ITransactionRequest>
) => {
  return api.post("/trans-stock-request-mas/create", action.payload);
};

export function* AddTransactionRequestCall(
  action: PayloadAction<ITransactionRequest>
) {
  try {
    const response: AxiosResponse = yield call(
      AddTransactionRequestApi,
      action
    );
    yield put(successaddTransactionRequest(response.data));
    yield put(GenerateSequenceStart("Request"));
  } catch (error) {
    yield put(failureaddTransactionRequest(error));
  }
}

// edit
const EditTransactionRequestApi = (
  action: PayloadAction<ITransactionRequest>
) => {
  return api.patch("/trans-stock-request-mas/update", action.payload, {
    params: { id: action.payload.id },
  });
};

export function* EditTransactionRequestCall(
  action: PayloadAction<ITransactionRequest>
) {
  try {
    const response: AxiosResponse = yield call(
      EditTransactionRequestApi,
      action
    );
    yield put(successeditTransactionRequest(response.data));
  } catch (error) {
    yield put(failureeditTransactionRequest(error));
  }
}

// getone
const GetoneTransactionRequestApi = (action: PayloadAction<number>) => {
  return api.get("/trans-stock-request-mas/getone", {
    params: { id: action.payload },
  });
};

export function* GetoneTransactionRequestCall(action: PayloadAction<number>) {
  try {
    const response: AxiosResponse = yield call(
      GetoneTransactionRequestApi,
      action
    );
    yield put(successGetoneTransactionRequest(response.data));
  } catch (error) {
    yield put(failureGetoneTransactionRequest(error));
  }
}

// delete
const DeleteTransactionRequestApi = (action: PayloadAction<number>) => {
  return api.delete("/trans-stock-request-mas/delete", {
    params: { id: action.payload },
  });
};

export function* DeleteTransactionRequestCall(action: PayloadAction<number>) {
  try {
    const response: AxiosResponse = yield call(
      DeleteTransactionRequestApi,
      action
    );
    yield put(successdeleteTransactionRequest(response.data));
  } catch (error) {
    yield put(failuredeleteTransactionRequest(error));
  }
}
