import { FunctionComponent, useEffect, useRef, useState } from "react";
import "./DivisionMaster.scss";
import { DevisionMasterProps, IDivisionMaster } from "./IDivisionMaster";
import Table from "../../Components/Table/Table";
import CustomToolbar from "../../Components/Table/components/CustomToolbar";
import ButtonField from "../../Components/Button/ButtonField";
import TableSearch from "../../Components/Table/components/TableSearch";
import { Columns } from "../../Components/Table/ITable";
import ResponseDialog from "../../Components/ResponseDialog/ResponseDialog";
import Dialog from "../../Components/Dialog/Dialog";
import DivisionMasterForm from "./DivisionMasterForm";
import { useAppDispatch, useAppSelector } from "../../Redux/Store/Hooks";
import { listDivisionMaster } from "../../Redux/Slices/DivisionMaster/ListDivisionMasterSlice";
import { clearAddDivisionMasterResponse } from "../../Redux/Slices/DivisionMaster/AddDivisionMasterSlice";
import { GridRenderCellParams } from "@mui/x-data-grid";
import { clearEditDivisionMasterResponse } from "../../Redux/Slices/DivisionMaster/EditDivisionMasterSlice";
import IconButtonField from "../../Components/Button/IconButtonField";
import DeleteDialog from "../../Components/DeleteDialog/DeleteDialog";
import {
  clearDeleteDivisionMasterResponse,
  deleteDivisionMaster,
} from "../../Redux/Slices/DivisionMaster/DeleteDivisionMasterSlice";
import { IDeleteDialog } from "../../Components/DeleteDialog/IDeleteDialog";
import { SvgIcon } from "@mui/material";
import { ReactComponent as Delete } from "../../Assets/Images/delete.svg";
import { ReactComponent as Edit } from "../../Assets/Images/edit.svg";

const DevisionMaster: FunctionComponent<DevisionMasterProps> = () => {
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState<boolean>(false);
  const [deleteDialogData, setDeleteData] = useState<IDeleteDialog>({});
  const [initialValues, setInitialValues] = useState<IDivisionMaster>({});
  const dispatch = useAppDispatch();
  const formRef = useRef<HTMLFormElement>(null);
  const { data: division } = useAppSelector(
    (state) => state.ListDivisionMaster
  );
  const { data: addDivision } = useAppSelector(
    (state) => state.AddDivisionMaster
  );
  const { data: editDivision } = useAppSelector(
    (state) => state.EditDivisionMaster
  );
  const { data: deleteDivision } = useAppSelector(
    (state) => state.DeleteDivisionMaster
  );

  const column: Columns = [
    {
      field: "index",
      renderHeader: () => <b>S.No</b>,
      align: "center",
      headerAlign: "center",
      width: 90,
    },
    {
      field: "name",
      renderHeader: () => <b>Name</b>,
      flex: 1,
      align: "left",
      headerAlign: "left",
      type: "string",
    },
    {
      field: "code",
      renderHeader: () => <b>Code</b>,
      flex: 1,
      align: "left",
      headerAlign: "left",
      type: "string",
    },
    {
      field: "actions",
      renderHeader: () => <b>Action</b>,
      type: "actions",
      renderCell: (params: GridRenderCellParams<IDivisionMaster>) => {
        return (
          <>
            <IconButtonField
              onClick={() => {
                setInitialValues({ ...params.row });
                setDialogOpen(true);
              }}
            >
              <SvgIcon sx={{ fill: "none" }} component={Edit} inheritViewBox />
            </IconButtonField>
            <IconButtonField
              onClick={() => {
                setDeleteData({ id: params.id, name: params.row.name });
                setDeleteDialogOpen(true);
              }}
            >
              <SvgIcon component={Delete} inheritViewBox />
            </IconButtonField>
          </>
        );
      },
    },
  ];

  useEffect(() => {
    dispatch(listDivisionMaster());
  }, [addDivision, editDivision, deleteDivision, dispatch]);
  useEffect(() => {
    return () => {
      dispatch(clearAddDivisionMasterResponse());
      dispatch(clearEditDivisionMasterResponse());
      dispatch(clearDeleteDivisionMasterResponse());
    };
  }, [dispatch]);

  const divisionList = Array.isArray(division?.data)
    ? division?.data.map((division, index) => ({
        ...division,
        index: index + 1,
      }))
    : [];

  return (
    <>
      <Table rows={divisionList ?? []} columns={column}>
        <CustomToolbar>
          <h6>Division</h6>
          <TableSearch />
          <ButtonField
            className="tabel_add_new_button"
            onClick={() => setDialogOpen(true)}
          >
            Add new
          </ButtonField>
        </CustomToolbar>
      </Table>
      <>
        <Dialog
          open={dialogOpen}
          dialogClassName="dialog"
          Dialog_Title={`${initialValues.id ? "Update" : "Add"} Division`}
          Dialog_Content={
            <DivisionMasterForm
              formRef={formRef}
              initialValues={initialValues}
              setInitialValues={setInitialValues}
            />
          }
          Dialog_Actions={
            <>
              <ButtonField
                className="form_cancel_button"
                onClick={() => setDialogOpen(false)}
              >
                Cancel
              </ButtonField>
              <ButtonField
                className="form_save_button"
                onClick={() => formRef.current?.requestSubmit()}
              >
                Save
              </ButtonField>
            </>
          }
        />
      </>

      <ResponseDialog response={addDivision} parentDialog={setDialogOpen} />
      <ResponseDialog response={editDivision} parentDialog={setDialogOpen} />
      <DeleteDialog
        deleteableName={deleteDialogData.name ?? ""}
        open={deleteDialogOpen}
        setOpen={setDeleteDialogOpen}
        deleteFunction={() => {
          dispatch(deleteDivisionMaster(deleteDialogData.id));
        }}
        response={deleteDivision}
      />
    </>
  );
};

export default DevisionMaster;
