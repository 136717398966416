import { PayloadAction } from "@reduxjs/toolkit";
import { call, put } from "redux-saga/effects";
import api from "../../Axios/middleware";
import { AxiosResponse } from "axios";
import {
  successListStockDetails,
  failureListStockDetails,
} from "./ListStockDetails";

const StockListAPI = () => {
  return api.get(`/stock-detail/getall`);
};

export function* ListStockDetailsCall() {
  try {
    const response: AxiosResponse = yield call(StockListAPI);
    yield put(successListStockDetails(response.data));
  } catch (error) {
    yield put(failureListStockDetails(error));
  }
}
