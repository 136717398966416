import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IReduxState } from "../../interfaces/IReduxState";
import { ILocationMaster } from "../../../Pages/LocationMaster/ILocationMaster";

export const EditLocationMasterSlice = createSlice({
  name: "EditLocationMaster",
  initialState: {} as IReduxState<ILocationMaster>,
  reducers: {
    editLocationMaster: (state, _: PayloadAction<ILocationMaster>) => {
      state.isLoading = true;
    },
    successeditLocationMaster: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
    failureeditLocationMaster: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
    clearEditLocationMasterResponse: (state) => {
      state.data = {};
    },
  },
});

export const {
  editLocationMaster,
  failureeditLocationMaster,
  successeditLocationMaster,
  clearEditLocationMasterResponse,
} = EditLocationMasterSlice.actions;

export default EditLocationMasterSlice.reducer;
