import { FunctionComponent, useEffect, useState } from "react";
import "./ReportsStockReceive.scss";
import { ReportsStockReceiveProps } from "./IReportsStockReceive";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";

import { dateFormatter } from "../../Utils/dateFormatter";
import Select from "../../Components/withoutControllerComponents/Select/Select";
import { useAppDispatch, useAppSelector } from "../../Redux/Store/Hooks";
import { listLocationMaster } from "../../Redux/Slices/LocationMaster/ListLocationMasterSlice";
import { AutocompleteOption } from "../../Components/Select/ISelectProps";
import TableRP from "../../Components/Table/TableRP";
import { ColumnMeta } from "../../Components/Table/ITableRP";
import { listStockRecieveStart } from "../../Redux/Slices/StockRecieve/ListStockRecieve";
import {
  IListStockRecieve,
  statusOptionsFD,
} from "../StockRecieve/IStockRecieve";
import Dialog from "../../Components/Dialog/Dialog";
import ViewStockItems from "../StockRecieve/ViewStockItems";
import IconButtonField from "../../Components/Button/IconButtonField";
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  SvgIcon,
} from "@mui/material";
import DateRangePicker from "../../Components/DateRangePicker/DateRangePicker";
import { Range, RangeKeyDict } from "react-date-range";
import { addTimeToDate } from "../../Utils/AddTimeToDate";
import { RadioButton, RadioButtonChangeEvent } from "primereact/radiobutton";

const ReportsStockReceive: FunctionComponent<ReportsStockReceiveProps> = () => {
  const dispatch = useAppDispatch();
  const [viewDialogOpen, setViewDialogOpen] = useState<boolean>(false);
  const [dateRange, setDateRange] = useState<Range | undefined>();
  const [locationFilterId, SetLocationFilterId] = useState<number>();
  const [detailedReport, setDetailedReport] = useState<boolean>(false);

  const { data: location, isLoading: locationLoading } = useAppSelector(
    (state) => state.ListLocationMaster
  );
  const { data: stockRecieveList } = useAppSelector(
    (state) => state.ListStockRecieve
  );
  const stockRecieveListRow = Array.isArray(stockRecieveList?.data)
    ? stockRecieveList?.data.map((stock, index) => ({
        index: index + 1,
        entry_no: stock.entry_no,
        entry_date: dateFormatter(stock.entry_date),
        received_from:
          stock.from_name?.length! > 1 ? stock.from_name : stock.supplier_name,
        total_items: stock.item_details?.length,
        status: statusOptionsFD.find((status) => status.value === stock.status)
          ?.label,
      }))
    : [];
  const detailedReceiveListRow = Array.isArray(stockRecieveList?.data)
    ? stockRecieveList?.data.flatMap((stock) => {
        return stock.item_details?.map((items, i) => ({
          entry_no: stock.entry_no,
          entry_date: dateFormatter(stock.entry_date),
          received_from:
            stock.from_name?.length! > 1
              ? stock.from_name
              : stock.supplier_name,
          item_name: items.item_name,
          item_code: items.item_code,
          item_bar_code: items.cus_serial_no,
          item_qnty: items.item_qnty,
          status: statusOptionsFD.find(
            (status) => status.value === stock.status
          )?.label,
        }));
      }) || []
    : [];
  const DetailedList = detailedReceiveListRow?.map((stock, i) => ({
    s_no: i + 1,
    ...stock,
  }));

  console.log(detailedReceiveListRow);

  const columns: ColumnMeta[] =
    detailedReport === false
      ? [
          {
            field: "index",
            header: "S.No",
            align: "center",
            alignHeader: "center",
          },
          {
            field: "entry_no",
            header: "Entry No",
            align: "left",
            alignHeader: "left",
          },
          {
            field: "entry_date",
            header: "Entry Date",
            align: "center",
            alignHeader: "center",
          },
          {
            field: "received_from",
            header: "Received From / Supplier Name",
            align: "left",
            alignHeader: "left",
          },
          {
            field: "total_items",
            header: "Total Items",
            align: "right",
            alignHeader: "right",
          },
          {
            field: "status",
            header: "Status",
            align: "left",
            alignHeader: "left",
          },
        ]
      : [
          {
            field: "s_no",
            header: "S.No",
            align: "center",
            alignHeader: "center",
          },
          {
            field: "entry_no",
            header: "Entry No",
            align: "left",
            alignHeader: "left",
          },
          {
            field: "entry_date",
            header: "Entry Date",
            align: "center",
            alignHeader: "center",
          },
          {
            field: "received_from",
            header: "Received From / Supplier Name",
            align: "left",
            alignHeader: "left",
          },
          {
            field: "item_name",
            header: "Item Name",
            align: "left",
            alignHeader: "left",
          },
          {
            field: "item_code",
            header: "Item Code",
            align: "left",
            alignHeader: "left",
          },

          {
            field: "item_bar_code",
            header: "Item Barcode",
            align: "left",
            alignHeader: "left",
          },
          {
            field: "item_qnty",
            header: "Item Quantity",
            align: "right",
            alignHeader: "right",
          },
          {
            field: "status",
            header: "Status",
            align: "left",
            alignHeader: "left",
          },
        ];
  useEffect(() => {
    dispatch(listStockRecieveStart({ key: "SRE" }));
    dispatch(listLocationMaster());
  }, [dispatch]);

  const locationList = location?.data?.map((location) => ({
    label: location.name!,
    value: location.id,
  }));
  console.log(stockRecieveListRow);
  const [value, setValue] = useState("summary");

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue((event.target as HTMLInputElement).value);
    setDetailedReport(
      (event.target as HTMLInputElement).value === "detail" ? true : false
    );
  };

  return (
    <>
      <TableRP
        sortMode="multiple"
        columns={columns}
        size="small"
        rows={detailedReport === false ? stockRecieveListRow! : DetailedList!}
        viewRowData={(data: IListStockRecieve) => {
          localStorage.setItem("viewStock", String(data.id));
          setViewDialogOpen(true);
        }}
        headerWithSearch
        headerTitle="Stock Receive Report"
        // actionColumn
        headerStartComponent={
          <div className="report_start_component">
            <fieldset className="report_fieldset">
              <legend className="reports_label">Report Type</legend>
              <RadioGroup
                name="controlled-radio-buttons-group"
                value={value}
                onChange={handleChange}
                sx={{
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <FormControlLabel
                  value="summary"
                  control={<Radio size="small" />}
                  label="Summary"
                />
                <FormControlLabel
                  value="detail"
                  control={<Radio size="small" />}
                  label="Detail"
                />
              </RadioGroup>
            </fieldset>
            <div className="report_filters_container">
              <DateRangePicker
                setRanges={setDateRange}
                showMonthAndYearPickers={false}
                size="small"
                placeholder="Choose Report Period"
                ranges={dateRange}
                direction="horizontal"
                handleOkBtn={() =>
                  dispatch(
                    listStockRecieveStart({
                      key: "SRE",
                      startDate: addTimeToDate(dateRange?.startDate!, 5, 30),
                      endDate: addTimeToDate(dateRange?.endDate!, 5, 30),
                      from: locationFilterId && locationFilterId,
                    })
                  )
                }
                onChange={(item: RangeKeyDict) => {
                  setDateRange(item.selection);
                }}
                handleRangeClear={() =>
                  dispatch(
                    listStockRecieveStart({
                      key: "SRE",
                      from: locationFilterId && locationFilterId,
                    })
                  )
                }
                value={dateRange}
              />
              <Select
                className="report_filter"
                size="small"
                placeholder="Receive From"
                options={locationList ?? []}
                loading={locationLoading}
                onChange={(_, val) => {
                  const value = val as AutocompleteOption;
                  if (value) {
                    SetLocationFilterId(value.value);
                    dispatch(
                      listStockRecieveStart({
                        from: value.value,
                        key: "SRE",
                        startDate:
                          dateRange?.startDate &&
                          addTimeToDate(dateRange?.startDate!, 5, 30),
                        endDate:
                          dateRange?.endDate &&
                          addTimeToDate(dateRange?.endDate!, 5, 30),
                      })
                    );
                  } else {
                    SetLocationFilterId(undefined);
                    dispatch(
                      listStockRecieveStart({
                        key: "SRE",
                        startDate:
                          dateRange?.startDate &&
                          addTimeToDate(dateRange?.startDate!, 5, 30),
                        endDate:
                          dateRange?.endDate &&
                          addTimeToDate(dateRange?.endDate!, 5, 30),
                      })
                    );
                  }
                }}
              />
            </div>
          </div>
        }
        CSVExport
        ExcelExport
        PdfExport
        ExportName="stock_receive_report"
      />
      <Dialog
        open={viewDialogOpen}
        setOpen={setViewDialogOpen}
        Dialog_Content={<ViewStockItems />}
        dialogClassName="dialog_double_input"
        Dialog_Title={
          <>
            View Stock Items Received
            <IconButtonField
              onClick={() => {
                setViewDialogOpen(false);
              }}
            >
              <SvgIcon
                component={CloseOutlinedIcon}
                inheritViewBox
                sx={{ height: "20px", width: "20px" }}
              />
            </IconButtonField>
          </>
        }
      />
    </>
  );
};

export default ReportsStockReceive;
