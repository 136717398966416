import { Divider } from "@mui/material";
import { FunctionComponent, useEffect, useRef } from "react";
import "./GeneralConfig.scss";
import { useAppDispatch, useAppSelector } from "../../Redux/Store/Hooks";
import { getGenerateSerialNoStart } from "../../Redux/Slices/GeneralSetting/GenerateSerialNo/GetGenerateSerialNo";
import Switchs from "../../Components/Switch/Switchs";
import ButtonField from "../../Components/Button/ButtonField";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import NumberInputField from "../../Components/NumberInput/NumberInputField";
import { IGeneralConfig } from "./IGeneralConfig";
import { DevTool } from "@hookform/devtools";
import {
  clearUpdateGeneralConfig,
  updateGeneralConfig,
} from "../../Redux/Slices/GeneralSetting/GenerateSerialNo/UpdateGeneralConfig";
import ResponseDialog from "../../Components/ResponseDialog/ResponseDialog";

const GeneralConfig: FunctionComponent = () => {
  const dispatch = useAppDispatch();
  const { data: generalConfig } = useAppSelector(
    (state) => state.GetGenerateSerialNo
  );
  const { data: updatedCusSerialStatus } = useAppSelector(
    (state) => state.UpdateGeneralConfig
  );

  const formRef = useRef<HTMLFormElement>(null);

  const schema = z.object({
    cus_serial_no: z.boolean().optional(),
    fifo_config: z.boolean().optional(),
    no_of_days: z.number().optional().nullable(),
  });

  const methods = useForm<IGeneralConfig>({
    resolver: zodResolver(schema),
  });

  const onSubmit: SubmitHandler<IGeneralConfig> = (val) => {
    console.log(val);
    dispatch(updateGeneralConfig(val));
  };

  // init
  useEffect(() => {
    dispatch(getGenerateSerialNoStart());
  }, [updatedCusSerialStatus, dispatch]);

  // clear
  useEffect(() => {
    return () => {
      dispatch(clearUpdateGeneralConfig());
    };
  }, [dispatch]);

  useEffect(() => {
    methods.reset(generalConfig?.data ?? {});
  }, [generalConfig, methods]);

  return (
    <>
      <div className="general_config_main_con">
        <FormProvider {...methods}>
          <form
            className="main_con"
            ref={formRef}
            onSubmit={methods.handleSubmit(onSubmit)}
          >
            <DevTool control={methods.control} />
            <h1>General Configuration</h1>
            <Divider />

            <div className="flex_view">
              <div className="label_input">
                <label htmlFor="cus_serial_no">Custom Serial No : </label>
                <Switchs
                  {...methods.register("cus_serial_no")}
                  id="cus_serial_no"
                  checked={methods.getValues("cus_serial_no")}
                  name="cus_serial_no"
                  onChange={(_, val) => {
                    methods.setValue("cus_serial_no", val, {
                      shouldValidate: true,
                    });
                  }}
                />
              </div>
            </div>

            <Divider />
            <h2>FIFO Configuration</h2>
            <div className="flex_view">
              <div className="label_input">
                <label htmlFor="fifo_config">FIFO : </label>
                <Switchs
                  {...methods.register("fifo_config")}
                  id="fifo_config"
                  checked={methods.getValues("fifo_config")}
                  name="fifo_config"
                  onChange={(_, val) => {
                    methods.setValue("fifo_config", val, {
                      shouldValidate: true,
                    });
                  }}
                />
              </div>
              {!!methods.getValues("fifo_config") && (
                <div className="label_input">
                  <label htmlFor="no_of_days">Number Of Days : </label>
                  <NumberInputField name="no_of_days" id="no_of_days" />
                </div>
              )}
            </div>

            {/* remove this field after using controller field ( by default ). Otherwise form doesn't work. */}
            <NumberInputField
              name="no_of_days"
              id="temp_no_of_days"
              sx={{ display: "none" }}
            />
            {/*  */}
          </form>
        </FormProvider>

        <div className="footer">
          <ButtonField className="form_cancel_button">Cancel</ButtonField>
          <ButtonField
            className="form_save_button"
            onClick={() => formRef.current?.requestSubmit()}
          >
            Save
          </ButtonField>
        </div>

        <ResponseDialog response={updatedCusSerialStatus?.data} />
      </div>
    </>
  );
};

export default GeneralConfig;
