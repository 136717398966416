import { createSlice } from "@reduxjs/toolkit";
import { IReduxState } from "../../interfaces/IReduxState";
import { IZoneConfig } from "../../../Pages/ZoneConfig/IZoneConfig";

export const DeleteZoneConfigSlice = createSlice({
  name: "DeleteZoneConfig",
  initialState: {} as IReduxState<IZoneConfig>,
  reducers: {
    deleteZoneConfig: (state, _) => {
      state.isLoading = true;
    },
    successdeleteZoneConfig: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
    failuredeleteZoneConfig: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
    clearDeleteZoneConfigResponse: (state) => {
      state.data = {};
    },
  },
});

export const {
  deleteZoneConfig,
  failuredeleteZoneConfig,
  successdeleteZoneConfig,
  clearDeleteZoneConfigResponse,
} = DeleteZoneConfigSlice.actions;

export default DeleteZoneConfigSlice.reducer;
