import "./Switchs.scss";

import { FormControlLabel, FormGroup } from "@mui/material";
import { FunctionComponent } from "react";
import Switchs from "./Switchs";
import { LabeledSwitchProps } from "./ISwitchs";

const LabeledSwitch: FunctionComponent<LabeledSwitchProps> = (props) => {
  const {
    label,
    labelSx,
    required,
    name,
    labelPlacement,
    disabled,
    checked,
    onChange,
    children,
    id,
  } = props;
  return (
    <FormGroup>
      <FormControlLabel
        id={id}
        label={label ?? children}
        checked={checked}
        disabled={disabled}
        onChange={onChange}
        labelPlacement={labelPlacement}
        name={name}
        required={required}
        sx={labelSx}
        control={<Switchs {...props} />}
      />
    </FormGroup>
  );
};

export default LabeledSwitch;
