import "./DatePicker.scss";

import { FunctionComponent } from "react";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { DatePickerProps } from "./IDatePicker";
import { Controller, useFormContext } from "react-hook-form";

const DatePickers: FunctionComponent<DatePickerProps> = (props) => {
  const {
    label,
    disableFuture,
    disableOpenPicker,
    disableHighlightToday,
    disablePast,
    disabled,
    displayWeekNumber,
    readOnly,
    views,
    closeOnSelect,
    open,
    onClose,
    onOpen,
    openTo,
    maxDate,
    minDate,
    format = "DD-MM-YYYY",
    onChange,
    onFocus,
    onBlur,
    error,
    required,
    sx,
    inputClassName,
    inputLableStyle,
    InputProps,
    shrink,
    variant,
    labelSize,
    name,
    value,
    size = "small",
    onKeyDown,
  } = props;
  const {
    control,
    formState: { errors },
    setValue,
  } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            {...props}
            {...field}
            name={name}
            sx={sx}
            label={label}
            onChange={(val) => {
              field.onChange(val);
              setValue(name, val, { shouldValidate: true });
            }}
            disableFuture={disableFuture}
            disableHighlightToday={disableHighlightToday}
            disableOpenPicker={disableOpenPicker}
            disablePast={disablePast}
            disabled={disabled}
            displayWeekNumber={displayWeekNumber}
            defaultValue={value}
            readOnly={readOnly}
            views={views}
            closeOnSelect={closeOnSelect}
            open={open}
            openTo={openTo}
            onClose={onClose}
            onOpen={onOpen}
            minDate={minDate!}
            maxDate={maxDate!}
            format={format}
            // value={value}
            slotProps={{
              textField: {
                helperText: errors[name] ? String(errors[name]?.message) : "",
                onFocus: onFocus,
                onBlur: onBlur,
                error: !!errors[name],
                required: required,
                size: size,
                variant: variant,
                InputProps: {
                  ...InputProps,
                  disableUnderline: true,
                  readOnly: readOnly,
                  className: `GlobalInputPropsStyle ${inputClassName}`,
                  onKeyDown: onKeyDown,
                },
                InputLabelProps: {
                  shrink: shrink,
                  size: labelSize,
                  className: `GlobalInputLableStyle ${inputLableStyle}`,
                },
              },
            }}
          />
        </LocalizationProvider>
      )}
    />
  );
};

export default DatePickers;
