import "./ButtonField.scss";

import { FunctionComponent } from "react";
import { UploadButtonFieldProps } from "./IButtonField";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

const UploadButtonField: FunctionComponent<UploadButtonFieldProps> = (
  props
) => {
  const {
    children,
    sx = { textTransform: "none" },
    disabled,
    variant,
    color,
    size,
    startIcon,
    endIcon,
    disableRipple,
    className,
    id,
    onChange,
    name,
    fileAccept,
    onBlur,
  } = props;

  return (
    <Button
      component="label"
      role={undefined}
      variant={variant}
      tabIndex={-1}
      disabled={disabled}
      startIcon={startIcon}
      color={color}
      size={size}
      endIcon={endIcon}
      disableRipple={disableRipple}
      sx={sx}
      className={className}
    >
      {children}
      <VisuallyHiddenInput
        name={name}
        type="file"
        id={id}
        onChange={onChange}
        accept={fileAccept}
        onBlur={onBlur}
      />
    </Button>
  );
};

export default UploadButtonField;
