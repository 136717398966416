import "./Login.scss";

import {
  CircularProgress,
  Divider,
  InputAdornment,
  SvgIcon,
} from "@mui/material";
import TextInputField from "../../Components/TextInput/TextInputField";
import { FunctionComponent, useEffect, useState } from "react";
import { ReactComponent as Mail } from "../../Assets/Images/mail.svg";
import { ReactComponent as Lock } from "../../Assets/Images/lock.svg";
import { ReactComponent as ClosedEye } from "../../Assets/Images/closedEye.svg";
import ButtonField from "../../Components/Button/ButtonField";
import { Link, useNavigate } from "react-router-dom";
import VisibilityIcon from "@mui/icons-material/Visibility";
import IconButtonField from "../../Components/Button/IconButtonField";
import { useAppDispatch, useAppSelector } from "../../Redux/Store/Hooks";
import {
  clearLoginStatus,
  loginStart,
} from "../../Redux/Slices/LoginSlicer/LoginSlicer";
import { testCall } from "../../Redux/Slices/TestSlicers/TestSlicers";

import { useFormik } from "formik";
import * as Yup from "yup";
import { ILoginForm } from "./ILogin";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import TextInputFieldC from "../../Components/TextInput/TextInputFieldC";
import Snackbar from "../../Components/Snackbar/Snackbar";
import LoadingButtonField from "../../Components/Button/LoadingButtonField";

const Login: React.FC = () => {
  const [show, setShow] = useState<boolean>(false);
  const [isSubmit, setIsSubmit] = useState<boolean>(false);
  const nav = useNavigate();
  const dispatch = useAppDispatch();

  const loginResponse = useAppSelector((state) => state.LoginSlicer);
  console.log(loginResponse);

  // const loginInitialValues: ILoginForm = {
  //   email: "",
  //   password: "",
  // };

  // const loginSchema = Yup.object({
  //   email: Yup.string()
  //     .email("Invalid Email")
  //     .required("User Name is required"),
  //   password: Yup.string().required("Password is required"),
  // });

  // const loginForm = useFormik({
  //   initialValues: loginInitialValues,
  //   validationSchema: loginSchema,
  //   onSubmit: async (value) => {
  //     await dispatch(loginStart(value));

  //     localStorage.setItem("token", JSON.stringify(value));
  //     nav("/dashboard");
  //   },
  // });

  const loginSchema = z.object({
    email: z
      .string({
        required_error: "Email is required",
        message: "Email is required",
      })
      .email({
        message: "Invalid email",
      }),
    password: z.string({
      required_error: "Password is required",
      message: "Password is required",
    }),
  });
  const methods = useForm<ILoginForm>({
    defaultValues: {
      email: null,
      password: null,
    },
    resolver: zodResolver(loginSchema),
  });
  const onSubmit: SubmitHandler<ILoginForm> = (val) => {
    setIsSubmit(true);
    dispatch(loginStart(val));
    // localStorage.setItem("token", JSON.stringify(val));
    // nav("/dashboard");
  };
  useEffect(() => {
    const getToken = localStorage.getItem("token");
    if (loginResponse.data?.status && getToken) {
      setTimeout(() => {
        nav("/dashboard");
      }, 1000);
    }
  }, [loginResponse, nav]);

  useEffect(() => {
    return () => {
      if (!isSubmit) {
        dispatch(clearLoginStatus());
      }
    };
  }, []);

  return (
    <div className="backGround">
      <Snackbar response={loginResponse.data} />;
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <div className="main">
            <div>
              <div className="heading">Login</div>
              {/* <div className="subHeading">
                Don't have an account yet? <Link to={"#"}>Sign Up</Link>
              </div> */}
              <Divider />
            </div>

            <div className="textFields">
              <TextInputFieldC
                className="textField"
                name="email"
                type="text"
                placeholder="Email"
                variant="outlined"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SvgIcon
                        sx={{
                          width: "28px",
                          height: "28px",
                        }}
                        component={AccountCircleIcon}
                        inheritViewBox
                      />
                    </InputAdornment>
                  ),
                }}
              />
              <TextInputFieldC
                className="textField"
                name="password"
                placeholder="Password"
                type={show ? "text" : "password"}
                variant="outlined"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SvgIcon
                        sx={{
                          width: "20px",
                          height: "20px",
                        }}
                        component={Lock}
                        inheritViewBox
                      />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <IconButtonField onClick={() => setShow(!show)}>
                      <SvgIcon
                        sx={{
                          width: "20px",
                          height: "20px",
                        }}
                        component={show ? VisibilityIcon : ClosedEye}
                        inheritViewBox
                      />
                    </IconButtonField>
                  ),
                }}
              />
              {/* <TextInputField
              className="textField"
              type="text"
              id="email"
              name="email"
              value={loginForm.values.email}
              onChange={loginForm.handleChange}
              onBlur={loginForm.handleBlur}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SvgIcon
                      sx={{
                        width: "28px",
                        height: "28px",
                      }}
                      component={AccountCircleIcon}
                      inheritViewBox
                    />
                  </InputAdornment>
                ),
              }}
              placeholder="User Name"
              helperText={loginForm.touched.email && loginForm.errors.email}
              error={loginForm.touched.email && Boolean(loginForm.errors.email)}
            />

            <TextInputField
              type={show ? "text" : "password"}
              className="textField"
              id="password"
              name="password"
              value={loginForm.values.password}
              onChange={loginForm.handleChange}
              onBlur={loginForm.handleBlur}
              helperText={
                loginForm.touched.password && loginForm.errors.password
              }
              error={
                loginForm.touched.password && Boolean(loginForm.errors.password)
              }
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SvgIcon
                      sx={{
                        width: "20px",
                        height: "20px",
                      }}
                      component={Lock}
                      inheritViewBox
                    />
                  </InputAdornment>
                ),
                endAdornment: (
                  <IconButtonField onClick={() => setShow(!show)}>
                    <SvgIcon
                      sx={{
                        width: "20px",
                        height: "20px",
                      }}
                      component={show ? VisibilityIcon : ClosedEye}
                      inheritViewBox
                    />
                  </IconButtonField>
                ),
              }}
              placeholder="Password"
            /> */}
            </div>

            <div className="textFields">
              <ButtonField
                className="buttonfield"
                disabled={loginResponse.isLoading}
                type="submit"
              >
                Login
                {loginResponse.isLoading && (
                  <CircularProgress
                    size={24}
                    sx={{
                      color: "white",
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      marginTop: "-12px",
                      marginLeft: "-12px",
                    }}
                  />
                )}
              </ButtonField>
              <Link to={"/forgotPassword"} className="forgetPassword">
                Forgot Password
              </Link>
            </div>
          </div>
        </form>
      </FormProvider>
    </div>
  );
};

export default Login;
