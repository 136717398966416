import { put, call } from "redux-saga/effects";
import api from "../../../Axios/middleware";
import { AxiosResponse } from "axios";
import { successGetItemType, failureGetItemType } from "./GetItemType";
import { PayloadAction } from "@reduxjs/toolkit";

// ItemType
const ItemTypeAPI = (action: PayloadAction<string>) => {
  return api.get(`/general-settings/type?key=${action.payload}`);
};
export function* GetItemTypeCall(action: PayloadAction<string>) {
  try {
    const response: AxiosResponse = yield call(ItemTypeAPI, action);
    yield put(successGetItemType(response.data));
  } catch (error) {
    yield put(failureGetItemType(error));
  }
}
