import { FunctionComponent, memo, useEffect } from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { IDivisionMaster } from "./IDivisionMaster";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import TextInputFieldC from "../../Components/TextInput/TextInputFieldC";
import { useAppDispatch } from "../../Redux/Store/Hooks";
import { addDivisionMaster } from "../../Redux/Slices/DivisionMaster/AddDivisionMasterSlice";
import { editDivisionMaster } from "../../Redux/Slices/DivisionMaster/EditDivisionMasterSlice";

interface DivisionMasterFormProps {
  formRef: React.RefObject<HTMLFormElement>;
  initialValues: IDivisionMaster;
  setInitialValues: React.Dispatch<React.SetStateAction<IDivisionMaster>>;
}

const DivisionMasterForm: FunctionComponent<DivisionMasterFormProps> = (
  props
) => {
  const dispatch = useAppDispatch();
  const divisionSchema = z.object({
    id: z.number().optional(),
    name: z
      .string({ message: "Please enter Name" })
      .min(1, { message: "Please enter Name" }),
    code: z
      .string({ message: "Please enter Code" })
      .min(1, { message: "Please enter Code" }),
  });
  const methods = useForm<IDivisionMaster>({
    defaultValues: props.initialValues,
    resolver: zodResolver(divisionSchema),
  });

  const onSubmit: SubmitHandler<IDivisionMaster> = (val) => {
    if (val.id) {
      dispatch(editDivisionMaster(val));
    } else {
      dispatch(addDivisionMaster(val));
    }
  };

  useEffect(() => {
    return () => {
      props.setInitialValues({});
    };
  }, [props]);

  return (
    <FormProvider {...methods}>
      <form
        ref={props.formRef}
        onSubmit={methods.handleSubmit(onSubmit)}
        className="form_content"
      >
        <TextInputFieldC name="name" label="Name" />
        <TextInputFieldC name="code" label="Code" />
      </form>
    </FormProvider>
  );
};

export default memo(DivisionMasterForm);
