import { createSlice } from "@reduxjs/toolkit";
import { IReduxState } from "../../interfaces/IReduxState";

export const UpdateStockReceive = createSlice({
  name: "UpdateStockReceive",
  initialState: {
    isLoading: false,
    data: {},
  } as IReduxState<{}>,
  reducers: {
    UpdateStockReceiveStart: (state, _) => {
      state.isLoading = true;
    },
    SuccessUpdateStockReceive: (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    },
    FailureUpdateStockReceive: (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    },
    clearUpdateStock: (state) => {
      state.data = {};
    },
  },
});

export const {
  FailureUpdateStockReceive,
  SuccessUpdateStockReceive,
  UpdateStockReceiveStart,
  clearUpdateStock,
} = UpdateStockReceive.actions;
export default UpdateStockReceive.reducer;
