import { createSlice } from "@reduxjs/toolkit";
import { IReduxState } from "../../interfaces/IReduxState";
import { ILocationType } from "../../../Pages/ZoneConfig/IZoneConfig";

export const LocationTypeSlice = createSlice({
  name: "ListLocationType",
  initialState: {} as IReduxState<ILocationType[]>,
  reducers: {
    listLocationType: (state) => {
      state.isLoading = true;
    },
    successLocationType: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
    failureLocationType: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
  },
});

export const { failureLocationType, listLocationType, successLocationType } =
  LocationTypeSlice.actions;

export default LocationTypeSlice.reducer;
