import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IReduxState } from "../../interfaces/IReduxState";
import { IReportsStockTransfer } from "../../../Pages/ReportsStockTransfer/IReportsStockTransfer";
import { IStockTransfer } from "../../../Pages/StockTransfer/IStockTransfer";
import { Filter } from "../../../Pages/StockRecieve/IStockRecieve";

export const ListReportsStockTransfer = createSlice({
  name: "ListReportsStockTransfer",
  initialState: {} as IReduxState<IStockTransfer[]>,
  reducers: {
    listReportsStockTransfer: (state, _:PayloadAction<Filter>) => {
      state.isLoading = true;
    },
    responseReportsStockTransfer: (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    },
  },
});

export const { listReportsStockTransfer, responseReportsStockTransfer } =
  ListReportsStockTransfer.actions;
export default ListReportsStockTransfer.reducer;
