import { Columns, Rows } from "../Components/Table/ITable";
import { ColumnMeta } from "../Components/Table/ITableRP";
import { dateFormatter } from "./dateFormatter";

export const exportToCSV = (
  columns: ColumnMeta[],
  rows: Rows,
  fileName: string
) => {
  const currentDate = new Date();
  if (rows.length !== 0) {
    const csvData: string[][] = [];
    const header = columns.map((col) => col.header);
    csvData.push(header as string[]);
    rows.forEach((row) => {
      const rowData: any[] = [];
      columns.forEach((col) => {
        let cellData = row[col.field];
        // Check if cell data contains a comma or double quote, and escape it if needed
        if (
          typeof cellData === "string" &&
          (cellData.includes(",") || cellData.includes('"'))
        ) {
          // Escape double quotes by doubling them and wrap the entire field in quotes
          cellData = `"${cellData.replace(/"/g, '""')}"`;
          // console.log(cellData);
        }
        rowData.push(cellData);
      });
      csvData.push(rowData);
    });
    // create csv content
    const csvContent =
      "data:text/csv;charset=utf-8," +
      csvData.map((e) => e.join(",")).join("\n");
    const encodedUri = encodeURI(csvContent);
    // create download link
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute(
      "download",
      `${fileName}_${dateFormatter(currentDate, "DD-MM-YYYY")}.csv`
    );
    // Append link to body and trigger click event
    document.body.appendChild(link);
    link.click();
  } else {
    alert("No data found");
  }
};
