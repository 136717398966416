import { FunctionComponent, memo, useEffect } from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { IItemMaster, ItemTypeOptions } from "./IItemMaster";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import TextInputFieldC from "../../Components/TextInput/TextInputFieldC";
import { useAppDispatch, useAppSelector } from "../../Redux/Store/Hooks";
import { addItemMaster } from "../../Redux/Slices/ItemMaster/AddItemMasterSlice";
import { editItemMaster } from "../../Redux/Slices/ItemMaster/EditItemMasterSlice";
import TextAreaInputField from "../../Components/TextAreaInput/TextAreaInputField";
import { listDivisionMaster } from "../../Redux/Slices/DivisionMaster/ListDivisionMasterSlice";
import Select from "../../Components/Select/AutoComplete";
import { AutocompleteOption } from "../../Components/Select/ISelectProps";
import { getItemTypeStart } from "../../Redux/Slices/GeneralSetting/ItemType/GetItemType";

interface ItemMasterFormProps {
  formRef: React.RefObject<HTMLFormElement>;
  initialValues: IItemMaster;
  setInitialValues: React.Dispatch<React.SetStateAction<IItemMaster>>;
}

const ItemMasterForm: FunctionComponent<ItemMasterFormProps> = (props) => {
  const dispatch = useAppDispatch();

  const { data: division, isLoading: divisionLoading } = useAppSelector(
    (state) => state.ListDivisionMaster
  );
  const { data: ItemTypeList } = useAppSelector((state) => state.GetItemType);
  const ItemTypeLists = Array.isArray(ItemTypeList?.data)
    ? ItemTypeList?.data.map((type) => ({
        label: type.name!,
        value: type.description!,
      }))
    : [];

  const divisionList = Array.isArray(division?.data)
    ? division?.data.map((type) => ({ label: type.name!, value: type.id }))
    : [];

  const itemSchema = z.object({
    id: z.number().optional(),
    division_id: z.number({ message: "Please Select Location type" }),
    item_type: z.string({ message: "Please Select Item Type" }),
    item_name: z
      .string({ message: "Please Enter Name" })
      .min(1, { message: "Please Enter Name" }),
    item_code: z
      .string({ message: "Please Enter Code" })
      .min(1, { message: "Please Enter Code" }),
    base_uom: z
      .string({ message: "Please Enter UOM-Unit Of Measurement" })
      .min(1, { message: "Please Enter Code" }),
    description: z
      .string({ message: "Please Enter Description" })
      .min(1, { message: "Please Enter Description" }),
  });
  const methods = useForm<IItemMaster>({
    defaultValues: props.initialValues,
    resolver: zodResolver(itemSchema),
  });

  const onSubmit: SubmitHandler<IItemMaster> = (val) => {
    // console.log(val);

    if (val.id) {
      dispatch(editItemMaster(val));
    } else {
      dispatch(addItemMaster(val));
    }
  };
  useEffect(() => {
    dispatch(listDivisionMaster());
    dispatch(getItemTypeStart("IT"));
  }, [dispatch]);

  useEffect(() => {
    return () => {
      props.setInitialValues({});
    };
  }, [dispatch, props]);
  console.log(methods.getValues());

  return (
    <FormProvider {...methods}>
      <form
        ref={props.formRef}
        onSubmit={methods.handleSubmit(onSubmit)}
        className="form_content"
      >
        <Select
          name="division_id"
          label="Division"
          loading={divisionLoading}
          options={divisionList}
          value={
            divisionList?.find(
              (opt) => opt.value === methods.getValues("division_id")
            ) ?? null
          }
          onChange={(_, value) => {
            const val = value as AutocompleteOption;
            methods.setValue("division_id", val?.value ?? null, {
              shouldValidate: true,
            });
          }}
        />
        <Select
          name="item_type"
          label="Item Type"
          options={ItemTypeLists}
          value={
            ItemTypeLists?.find(
              (opt) => opt.value === methods.getValues("item_type")
            ) ?? null
          }
          onChange={(_, value) => {
            const val = value as AutocompleteOption;
            methods.setValue("item_type", val?.value ?? null, {
              shouldValidate: true,
            });
          }}
        />
        <TextInputFieldC name="item_name" label="Name" />

        <TextInputFieldC name="item_code" label="Code" />
        <TextInputFieldC name="base_uom" label="UOM" />

        <TextAreaInputField
          name={"description"}
          placeholder="Description..."
          label="Description"
        />
      </form>
    </FormProvider>
  );
};

export default memo(ItemMasterForm);
