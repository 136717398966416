import { PayloadAction } from "@reduxjs/toolkit";
import api from "../../Axios/middleware";
import { AxiosResponse } from "axios";
import { call, put } from "redux-saga/effects";
import { successLogin, failureLogin } from "./LoginSlicer";
import { FailureForgotPassword, SuccessForgotPassword } from "./ForgotPassword";
import { encode } from "../../../Utils/encodeDecode";

const LoginApi = (action: PayloadAction<{}>) => {
  return api.post("/user/login", action.payload);
};

export function* LoginCall(action: PayloadAction<{}>) {
  try {
    const respons: AxiosResponse = yield call(LoginApi, action);
    yield put(successLogin(respons.data));
    console.log(respons.data.data);

    if (respons.data.data) {
      localStorage.setItem("token", respons.data.data.token);
      localStorage.setItem("location_id", respons.data.data.location);
      localStorage.setItem("location_type", respons.data.data.loc_type);
      localStorage.setItem("details", encode(respons.data.data));
    }
  } catch (error) {
    yield put(failureLogin(error));
  }
}

const ForgotPasswordAPI = (action: PayloadAction<string>) => {
  return api.get(`/user/forgot?email=${action.payload}`);
};

export function* ForgotPasswordCall(action: PayloadAction<string>) {
  try {
    const response: AxiosResponse = yield call(ForgotPasswordAPI, action);
    yield put(SuccessForgotPassword(response.data));
  } catch (error) {
    yield put(FailureForgotPassword(error));
  }
}
