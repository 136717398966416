import { createSlice } from "@reduxjs/toolkit";
import { menu, testMenu } from "../../../Components/Sidnavbar/menuData";
import { IMenuItem } from "../../../Components/Sidnavbar/ISidnavbar";

const testArray = (arr: IMenuItem[], to: IMenuItem[]): IMenuItem[] => {
  return arr.map((item) => {
    const findTo = to.find((givenTo) => givenTo.to === item.to);

    if (item.items) {
      const subTo = to.filter((sub) => sub.to?.split("/").includes(item.to!));
      const showSub = subTo.find((sub) => !!sub.permissions?.view);

      if (!!showSub) {
        return {
          ...item,
          items: testArray(item.items, to),
        };
      } else {
        return {
          ...item,
          permissions: { view: false },
          items: item.items.map((falseItem) => ({
            ...falseItem,
            permissions: { ...falseItem.permissions, show: false, view: false },
          })),
        };
      }
    }
    return {
      ...item,
      permissions: {
        ...findTo?.permissions,
        show: item.permissions?.show,
      },
    };
  });
};

const loopArray = (arr: { [key: string]: IMenuItem[] }, to: IMenuItem[]) => {
  const newArr: { [key: string]: IMenuItem[] } = {};
  Object.keys(arr).forEach((key) => {
    newArr[key] = testArray(arr[key], to);
  });
  return newArr;
};

interface ISideMenu {
  data: { [key: string]: IMenuItem[] };
  isLoading?: boolean;
}

export const SideMenuPermissionSlice = createSlice({
  name: "SideMenuPermission",
  initialState: { data: {} } as ISideMenu,
  reducers: {
    listSideMenuPermission: (state) => {
      state.isLoading = true;
    },
    responseSideMenuPermission: (state, action) => {
      // const gettedData = action.payload;
      const gettedData = testMenu;
      if (gettedData) {
        console.log(gettedData);

        const updatedMenu = loopArray(menu, gettedData);

        console.log(updatedMenu);

        state.data = updatedMenu;
      } else {
        state.data = { Dashboard: [{ to: "/", permissions: { view: true } }] };
      }
      state.isLoading = false;
    },
    clearMenu: (state) => {
      state.data = {};
    },
  },
});

export const { listSideMenuPermission, responseSideMenuPermission, clearMenu } =
  SideMenuPermissionSlice.actions;
export default SideMenuPermissionSlice.reducer;
