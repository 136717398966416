import "./StorageAreaDashboard.scss";
import { FunctionComponent, useEffect, useLayoutEffect, useRef } from "react";
import Cards from "../../Components/Card/cards";
import { darken } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../Redux/Store/Hooks";
import {
  clearDashboardStorageArea,
  listDashboardStorageArea,
} from "../../Redux/Slices/Dashboard/ListDashboardStorageAreaSlice";
import StorageContainerComponent from "../StorageArea/StorageContainerComponent";
import { listZoneConfig } from "../../Redux/Slices/ZoneConfig/ListZoneConfigSlice";
import { AutocompleteOption } from "../../Components/Select/ISelectProps";
import { dashboardCard } from "../../Redux/Slices/Dashboard/DashboardCard";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { IStorageAreaFilter } from "./IStorageAreaDashboard";
import Select from "../../Components/Select/AutoComplete";
import ButtonField from "../../Components/Button/ButtonField";
import { listItemMaster } from "../../Redux/Slices/ItemMaster/ListItemMasterSlice";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import DateRangePickerC from "../../Components/DateRangePicker/DateRangePickerC";
import { RangeKeyDict } from "react-date-range";
import dayjs from "dayjs";
import TextInputFieldC from "../../Components/TextInput/TextInputFieldC";
import { listGRNNo } from "../../Redux/Slices/Dashboard/ListGRNNo";

const StorageAreaDashboard: FunctionComponent = () => {
  const dispatch = useAppDispatch();
  const { data } = useAppSelector((state) => state.ListDashboardStorageArea);
  const { data: zone, isLoading } = useAppSelector(
    (state) => state.ListZoneConfig
  );
  const { data: items } = useAppSelector((state) => state.ListItemMaster);
  const { data: grn } = useAppSelector((state) => state.ListGRNNo);

  const { data: dashboardCards } = useAppSelector(
    (state) => state.DashboardCard
  );

  const formRef = useRef<HTMLFormElement>(null);

  const cards = [
    {
      name: "Stock Receive",
      counts: dashboardCards?.data?.receive,
      bgcolor: "rgb(255, 244, 222)",
    },
    {
      name: "Stock Requests",
      counts: dashboardCards?.data?.request,
      bgcolor: "rgb(220, 252, 231)",
    },
    {
      name: "Stock Transfer",
      counts: dashboardCards?.data?.transfer,
      bgcolor: "rgb(220, 225, 252)",
    },
    {
      name: "Location Receive",
      counts: dashboardCards?.data?.location_receive,
      bgcolor: "rgb(255, 226, 229)",
    },
  ];

  const filterSchema = z.object({
    grn_no: z.string().optional().nullable(),
    item_code: z.string().optional().nullable(),
    item_barcode: z.string().optional().nullable(),
    item_name: z.string().optional().nullable(),
    zone_id: z.number().optional().nullable(),
    startDate: z
      .custom((val) => val && dayjs(val).isValid(), {
        message: "Please select the date",
      })
      .optional(),
    endDate: z
      .custom((val) => val && dayjs(val).isValid(), {
        message: "Please select the date",
      })
      .optional(),
  });

  const methods = useForm<IStorageAreaFilter>({
    resolver: zodResolver(filterSchema),
  });

  // init
  useEffect(() => {
    dispatch(listZoneConfig(""));
    dispatch(dashboardCard());
    dispatch(listItemMaster({}));
    dispatch(listGRNNo());
    return () => {
      dispatch(clearDashboardStorageArea());
    };
  }, [dispatch]);

  // zone option list
  const zoneOption = zone?.data?.map((data) => ({
    label: data.name!,
    value: data.id,
  }));

  // item name list
  const itemNameOption = items?.data?.map((item) => item.item_name!);

  // item code list
  const itemCodeOption = items?.data?.map((item) => item.item_code!);

  // grn no
  const grnOption = grn?.data?.map((grn) => grn.grn_no);

  const onSubmit: SubmitHandler<IStorageAreaFilter> = (val) => {
    console.log(val);
    dispatch(listDashboardStorageArea(val));
  };

  useEffect(() => {
    if (zone?.data) {
      methods.setValue("zone_id", zone.data[0]?.id);
    }
  }, [methods, zone]);

  return (
    <div className="dashboard_container">
      <Cards className="dashboard_cards_container">
        {cards.map((card) => (
          <Cards
            className="dashboard_cards"
            sx={{ bgcolor: card.bgcolor, color: darken(card.bgcolor, 0.7) }}
          >
            <div className="heading_name">{card.name}</div>
            <div className="count_no">{card.counts}</div>
          </Cards>
        ))}
      </Cards>

      <div className="storage_area_filter">
        <FormProvider {...methods}>
          <form
            ref={formRef}
            onSubmit={methods.handleSubmit(onSubmit)}
            className="storage_area_filter_form"
          >
            <TextInputFieldC name="item_barcode" placeholder="Item Barcode" />

            <DateRangePickerC
              name="date_range"
              ranges={methods.watch("date_range")}
              placeholder="Date Range"
              onChange={(item: RangeKeyDict) => {
                console.log(item.selection.startDate);

                methods.setValue("date_range", item.selection, {
                  shouldValidate: true,
                });
                methods.setValue("startDate", item.selection.startDate, {
                  shouldValidate: true,
                });
                methods.setValue("endDate", item.selection.endDate, {
                  shouldValidate: true,
                });
              }}
              setRanges={() => {}}
              handleRangeClear={() => {
                methods.setValue("date_range", undefined);
                methods.setValue("startDate", undefined, {
                  shouldValidate: true,
                });
                methods.setValue("endDate", undefined, {
                  shouldValidate: true,
                });
              }}
            />

            <Select
              name="grn_no"
              size="small"
              placeholder="Select GRN No"
              options={grnOption ?? []}
              onChange={(_, val) => {
                methods.setValue("grn_no", val as string);
              }}
            />

            <Select
              disableClearable
              name="zone_id"
              loading={isLoading}
              size="small"
              placeholder="Select Zone"
              value={
                zoneOption?.find(
                  (zone) => zone.value === methods.getValues("zone_id")
                ) ?? null
              }
              options={zoneOption ?? []}
              onChange={(_, val) => {
                const value = val as AutocompleteOption;
                methods.setValue("zone_id", value?.value);
              }}
            />

            <Select
              name="item_name"
              size="small"
              placeholder="Select Item Name"
              options={itemNameOption ?? []}
              onChange={(_, val) => {
                methods.setValue("item_name", val as string);
              }}
            />

            <Select
              name="item_code"
              size="small"
              placeholder="Select Item Code"
              options={itemCodeOption ?? []}
              onChange={(_, val) => {
                // dispatch(listDashboardStorageArea(value?.value ?? null));
                methods.setValue("item_code", val as string);
              }}
            />
          </form>

          <ButtonField
            sx={{ float: "right", marginTop: "8px" }}
            className="form_save_button"
            onClick={() => formRef.current?.requestSubmit()}
          >
            Search
          </ButtonField>
        </FormProvider>
      </div>

      <div style={{ height: "615px" }}>
        <StorageContainerComponent data={data} />
      </div>
    </div>
  );
};

export default StorageAreaDashboard;
