import React, { FunctionComponent } from "react";
import { useState } from "react";
import DatePicker from "react-multi-date-picker";
import { MultipleDatePickerProps } from "./IMultipleDatePickerProps";
import "./MultipleDatePicker.scss";
import { DemoItem } from "@mui/x-date-pickers/internals/demo";
import { DateRangeIcon } from "@mui/x-date-pickers";
import { SvgIcon } from "@mui/material";
import IconButtonField from "../Button/IconButtonField";
import { Controller, useFormContext } from "react-hook-form";

const MultipleDatePicker: FunctionComponent<MultipleDatePickerProps> = (
  props
) => {
  const {
    multiple,
    range,
    format,
    pickerLable,
    onlyMonthPicker,
    onlyYearPicker,
    calendar,
    locale,
    mapDays,
    plugins,
    zIndex,
    onChange,
    dateSeparator,
    multipleRangeSeparator,
    typingTimeout,
    sort,
    className,
    value,
    ref,
    formattingIgnoreList,
    weekDays,
    months,
    showOtherDays,
    maxDate,
    minDate,
    disableDayPicker,
    disableMonthPicker,
    disableYearPicker,
    numberOfMonths,
    digits,
    currentDate,
    buttons,
    weekStartDayIndex,
    renderButton,
    readOnly,
    disabled,
    hideMonth,
    hideWeekDays,
    hideYear,
    shadow,
    fullYear,
    rangeHover,
    highlightToday,
    style,
    inputClassName,
    name,
    title,
    id,
    placeholder,
    render,
    onClose,
    onOpen,
    onFocusedDateChange,
    onMonthChange,
    onPropsChange,
    onYearChange,
    containerClassName,
    containerStyle,
    customErrorMessage,
  } = props;
  const {
    control,
    formState: { errors },
  } = useFormContext();

  return (
    <Controller
      name={name!}
      control={control}
      render={({ field }) => (
        <>
          <div className="component_multidate_inpt_con">
            <IconButtonField className="component_multi_date_icon">
              <DateRangeIcon />
            </IconButtonField>

            <DatePicker
              {...props}
              onOpen={onOpen}
              onClose={onClose}
              onFocusedDateChange={onFocusedDateChange}
              onYearChange={onYearChange}
              onMonthChange={onMonthChange}
              containerClassName={`containerClass ${containerClassName}`}
              style={style}
              className={className}
              name={name}
              title={title}
              id={id}
              placeholder={placeholder}
              ref={ref}
              value={field.value}
              multiple={true}
              range={Boolean(!multiple && range)}
              rangeHover={Boolean(!multiple && range && rangeHover)}
              onlyMonthPicker={onlyMonthPicker}
              onlyYearPicker={onlyYearPicker}
              locale={locale}
              calendar={calendar}
              mapDays={mapDays}
              plugins={plugins}
              zIndex={zIndex}
              onChange={(val) => field.onChange(val)}
              dateSeparator={dateSeparator}
              multipleRangeSeparator={multipleRangeSeparator}
              typingTimeout={typingTimeout}
              sort={sort}
              formattingIgnoreList={formattingIgnoreList}
              weekDays={weekDays}
              months={months}
              showOtherDays={showOtherDays}
              minDate={minDate}
              maxDate={maxDate}
              disableDayPicker={disableDayPicker}
              disableYearPicker={disableYearPicker}
              disableMonthPicker={disableMonthPicker}
              numberOfMonths={numberOfMonths}
              weekStartDayIndex={weekStartDayIndex}
              highlightToday={highlightToday}
              // headerOrder={["LEFT_BUTTON", "RIGHT_BUTTON", "MONTH_YEAR"]}
              // arrow={false}
              // arrowStyle={{ color: "red" }}
              inputClass={`${
                errors[name!] ? "inputClass_error" : "inputClass"
              }`}
              render={render}
            />
          </div>
          {errors[name!] ? (
            <p
              style={{
                textAlign: "left",
                color: "#BD0000",
                fontSize: "0.75rem",
                padding: "0px",
                margin: "0px 0px 0px 10px",
              }}
            >
              {String(errors[name!]?.message) ===
                "Expected array, received null" ||
              String(errors[name!]?.message) === "undefined"
                ? `${customErrorMessage}`
                : String(errors[name!]?.message)}
            </p>
          ) : null}
        </>
      )}
    />
  );
};

export default MultipleDatePicker;
