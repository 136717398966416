import { TransactionDetailsProps } from "./ITransactionDetails";
import "./TransactionDetails.scss";
import { FunctionComponent } from "react";

const TransactionDetails: FunctionComponent<TransactionDetailsProps> = () => {
  //   useEffect(() => {
  //     dispatch(listItemMaster());
  //   }, [addItem, editItem, deleteItem]);
  //   useEffect(() => {
  //     return () => {
  //       dispatch(clearAddItemMasterResponse());
  //       dispatch(clearEditItemMasterResponse());
  //       dispatch(clearDeleteItemMasterResponse());
  //     };
  //   }, []);

  return <></>;
};

export default TransactionDetails;
