import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IReduxState } from "../../interfaces/IReduxState";
import { IGetOnePick } from "../../../Pages/StockTransfer/IStockTransfer";

export const GetOnePickListSlice = createSlice({
  name: "GetOnePickList",
  initialState: {} as IReduxState<IGetOnePick>,
  reducers: {
    getOnePickList: (state, _: PayloadAction<number>) => {
      state.isLoading = true;
    },
    successGetOnePickList: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
    failureGetOnePickList: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
    clearGetOnePickListResponse: (state) => {
      state.data = {};
    },
  },
});

export const {
  getOnePickList,
  failureGetOnePickList,
  successGetOnePickList,
  clearGetOnePickListResponse,
} = GetOnePickListSlice.actions;

export default GetOnePickListSlice.reducer;
