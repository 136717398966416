import { FunctionComponent } from "react";
import Table from "../../Components/Table/Table";
import CustomToolbar from "../../Components/Table/components/CustomToolbar";
import TableSearch from "../../Components/Table/components/TableSearch";
import { ILocationReceiveItems } from "./ILocationReceive";
import { Columns } from "../../Components/Table/ITable";

interface LocationReceiveViewProps {
  tableRows: ILocationReceiveItems[];
}

const LocationReceiveView: FunctionComponent<LocationReceiveViewProps> = (
  props
) => {
  const columns: Columns = [
    {
      field: "index",
      renderHeader: () => <b>S.No</b>,
      align: "center",
      headerAlign: "center",
      width: 90,
    },
    {
      field: "item_name",
      renderHeader: () => <b>Item Name</b>,
      flex: 1,
      headerAlign: "left",
      align: "left",
    },
    {
      field: "item_qnty",
      renderHeader: () => <b>Item Quantity</b>,
      flex: 1,
      align: "right",
      headerAlign: "right",
      type: "string",
    },
  ];
  return (
    <>
      <Table rows={props.tableRows ?? []} columns={columns}>
        <CustomToolbar>
          <TableSearch className="view_table_left_align_search" />
        </CustomToolbar>
      </Table>
    </>
  );
};

export default LocationReceiveView;
