import { createSlice } from "@reduxjs/toolkit";
import { IReduxState } from "../../interfaces/IReduxState";
import { IZoneStorageArea } from "../../../Pages/StorageArea/IStorageArea";

export const StorageAreaSlice = createSlice({
  name: "ListStorageArea",
  initialState: {} as IReduxState<IZoneStorageArea>,
  reducers: {
    listStorageArea: (state, _) => {
      state.isLoading = true;
    },
    successStorageArea: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
    failureStorageArea: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
    clearStorageArea: (state) => {
      state.data = {};
    },
  },
});

export const {
  failureStorageArea,
  listStorageArea,
  successStorageArea,
  clearStorageArea,
} = StorageAreaSlice.actions;

export default StorageAreaSlice.reducer;
