import { createSlice } from "@reduxjs/toolkit";
import { IReduxState } from "../../interfaces/IReduxState";
import { UserPermissionType } from "./IUserPermission";

export const EditUserPermission = createSlice({
  name: "EditUserPermission",
  initialState: {
    data: {},
    isLoading: false,
  } as IReduxState<UserPermissionType[]>,
  reducers: {
    editUserPermission: (state, _) => {
      state.isLoading = true;
    },
    successeditUserPermission: (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    },
    failureeditUserPermission: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
  },
});

export const {
  editUserPermission,
  successeditUserPermission,
  failureeditUserPermission,
} = EditUserPermission.actions;
export default EditUserPermission.reducer;
