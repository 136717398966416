import { FunctionComponent } from "react";
import CustomToolbar from "../../Components/Table/components/CustomToolbar";
import Table from "../../Components/Table/Table";
import TableSearch from "../../Components/Table/components/TableSearch";
import { Columns } from "../../Components/Table/ITable";
import { IItemDetails } from "./ITransactionRequest";
import { GridValueFormatterParams } from "@mui/x-data-grid";
import { IItems } from "../StockRecieve/IStockRecieve";

interface TransactionRequestViewItemsProps {
  tableRows: IItemDetails[];
}

const TransactionRequestViewItems: FunctionComponent<
  TransactionRequestViewItemsProps
> = (props) => {
  const columns: Columns = [
    {
      field: "index",
      renderHeader: () => <b>S.No</b>,
      align: "center",
      headerAlign: "center",
      width: 90,
    },
    {
      field: "items",
      renderHeader: () => <b>Item Name</b>,
      flex: 1,
      valueGetter: (params: GridValueFormatterParams<IItems>) =>
        params.value?.item_name,
      headerAlign: "left",
      align: "left",
    },
    {
      field: "item_code",
      renderHeader: () => <b>Item Code</b>,
      flex: 1,
      headerAlign: "left",
      align: "left",
      type: "string",
    },
    {
      field: "item_qnty",
      renderHeader: () => <b>Requested Quantity</b>,
      flex: 1,
      align: "right",
      headerAlign: "right",
    },
    {
      field: "init_qnty",
      renderHeader: () => <b>transferred Quantity</b>,
      flex: 1,
      align: "right",
      headerAlign: "right",
    },
    {
      field: "picked_qnty",
      renderHeader: () => <b>Picked Quantity</b>,
      flex: 1,
      align: "right",
      headerAlign: "right",
    },
  ];
  return (
    <>
      <Table rows={props.tableRows ?? []} columns={columns}>
        <CustomToolbar>
          <TableSearch className="view_table_left_align_search" />
        </CustomToolbar>
      </Table>
    </>
  );
};

export default TransactionRequestViewItems;
