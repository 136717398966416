import React, { FunctionComponent } from "react";
import TimeRangePickers, {
  TimeRangePickersProps,
} from "../TimeRangePicker/TimeRangePickers";
import { Controller, useFormContext } from "react-hook-form";

const TimeRangePickerC: FunctionComponent<TimeRangePickersProps> = (props) => {
  const { name, customErrorMessage, ...rest } = props;
  const {
    control,
    formState: { errors },
  } = useFormContext();
  return (
    <>
      <Controller
        control={control}
        name={name!}
        render={({ field }) => {
          //   console.log(field.value);

          return (
            <TimeRangePickers
              {...field}
              {...rest}
              error={!!errors[name!]}
              helperText={
                (errors[name!] && errors[name!]?.message === undefined) ||
                errors[name!]?.message
                  ? `${customErrorMessage}`
                  : null
              }
              onChange={(val) => {
                field.onChange(val);
                // console.log(val);
              }}
              //   value={field.value}
              valueTimeRangePicker={field.value}
            />
          );
        }}
      />
    </>
  );
};

export default TimeRangePickerC;
