import { FunctionComponent } from "react";
import { GridPrintExportMenuItem } from "@mui/x-data-grid";
import { ITablePrintProps } from "../ITable";
import { Button } from "@mui/material";

const TablePrintButton: FunctionComponent<ITablePrintProps> = (props) => {
  const {
    fileName,
    hideToolbar,
    hideFooter,
    includeCheckboxes,
    bodyClassName,
    pageStyle,
    className,
  } = props;
  return (
    <div className={className}>
      <GridPrintExportMenuItem
        options={{ fileName: fileName, hideToolbar: hideToolbar }}
      />
    </div>
  );
};

export default TablePrintButton;
