import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IReduxState } from "../../interfaces/IReduxState";
import { IGetOnePick } from "../../../Pages/StockTransfer/IStockTransfer";

export const EditPickItemSlice = createSlice({
  name: "EditPickItem",
  initialState: {} as IReduxState<IGetOnePick>,
  reducers: {
    editPickItem: (state, _: PayloadAction<IGetOnePick>) => {
      state.isLoading = true;
    },
    successeditPickItem: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
    failureeditPickItem: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
    clearEditPickItemResponse: (state) => {
      state.data = {};
    },
  },
});

export const {
  editPickItem,
  failureeditPickItem,
  successeditPickItem,
  clearEditPickItemResponse,
} = EditPickItemSlice.actions;

export default EditPickItemSlice.reducer;
