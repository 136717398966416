import { put, call } from "redux-saga/effects";
import api from "../../../Axios/middleware";
import { PayloadAction } from "@reduxjs/toolkit";
import {
  FailureListTranscationType,
  SuccessListTransactionType,
} from "./ListTransactionType";
import { AxiosResponse } from "axios";

// List TT
const ListTranscationTypeAPI = (action: PayloadAction<string>) => {
  return api.get(`/general-settings/type?key=${action.payload}`);
};

export function* ListTransactionTypeCall(action: PayloadAction<string>) {
  try {
    const response: AxiosResponse = yield call(ListTranscationTypeAPI, action);
    yield put(SuccessListTransactionType(response.data));
  } catch (error) {
    yield put(FailureListTranscationType(error));
  }
}
