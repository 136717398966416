import React, { FunctionComponent } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { UploadButtonFieldProps } from "./IButtonField";
import UploadButtonField from "./UploadButtonField";

const UploadButtonC: FunctionComponent<UploadButtonFieldProps> = (props) => {
  const { name, customErrorMessage, fileName, ...rest } = props;
  const {
    control,
    formState: { errors },
  } = useFormContext();
  return (
    <Controller
      name={name!}
      control={control}
      render={({ field }) => (
        <>
          <UploadButtonField {...field} {...rest} />
          {errors[name!] ? (
            <p
              style={{
                textAlign: "center",
                color: "#BD0000",
                fontSize: "9px",
                padding: "5px",
                margin: "0px",
              }}
            >
              {String(errors[name!]?.message) ===
                "Input not instance of File" ||
              String(errors[name!]?.message) === "undefined"
                ? `${customErrorMessage}`
                : String(errors[name!]?.message)}
            </p>
          ) : null}
          {fileName ?? (
            <p
              style={{
                width: "100%",
                fontSize: "1rem",
                padding: "0px",
                margin: "5px",
                textAlign: "center",
              }}
            >
              {fileName}
            </p>
          )}
        </>
      )}
    />
  );
};

export default UploadButtonC;
