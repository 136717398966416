import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IReduxState } from "../../interfaces/IReduxState";
import { IStockTransfer } from "../../../Pages/StockTransfer/IStockTransfer";

export const AddStockTransferSlice = createSlice({
  name: "AddStockTransfer",
  initialState: {} as IReduxState<IStockTransfer>,
  reducers: {
    addStockTransfer: (state, _: PayloadAction<IStockTransfer>) => {
      state.isLoading = true;
    },
    successaddStockTransfer: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
    failureaddStockTransfer: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
    clearAddStockTransferResponse: (state) => {
      state.data = {};
    },
  },
});

export const {
  addStockTransfer,
  failureaddStockTransfer,
  successaddStockTransfer,
  clearAddStockTransferResponse,
} = AddStockTransferSlice.actions;

export default AddStockTransferSlice.reducer;
