import { put, call } from "redux-saga/effects";
import api from "../../../Axios/middleware";
import { AxiosResponse } from "axios";
import {
  successGetGenerateSerialNo,
  failureGetGenerateSerialNo,
} from "./GetGenerateSerialNo";
import { IAPIResponse } from "../../../interfaces/IAPIResponse";
import { IGetGenerateSerialNo } from "./IGenerateSerial";
import { PayloadAction } from "@reduxjs/toolkit";
import {
  failureUpdateGeneralConfig,
  successUpdateGeneralConfig,
} from "./UpdateGeneralConfig";
import { IGeneralConfig } from "../../../../Pages/GeneralConfig/IGeneralConfig";

// GetGenarateSerialNo
const GetGenerateSerialNoAPI = () => {
  return api.get("/sequence-no/findcus");
};
export function* GetGenerateSerialNoCall() {
  try {
    const response: AxiosResponse<IAPIResponse<IGetGenerateSerialNo>> =
      yield call(GetGenerateSerialNoAPI);
    yield put(successGetGenerateSerialNo(response.data));
  } catch (error) {
    yield put(failureGetGenerateSerialNo(error));
  }
}

// UpdateGeneralConfig
const UpdateGeneralConfigAPI = (action: PayloadAction<IGeneralConfig>) => {
  return api.patch(`/sequence-no/config_settings`, action.payload);
};

export function* UpdateGeneralConfig(action: PayloadAction<IGeneralConfig>) {
  try {
    const response: AxiosResponse = yield call(UpdateGeneralConfigAPI, action);

    yield put(successUpdateGeneralConfig(response));
  } catch (error) {
    yield put(failureUpdateGeneralConfig(error));
  }
}
