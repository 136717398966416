import "./MaskInput.scss";

import TextField from "@mui/material/TextField";
import InputMask from "react-input-mask";
import { MaskInputProps } from "./IMaskInput";
import { FC } from "react";
import React from "react";

const MaskInput: FC<MaskInputProps> = (props) => {
  const {
    id,
    name,
    placeholder,
    variant,
    label,
    color,
    required,
    className,
    sx,
    value,
    defaultValue,
    error,
    disabled,
    readOnly,
    fullWidth,
    type,
    inputClassName,
    helperText,
    InputProps,
    shrink,
    inputLableStyle,
    size,
    labelSize,
    onChange,
    onBlur,
    onFocus,

    mask,
    alwaysShowMask,
    maskChar,
  } = props;
  const input = (inputProps: any) => {
    return (
      <InputMask
        mask={mask}
        maskChar={maskChar ?? null}
        alwaysShowMask={alwaysShowMask}
        {...inputProps}
      >
        {(inputProps: { value: string }) => <input {...inputProps} />}
      </InputMask>
    );
  };

  return (
    <TextField
      id={id}
      name={name}
      placeholder={placeholder}
      variant={variant}
      label={label}
      color={color}
      required={required}
      fullWidth={fullWidth}
      className={`GlobalInputStyle ${className}`}
      sx={sx}
      value={value}
      defaultValue={defaultValue}
      error={error}
      helperText={helperText}
      onChange={onChange}
      onBlur={onBlur}
      onFocus={onFocus}
      disabled={disabled}
      type={type ?? "text"}
      size={size}
      InputProps={{
        ...InputProps,
        inputComponent: input,
        readOnly: readOnly,
        className: `GlobalInputPropsStyle ${inputClassName}`,
      }}
      InputLabelProps={{
        shrink: shrink,
        size: labelSize,
        className: `GlobalInputLableStyle ${inputLableStyle}`,
      }}
    />
  );
};

export default React.memo(MaskInput);
