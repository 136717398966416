import { FunctionComponent } from "react";
import Cards from "../../Components/Card/cards";
import CardHeaders from "../../Components/Card/Components/CardHeader";
import IconButtonField from "../../Components/Button/IconButtonField";
import CardContents from "../../Components/Card/Components/CardContent";
import { SvgIcon, Tooltip } from "@mui/material";
import { ReactComponent as Stock } from "../../Assets/Images/Stock.svg";
import { IAPIResponse } from "../../Redux/interfaces/IAPIResponse";
import { IZoneStorageArea } from "./IStorageArea";

interface StorageContainerComponentProps {
  data: IAPIResponse<IZoneStorageArea> | undefined;
}
const getLuminance = (hexColor: string) => {
  const rgb = parseInt(hexColor.slice(1), 16);
  const r = (rgb >> 16) & 0xff;
  const g = (rgb >> 8) & 0xff;
  const b = (rgb >> 0) & 0xff;

  const luminance = 0.2126 * r + 0.7152 * g + 0.0722 * b;
  return luminance;
};
const StorageContainerComponent: FunctionComponent<
  StorageContainerComponentProps
> = (props) => {
  return (
    <>
      <div className="storage_card_container">
        {props.data?.data?.liststored_data?.map((row) => {
          return (
            <div className="rows">
              {row?.map((col) => (
                <>
                  <Cards className="card" sx={{ bgcolor: col.check }}>
                    <CardHeaders
                      title={
                        <>
                          Storage Area : <b>{col?.areacode}</b>
                        </>
                      }
                      action={
                        <IconButtonField
                          disableRipple
                          sx={{ fontSize: "inherit" }}
                        >
                          Qty-Onhand : <>{col.total_onhand}</>
                        </IconButtonField>
                      }
                    />
                    <CardContents className="card_content">
                      {col?.storage_details?.map((item) => (
                        <Tooltip
                          arrow
                          componentsProps={{
                            tooltip: {
                              sx: {
                                bgcolor: `${item.stock_receive.color}`,
                                color: `${
                                  getLuminance(item.stock_receive.color) > 128
                                    ? "black"
                                    : "white"
                                }`,
                                fontWeight: "550",
                                maxWidth: "none",
                              },
                            },
                            arrow: {
                              sx: {
                                color: `${item.stock_receive.color}`,
                              },
                            },
                          }}
                          title={
                            <>
                              <div>GRN No : {item?.grn_no}</div>
                              <div>
                                Item Name : {item?.item_details?.item_name}
                              </div>
                              <div>Item Quantity : {item?.onhand_qnty}</div>
                              <div>Item Code : {item?.item_code}</div>
                              <div>Item Barcode : {item?.item_barcode}</div>
                            </>
                          }
                        >
                          <SvgIcon
                            component={Stock}
                            inheritViewBox
                            sx={{
                              cursor: "pointer",
                              height: "25px",
                              width: "25px",
                              fill: item?.stock_receive?.color,
                              // stroke: item?.stock_receive?.color,
                            }}
                          />
                        </Tooltip>
                      ))}
                      {/* <SvgIcon
                        component={Stock}
                        inheritViewBox
                        sx={{ fill: "#bdbdbd" }}
                      /> */}
                    </CardContents>
                  </Cards>
                </>
              ))}
            </div>
          );
        })}
      </div>
    </>
  );
};

export default StorageContainerComponent;
