import React, { FunctionComponent } from "react";
import "./TableComponent.scss";

export interface ITableAggregationProps {
  name?: string;
  value?: number;
  style?: React.CSSProperties;
}

const TableAggregation: FunctionComponent<ITableAggregationProps> = (props) => {
  const { name, value, style } = props;
  return (
    <>
      <div className="table_aggregation_container" style={style}>
        <h5>{name}</h5>
        <div className="value">
          <p>{value}</p>
        </div>
      </div>
    </>
  );
};

export default TableAggregation;
