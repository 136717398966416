import { FunctionComponent } from "react";
import { TextInputFieldProps } from "../../TextInput/ITextInputFieldProps";
import { GridToolbarExport } from "@mui/x-data-grid";
import { ButtonFieldProps } from "../../Button/IButtonField";

const TableExport: FunctionComponent<ButtonFieldProps> = (props) => {
  return <GridToolbarExport {...props} />;
};

export default TableExport;
