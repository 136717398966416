import { FunctionComponent, useEffect, useLayoutEffect, useState } from "react";
import "./TransactionRequest.scss";
import {
  IItemDetails,
  ITransactionRequest,
  TransactionRequestProps,
} from "./ITransactionRequest";
import { useAppDispatch, useAppSelector } from "../../Redux/Store/Hooks";
import { IDeleteDialog } from "../../Components/DeleteDialog/IDeleteDialog";
import {
  GridPreProcessEditCellProps,
  GridRenderCellParams,
  GridValueFormatterParams,
} from "@mui/x-data-grid";
import IconButtonField from "../../Components/Button/IconButtonField";
import { SvgIcon } from "@mui/material";
import { Columns } from "../../Components/Table/ITable";
import { ReactComponent as Delete } from "../../Assets/Images/delete.svg";
import { ReactComponent as Edit } from "../../Assets/Images/edit.svg";
import { ReactComponent as ViewIcon } from "../../Assets/Images/solar_eye-linear.svg";
import { ReactComponent as Transaction } from "../../Assets/Images/transactions.svg";
import { listTransactionRequest } from "../../Redux/Slices/TransactionRequest/ListTransactionRequestSlice";
import { ILocationMaster } from "../LocationMaster/ILocationMaster";
import Table from "../../Components/Table/Table";
import CustomToolbar from "../../Components/Table/components/CustomToolbar";
import ButtonField from "../../Components/Button/ButtonField";
import TableSearch from "../../Components/Table/components/TableSearch";
import Dialog from "../../Components/Dialog/Dialog";
import { dateFormatter } from "../../Utils/dateFormatter";
import { useNavigate } from "react-router-dom";
import TransactionRequestViewItems from "./TransactionRequestViewItems";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import DeleteDialog from "../../Components/DeleteDialog/DeleteDialog";
import {
  clearDeleteTransactionRequestResponse,
  deleteTransactionRequest,
} from "../../Redux/Slices/TransactionRequest/DeleteTransactionRequestSlice";
import { statusOptionsFD } from "../StockRecieve/IStockRecieve";
import Loading from "../../Components/Loading/Loading";

const TransactionRequest: FunctionComponent<TransactionRequestProps> = () => {
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState<boolean>(false);
  const [deleteDialogData, setDeleteData] = useState<IDeleteDialog>({});
  const [viewInitialValues, setViewInitialValues] = useState<IItemDetails[]>(
    []
  );
  const [locType, setLocType] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const nav = useNavigate();
  const getLoginLocation = localStorage.getItem("location_type");
  // console.log(getLoginLocation);

  const ListTransaction = useAppSelector(
    (state) => state.ListTransactionRequest
  );
  const { data: transaction } = useAppSelector(
    (state) => state.ListTransactionRequest
  );
  const { data: deleteTransaction } = useAppSelector(
    (state) => state.DeleteTransactionRequest
  );

  const column: Columns = [
    {
      field: "index",
      renderHeader: () => <b>S.No</b>,
      align: "center",
      headerAlign: "center",
      width: 90,
    },
    {
      field: "entry_no",
      renderHeader: () => <b>Entry No</b>,
      flex: 0.6,
      align: "left",
      headerAlign: "left",
      type: "string",
    },
    {
      field: "entry_date",
      renderHeader: () => <b>Entry Date</b>,
      flex: 1,
      align: "center",
      headerAlign: "center",
      type: "date",
      valueFormatter: (params) => dateFormatter(params.value),
    },
    {
      field: "requestfrom",
      renderHeader: () => <b>Request From</b>,
      flex: 1,
      align: "left",
      headerAlign: "left",
      type: "string",
      valueFormatter: (params: GridValueFormatterParams<ILocationMaster>) => {
        return params.value.name;
      },
    },
    {
      field: "requestto",
      renderHeader: () => <b>Request To</b>,
      flex: 1,
      align: "left",
      headerAlign: "left",
      type: "string",
      valueFormatter: (params: GridValueFormatterParams<ILocationMaster>) => {
        return params.value.name;
      },
    },
    {
      field: "status",
      renderHeader: () => <b>Status</b>,
      flex: 1,
      align: "left",
      headerAlign: "left",
      type: "singleSelect",
      valueOptions: statusOptionsFD,
      preProcessEditCellProps: (params: GridPreProcessEditCellProps) => {
        // dispatch()
        return { ...params.props };
      },
    },
    {
      field: "actions",
      renderHeader: () => <b>Action</b>,
      type: "actions",
      flex: 1,
      renderCell: (params: GridRenderCellParams<ITransactionRequest>) => {
        return (
          <>
            {/* view */}
            <IconButtonField
              onClick={() => {
                const itemDetails = params.row?.item_details?.map(
                  (items, index) => ({
                    ...items,
                    index: index + 1,
                    init_qnty: params.row.picklist?.length
                      ? params.row.picklist[index]?.need_quantity
                      : "",
                    picked_qnty: params.row.picklist?.length
                      ? params.row.picklist[index]?.item_quantity
                      : "",
                  })
                );
                setDialogOpen(true);
                setViewInitialValues(itemDetails ?? []);
              }}
            >
              <SvgIcon
                inheritViewBox
                component={ViewIcon}
                sx={{ fill: "none" }}
              />
            </IconButtonField>

            {/* edit */}
            <IconButtonField
              sx={{
                display: `${getLoginLocation === "true" ? "none" : "flex"}`,
              }}
              disabled={!!params.row?.request.length}
              onClick={() => {
                nav("edit", { state: params.id });
              }}
            >
              <SvgIcon sx={{ fill: "none" }} component={Edit} inheritViewBox />
            </IconButtonField>

            {/* transfer */}
            <IconButtonField
              // disabled={!!params.row?.request.length}
              disabled={!["PND", "PCKINT"].includes(params.row.status ?? "")}
              sx={{ display: locType === true ? "block" : "none" }}
              onClick={() => {
                const transferId = params.row?.request.length
                  ? params.row?.request[0].id
                  : 0;
                nav("stock-transfer/edit", {
                  state: transferId
                    ? { update_id: transferId }
                    : { req_id: params.id, key: "RT" },
                });
              }}
            >
              <SvgIcon
                inheritViewBox
                component={Transaction}
                sx={{ fill: "none" }}
              />
            </IconButtonField>

            {/* delete */}
            <IconButtonField
              sx={{
                display: `${getLoginLocation === "true" ? "none" : "flex"}`,
              }}
              disabled={!!params.row?.request.length}
              onClick={() => {
                setDeleteData({ id: params.id, name: params.row.entry_no });
                setDeleteDialogOpen(true);
              }}
            >
              <SvgIcon component={Delete} inheritViewBox />
            </IconButtonField>
          </>
        );
      },
    },
  ];
  useLayoutEffect(() => {
    const location_type = localStorage.getItem("location_type");
    setLocType(location_type === "true" ? true : false);
  }, []);

  useEffect(() => {
    dispatch(listTransactionRequest("ST"));
  }, [deleteTransaction, dispatch]);
  useEffect(() => {
    return () => {
      dispatch(clearDeleteTransactionRequestResponse());
    };
  }, [dispatch]);

  const itemList = Array.isArray(transaction?.data)
    ? transaction?.data.map((item, index) => ({
        ...item,
        index: index + 1,
      }))
    : [];

  if (ListTransaction.isLoading!) {
    return (
      <>
        <Loading isLoading={ListTransaction.isLoading!} />
      </>
    );
  }
  return (
    <>
      <Table
        rows={itemList ?? []}
        columns={column}
        columnVisibilityModel={{
          requestfrom: locType === true ? true : false,
          requestto: locType === false ? true : false,
        }}
        disableColumnMenu={false}
      >
        <CustomToolbar>
          <h6>Stock Request</h6>
          <TableSearch />
          <ButtonField
            sx={{ display: `${getLoginLocation === "true" ? "none" : "flex"}` }}
            className="tabel_add_new_button"
            onClick={() => nav("add")}
          >
            Add new
          </ButtonField>
        </CustomToolbar>
      </Table>

      <Dialog
        open={dialogOpen}
        maxWidth={"md"}
        fullWidth
        Dialog_Title={
          <>
            Items
            <IconButtonField
              onClick={() => {
                setDialogOpen(false);
              }}
            >
              <SvgIcon
                component={CloseOutlinedIcon}
                inheritViewBox
                sx={{ height: "20px", width: "20px" }}
              />
            </IconButtonField>
          </>
        }
        Dialog_Content={
          <TransactionRequestViewItems tableRows={viewInitialValues} />
        }
      />

      <DeleteDialog
        open={deleteDialogOpen}
        setOpen={setDeleteDialogOpen}
        deleteableName={deleteDialogData.name ?? ""}
        response={deleteTransaction}
        deleteFunction={() =>
          dispatch(deleteTransactionRequest(deleteDialogData.id))
        }
      />
    </>
  );
};

export default TransactionRequest;
