import { createSlice } from "@reduxjs/toolkit";
import { IReduxState } from "../../interfaces/IReduxState";
// import { ILocationReceiveDetails } from "../../../Pages/LocationReceive/ILocationReceive";

export const StockRequestDetailsSlice = createSlice({
  name: "ListStockRequestDetails",
  initialState: {} as IReduxState<{}>,
  reducers: {
    listStockRequestDetails: (state, _) => {
      state.isLoading = true;
    },
    successStockRequestDetails: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
    failureStockRequestDetails: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
    clearStockRequestDetails: (state) => {
      state.data = {};
    },
  },
});

export const {
  failureStockRequestDetails,
  listStockRequestDetails,
  successStockRequestDetails,
  clearStockRequestDetails,
} = StockRequestDetailsSlice.actions;

export default StockRequestDetailsSlice.reducer;
