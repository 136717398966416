import { FunctionComponent, useEffect, useState } from "react";
import Table from "../../Components/Table/Table";
import CustomToolbar from "../../Components/Table/components/CustomToolbar";
import TableSearch from "../../Components/Table/components/TableSearch";
import ButtonField from "../../Components/Button/ButtonField";
import IconButtonField from "../../Components/Button/IconButtonField";
import { SvgIcon } from "@mui/material";
import { ReactComponent as Edit } from "../../Assets/Images/edit.svg";
import { ReactComponent as ViewIcon } from "../../Assets/Images/solar_eye-linear.svg";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import {
  GridPreProcessEditCellProps,
  GridRenderCellParams,
  GridValueFormatterParams,
} from "@mui/x-data-grid";
import { IStockTransfer } from "../StockTransfer/IStockTransfer";
import { statusOptionsFD } from "../StockRecieve/IStockRecieve";
import { ILocationMaster } from "../LocationMaster/ILocationMaster";
import { dateFormatter } from "../../Utils/dateFormatter";
import { Columns } from "../../Components/Table/ITable";
import { useAppDispatch, useAppSelector } from "../../Redux/Store/Hooks";
import { useNavigate } from "react-router-dom";
import StockTransferItemsView from "../StockTransfer/StockTransferItemsView";
import Dialog from "../../Components/Dialog/Dialog";
import { listStockAdjustment } from "../../Redux/Slices/StockAdjustment/ListStockAdjustment";
import { IAdjustItems } from "./IStockAdjustment";
import ViewStockAdjustmentItems from "./ViewStockAdjustmentItems";
import { GenerateSequenceStart } from "../../Redux/Slices/SequenceSlicer/GenerateSequence";

const StockAdjustment: FunctionComponent = () => {
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [viewInitialValues, setViewInitialValues] = useState<IAdjustItems[]>();
  const dispatch = useAppDispatch();
  const nav = useNavigate();
  const { data: ListStockAdjustment } = useAppSelector(
    (state) => state.ListStockAdjustment
  );
  console.log(ListStockAdjustment?.data);
  const stockTransferList = ListStockAdjustment?.data?.map((stock, index) => ({
    ...stock,
    index: index + 1,
    total_adjusted_items: stock?.item_details?.reduce((sum, item) => {
      return sum + (item.adjust_qty ? Number(item.adjust_qty) : 0);
    }, 0),
    total_items: stock.item_details?.length,
  }));

  const column: Columns = [
    {
      field: "index",
      renderHeader: () => <b>S.No</b>,
      align: "center",
      headerAlign: "center",
      width: 90,
    },
    {
      field: "entry_no",
      renderHeader: () => <b>Entry No</b>,
      flex: 0.6,
      align: "left",
      headerAlign: "left",
      type: "string",
    },
    {
      field: "entry_date",
      renderHeader: () => <b>Entry Date</b>,
      flex: 0.6,
      align: "center",
      headerAlign: "center",
      type: "date",
      valueFormatter: (params) => dateFormatter(params.value),
    },
    {
      field: "remarks",
      renderHeader: () => <b>Remarks</b>,
      flex: 1,
      align: "left",
      headerAlign: "left",
      type: "string",
    },
    {
      field: "total_items",
      renderHeader: () => <b>Total Items</b>,
      flex: 0.6,
      align: "right",
      headerAlign: "right",
      type: "number",
    },
    {
      field: "total_adjusted_items",
      renderHeader: () => <b>Total Adjusted Item Quantities</b>,
      flex: 0.7,
      align: "right",
      headerAlign: "right",
      type: "number",
    },
    {
      field: "actions",
      renderHeader: () => <b>Action</b>,
      type: "actions",
      renderCell: (params: GridRenderCellParams<IStockTransfer>) => {
        return (
          <>
            <IconButtonField
              onClick={() => {
                const itemDetails = params.row?.item_details?.map(
                  (items, index) => ({
                    ...items,
                    index: index + 1,
                    item_name: items.items?.item_name,
                  })
                );
                console.log(itemDetails);
                setDialogOpen(true);
                setViewInitialValues(itemDetails);
              }}
            >
              <SvgIcon
                inheritViewBox
                component={ViewIcon}
                sx={{ fill: "none" }}
              />
            </IconButtonField>
          </>
        );
      },
    },
  ];

  useEffect(() => {
    dispatch(listStockAdjustment({ key: "SRE" }));
  }, [dispatch]);

  return (
    <>
      <Table
        rows={stockTransferList ?? []}
        columns={column}
        disableColumnMenu={false}
      >
        <CustomToolbar>
          <h6>Stock Adjustment</h6>
          <TableSearch />
          <ButtonField
            className="tabel_add_new_button"
            onClick={() => nav("add")}
          >
            Add new
          </ButtonField>
        </CustomToolbar>
      </Table>
      <Dialog
        open={dialogOpen}
        dialogClassName="dialog_double_input"
        Dialog_Title={
          <>
            Stock Adjustment Items
            <IconButtonField
              onClick={() => {
                setDialogOpen(false);
              }}
            >
              <SvgIcon
                component={CloseOutlinedIcon}
                inheritViewBox
                sx={{ height: "20px", width: "20px" }}
              />
            </IconButtonField>
          </>
        }
        Dialog_Content={
          <ViewStockAdjustmentItems viewInitialValues={viewInitialValues} />
        }
      />
    </>
  );
};

export default StockAdjustment;
