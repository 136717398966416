import "./Sequence.scss";

import { FunctionComponent, useEffect, useRef, useState } from "react";
import Table from "../../Components/Table/Table";
import CustomToolbar from "../../Components/Table/components/CustomToolbar";
import ButtonField from "../../Components/Button/ButtonField";
import { SvgIcon } from "@mui/material";
// import { ReactComponent as add_new } from "../../Assets/Images/";
import Dialog from "../../Components/Dialog/Dialog";
import SequenceForm from "./SequenceForm";
import { useAppDispatch, useAppSelector } from "../../Redux/Store/Hooks";
// import { getSequenceListStart } from "../../Redux/Slicers/Sequence/GetSequenceList";
import { Columns } from "../../Components/Table/ITable";
import { dateFormatter } from "../../Utils/dateFormatter";
import { Sequence } from "./ISequence";
import ResponseDialog from "../../Components/ResponseDialog/ResponseDialog";
import { getSequenceListStart } from "../../Redux/Slices/SequenceSlicer/GetSequenceList";
import IconButtonField from "../../Components/Button/IconButtonField";
import { GridRenderCellParams } from "@mui/x-data-grid";
import { ReactComponent as Delete } from "../../Assets/Images/delete.svg";
import { ReactComponent as Edit } from "../../Assets/Images/edit.svg";
import { clearCreateSequence } from "../../Redux/Slices/SequenceSlicer/CreateSequence";
import {
  cleareDeleteSequenceConfig,
  DeleteSequenceConfigStart,
} from "../../Redux/Slices/SequenceSlicer/DeleteSequenceConfig";
import DeleteDialog from "../../Components/DeleteDialog/DeleteDialog";
import { IDeleteDialog } from "../../Components/DeleteDialog/IDeleteDialog";
import { clearUpdateSequenceConfig } from "../../Redux/Slices/SequenceSlicer/UpdateSequenceConfig";
interface SequenceTableProps {}

const SequenceTable: FunctionComponent<SequenceTableProps> = () => {
  const dispatch = useAppDispatch();
  const formRef = useRef<HTMLFormElement>(null);
  const [initialValues, SetInitialValues] = useState<Sequence>({});
  const [deleteDialogOpen, setDeleteDialogOpen] = useState<boolean>(false);
  const [deleteDialogData, setDeleteData] = useState<IDeleteDialog>({});
  const { data: sequenceList } = useAppSelector(
    (state) => state.GetSequenceList
  );

  const { data: addSequence } = useAppSelector((state) => state.CreateSequence);
  const { data: deleteSequenceResponse } = useAppSelector(
    (state) => state.DeleteSequenceConfig
  );
  const { data: updateResponse } = useAppSelector(
    (state) => state.UpdateSequenceConfig
  );

  const [dialogOpen, setDialogOpen] = useState<boolean>(false);

  const custSequenceList = Array.isArray(sequenceList?.data)
    ? sequenceList?.data.map((sequence, index) => ({
        ...sequence,
        s_no: index + 1,
        status: sequence.status ? "Active" : "In-active",
      }))
    : [];

  const column: Columns = [
    {
      field: "s_no",
      renderHeader: () => <b>S.No</b>,
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "division",
      renderHeader: () => <b>Division</b>,
      flex: 1,
      align: "left",
      headerAlign: "left",
      type: "string",
      valueGetter: (params) => params.value?.name ?? "",
    },
    {
      field: "transaction_type",
      renderHeader: () => <b>Transaction Type</b>,
      flex: 1,
      align: "left",
      headerAlign: "left",
      type: "string",
    },
    {
      field: "startDate",
      renderHeader: () => <b>Start Date</b>,
      flex: 1,
      type: "dateTime",
      align: "left",
      headerAlign: "left",
      valueFormatter: (params) => dateFormatter(params.value),
    },
    {
      field: "endDate",
      renderHeader: () => <b>End Date</b>,
      flex: 1,
      type: "dateTime",
      align: "left",
      headerAlign: "left",
      valueFormatter: (params) => dateFormatter(params.value),
    },
    {
      field: "num_len",
      renderHeader: () => <b>Number Count</b>,
      flex: 1,
      align: "right",
      headerAlign: "right",
    },
    {
      field: "prefix",
      renderHeader: () => <b>Prefix</b>,
      flex: 1,
      align: "left",
      headerAlign: "left",
    },
    {
      field: "number",
      renderHeader: () => <b>Start From</b>,
      flex: 1,
      align: "right",
      headerAlign: "right",
    },
    {
      field: "status",
      renderHeader: () => <b>Status</b>,
      flex: 1,
      align: "left",
      headerAlign: "left",
    },
    {
      field: "action",
      renderHeader: () => <b>Action</b>,
      flex: 1,
      align: "center",
      headerAlign: "center",
      type: "actions",
      renderCell: (params: GridRenderCellParams<Sequence>) => {
        return (
          <>
            <IconButtonField
              onClick={() => {
                console.log(params.row);
                SetInitialValues(params.row);
                setDialogOpen(true);
              }}
            >
              <SvgIcon sx={{ fill: "none" }} component={Edit} inheritViewBox />
            </IconButtonField>
            <IconButtonField
              onClick={() => {
                setDeleteDialogOpen(true);
                setDeleteData({
                  id: params.row.id!,
                  name:
                    params.row.division?.name +
                    "-" +
                    params.row.transaction_type,
                });
              }}
            >
              <SvgIcon component={Delete} inheritViewBox />
            </IconButtonField>
          </>
        );
      },
    },
  ];

  useEffect(() => {
    dispatch(getSequenceListStart());
  }, [addSequence, deleteSequenceResponse, updateResponse?.data, dispatch]);
  useEffect(() => {
    return () => {
      dispatch(clearCreateSequence());
      dispatch(cleareDeleteSequenceConfig());
      dispatch(clearUpdateSequenceConfig());
    };
  }, [dispatch]);
  return (
    <>
      <Table rows={custSequenceList ?? []} columns={column}>
        <CustomToolbar>
          <h6>Sequence Configuration</h6>
          <ButtonField
            className="add_layout_btn"
            type="button"
            onClick={() => setDialogOpen(true)}
          >
            {/* <SvgIcon component={add_new} style={{ fill: "white" }} /> */}
            Add New
          </ButtonField>
        </CustomToolbar>
      </Table>

      <Dialog
        open={dialogOpen}
        // setOpen={setDialogOpen}
        Dialog_Title={`${
          initialValues.id! > 0 ? "Edit Sequence" : "Add Sequence"
        }`}
        dialogClassName="dialog_double_input"
        Dialog_Content={
          <SequenceForm
            formRef={formRef}
            initialValues={initialValues}
            setInitialValues={SetInitialValues}
          />
        }
        Dialog_Actions={
          <>
            <ButtonField
              className="form_cancel_button"
              onClick={() => setDialogOpen(false)}
            >
              Cancel
            </ButtonField>
            <ButtonField
              className="form_save_button"
              onClick={() => {
                formRef.current?.requestSubmit();
                // setDialogOpen(false);
              }}
            >
              Save
            </ButtonField>
          </>
        }
      />
      <DeleteDialog
        deleteableName={deleteDialogData.name ?? ""}
        open={deleteDialogOpen}
        setOpen={setDeleteDialogOpen}
        deleteFunction={() => {
          dispatch(DeleteSequenceConfigStart(deleteDialogData.id));
        }}
        response={deleteSequenceResponse}
      />
      <ResponseDialog response={addSequence} parentDialog={setDialogOpen} />
      <ResponseDialog response={updateResponse} parentDialog={setDialogOpen} />
    </>
  );
};

export default SequenceTable;
