import { createSlice } from "@reduxjs/toolkit";
import { IReduxState } from "../../interfaces/IReduxState";
import { ILocationMaster } from "../../../Pages/LocationMaster/ILocationMaster";

export const LocationMasterSlice = createSlice({
  name: "ListLocationMaster",
  initialState: {} as IReduxState<ILocationMaster[]>,
  reducers: {
    listLocationMaster: (state) => {
      state.isLoading = true;
    },
    successLocationMaster: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
    failureLocationMaster: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
  },
});

export const {
  failureLocationMaster,
  listLocationMaster,
  successLocationMaster,
} = LocationMasterSlice.actions;

export default LocationMasterSlice.reducer;
