import "./cards.scss";

import { Card } from "@mui/material";
import { FunctionComponent } from "react";
import { CardsProps } from "./Icards";

const Cards: FunctionComponent<CardsProps> = (props) => {
  const { sx, raised = false, children, variant, square, className } = props;
  return (
    <Card
      className={`cardClass ${className}`}
      sx={sx}
      raised={raised}
      variant={variant}
      square={square}
    >
      {children}
    </Card>
  );
};

export default Cards;
