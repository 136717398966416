import { createSlice } from "@reduxjs/toolkit";
import { IReduxState } from "../../../interfaces/IReduxState";
import { IGeneralConfig } from "../../../../Pages/GeneralConfig/IGeneralConfig";

export const GetGenerateSerialNo = createSlice({
  name: "GetGenerateSerialNo",
  initialState: {} as IReduxState<IGeneralConfig>,
  reducers: {
    getGenerateSerialNoStart: (state) => {
      state.isLoading = true;
    },
    successGetGenerateSerialNo: (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    },
    failureGetGenerateSerialNo: (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    },
    clearGetGenerateSerialNo: (state) => {
      state.data = {};
    },
  },
});

export const {
  failureGetGenerateSerialNo,
  getGenerateSerialNoStart,
  successGetGenerateSerialNo,
  clearGetGenerateSerialNo,
} = GetGenerateSerialNo.actions;

export default GetGenerateSerialNo.reducer;
