import { AxiosResponse } from "axios";
import { call, put } from "redux-saga/effects";
import api from "../../Axios/middleware";
import { failureRoleSlice, successRoleSlice } from "./ListRoleSlice";

const ListRoleApi = () => {
  return api.get("/role/findAll");
};

export function* ListRoleCall() {
  try {
    const response: AxiosResponse = yield call(ListRoleApi);
    yield put(successRoleSlice(response.data));
  } catch (error) {
    yield put(failureRoleSlice(error));
  }
}
