import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IReduxState } from "../../interfaces/IReduxState";
import { ITransactionRequest } from "../../../Pages/TransactionRequest/ITransactionRequest";

export const EditTransactionRequestSlice = createSlice({
  name: "EditTransactionRequest",
  initialState: {} as IReduxState<ITransactionRequest>,
  reducers: {
    editTransactionRequest: (state, _: PayloadAction<ITransactionRequest>) => {
      state.isLoading = true;
    },
    successeditTransactionRequest: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
    failureeditTransactionRequest: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
    clearEditTransactionRequestResponse: (state) => {
      state.data = {};
    },
  },
});

export const {
  editTransactionRequest,
  failureeditTransactionRequest,
  successeditTransactionRequest,
  clearEditTransactionRequestResponse,
} = EditTransactionRequestSlice.actions;

export default EditTransactionRequestSlice.reducer;
