import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IReduxState } from "../../interfaces/IReduxState";
import { IItemMaster } from "../../../Pages/ItemMaster/IItemMaster";

export const ImportItemMasterSlice = createSlice({
  name: "ImportItemMaster",
  initialState: {} as IReduxState<{}>,
  reducers: {
    importItemMasterStart: (state, _) => {
      state.isLoading = true;
    },
    successImportItemMaster: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
    failureImportItemMaster: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
    clearImportItemMasterResponse: (state) => {
      state.data = {};
    },
  },
});

export const {
  importItemMasterStart,
  clearImportItemMasterResponse,
  failureImportItemMaster,
  successImportItemMaster,
} = ImportItemMasterSlice.actions;

export default ImportItemMasterSlice.reducer;
