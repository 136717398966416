import "./DateRangePicker.scss";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";

import {
  FunctionComponent,
  LegacyRef,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { DateRangePickerProps } from "./IDateRangePicker";
import {
  DateRangePicker as DateRangePickers,
  Range,
  DateRange,
} from "react-date-range";
import {
  IconButton,
  Popover,
  SvgIcon,
  TextField,
  Theme,
  useMediaQuery,
} from "@mui/material";
import TextInputField from "../TextInput/TextInputField";
import DateRangeIcon from "@mui/icons-material/DateRange";
import palette from "../../Scss/Variables.module.scss";
import ButtonField from "../Button/ButtonField";
import { dateFormatter } from "../../Utils/dateFormatter";
import IconButtonField from "../Button/IconButtonField";

const DateRangePicker: FunctionComponent<DateRangePickerProps> = (props) => {
  const {
    ranges,
    onChange,
    direction = "horizontal",
    disabled,
    months = 1,
    showMonthAndYearPickers,
    minDate,
    maxDate,
    disabledDates,
    showMonthArrow,
    setRanges,
    handleOkBtn,
    handleRangeClear,
    resetRef,
    ...rest
  } = props;
  const defaultRange = {
    startDate: new Date(),
    endDate: new Date(),
    key: "selection",
  } as Range;
  const buttonRef = useRef<HTMLButtonElement>(null);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [dateRange, setDateRange] = useState<Range[]>([
    {
      startDate: !!ranges ? ranges.startDate : new Date(),
      // endDate: addDays(new Date(), 7),
      endDate: !!ranges ? ranges.endDate : new Date(),
      key: "selection",
    },
  ]);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setError(null);
  };
  const handleOk = () => {
    if (inputValue.length > 1) {
      handleClose();
      handleOkBtn && handleOkBtn();
    } else {
      setError("Please Select Date Range");
    }
  };
  const handleClearReset = () => {
    setRanges(undefined);
    setDateRange([defaultRange]);
    setInputValue("");
    handleRangeClear && handleRangeClear();
  };
  useImperativeHandle(resetRef, () => ({
    reset: () => {
      handleClearReset();
    },
  }));
  // console.log(!!(ranges && Object.keys(ranges).length !== 0));

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const smBreackPoint = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("xl")
  );
  const [inputValue, setInputValue] = useState<string>(
    `${
      !!ranges
        ? dateFormatter(ranges.startDate!, "DD-MM-YYYY") +
          " - " +
          dateFormatter(ranges.endDate!, "DD-MM-YYYY")
        : ""
    }`
  );

  const Component = smBreackPoint ? DateRange : DateRangePickers;

  return (
    <>
      <div className="date_range_container">
        <TextInputField
          {...rest}
          value={inputValue}
          onClick={() => buttonRef.current?.click()}
          readOnly={true}
          fullWidth
          InputProps={{
            endAdornment: (
              <>
                <IconButton
                  ref={buttonRef}
                  onClick={disabled ? undefined : handleClick}
                >
                  <DateRangeIcon />
                </IconButton>
              </>
            ),
          }}
          disabled={disabled}
          shrink={!!(ranges && Object.keys(ranges).length !== 0)}
        />

        <IconButtonField
          sx={{
            display: ranges?.startDate && ranges.endDate ? "flex" : "none",
          }}
          className="range_clear_btn"
          onClick={handleClearReset}
        >
          <SvgIcon
            component={CloseOutlinedIcon}
            inheritViewBox
            sx={{ height: "17px", width: "17px" }}
          />
        </IconButtonField>
      </div>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        // onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        slotProps={{ paper: { sx: { overflow: "auto" } } }}
      >
        <Component
          className="date_range_picker_component"
          editableDateInputs={true}
          moveRangeOnFirstSelection={false}
          showMonthAndYearPickers={showMonthAndYearPickers}
          // retainEndDateOnFirstSelection={true}
          onChange={(event) => {
            onChange(event);
            setDateRange([event.selection]);
            if (event.selection.endDate) {
              setError(null);
              setInputValue(
                `${
                  event.selection
                    ? dateFormatter(event.selection.startDate!, "DD-MM-YYYY") +
                      " - " +
                      dateFormatter(event.selection.endDate!, "DD-MM-YYYY")
                    : ""
                }`
              );
            }
          }}
          months={months}
          ranges={dateRange}
          direction={direction}
          color={palette.primary}
          rangeColors={[palette.primary]}
          minDate={minDate}
          maxDate={maxDate}
          disabledDates={disabledDates}
          showMonthArrow={showMonthArrow}
        />

        <footer className="date_range_footer">
          <p>{error}</p>
          <div className="date_range_footer_btns">
            <ButtonField
              className="data_range_cancel_button"
              onClick={() => {
                setRanges(undefined);
                setDateRange([defaultRange]);
                setInputValue("");
                handleClose();
              }}
            >
              Cancel
            </ButtonField>
            <ButtonField className="date_range_ok_button" onClick={handleOk}>
              OK
            </ButtonField>
          </div>
        </footer>
      </Popover>
    </>
  );
};

export default DateRangePicker;
