import { AxiosResponse } from "axios";
import { call, put } from "redux-saga/effects";
import api from "../../Axios/middleware";
import {
  failureDashboardStorageArea,
  successDashboardStorageArea,
} from "./ListDashboardStorageAreaSlice";
import { PayloadAction } from "@reduxjs/toolkit";
import { failureDashboardCard, successDashboardCard } from "./DashboardCard";
import { IStorageAreaFilter } from "../../../Pages/StorageAreaDashboard/IStorageAreaDashboard";
import { failureGRNNo, successGRNNo } from "./ListGRNNo";

// list
const ListDashboardStorageAreaApi = (
  action: PayloadAction<IStorageAreaFilter>
) => {
  return api.get("/storage-area/dashboard", {
    params: action.payload,
  });
};

export function* ListDashboardStorageAreaCall(
  action: PayloadAction<IStorageAreaFilter>
) {
  try {
    const response: AxiosResponse = yield call(
      ListDashboardStorageAreaApi,
      action
    );
    yield put(successDashboardStorageArea(response.data));
  } catch (error) {
    yield put(failureDashboardStorageArea(error));
  }
}

// cards data
const DashboardCardApi = () => {
  return api.get("/storage-area/dashboardcard");
};

export function* DashboardCardCall() {
  try {
    const response: AxiosResponse = yield call(DashboardCardApi);
    yield put(successDashboardCard(response.data));
  } catch (error) {
    yield put(failureDashboardCard(error));
  }
}

// GRN no
const GRNNoApi = () => {
  return api.get("/transa-stock-receive/find-grn");
};

export function* GRNNoCall() {
  try {
    const response: AxiosResponse = yield call(GRNNoApi);
    yield put(successGRNNo(response.data));
  } catch (error) {
    yield put(failureGRNNo(error));
  }
}
