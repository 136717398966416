import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IReduxState } from "../../interfaces/IReduxState";
import { IStockTransfer } from "../../../Pages/StockTransfer/IStockTransfer";

export const GetoneStockTransferSlice = createSlice({
  name: "GetoneStockTransfer",
  initialState: {} as IReduxState<IStockTransfer>,
  reducers: {
    getoneStockTransfer: (state, _: PayloadAction<number>) => {
      state.isLoading = true;
    },
    successGetoneStockTransfer: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
    failureGetoneStockTransfer: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
    clearGetoneStockTransferResponse: (state) => {
      state.data = {};
    },
  },
});

export const {
  getoneStockTransfer,
  failureGetoneStockTransfer,
  successGetoneStockTransfer,
  clearGetoneStockTransferResponse,
} = GetoneStockTransferSlice.actions;

export default GetoneStockTransferSlice.reducer;
