import { createSlice } from "@reduxjs/toolkit";
import { IReduxState } from "../../interfaces/IReduxState";

export const UpdateSequenceConfig = createSlice({
  name: "UpdateSequenceConfig",
  initialState: {
    isLoading: false,
    date: {},
  } as IReduxState<null>,
  reducers: {
    UpdateSequenceConfigStart: (state, _) => {
      state.isLoading = true;
    },
    successUpdateSequenceConfig: (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    },
    failureUpdateSequenceConfig: (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    },
    clearUpdateSequenceConfig: (state) => {
      state.data = {};
    },
  },
});

export const {
  UpdateSequenceConfigStart,
  successUpdateSequenceConfig,
  failureUpdateSequenceConfig,
  clearUpdateSequenceConfig,
} = UpdateSequenceConfig.actions;
export default UpdateSequenceConfig.reducer;
