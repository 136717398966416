import "./Table.scss";

import { DataGrid } from "@mui/x-data-grid";
import React, { FunctionComponent } from "react";
import { ICustomFooter, ICustomToolbar, TableProps } from "./ITable";
import CustomToolbar from "./components/CustomToolbar";
import CustomFooter from "./components/CustomFooter";
import { ReactComponent as Search } from "../../Assets/Images/search.svg";
import { ReactComponent as NoData } from "../../Assets/Images/NoData.svg";
import { SvgIcon } from "@mui/material";

const Table: FunctionComponent<TableProps> = (props) => {
  const {
    rows,
    columns,
    autoHeight,
    density,
    loading,
    isCellEditable,
    columnVisibilityModel,
    disableColumnMenu,
    checkboxSelection,
    getRowId,
    rowCount,
    rowHeight,
    children,
    initialPageSize,
    pageSizeOptions,
    localeText,
    processRowUpdate,
    editMode,
    rowModesModel,
    onRowModesModelChange,
    onRowEditStop,
    sx = {
      // boxShadow: " 0px 12px 24px -4px rgba(145, 158, 171, 0.12)",

      boxShadow: "0px 0px 2px 0px rgba(145, 158, 171, 0.2)",

      borderRadius: "16px",
      // Neutralize the hover colour (causing a flash)
      "& .MuiDataGrid-row.Mui-hovered": {
        backgroundColor: "transparent",
      },
      // Take out the hover colour
      "& .MuiDataGrid-row:hover": {
        backgroundColor: "transparent",
      },
    },
  } = props;

  //    Clasify the child
  const customToolbar = React.Children.toArray(children).find(
    (child) => React.isValidElement(child) && child.type === CustomToolbar
  );

  const customFooter = React.Children.toArray(children).find(
    (child) => React.isValidElement(child) && child.type === CustomFooter
  );

  //    Access the props of the component
  const customToolbarProps: ICustomToolbar | {} =
    React.isValidElement(customToolbar) && customToolbar.props
      ? (customToolbar.props as ICustomToolbar)
      : {};

  const customFooterProps: ICustomFooter | {} =
    React.isValidElement(customFooter) && customFooter.props
      ? (customFooter.props as ICustomFooter)
      : {};

  return (
    <DataGrid
      editMode={editMode}
      rowModesModel={rowModesModel}
      onRowModesModelChange={onRowModesModelChange}
      onRowEditStop={onRowEditStop}
      localeText={{
        ...localeText,
        toolbarExport: "export",
        toolbarExportCSV: "CSV",
        toolbarQuickFilterPlaceholder: "Search",
      }}
      disableRowSelectionOnClick
      sx={sx}
      isCellEditable={isCellEditable}
      className="Table"
      rows={rows}
      columns={columns}
      autoHeight={autoHeight}
      loading={loading}
      density={density ?? "standard"}
      disableColumnMenu={disableColumnMenu ?? true}
      checkboxSelection={checkboxSelection}
      getRowId={getRowId}
      rowCount={rowCount}
      rowHeight={rowHeight}
      getRowHeight={() => "auto"}
      slots={{
        toolbar: customToolbar ? CustomToolbar : undefined,
        footer: customFooter ? CustomFooter : undefined,
        noRowsOverlay: CustomNoRows,
        quickFilterIcon: Search,
      }}
      slotProps={{
        toolbar: {
          ...customToolbarProps,
          exportButton: true,
          exportCsv: {
            text: "CSV",
          },
        },
        footer: customFooterProps,
      }}
      pageSizeOptions={pageSizeOptions ?? [25, 50, 100]}
      initialState={{
        columns: {
          columnVisibilityModel: columnVisibilityModel,
        },
        pagination: { paginationModel: { pageSize: initialPageSize ?? 25 } },
      }}
      processRowUpdate={processRowUpdate}
    />
  );
};

export default Table;

interface CustomNoRowsProps {}

const CustomNoRows: FunctionComponent<CustomNoRowsProps> = () => {
  return (
    <div className="custom_no_rows">
      <SvgIcon className="table_svg_icon" component={NoData} inheritViewBox />
      No Data Found
    </div>
  );
};
