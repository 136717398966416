import { FunctionComponent, useEffect, useRef, useState } from "react";
import "./ColorCode.scss";
import { ColorCodeProps, IColorCode } from "./IColorCode";
import { FormProvider, useForm } from "react-hook-form";
import Table from "../../Components/Table/Table";
import CustomToolbar from "../../Components/Table/components/CustomToolbar";
import ButtonField from "../../Components/Button/ButtonField";
import TableSearch from "../../Components/Table/components/TableSearch";
import IconButtonField from "../../Components/Button/IconButtonField";
import { GridRenderCellParams } from "@mui/x-data-grid";
import { SvgIcon } from "@mui/material";
import { Columns } from "../../Components/Table/ITable";
import { ReactComponent as Edit } from "../../Assets/Images/edit.svg";
import Dialog from "../../Components/Dialog/Dialog";
import ColorCodeForm from "./ColorCodeForm";
import { dateFormatter } from "../../Utils/dateFormatter";
import { listColorCode } from "../../Redux/Slices/ColorCode/ListColorCodeSlice";
import { useAppDispatch, useAppSelector } from "../../Redux/Store/Hooks";
import { clearAddColorCodeResponse } from "../../Redux/Slices/ColorCode/AddColorCodeSlice";
import { clearEditColorCodeResponse } from "../../Redux/Slices/ColorCode/EditColorCodeSlice";
import ResponseDialog from "../../Components/ResponseDialog/ResponseDialog";
import dayjs from "dayjs";

const ColorCode: FunctionComponent<ColorCodeProps> = () => {
  const dispatch = useAppDispatch();
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [initialValues, setInitialValues] = useState<IColorCode>({});

  const { data: colorCode } = useAppSelector((state) => state.ListColorCode);
  const { data: addColorCode } = useAppSelector((state) => state.AddColorCode);
  const { data: editColorCode } = useAppSelector(
    (state) => state.EditColorCode
  );

  const formRef = useRef<HTMLFormElement>(null);

  const columns: Columns = [
    {
      field: "index",
      renderHeader: () => <b>S.No</b>,
      width: 90,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "color",
      renderHeader: () => <b>Color</b>,
      flex: 1,
      type: "string",
      headerAlign: "center",
      renderCell: (params) => (
        <div
          style={{
            height: "100%",
            width: "100%",
            backgroundColor: params.value,
            border: "1px solid rgb(180, 180, 180)",
          }}
        />
      ),
    },
    // {
    //   field: "year",
    //   renderHeader: () => <b>Year</b>,
    //   flex: 1,
    //   type: "dateTime",
    //   headerAlign: "right",
    //   align: "right",
    //   valueFormatter: (params) => dateFormatter(params.value, "YYYY"),
    // },
    {
      field: "month",
      renderHeader: () => <b>Month</b>,
      flex: 1,
      type: "dateTime",
      headerAlign: "left",
      align: "left",
      valueFormatter: (params) => dateFormatter(params.value, "MMMM"),
    },
    {
      field: "status",
      renderHeader: () => <b>Status</b>,
      flex: 1,
      type: "boolean",
      headerAlign: "left",
      align: "left",
      renderCell: (params) => (params.value ? "Active" : "In-active"),
    },
    {
      field: "action",
      renderHeader: () => <b>Action</b>,
      flex: 1,
      type: "actions",
      renderCell: (params: GridRenderCellParams<IColorCode>) => {
        return (
          <>
            <IconButtonField
              onClick={() => {
                setInitialValues({
                  ...params.row,
                  month: dayjs(params.row.month),
                  year: dayjs(params.row.year),
                });
                setDialogOpen(true);
              }}
            >
              <SvgIcon sx={{ fill: "none" }} component={Edit} inheritViewBox />
            </IconButtonField>
          </>
        );
      },
    },
  ];

  // react hook form init
  const methods = useForm();

  // init
  useEffect(() => {
    dispatch(listColorCode());
  }, [dispatch, addColorCode, editColorCode]);

  // clear
  useEffect(() => {
    return () => {
      dispatch(clearAddColorCodeResponse());
      dispatch(clearEditColorCodeResponse());
    };
  }, [dispatch]);

  const colorCodeList = colorCode?.data?.map((color, index) => ({
    ...color,
    index: index + 1,
  }));

  return (
    <>
      <FormProvider {...methods}>
        <Table rows={colorCodeList ?? []} columns={columns}>
          <CustomToolbar>
            <h6>Color Code</h6>
            <TableSearch />
            <ButtonField
              className="tabel_add_new_button"
              onClick={() => setDialogOpen(true)}
            >
              Add new
            </ButtonField>
          </CustomToolbar>
        </Table>
      </FormProvider>

      <Dialog
        open={dialogOpen}
        dialogClassName="dialog"
        Dialog_Title={`${
          (initialValues as IColorCode).id ? "Update" : "Add"
        } User`}
        dialogContentClassName="dialog_content"
        Dialog_Content={
          <ColorCodeForm
            formRef={formRef}
            initialValues={initialValues}
            setInitialValues={setInitialValues}
          />
        }
        Dialog_Actions={
          <>
            <ButtonField
              className="form_cancel_button"
              onClick={() => setDialogOpen(false)}
            >
              Cancel
            </ButtonField>
            <ButtonField
              className="form_save_button"
              onClick={() => formRef.current?.requestSubmit()}
            >
              Save
            </ButtonField>
          </>
        }
      />

      <ResponseDialog response={addColorCode} parentDialog={setDialogOpen} />
      <ResponseDialog response={editColorCode} parentDialog={setDialogOpen} />
    </>
  );
};

export default ColorCode;
