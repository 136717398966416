import "./NumberInputField.scss";

import { FunctionComponent, useState } from "react";
import { TextField } from "@mui/material";
import { NumberInputFieldProps } from "./INumberInputFieldProps";
import { Controller, useFormContext } from "react-hook-form";

const NumberInputField: FunctionComponent<NumberInputFieldProps> = (props) => {
  const {
    id,
    name,
    placeholder,
    variant,
    label,
    color,
    required,
    className,
    sx,
    defaultValue,
    readOnly,
    fullWidth,
    disabledScrollBar,
    disabledScrollBarFunction,
    type,
    inputClassName,
    InputProps,
    inputProps,
    inputLableStyle,
    size = "small",
    labelSize,
    onChange,
    disabled,
  } = props;
  const [focus, setFocus] = useState<boolean>(false);
  const {
    control,
    setValue,
    formState: { errors },
  } = useFormContext();
  // console.log(errors[name!]?.message);

  return (
    <Controller
      name={name!}
      control={control}
      render={({ field }) => (
        <TextField
          {...field}
          id={id}
          name={name}
          placeholder={placeholder}
          variant={variant}
          label={label}
          color={color}
          required={required}
          fullWidth={fullWidth}
          disabled={disabled}
          className={`GlobalInputStyle ${className}`}
          sx={{
            ...sx,
            "& input[type=number]": {
              "-moz-appearance": disabledScrollBar ? "textfield" : null,
            },
            "& input[type=number]::-webkit-outer-spin-button": {
              "-webkit-appearance": "none",
              margin: disabledScrollBar ? 0 : undefined,
            },
            "& input[type=number]::-webkit-inner-spin-button": {
              "-webkit-appearance": disabledScrollBar ? "none" : undefined,
              margin: disabledScrollBar ? 0 : undefined,
            },

            /*    hiding scroll function too     */
            "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
              {
                display: disabledScrollBarFunction ? "none" : undefined,
              },
          }}
          defaultValue={defaultValue}
          error={!!errors[name!]}
          helperText={
            errors[name!]
              ? String(errors[name!]?.message)
              : `${
                  errors[name!]?.message !== undefined
                    ? errors[name!]?.message
                    : ""
                }`
          }
          onFocus={() => setFocus(true)}
          onBlur={() => setFocus(false)}
          onChange={(event) => {
            const newValue = event.target.value;
            if (/^-?\d*\.?\d*$/.test(newValue)) {
              onChange && onChange(event);
              setValue(name, parseFloat(newValue) || newValue, {
                shouldValidate: true,
              });
            }
          }}
          type={type}
          size={size}
          InputProps={{
            ...InputProps,
            readOnly: readOnly,
            className: `GlobalInputPropsStyle ${inputClassName}`,
            inputMode: "numeric",
          }}
          InputLabelProps={{
            shrink: !!field.value || focus,
            size: labelSize,
            className: `GlobalInputLableStyle ${inputLableStyle}`,
          }}
          inputProps={inputProps}
        />
      )}
    />
  );
};

export default NumberInputField;
