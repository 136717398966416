import "./Select.scss";

import { Autocomplete as AC, TextField } from "@mui/material";
import { FunctionComponent } from "react";
import { SelectProps } from "./ISelectProps";
import { Controller, useFormContext } from "react-hook-form";

const Select: FunctionComponent<SelectProps> = (props) => {
  const {
    sx,
    value,
    defaultValue,
    options,
    id,
    loading,
    readOnly,
    disabled,
    fullWidth,
    disableCloseOnSelect,
    disableClearable,
    filterSelectedOptions,
    autoHighlight,
    loadingText,
    renderOption,
    label,
    noOptionsText,
    AutoCompleteClassName,
    freeSolo,
    onChange,
    getOptionDisabled,
    onOpen,
    onClose,
    getOptionLabel,
    onFocus,
    onBlur,
    inputClassName,

    /*    input    */
    name,
    placeholder,
    variant,
    color,
    required,
    className,
    inputSx,
    shrink,
    inputLableStyle,
    size = "small",
    labelSize,
    ref,
    InputRef,
    hidden,
    tabIndex,
    onKeyDown,
  } = props;
  const {
    control,
    formState: { errors },
  } = useFormContext();
  return (
    <Controller
      name={name!}
      control={control}
      render={({ field }) => (
        <AC
          {...field}
          ref={ref}
          hidden={hidden}
          disablePortal
          id={id}
          options={options ?? []}
          sx={sx}
          value={value}
          defaultValue={defaultValue}
          filterSelectedOptions={filterSelectedOptions}
          loading={loading}
          className={`GlobalAutoCompleteStyle ${AutoCompleteClassName}`}
          readOnly={readOnly}
          disabled={disabled}
          disableCloseOnSelect={disableCloseOnSelect}
          disableClearable={disableClearable}
          autoHighlight={autoHighlight}
          loadingText={loadingText}
          noOptionsText={noOptionsText}
          fullWidth={fullWidth}
          freeSolo={freeSolo}
          size={size}
          getOptionDisabled={getOptionDisabled}
          getOptionLabel={getOptionLabel}
          onChange={onChange}
          onOpen={onOpen}
          onClose={onClose}
          onFocus={onFocus}
          onBlur={onBlur}
          renderOption={renderOption}
          renderInput={(params) => (
            <TextField
              onKeyDown={onKeyDown}
              {...params}
              label={label}
              inputRef={InputRef}
              name={name}
              placeholder={placeholder}
              variant={variant}
              color={color}
              required={required}
              className={`GlobalInputStyle ${className}`}
              sx={inputSx}
              error={!!errors[name!]}
              tabIndex={tabIndex}
              helperText={
                errors[name!]?.message ? String(errors[name!]?.message) : ""
              }
              InputProps={{
                ...params.InputProps,
                disableUnderline: true,
              }}
              InputLabelProps={{
                shrink: shrink,
                size: labelSize,
                className: `GlobalInputLableStyle ${inputLableStyle}`,
              }}
            />
          )}
        />
      )}
    />
  );
};

export default Select;
