import { FunctionComponent, memo, useEffect } from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { IUserMaster } from "./IUserMaster";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import TextInputFieldC from "../../Components/TextInput/TextInputFieldC";
import Select from "../../Components/Select/AutoComplete";
import { useAppDispatch, useAppSelector } from "../../Redux/Store/Hooks";
import { AutocompleteOption } from "../../Components/Select/ISelectProps";
import { addUserMaster } from "../../Redux/Slices/UserMaster/AddUserMasterSlice";
import { listRoleSlice } from "../../Redux/Slices/Role/ListRoleSlice";
import { editUserMaster } from "../../Redux/Slices/UserMaster/EditUserMasterSlice";
import { listLocationMaster } from "../../Redux/Slices/LocationMaster/ListLocationMasterSlice";

interface UserFormProps {
  formRef: React.RefObject<HTMLFormElement>;
  initialValues: IUserMaster | {};
  setInitialValues: React.Dispatch<React.SetStateAction<IUserMaster | {}>>;
}

const UserForm: FunctionComponent<UserFormProps> = (props) => {
  const dispatch = useAppDispatch();
  const { data: role } = useAppSelector((state) => state.RoleSlice);

  const roleList = Array.isArray(role?.data)
    ? role?.data.map((role) => ({ label: role.role_name, value: role.id }))
    : [];
  const userSchema = z.object({
    id: z.number().optional(),
    name: z
      .string({ message: "Please enter Name" })
      .min(1, { message: "Please enter Name" }),
    mob_no: z
      .string({ message: "Please enter Mobile Number" })
      .min(1, { message: "Please enter Mobile Number" }),
    email: z
      .string({ message: "Please enter Email" })
      .email({ message: "Please enter Email" })
      .min(1, { message: "Please enter Email" }),
    role_id: z.number({ message: "Please select role" }),
    location_id: z.number().nullable().optional(),
  });
  const methods = useForm<IUserMaster>({
    defaultValues: props.initialValues,
    resolver: zodResolver(userSchema),
  });

  const onSubmit: SubmitHandler<IUserMaster> = (val) => {
    console.log(val);
    if (val.id) {
      dispatch(editUserMaster(val));
    } else {
      dispatch(addUserMaster(val));
    }
  };

  useEffect(() => {
    dispatch(listRoleSlice());
    dispatch(listLocationMaster());
    return () => {
      props.setInitialValues({});
    };
  }, [dispatch, props]);

  const { data: location } = useAppSelector(
    (state) => state.ListLocationMaster
  );
  const locationList = Array.isArray(location?.data)
    ? location?.data.map((type) => ({ label: type.name!, value: type.id }))
    : [];

  return (
    <FormProvider {...methods}>
      <form
        ref={props.formRef}
        onSubmit={methods.handleSubmit(onSubmit)}
        className="form_content"
      >
        <TextInputFieldC name="name" label="Name" type="text" />

        <TextInputFieldC name="mob_no" label="Mobile Number" type="text" />

        <TextInputFieldC name="email" label="Email" type="text" />

        <Select
          name="role_id"
          label="Role"
          options={roleList}
          value={
            roleList?.find(
              (role) => role.value === methods.getValues("role_id")
            ) ?? null
          }
          onChange={(_, value) => {
            const val = value as AutocompleteOption;
            methods.setValue("role_id", val?.value, { shouldValidate: true });
          }}
        />

        <Select
          name="location_id"
          label="Location"
          options={locationList}
          value={
            locationList?.find(
              (role) => role.value === methods.getValues("location_id")
            ) ?? null
          }
          onChange={(_, value) => {
            const val = value as AutocompleteOption;
            methods.setValue("location_id", val?.value, {
              shouldValidate: true,
            });
          }}
        />
      </form>
    </FormProvider>
  );
};

export default memo(UserForm);
