import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IReduxState } from "../../interfaces/IReduxState";
import { IDivisionMaster } from "../../../Pages/DivisionMaster/IDivisionMaster";

export const DeleteDivisionMasterSlice = createSlice({
  name: "DeleteDivisionMaster",
  initialState: {} as IReduxState<IDivisionMaster>,
  reducers: {
    deleteDivisionMaster: (state, _) => {
      state.isLoading = true;
    },
    successdeleteDivisionMaster: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
    failuredeleteDivisionMaster: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
    clearDeleteDivisionMasterResponse: (state) => {
      state.data = {};
    },
  },
});

export const {
  deleteDivisionMaster,
  failuredeleteDivisionMaster,
  successdeleteDivisionMaster,
  clearDeleteDivisionMasterResponse,
} = DeleteDivisionMasterSlice.actions;

export default DeleteDivisionMasterSlice.reducer;
