import { DataGrid, GridRowsProp } from "@mui/x-data-grid";
import React, { FunctionComponent } from "react";
import { ITableAPIProps } from "./ITableAPI";
import "./Table.scss";
import { ICustomToolbar } from "./ITable";
import CustomToolbar from "./components/CustomToolbar";
import { SvgIcon } from "@mui/material";
import { ReactComponent as NoData } from "../../Assets/Images/NoData.svg";

const TableAPI: FunctionComponent<ITableAPIProps> = (props) => {
  const {
    columns,
    rows,
    children,
    density,
    disableColumnMenu,
    loading,
    localeText,
    rowCount,
    rowHeight,
    pageSizeOptions,
    paginationModel,
    onPaginationModelChange,
    sx = {
      // boxShadow: " 0px 12px 24px -4px rgba(145, 158, 171, 0.12)",

      boxShadow: "0px 0px 2px 0px rgba(145, 158, 171, 0.2)",

      borderRadius: "16px",
      // Neutralize the hover colour (causing a flash)
      "& .MuiDataGrid-row.Mui-hovered": {
        backgroundColor: "transparent",
      },
      // Take out the hover colour
      "& .MuiDataGrid-row:hover": {
        backgroundColor: "transparent",
      },
    },
    ...rest
  } = props;

  //    Clasify the child
  const customToolbar = React.Children.toArray(children).find(
    (child) => React.isValidElement(child) && child.type === CustomToolbar
  );

  //    Access the props of the component
  const customToolbarProps: ICustomToolbar | {} =
    React.isValidElement(customToolbar) && customToolbar.props
      ? (customToolbar.props as ICustomToolbar)
      : {};

  return (
    <>
      <DataGrid
        {...rest}
        rows={rows}
        columns={columns}
        getRowHeight={() => "auto"}
        pagination
        density={"standard"}
        paginationMode="server"
        className="Table"
        rowCount={rowCount}
        loading={loading}
        paginationModel={paginationModel}
        pageSizeOptions={pageSizeOptions}
        onPaginationModelChange={onPaginationModelChange}
        slots={{
          toolbar: customToolbar ? CustomToolbar : undefined,
          noRowsOverlay: CustomNoRows,
        }}
        slotProps={{
          toolbar: {
            ...customToolbarProps,
          },
        }}
        rowSelection={false}
        sx={sx}
      />
    </>
  );
};

export default TableAPI;
interface CustomNoRowsProps {}
const CustomNoRows: FunctionComponent<CustomNoRowsProps> = () => {
  return (
    <div className="custom_no_rows">
      <SvgIcon className="table_svg_icon" component={NoData} inheritViewBox />
      No Data Found
    </div>
  );
};
