import { createSlice } from "@reduxjs/toolkit";
import { IReduxState } from "../../../interfaces/IReduxState";
import { IListTransactionType } from "./ITranscationType";

export const ListTransactionType = createSlice({
  name: "ListTransactionType",
  initialState: { isLoading: false, data: {} } as IReduxState<
    IListTransactionType[]
  >,
  reducers: {
    ListTranscationTypeStart: (state, _) => {
      state.isLoading = true;
    },
    SuccessListTransactionType: (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    },
    FailureListTranscationType: (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    },
  },
});

export const {
  FailureListTranscationType,
  ListTranscationTypeStart,
  SuccessListTransactionType,
} = ListTransactionType.actions;

export default ListTransactionType.reducer;
