import "./ButtonField.scss";

import { FunctionComponent } from "react";
import { ButtonFieldProps } from "./IButtonField";
import IconButton from "@mui/material/IconButton";

const IconButtonField: FunctionComponent<ButtonFieldProps> = (props) => {
  const {
    children,
    sx,
    disabled,
    onClick,
    color,
    size,
    disableRipple,
    className,
    ref,
  } = props;

  return (
    <IconButton
      ref={ref}
      className={`iconButton ${className}`}
      sx={sx}
      disabled={disabled}
      onClick={onClick}
      color={color}
      size={size}
      disableRipple={disableRipple}
    >
      {children}
    </IconButton>
  );
};

export default IconButtonField;
