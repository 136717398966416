import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IReduxState } from "../../interfaces/IReduxState";
import { IcreateStock } from "../../../Pages/StockRecieve/IStockRecieve";

export const FindOneStockReceive = createSlice({
  name: "FindOneStockReceive",
  initialState: {} as IReduxState<IcreateStock>,
  reducers: {
    findOneStockReceiveStart: (state, _: PayloadAction<number>) => {
      state.isLoading = true;
    },
    successFindOneStockReceive: (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    },
    failureFindOneStockReceive: (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    },
    clearFindOneStockReceive: (state) => {
      state.data = {};
    },
  },
});

export const {
  clearFindOneStockReceive,
  failureFindOneStockReceive,
  findOneStockReceiveStart,
  successFindOneStockReceive,
} = FindOneStockReceive.actions;

export default FindOneStockReceive.reducer;
