import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IReduxState } from "../../interfaces/IReduxState";
import { IDivisionMaster } from "../../../Pages/DivisionMaster/IDivisionMaster";

export const AddDivisionMasterSlice = createSlice({
  name: "AddDivisionMaster",
  initialState: {} as IReduxState<IDivisionMaster>,
  reducers: {
    addDivisionMaster: (state, _: PayloadAction<IDivisionMaster>) => {
      state.isLoading = true;
    },
    successaddDivisionMaster: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
    failureaddDivisionMaster: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
    clearAddDivisionMasterResponse: (state) => {
      state.data = {};
    },
  },
});

export const {
  addDivisionMaster,
  failureaddDivisionMaster,
  successaddDivisionMaster,
  clearAddDivisionMasterResponse,
} = AddDivisionMasterSlice.actions;

export default AddDivisionMasterSlice.reducer;
