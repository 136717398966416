import { createSlice } from "@reduxjs/toolkit";
import { IReduxState } from "../../interfaces/IReduxState";

export const DeleteSequenceConfig = createSlice({
  name: "DeleteSequenceConfig",
  initialState: {} as IReduxState<{}>,
  reducers: {
    DeleteSequenceConfigStart: (state, _) => {
      state.isLoading = true;
    },
    SuccessDeleteSequenceConfig: (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    },
    FailureDeleteSequenceConfig: (state, action) => {
      state.isLoading = true;
      state.data = action.payload;
    },
    cleareDeleteSequenceConfig: (state) => {
      state.data = {};
    },
  },
});
export const {
  DeleteSequenceConfigStart,
  FailureDeleteSequenceConfig,
  SuccessDeleteSequenceConfig,
  cleareDeleteSequenceConfig,
} = DeleteSequenceConfig.actions;

export default DeleteSequenceConfig.reducer;
