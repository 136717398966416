import { PayloadAction } from "@reduxjs/toolkit";
import api from "../../Axios/middleware";
import { AxiosResponse } from "axios";
import { call, put } from "redux-saga/effects";
import { successCreateSequence, failureCreateSequence } from "./CreateSequence";
import {
  getSequenceListFailure,
  getSequenceListSuccess,
} from "./GetSequenceList";
import { failureGetCode, successGetCode } from "./GetCode";
import {
  failureGenerateSequence,
  successGenerateSequence,
} from "./GenerateSequence";
import {
  FailureDeleteSequenceConfig,
  SuccessDeleteSequenceConfig,
} from "./DeleteSequenceConfig";
import {
  failureUpdateSequenceConfig,
  successUpdateSequenceConfig,
} from "./UpdateSequenceConfig";
import { IUpdateSequence } from "../../../Pages/Sequence/ISequence";

// Create_Sequence_API_Call
const CreateSequenceAPI = (action: PayloadAction<{}>) => {
  return api.post("/sequence-no", action.payload);
};

// Get_Sequence_List_API_Call
const GetSequenceListAPI = () => {
  return api.get("/sequence-no/seq_config");
};

// Create_Sequence_Generator_Function
export function* CreateSequenceCall(action: PayloadAction<{}>) {
  try {
    const response: AxiosResponse = yield call(CreateSequenceAPI, action);
    yield put(successCreateSequence(response.data));
  } catch (error) {
    yield put(failureCreateSequence(error));
  }
}

// Get_Sequence_List_Generator_Function
export function* GetSequenceListCall() {
  try {
    const response: AxiosResponse = yield call(GetSequenceListAPI);
    yield put(getSequenceListSuccess(response.data));
  } catch (error) {
    yield put(getSequenceListFailure(error));
  }
}

// Get Code
const GetCodeAPI = (action: PayloadAction<string>) => {
  return api.get(`/sequence-no/getcode?type=${action.payload}`);
};

export function* GetCodeCall(action: PayloadAction<string>) {
  try {
    const response: AxiosResponse = yield call(GetCodeAPI, action);
    yield put(successGetCode(response.data));
  } catch (error) {
    yield put(failureGetCode(error));
  }
}

// Delete Sequence config
const DeleteSequenceConfigAPI = (action: PayloadAction<number>) => {
  return api.delete(`/sequence-no/delete?id=${action.payload}`);
};
export function* DeleteSequenceConfigCall(action: PayloadAction<number>) {
  try {
    const response: AxiosResponse = yield call(DeleteSequenceConfigAPI, action);
    yield put(SuccessDeleteSequenceConfig(response.data));
  } catch (error) {
    yield put(FailureDeleteSequenceConfig(error));
  }
}
// Update Sequence Config
const UpdateSequenceConfigAPI = (action: PayloadAction<IUpdateSequence>) => {
  return api.patch(
    `/sequence-no/update?id=${action.payload.id}`,
    action.payload.data
  );
};
export function* UpdateSequenceConfigCall(
  action: PayloadAction<IUpdateSequence>
) {
  try {
    const response: AxiosResponse = yield call(UpdateSequenceConfigAPI, action);
    yield put(successUpdateSequenceConfig(response.data));
  } catch (error) {
    yield put(failureUpdateSequenceConfig(error));
  }
}
// Generate Sequence
const GenerateSequenceAPI = (action: PayloadAction<string>) => {
  return api.post(
    `/sequence-no/generate_seq?transaction_type=${action.payload}`
  );
};

export function* GenerateSequenceCall(action: PayloadAction<string>) {
  try {
    const response: AxiosResponse = yield call(GenerateSequenceAPI, action);
    yield put(successGenerateSequence(response.data));
  } catch (error) {
    yield put(failureGenerateSequence(error));
  }
}
