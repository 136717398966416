import "./TransactionRequest.scss";

import { FunctionComponent, memo, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../Redux/Store/Hooks";
import { z } from "zod";
import {
  FormProvider,
  SubmitHandler,
  UseFieldArrayAppend,
  UseFieldArrayUpdate,
  useForm,
} from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { IItemDetails, ITransactionRequest } from "./ITransactionRequest";
import Select from "../../Components/Select/AutoComplete";
import { listItemMaster } from "../../Redux/Slices/ItemMaster/ListItemMasterSlice";
import { AutocompleteOption } from "../../Components/Select/ISelectProps";
import NumberInputField from "../../Components/NumberInput/NumberInputField";
import { IStockTransfer } from "../StockTransfer/IStockTransfer";
import { Autocomplete, TextField } from "@mui/material";

interface ItemFormProps {
  formRef: React.RefObject<HTMLFormElement>;
  initialValues: IItemDetails;
  setInitialValues: React.Dispatch<React.SetStateAction<IItemDetails>>;
  itemSchema:
    | z.ZodObject<{
        id: z.ZodOptional<z.ZodNumber>;
        item_id: z.ZodNumber;
        item_qnty: z.ZodNumber;
        item_code: z.ZodOptional<z.ZodString>;
      }>
    | z.ZodObject<{
        id: z.ZodOptional<z.ZodNumber>;
        item_id: z.ZodNumber;
        req_item_qnty: z.ZodOptional<z.ZodNumber>;
        item_qnty: z.ZodNullable<z.ZodOptional<z.ZodNumber>>;
        item_code: z.ZodOptional<z.ZodString>;
      }>;
  append:
    | UseFieldArrayAppend<ITransactionRequest, "item_details">
    | UseFieldArrayAppend<IStockTransfer, "item_details">;
  update:
    | UseFieldArrayUpdate<ITransactionRequest, "item_details">
    | UseFieldArrayUpdate<IStockTransfer, "item_details">;
  setDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const ItemForm: FunctionComponent<ItemFormProps> = (props) => {
  const dispatch = useAppDispatch();
  const { data: itemList } = useAppSelector((state) => state.ListItemMaster);
  const methods = useForm<IItemDetails>({
    defaultValues: props.initialValues,
    resolver: zodResolver(props.itemSchema),
  });

  const onSubmit: SubmitHandler<IItemDetails> = (val) => {
    console.log(val);

    if (val.id || val.id === 0) {
      console.log("update");

      props.update(val.id!, val);
    } else {
      console.log("add");
      props.append(val);
    }
    props.setDialogOpen(false);
  };

  useEffect(() => {
    dispatch(listItemMaster({}));
  }, [dispatch]);

  useEffect(() => {
    return () => {
      props.setInitialValues({});
    };
  }, [props]);

  const items = Array.isArray(itemList?.data)
    ? itemList?.data.map((item) => ({ label: item.item_name!, value: item.id }))
    : [];

  return (
    <FormProvider {...methods}>
      <form
        ref={props.formRef}
        onSubmit={methods.handleSubmit(onSubmit)}
        className="form_content"
      >
        <Select
          className="select_popover"
          options={items}
          name="item_id"
          label="Item"
          value={
            items?.find(
              (item) => item.value === methods.getValues("item_id")
            ) ?? null
          }
          onChange={(_, val) => {
            const value = (val as AutocompleteOption)?.value;
            methods.setValue("item_id", value ?? null, {
              shouldValidate: true,
            });
            if (value) {
              const getCode = itemList?.data?.find((item) => item.id === value);
              methods.setValue("item_code", getCode?.item_code ?? null);
              methods.setValue("items", getCode);
            }
          }}
        />
        <NumberInputField name="item_qnty" label="Quantity" />
      </form>
    </FormProvider>
  );
};

export default memo(ItemForm);
