import { AxiosResponse } from "axios";
import { call, put } from "redux-saga/effects";
import api from "../../Axios/middleware";
import { responseReportsStockRequest } from "./ListReportsStockRequestSlice";
import { PayloadAction } from "@reduxjs/toolkit";
import { IReportFilter } from "../../../Pages/ReportsStockReceive/IReportsStockReceive";
import { Filter } from "../../../Pages/StockRecieve/IStockRecieve";

const ReportsStockRequestApi = (action: PayloadAction<Filter>) => {
  console.log(action.payload);

  return api.get('/trans-stock-request-mas/getall',{params:action.payload});
};

export function* ReportsStockRequestCall(action: PayloadAction<Filter>) {
  try {
    const respose: AxiosResponse = yield call(ReportsStockRequestApi, action);
    yield put(responseReportsStockRequest(respose.data));
  } catch (error) {
    yield put(responseReportsStockRequest({}));
  }
}
