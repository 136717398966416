import { createSlice } from "@reduxjs/toolkit";
import { IReduxState } from "../../interfaces/IReduxState";

export const DeleteStockRecieve = createSlice({
  name: "DeleteStockRecieve",
  initialState: {
    isLoading: false,
    data: {},
  } as IReduxState<{}>,
  reducers: {
    deleteStockReceiveStart: (state, _) => {
      state.isLoading = true;
    },
    successDeleteStockRecieve: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
    failureDeleteStockRecieve: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
    cleareDeleteStockReceive: (state) => {
      state.data = {};
    },
  },
});

export const {
  cleareDeleteStockReceive,
  deleteStockReceiveStart,
  failureDeleteStockRecieve,
  successDeleteStockRecieve,
} = DeleteStockRecieve.actions;

export default DeleteStockRecieve.reducer;
