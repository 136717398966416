import dayjs from "dayjs";
import { key } from "../TransactionRequest/ITransactionRequest";
import { Interface } from "readline";

export type TStatus =
  | "BNG"
  | "CMPL"
  | "PND"
  | "PCKINT"
  | "PICD"
  | "PACD"
  | "RDYRCV"
  | "DLVD"
  | "TRANS";

export const statusOptionsFD = [
  {
    label: "Binning",
    value: "BNG",
  },
  {
    label: "Completed",
    value: "CMPL",
  },
  {
    label: "Pending",
    value: "PND",
  },
  {
    label: "Picked",
    value: "PICD",
  },
  {
    label: "Packed",
    value: "PACD",
  },
  {
    label: "Transferred",
    value: "TRANS",
  },
  {
    label: "Pick initiated",
    value: "PCKINT",
  },
  {
    label: "Ready to receive",
    value: "RDYRCV",
  },
  {
    label: "Delivered",
    value: "DLVD",
  },
];
export interface Filter {
  key: key;
  from?: number;
  to?: number;
  startDate?: Date | string;
  endDate?: Date | string;
}
export interface UpdateStatus {
  id: number;
  update: {
    status: number;
  };
}

export interface IcreateStock {
  id?: number;
  entry_date?: string | null | dayjs.Dayjs;
  entry_no?: string | null;
  grn_no?: string | null;
  receive_type?: number | null;
  receive_from?: number | null;
  item_type?: number | null;
  location_id?: number | string | null;
  supplier_name?: string | null;
  cus_status?: boolean | null;
  status?: TStatus | null;
  from_name?: string | null;
  item_details?: IStockItems[];
}
export interface IStockItems {
  id?: number;
  item_id?: string | number | null;
  item_qnty?: string | number | null;
  item_code?: string | null;
  base_uom?: string | null;
  item_name?: string | null;
  uom_id?: string | number | null;
  cus_serial_no?: string | null;
  status?: boolean | null;
  is_deleted?: boolean;
}
export interface IListStockRecieve extends Omit<IcreateStock, "item_details"> {
  is_deleted?: boolean;
  created_at?: string | number;
  updated_at?: string | number;
  item_details?: IDetails[] | [];
  location?: ILocation;
  status_value?: string;
  total_items?: number | null;
}

export interface IUpdateStockReceive {
  id: number;
  data: IcreateStock;
}

export interface IDetails {
  id?: number;
  stock_receive_id?: number;
  item_code?: string;
  item_name?: string;
  item_id?: string;
  quantity?: string;
  uom_id?: string | number | null;
  cus_serial_no?: string | null;
  status?: boolean | null;
  is_deleted?: boolean;
  created_at?: string | null;
  updated_at?: string | null;
  items?: IItems;
}

export interface ITemsDetails {
  id: number;
  stock_receive_id: number;
  item_name: string;
  item_code: string;
  item_id: number;
  item_qnty: number;
  uom_id: null;
  description: string;
}
export interface IItems {
  id?: number;
  item_name?: string;
  item_image?: string;
  item_code?: string;
  description?: null;
  stock_uom?: string;
  base_uom?: string;
  is_deleted?: boolean;
}
export interface ILocation {
  id: number;
  name: string;
  code: string;
  location_type: string;
  location_type_id: number;
  created_at: string | null;
  updated_at: string | null;
  created_by: null;
  updated_by: null;
  status: boolean | null;
  is_deleted: boolean;
}

interface IInputProps {
  item: null | string;
  item_qnty: null | string;
  id: string;
}

export interface IStockReceiveReportExport
  extends Omit<IcreateStock, "status"> {
  index?: number;
  total_items?: number | undefined;
  status?: string | undefined;
  entry_date?: string | null;
  from_supplier?: string | null | undefined;
}
