import { FunctionComponent } from "react";
import DateRangePicker from "../DateRangePicker/DateRangePicker";
import { DateRangePickerProps } from "../DateRangePicker/IDateRangePicker";
import { Controller, useFormContext } from "react-hook-form";

const DateRangePickerC: FunctionComponent<DateRangePickerProps> = (props) => {
  const { name, label, ...rest } = props;
  const {
    control,
    formState: { errors },
  } = useFormContext();

  return (
    <Controller
      name={name!}
      control={control}
      render={({ field }) => {
        return (
          <DateRangePicker
            {...field}
            {...rest}
            name={name!}
            label={label!}
            error={!!errors[name!] && !!!field.value}
            helperText={
              errors[name!] && !!!field.value
                ? String(errors[name!]?.message)
                : ""
            }
          />
        );
      }}
    />
  );
};

export default DateRangePickerC;
