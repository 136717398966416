import "./ButtonField.scss";

import { FunctionComponent } from "react";
import LoadingButton from "@mui/lab/LoadingButton";
import { LoadingButtonFieldProps } from "./IButtonField";

const LoadingButtonField: FunctionComponent<LoadingButtonFieldProps> = (
  props
) => {
  const {
    children,
    sx,
    disabled,
    variant,
    onClick,
    color,
    size,
    startIcon,
    endIcon,
    disableRipple,
    className,

    loading,
    loadingIndicator,
    loadingPosition,
  } = props;

  return (
    <LoadingButton
      className={`loadingButton ${className}`}
      loading={loading}
      loadingIndicator={loadingIndicator}
      loadingPosition={loadingPosition}
      sx={sx}
      disabled={disabled}
      variant={variant}
      onClick={onClick}
      color={color}
      size={size}
      startIcon={startIcon}
      endIcon={endIcon}
      disableRipple={disableRipple}
    >
      {children}
    </LoadingButton>
  );
};

export default LoadingButtonField;
