import { createSlice } from "@reduxjs/toolkit";
import { IReduxState } from "../../interfaces/IReduxState";

export const GetCode = createSlice({
  name: "GetCode",
  initialState: {} as IReduxState<string>,
  reducers: {
    getCodeStart: (state, _) => {
      state.isLoading = true;
    },
    successGetCode: (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    },
    failureGetCode: (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    },
    clearGetCode: (state) => {
      state.data = {};
    },
  },
});

export const { failureGetCode, getCodeStart, successGetCode, clearGetCode } =
  GetCode.actions;

export default GetCode.reducer;
