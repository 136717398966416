import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IReduxState } from "../../interfaces/IReduxState";
import { IUserMaster } from "../../../Pages/UserMaster/IUserMaster";

export const DeleteUserMasterSlice = createSlice({
  name: "DeleteUserMaster",
  initialState: {} as IReduxState<IUserMaster>,
  reducers: {
    deleteUserMaster: (state, _) => {
      state.isLoading = true;
    },
    successdeleteUserMaster: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
    failuredeleteUserMaster: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
    clearDeleteUserMasterResponse: (state) => {
      state.data = {};
    },
  },
});

export const {
  deleteUserMaster,
  failuredeleteUserMaster,
  successdeleteUserMaster,
  clearDeleteUserMasterResponse,
} = DeleteUserMasterSlice.actions;

export default DeleteUserMasterSlice.reducer;
