import React, {
  ChangeEvent,
  FunctionComponent,
  useEffect,
  useState,
} from "react";
import "./ImportItems.scss";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { ImportItemProps } from "./IItemMaster";
import ButtonField from "../../Components/Button/ButtonField";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../Redux/Store/Hooks";
import { importItemMasterStart } from "../../Redux/Slices/ItemMaster/ImportItemSlicer";
import { FormProvider, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import UploadButtonC from "../../Components/Button/UploadButtonC";
import Select from "../../Components/Select/AutoComplete";
import { listDivisionMaster } from "../../Redux/Slices/DivisionMaster/ListDivisionMasterSlice";
import { AutocompleteOption } from "../../Components/Select/ISelectProps";
import { ReactComponent as ExcelIcon } from "../../Assets/Images/Excel_CSV.svg";
import { ReactComponent as FileDownload } from "../../Assets/Images/file-download.svg";
import { Button, IconButton, SvgIcon, Tooltip } from "@mui/material";
import IconButtonField from "../../Components/Button/IconButtonField";
import templateFile from "../../Assets/file/Template_for_upload.csv";

interface ImportItemsProps {
  files: ChangeEvent<HTMLInputElement>;
  setFileSate: React.Dispatch<
    React.SetStateAction<ChangeEvent<HTMLInputElement> | null>
  >;
  setImportDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const ImportItems: FunctionComponent<ImportItemsProps> = (props) => {
  const nav = useNavigate();
  const dispatch = useAppDispatch();

  const { data: ImportItem } = useAppSelector(
    (state) => state.ImportItemMaster
  );
  const { data: division, isLoading: divisionLoading } = useAppSelector(
    (state) => state.ListDivisionMaster
  );
  const divisionList = Array.isArray(division?.data)
    ? division?.data.map((type) => ({ label: type.name!, value: type.id }))
    : [];

  const onSubmit = (data: ImportItemProps) => {
    console.log(data);
    dispatch(importItemMasterStart(data));
  };
  // Define allowed image MIME types
  const allowedMimeTypes = [
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    "application/vnd.ms-excel",
    "text/csv",
  ];
  // Define the maximum file size (e.g., 5MB)
  const MAX_FILE_SIZE = 2 * 1024 * 1024; // 5 MB in bytes
  const schema = z.object({
    division_id: z.number({ message: "Please Select Location type" }),
    csvfile: z
      .instanceof(File)
      .refine((file) => !!file, {
        message: "File is required.",
      })
      .refine((file) => allowedMimeTypes.includes(file.type), {
        message: "Invalid file type. Only Excel or CSV files are allowed.",
      })
      .refine((file) => file.size <= MAX_FILE_SIZE, {
        message: "File size should not exceed 2MB.",
      }),
  });
  const methods = useForm<ImportItemProps>({
    resolver: zodResolver(schema),
    defaultValues: {
      division_id: null,
      csvfile: null,
    },
  });
  useEffect(() => {
    dispatch(listDivisionMaster());
  }, [dispatch]);

  return (
    <>
      <FormProvider {...methods}>
        <form
          onSubmit={methods.handleSubmit(onSubmit)}
          className="form_content"
        >
          <Select
            name="division_id"
            label="Division"
            loading={divisionLoading}
            options={divisionList}
            value={
              divisionList?.find(
                (opt) => opt.value === methods.getValues("division_id")
              ) ?? null
            }
            onChange={(_, value) => {
              const val = value as AutocompleteOption;
              methods.setValue("division_id", val?.value ?? null, {
                shouldValidate: true,
              });
            }}
          />
          <div className="upload_file_area">
            <ExcelIcon width={"150px"} height={"80px"} />
            <UploadButtonC
              className="tabel_import_item_button"
              variant="contained"
              name="csvfile"
              startIcon={<CloudUploadIcon />}
              fileAccept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,text/csv,application/vnd.ms-excel"
              onChange={async (event) => {
                if (event.target.files?.length) {
                  const file = event.target.files[0];
                  methods.setValue("csvfile", file, { shouldValidate: true });
                }
              }}
              customErrorMessage="Please Choose File"
              fileName={`${
                methods.watch("csvfile") ? methods.watch("csvfile")?.name! : ""
              }`}
            >
              Choose File
            </UploadButtonC>
            <Tooltip className="template_download" title="Download Template">
              <IconButton
                style={{ width: "40px", height: "40px" }}
                href={templateFile}
                download="upload-template.csv"
              >
                <FileDownload style={{ width: "40px", height: "40px" }} />
              </IconButton>
            </Tooltip>
          </div>

          <div className="import_nav_btn">
            <ButtonField
              className="form_cancel_button"
              //   onClick={() => setDialogOpen(false)}
              onClick={() => props.setImportDialogOpen(false)}
            >
              Cancel
            </ButtonField>
            <ButtonField type="submit" className="form_save_button">
              Save
            </ButtonField>
          </div>
        </form>
      </FormProvider>
    </>
  );
};

export default ImportItems;
