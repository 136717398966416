import { PayloadAction } from "@reduxjs/toolkit";
import { call, put } from "redux-saga/effects";
import api from "../../Axios/middleware";
import { AxiosResponse } from "axios";
import { IReportFilter } from "../../../Pages/ReportsStockReceive/IReportsStockReceive";
import {
  failureListStockAdjustment,
  successListStockAdjustment,
} from "./ListStockAdjustment";
import { Filter } from "../../../Pages/StockRecieve/IStockRecieve";
import { failureScanItem, successScanItem } from "./ListScanItems";
import {
  failureCreateStockAdjustment,
  successCreateStockAdjustment,
} from "./CreateStockAdjustment";
import { ICreateStockAdjustment } from "../../../Pages/StockAdjustment/IStockAdjustment";
import { useAppDispatch } from "../../Store/Hooks";
import { GenerateSequenceStart } from "../SequenceSlicer/GenerateSequence";

export interface IGetScanItems {
  item_barcode?: string | null;
}
// List Api
const ListAPI = (action: PayloadAction<Filter>) =>
  api.get("/stock-adjustment/getall", { params: action.payload });

export function* ListStockAdjustmentCall(action: PayloadAction<Filter>) {
  try {
    const response: AxiosResponse = yield call(ListAPI, action);
    yield put(successListStockAdjustment(response.data));
  } catch (error) {
    yield put(failureListStockAdjustment(error));
  }
}

// List Scan Items
const ListScanItemAPi = (action: PayloadAction<IGetScanItems>) =>
  api.get("/stock-adjustment/findareas", { params: action.payload });

export function* ListScanItemCall(action: PayloadAction<IGetScanItems>) {
  try {
    const response: AxiosResponse = yield call(ListScanItemAPi, action);
    yield put(successScanItem(response.data));
  } catch (error) {
    yield put(failureScanItem(error));
  }
}

// Create Stock Adjustment
const createStockAdjustmentAPi = (action: PayloadAction<{}>) =>
  api.post("/stock-adjustment/create", action.payload);

export function* createStockAdjustmentCall(action: PayloadAction<{}>) {
  try {
    const response: AxiosResponse = yield call(
      createStockAdjustmentAPi,
      action
    );
    yield put(successCreateStockAdjustment(response.data));
    yield put(GenerateSequenceStart("Adjustment"));
  } catch (error) {
    yield put(failureCreateStockAdjustment(error));
  }
}
