import { configureStore } from "@reduxjs/toolkit";
import createSagaMiddleware from "redux-saga";
import rootSage from "../Saga/Saga";
import TestReducer from "../Slices/TestSlicers/TestSlicers";
import SessionExpiredReducer from "../Slices/SessionExpired/SessionExpired";
import EditUserPermissionsReducer from "../Slices/UserPermission/UpdateUserPermissionSlicer";
import UserPermissionRowsReducer from "../Slices/UserPermission/UserPermissionRowsSlicer";
import LoginReducer from "../Slices/LoginSlicer/LoginSlicer";
import CreateSequenceReducer from "../Slices/SequenceSlicer/CreateSequence";
import GetSequenceListReducer from "../Slices/SequenceSlicer/GetSequenceList";
import ListUserMasterReducer from "../Slices/UserMaster/ListUserMasterSlice";
import AddUserMasterSliceReducer from "../Slices/UserMaster/AddUserMasterSlice";
import DeleteUserMasterReducer from "../Slices/UserMaster/DeleteUserMasterSlice";
import EditUserMasterReducer from "../Slices/UserMaster/EditUserMasterSlice";
import RoleSliceReducer from "../Slices/Role/ListRoleSlice";
import ListStorageAreaReducer from "../Slices/StorageArea/ListStorageAreaSlice";
import AddZoneConfigReducer from "../Slices/ZoneConfig/AddZoneConfigSlice";
import EditZoneConfigReducer from "../Slices/ZoneConfig/EditZoneConfigSlice";
import DeleteZoneConfigReducer from "../Slices/ZoneConfig/DeleteZoneConfigSlice";
import ListZoneConfigReducer from "../Slices/ZoneConfig/ListZoneConfigSlice";
import ListLocationTypeReducer from "../Slices/LocationType/ListLocationTypeSlice";
import AddLocationMasterReducer from "../Slices/LocationMaster/AddLocationMasterSlice";
import EditLocationMasterReducer from "../Slices/LocationMaster/EditLocationMasterSlice";
import ListLocationMasterReducer from "../Slices/LocationMaster/ListLocationMasterSlice";
import DeleteLocationMasterReducer from "../Slices/LocationMaster/DeleteLocationMasterSlice";
import AddDivisionMasterReducer from "../Slices/DivisionMaster/AddDivisionMasterSlice";
import EditDivisionMasterReducer from "../Slices/DivisionMaster/EditDivisionMasterSlice";
import DeleteDivisionMasterReducer from "../Slices/DivisionMaster/DeleteDivisionMasterSlice";
import ListDivisionMasterReducer from "../Slices/DivisionMaster/ListDivisionMasterSlice";
import AddTransactionRequestReducer from "../Slices/TransactionRequest/AddTransactionRequestSlice";
import EditTransactionRequestReducer from "../Slices/TransactionRequest/EditTransactionRequestSlice";
import DeleteTransactionRequestReducer from "../Slices/TransactionRequest/DeleteTransactionRequestSlice";
import GetoneTransactionRequestReducer from "../Slices/TransactionRequest/GetOneTransactionRequestSlice";
import ListTransactionRequestReducer from "../Slices/TransactionRequest/ListTransactionRequestSlice";
import ListStockTransferReducer from "../Slices/StockTransfer/ListStockTransferSlice";
import ListStockRequestDetailsReducer from "../Slices/StockTransfer/ListStockRequestDetailsSlice";
import AddStockTransferReducer from "../Slices/StockTransfer/AddStockTransferSlice";
import EditStockTransferReducer from "../Slices/StockTransfer/EditStockTransferSlice";
import GetoneStockTransferReducer from "../Slices/StockTransfer/GetoneStockTransferSlice";
import AddItemMasterReducer from "../Slices/ItemMaster/AddItemMasterSlice";
import EditItemMasterReducer from "../Slices/ItemMaster/EditItemMasterSlice";
import DeleteItemMasterReducer from "../Slices/ItemMaster/DeleteItemMasterSlice";
import ListItemMasterReducer from "../Slices/ItemMaster/ListItemMasterSlice";
import ResetPasswordReducer from "../Slices/ResetPassword/ResetPassword";
import CreateStockRecieveReducer from "../Slices/StockRecieve/CreateStockRecieve";
import ListStockRecieveReducer from "../Slices/StockRecieve/ListStockRecieve";
import SideMenuPermissionReducer from "../Slices/SideMenuPermission/ListSideMenuPermissionSlice";
import PermissionsToPageReducer from "../Slices/UserPermission/PermissionsToPage";
import ListStockDetailsReducer from "../Slices/StockDetails/ListStockDetails";
import ImportItemMasterReducer from "../Slices/ItemMaster/ImportItemSlicer";
import EditStockRecieveReducer from "../Slices/StockRecieve/EditStockRecieve";
import UpdateStockReceiveReducer from "../Slices/StockRecieve/UpadateStockRecieve";
import DeleteStockRecieveReducer from "../Slices/StockRecieve/DeleteStockReceive";
import GetItemTypeReducer from "../Slices/GeneralSetting/ItemType/GetItemType";
import GetCodeReducer from "../Slices/SequenceSlicer/GetCode";
import GenerateSequenceReducer from "../Slices/SequenceSlicer/GenerateSequence";
import GetGenerateSerialNoReducer from "../Slices/GeneralSetting/GenerateSerialNo/GetGenerateSerialNo";
import ListReportsStockReceiveReducer from "../Slices/ReportsStockReceive/ListReportsStockReceiveSlice";
import ListReportsStockRequestReducer from "../Slices/ReportsStockRequest/ListReportsStockRequestSlice";
import ListReportsStockTransferReducer from "../Slices/ReportStockTransfer/ListReportsStockTransferSlice";
import ListReportsStockDetailsReducer from "../Slices/ReportStockDetails/ListReportsStockDetailsSlice";
import UpdateGeneralConfigReducer from "../Slices/GeneralSetting/GenerateSerialNo/UpdateGeneralConfig";
import ListTransactionTypeReducer from "../Slices/GeneralSetting/TransactionType/ListTransactionType";
import DeleteSequenceConfigReducer from "../Slices/SequenceSlicer/DeleteSequenceConfig";
import UpdateSequenceConfigReducer from "../Slices/SequenceSlicer/UpdateSequenceConfig";
import ForgotPasswordReducer from "../Slices/LoginSlicer/ForgotPassword";
import StockRecieveStatusUpdateReducer from "../Slices/StockRecieve/StockRecieveStatusUpdate";
import AddColorCodeReducer from "../Slices/ColorCode/AddColorCodeSlice";
import EditColorCodeReducer from "../Slices/ColorCode/EditColorCodeSlice";
import ListColorCodeReducer from "../Slices/ColorCode/ListColorCodeSlice";
import ListLocationReceiveReducer from "../Slices/LocationReceive/ListLocationReceiveSlice";
import GetoneLocationReceiveReducer from "../Slices/LocationReceive/GetoneLocationReceiveSlice";
import editLocationReceiveReducer from "../Slices/LocationReceive/EditLocationReceiveSlice";
import ListStockAdjustmentReducer from "../Slices/StockAdjustment/ListStockAdjustment";
import ListScanItemsReducer from "../Slices/StockAdjustment/ListScanItems";
import CreateStockAdjustmentReducer from "../Slices/StockAdjustment/CreateStockAdjustment";
import ListDashboardStorageAreaReducer from "../Slices/Dashboard/ListDashboardStorageAreaSlice";
import DashboardCardReducer from "../Slices/Dashboard/DashboardCard";
import FindOneStockReceiveReducer from "../Slices/StockRecieve/FindOneStockReceive";
import ListGRNNoReducer from "../Slices/Dashboard/ListGRNNo";
import GetOnePickListReducer from "../Slices/PickList/GetOnePickItemSlice";
import EditPickItemReducer from "../Slices/PickList/EditPickItemSlice";

const sagaMiddleware = createSagaMiddleware();

export const store = configureStore({
  reducer: {
    Test: TestReducer,
    // Dashboard
    ListDashboardStorageArea: ListDashboardStorageAreaReducer,
    DashboardCard: DashboardCardReducer,
    ListGRNNo: ListGRNNoReducer,
    // Permissions
    PermissionsToPage: PermissionsToPageReducer,
    // SessionExpired
    SessionExpired: SessionExpiredReducer,
    // User Permission
    EditUserPermission: EditUserPermissionsReducer,
    UserPermissionRows: UserPermissionRowsReducer,
    // Login
    LoginSlicer: LoginReducer,

    // Reset Password
    ResetPassword: ResetPasswordReducer,
    ForgotPassword: ForgotPasswordReducer,

    //Sequence
    CreateSequence: CreateSequenceReducer,
    GetSequenceList: GetSequenceListReducer,
    DeleteSequenceConfig: DeleteSequenceConfigReducer,
    UpdateSequenceConfig: UpdateSequenceConfigReducer,
    // User Master
    ListUserMaster: ListUserMasterReducer,
    AddUserMasterSlice: AddUserMasterSliceReducer,
    EditUserMaster: EditUserMasterReducer,
    DeleteUserMaster: DeleteUserMasterReducer,
    // ZoneConfig
    ListZoneConfig: ListZoneConfigReducer,
    AddZoneConfig: AddZoneConfigReducer,
    EditZoneConfig: EditZoneConfigReducer,
    DeleteZoneConfig: DeleteZoneConfigReducer,
    // LocationMaster
    AddLocationMaster: AddLocationMasterReducer,
    EditLocationMaster: EditLocationMasterReducer,
    ListLocationMaster: ListLocationMasterReducer,
    DeleteLocationMaster: DeleteLocationMasterReducer,
    // DivisionMaster
    AddDivisionMaster: AddDivisionMasterReducer,
    EditDivisionMaster: EditDivisionMasterReducer,
    DeleteDivisionMaster: DeleteDivisionMasterReducer,
    ListDivisionMaster: ListDivisionMasterReducer,
    // Transaction Request
    AddTransactionRequest: AddTransactionRequestReducer,
    ListTransactionRequest: ListTransactionRequestReducer,
    EditTransactionRequest: EditTransactionRequestReducer,
    DeleteTransactionRequest: DeleteTransactionRequestReducer,
    GetoneTransactionRequest: GetoneTransactionRequestReducer,

    // Stock Transfer
    AddStockTransfer: AddStockTransferReducer,
    EditStockTransfer: EditStockTransferReducer,
    GetoneStockTransfer: GetoneStockTransferReducer,
    ListStockTransfer: ListStockTransferReducer,
    ListStockRequestDetails: ListStockRequestDetailsReducer,
    // ItemMaster
    AddItemMaster: AddItemMasterReducer,
    EditItemMaster: EditItemMasterReducer,
    DeleteItemMaster: DeleteItemMasterReducer,
    ListItemMaster: ListItemMasterReducer,
    ImportItemMaster: ImportItemMasterReducer,

    // role Master
    RoleSlice: RoleSliceReducer,
    // Location Type
    ListLocationType: ListLocationTypeReducer,
    // ListStorageArea
    ListStorageArea: ListStorageAreaReducer,

    //Stock Recieve
    CreateStockRecieve: CreateStockRecieveReducer,
    ListStockRecieve: ListStockRecieveReducer,
    StockRecieveStatusUpdate: StockRecieveStatusUpdateReducer,
    FindOneStockReceive: FindOneStockReceiveReducer,

    // SideMenu Permission
    SideMenuPermission: SideMenuPermissionReducer,

    // Stock Details
    ListStockDetails: ListStockDetailsReducer,
    // List TranscationType
    ListTransactionType: ListTransactionTypeReducer,
    // General Settings
    GetItemType: GetItemTypeReducer,

    // Get Code
    GetCode: GetCodeReducer,
    // GetGenerateSerialNo
    GetGenerateSerialNo: GetGenerateSerialNoReducer,
    UpdateGeneralConfig: UpdateGeneralConfigReducer,
    // Generate Sequence
    GenerateSequence: GenerateSequenceReducer,
    EditStockRecieve: EditStockRecieveReducer,
    UpdateStockReceive: UpdateStockReceiveReducer,
    DeleteStockRecieve: DeleteStockRecieveReducer,

    // ColorCode
    AddColorCode: AddColorCodeReducer,
    EditColorCode: EditColorCodeReducer,
    ListColorCode: ListColorCodeReducer,

    // Location Receiver
    ListLocationReceive: ListLocationReceiveReducer,
    GetoneLocationReceive: GetoneLocationReceiveReducer,
    editLocationReceive: editLocationReceiveReducer,

    // GetOnePickList
    GetOnePickList: GetOnePickListReducer,
    EditPickItem: EditPickItemReducer,

    // Reports
    ListReportsStockReceive: ListReportsStockReceiveReducer,
    ListReportsStockRequest: ListReportsStockRequestReducer,
    ListReportsStockTransfer: ListReportsStockTransferReducer,
    ListReportsStockDetails: ListReportsStockDetailsReducer,

    // Stock Adjustment
    ListStockAdjustment: ListStockAdjustmentReducer,
    ListScanItems: ListScanItemsReducer,
    CreateStockAdjustment: CreateStockAdjustmentReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(sagaMiddleware),
});

sagaMiddleware.run(rootSage);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
