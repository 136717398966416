import React, { FunctionComponent } from "react";
import { IAdjustItems } from "./IStockAdjustment";
import Table from "../../Components/Table/Table";
import { Columns } from "../../Components/Table/ITable";
interface IViewStockAdjustmentProps {
  viewInitialValues: IAdjustItems[] | undefined;
}
const ViewStockAdjustmentItems: FunctionComponent<IViewStockAdjustmentProps> = (
  props
) => {
  console.log(props.viewInitialValues);
  const itemsRows = props.viewInitialValues?.map((items, i) => ({
    ...items,
  }));
  const column: Columns = [
    {
      field: "index",
      renderHeader: () => <b>S.No</b>,
      align: "center",
      headerAlign: "center",
      width: 90,
    },
    {
      field: "item_name",
      renderHeader: () => <b>Item Name</b>,
      flex: 0.6,
      align: "center",
      headerAlign: "center",
      type: "string",
    },
    {
      field: "item_barcode",
      renderHeader: () => <b>Item Barcode</b>,
      flex: 1,
      align: "center",
      headerAlign: "center",
      type: "string",
    },
    {
      field: "adjust_qty",
      renderHeader: () => <b>Adjusted Quantity</b>,
      flex: 0.6,
      align: "right",
      headerAlign: "right",
      type: "number",
    },
  ];

  return (
    <>
      <Table columns={column} rows={itemsRows ?? []} />
    </>
  );
};

export default ViewStockAdjustmentItems;
