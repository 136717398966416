import { AxiosResponse } from "axios";
import { call, put } from "redux-saga/effects";
import api from "../../Axios/middleware";
import { responseReportsStockTransfer } from "./ListReportsStockTransferSlice";
import { PayloadAction } from "@reduxjs/toolkit";
import { IReportFilter } from "../../../Pages/ReportsStockReceive/IReportsStockReceive";
import { Filter } from "../../../Pages/StockRecieve/IStockRecieve";

const ReportsStockTransferApi = (action: PayloadAction<Filter>) => {
  console.log(action.payload);

  return api.get('/trans-stock-transfer-mas/getall',{params:action.payload});
};

export function* ReportsStockTransferCall(
  action: PayloadAction<Filter>
) {
  try {
    const respose: AxiosResponse = yield call(ReportsStockTransferApi, action);
    yield put(responseReportsStockTransfer(respose.data));
  } catch (error) {
    yield put(responseReportsStockTransfer({}));
  }
}
