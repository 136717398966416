import { FunctionComponent, useEffect } from "react";
import "./ReportsStockDetails.scss";
import { ReportsStockDetailsProps } from "./IReportsStockDetails";
import { useAppDispatch, useAppSelector } from "../../Redux/Store/Hooks";
import { listReportsStockDetails } from "../../Redux/Slices/ReportStockDetails/ListReportsStockDetailsSlice";
import { ColumnMeta } from "../../Components/Table/ITableRP";
import TableRP from "../../Components/Table/TableRP";

const ReportsStockDetails: FunctionComponent<ReportsStockDetailsProps> = () => {
  const dispatch = useAppDispatch();
  const { data: stockReceive } = useAppSelector(
    (state) => state.ListReportsStockDetails
  );

  const columns: ColumnMeta[] = [
    {
      field: "index",
      header: "S.No",
      align: "center",
      alignHeader: "center",
    },
    {
      field: "item_name",
      header: "Item Name",
      align: "left",
      alignHeader: "left",
    },
    {
      field: "item_code",
      header: "Item Code",
      align: "left",
      alignHeader: "left",
    },
    {
      field: "available_quantity",
      header: "Available Quantity",
      align: "center",
      alignHeader: "center",
      sortable: true,
    },
  ];

  useEffect(() => {
    dispatch(listReportsStockDetails({}));
  }, [dispatch]);

  const stockReceiveList = stockReceive?.data?.map((stock, index) => ({
    index: index + 1,
    item_name: stock.item_name,
    item_code: stock.item_code,
    available_quantity: stock.available_quantity,
  }));
  // console.log(stockReceiveList);

  return (
    <>
      <TableRP
        CSVExport
        ExcelExport
        PdfExport
        ExportName="stock_details_report"
        columns={columns}
        rows={stockReceiveList ?? []}
        viewRowData={(data) => console.log(data)}
        headerWithSearch
        headerTitle="Stock Details"
        sortMode="multiple"
      />
    </>
  );
};

export default ReportsStockDetails;
