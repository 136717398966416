import { AxiosResponse } from "axios";
import { call, put } from "redux-saga/effects";
import api from "../../Axios/middleware";
import { responseSideMenuPermission } from "./ListSideMenuPermissionSlice";
import { sessionExpired } from "../SessionExpired/SessionExpired";

const SideMenuPermissionApi = () => {
  return api.get("/menu-permission/menu_list");
};

export function* SideMenuPermissionCall() {
  try {
    const response: AxiosResponse = yield call(SideMenuPermissionApi);
    console.log(response.data.data);

    yield put(responseSideMenuPermission(response.data.data));
  } catch (error) {
    yield put(responseSideMenuPermission(null));
    yield put(sessionExpired(true));
  }
}
