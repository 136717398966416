import "./ButtonField.scss";

import { FunctionComponent } from "react";
import { Button, Tooltip } from "@mui/material";
import { ButtonFieldProps } from "./IButtonField";

const ButtonField: FunctionComponent<ButtonFieldProps> = (props) => {
  const {
    children,
    sx,
    disabled,
    variant,
    onClick,
    color,
    size,
    startIcon,
    endIcon,
    disableRipple,
    className,
    type,
    toolTip,
    ref,
  } = props;

  return (
    <Button
      ref={ref}
      className={`button ${className}`}
      sx={{ ...sx, textTransform: "none" }}
      disabled={disabled}
      variant={variant}
      onClick={onClick}
      color={color}
      size={size}
      startIcon={startIcon}
      endIcon={endIcon}
      disableRipple={disableRipple}
      type={type}
    >
      {children}
    </Button>
  );
};

export default ButtonField;
