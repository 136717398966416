import React, {
  FunctionComponent,
  MouseEventHandler,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import TimePickers from "../TimePicker/TimePickers";
import "./TimeRangePickers.scss";
import dayjs, { Dayjs } from "dayjs";
import { IconButton, Popover } from "@mui/material";
import { DateRangeIcon } from "@mui/x-date-pickers";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import TextInputField from "../TextInput/TextInputField";
import ButtonField from "../Button/ButtonField";
import { TextInputFieldProps } from "../TextInput/ITextInputFieldProps";
import { ITimeRange, ITimeRangePickers, TimeT } from "./ITimeRangePicker";
export interface TimeRangePickersProps extends ITimeRangePickers {
  resetFunctionRef?: React.RefObject<{ timeRangeReset: () => void }>;
}

const TimeRangePickers: FunctionComponent<TimeRangePickersProps> = (props) => {
  const {
    resetFunctionRef,
    disableopenPickers,
    firstPickerLabel,
    secondPickerLabel,
    onChange,
    format,
    timezone,
    ampm,
    disablePast,
    closeOnSelect,
    disabled,
    restrictEndTime,
    isLikeTextField = true,
    clicOk,
    value,
    valueTimeRangePicker,
    ...rest
  } = props;
  const buttonRef = useRef<HTMLButtonElement>(null);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [timeRange, setTimeRange] = useState<ITimeRange>({
    startTime:
      valueTimeRangePicker && valueTimeRangePicker.startTime
        ? valueTimeRangePicker.startTime
        : null,
    endTime:
      valueTimeRangePicker && valueTimeRangePicker.endTime
        ? valueTimeRangePicker.endTime
        : null,
  });
  const handleStartTimeChange = (val: TimeT) => {
    setTimeRange((prev) => ({ ...prev, startTime: val }));
  };

  const handleEndTimeChange = (val: TimeT) => {
    setTimeRange((prev) => ({ ...prev, endTime: val }));
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleInputClick: MouseEventHandler<
    HTMLButtonElement | HTMLDivElement
  > = (event) => {
    setAnchorEl(event.currentTarget as HTMLButtonElement);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const resetTimeRange = () => {
    setTimeRange({
      startTime:
        valueTimeRangePicker && valueTimeRangePicker.startTime
          ? valueTimeRangePicker.startTime
          : null,
      endTime:
        valueTimeRangePicker && valueTimeRangePicker.endTime
          ? valueTimeRangePicker.endTime
          : null,
    });
  };

  // Expose reset function to parent component
  useImperativeHandle(
    resetFunctionRef,
    () => ({
      timeRangeReset: resetTimeRange,
    }),
    []
  );

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  useEffect(() => {
    if (onChange && timeRange.startTime && timeRange.endTime)
      onChange(timeRange);
  }, [timeRange]);
  if (!isLikeTextField) {
    return (
      <div className="time_range_pickers_container">
        <TimePickers
          disableOpenPicker={disableopenPickers}
          label={firstPickerLabel}
          onChange={(val) => handleStartTimeChange(val)}
          format={format}
          timezone={timezone}
          ampm={ampm}
          disablePast={disablePast}
          closeOnSelect={closeOnSelect}
          disabled={disabled}
          value={timeRange.startTime}
        />
        <div className="time_range_picers_divider"></div>
        <TimePickers
          disableOpenPicker={disableopenPickers}
          label={secondPickerLabel}
          onChange={(val) => handleEndTimeChange(val)}
          value={timeRange.endTime}
          format={format}
          timezone={timezone}
          ampm={ampm}
          disablePast={disablePast}
          closeOnSelect={closeOnSelect}
          disabled={disabled}
          minTime={restrictEndTime ? timeRange.startTime : null}
        />
      </div>
    );
  } else {
    return (
      <>
        <TextInputField
          {...rest}
          value={
            timeRange.startTime && timeRange.endTime
              ? `${dayjs
                  .utc(timeRange.startTime)
                  .tz("Asia/Kolkata")
                  .format("hh:mm A")} - ${dayjs
                  .utc(timeRange.endTime)
                  .tz("Asia/Kolkata")
                  .format("hh:mm A")}`
              : null
          }
          onClick={handleInputClick}
          readOnly={true}
          InputProps={{
            endAdornment: (
              <IconButton
                ref={buttonRef}
                onClick={disabled ? undefined : handleClick}
              >
                <AccessTimeIcon />
              </IconButton>
            ),
          }}
          disabled={disabled}
          shrink={!!(timeRange && timeRange.endTime && timeRange.startTime)}
        />
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          slotProps={{ paper: { sx: { overflow: "auto" } } }}
        >
          <div>
            <div className="time_range_pickers_container">
              <TimePickers
                disableOpenPicker={disableopenPickers}
                label={firstPickerLabel}
                onChange={(val) => handleStartTimeChange(val)}
                format={format}
                value={timeRange.startTime}
                timezone={timezone}
                ampm={ampm}
                disablePast={disablePast}
                closeOnSelect={closeOnSelect}
                disabled={disabled}
              />
              <div className="time_range_picers_divider"></div>
              <TimePickers
                disableOpenPicker={disableopenPickers}
                label={secondPickerLabel}
                onChange={(val) => handleEndTimeChange(val)}
                format={format}
                value={timeRange.endTime}
                timezone={timezone}
                ampm={ampm}
                disablePast={disablePast}
                closeOnSelect={closeOnSelect}
                disabled={disabled}
                minTime={restrictEndTime ? timeRange.startTime : null}
              />
            </div>
            <footer className="time_range_footer">
              <ButtonField
                className="time_range_ok_button"
                onClick={() => {
                  clicOk && clicOk();
                  handleClose();
                  resetTimeRange();
                }}
              >
                OK
              </ButtonField>
            </footer>
          </div>
        </Popover>
      </>
    );
  }
};

export default TimeRangePickers;
