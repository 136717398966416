import { useLayoutEffect, useState } from "react";
import "./App.scss";
import SessionExpiredDialog from "./Components/SessionExpired/SessionExpired";
import Sidnavbar from "./Components/Sidnavbar/Sidnavbar";
import { useAppDispatch } from "./Redux/Store/Hooks";
import api from "./Redux/Axios/middleware";
import { sessionExpired } from "./Redux/Slices/SessionExpired/SessionExpired";
import Loading from "./Components/Loading/Loading";
import NetworkStatus from "./Components/NetworkStatus/NetworkStatus";
import { PrimeReactProvider, PrimeReactContext } from "primereact/api";
import { useLocation } from "react-router-dom";

function App() {
  // const volume = document.querySelector("video"); // 1
  // const vou = document.querySelector("audio");
  // console.log(volume);
  // console.log(vou);
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState<boolean>(false);
  const { pathname } = useLocation();
  console.log(pathname);

  useLayoutEffect(() => {
    // request
    api.interceptors.request.use(
      (config) => {
        // console.log("Config>>>", config.url);
        if (config.url === "/item-master") {
          setLoading(false);
        } else {
          setLoading(true);
        }

        return config;
      },
      (error) => {
        setLoading(false);
        return Promise.reject(error);
      }
    );

    // response
    api.interceptors.response.use(
      (response) => {
        setLoading(false);
        return response;
      },
      (error) => {
        setLoading(false);
        // expired token
        if (error.response.status === 401) {
          dispatch(sessionExpired(true));
          return;
        }

        return Promise.reject(error);
      }
    );
  }, [dispatch]);

  return (
    <div className="App">
      <Sidnavbar />
      <NetworkStatus />
      <SessionExpiredDialog />
      <Loading isLoading={loading} />
    </div>
  );
}

export default App;
