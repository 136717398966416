import { AxiosResponse } from "axios";
import api from "../../Axios/middleware";
import { failureColorCode, successColorCode } from "./ListColorCodeSlice";
import { call, put } from "redux-saga/effects";
import { PayloadAction } from "@reduxjs/toolkit";
import { IColorCode } from "../../../Pages/ColorCode/IColorCode";
import { failureaddColorCode, successaddColorCode } from "./AddColorCodeSlice";
import {
  failureeditColorCode,
  successeditColorCode,
} from "./EditColorCodeSlice";

// list
const ListColorCodeApi = () => {
  return api.get("/color-code/getall");
};

export function* ListColorCodeCall() {
  try {
    const response: AxiosResponse = yield call(ListColorCodeApi);
    yield put(successColorCode(response.data));
  } catch (error) {
    yield put(failureColorCode(error));
  }
}

// add
const AddColorCodeApi = (action: PayloadAction<IColorCode>) => {
  return api.post("/color-code/create", action.payload);
};

export function* AddColorCodeCall(action: PayloadAction<IColorCode>) {
  try {
    const response: AxiosResponse = yield call(AddColorCodeApi, action);
    yield put(successaddColorCode(response.data));
  } catch (error) {
    yield put(failureaddColorCode(error));
  }
}

// edit
const EditColorCodeApi = (action: PayloadAction<IColorCode>) => {
  return api.patch("/color-code/update", action.payload, {
    params: { id: action.payload.id },
  });
};

export function* EditColorCodeCall(action: PayloadAction<IColorCode>) {
  try {
    const response: AxiosResponse = yield call(EditColorCodeApi, action);
    yield put(successeditColorCode(response.data));
  } catch (error) {
    yield put(failureeditColorCode(error));
  }
}
