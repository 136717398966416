import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IReduxState } from "../../interfaces/IReduxState";
import { IUserMaster } from "../../../Pages/UserMaster/IUserMaster";

export const EditUserMasterSlice = createSlice({
  name: "EditUserMaster",
  initialState: {} as IReduxState<IUserMaster>,
  reducers: {
    editUserMaster: (state, _: PayloadAction<IUserMaster>) => {
      state.isLoading = true;
    },
    successeditUserMaster: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
    failureeditUserMaster: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
    clearEditUserMasterResponse: (state) => {
      state.data = {};
    },
  },
});

export const {
  editUserMaster,
  failureeditUserMaster,
  successeditUserMaster,
  clearEditUserMasterResponse,
} = EditUserMasterSlice.actions;

export default EditUserMasterSlice.reducer;
