import "./StockTransfer.scss";
import {
  FunctionComponent,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { IStockTransfer, IStockTransferItemDetails } from "./IStockTransfer";
import Table from "../../Components/Table/Table";
import CustomToolbar from "../../Components/Table/components/CustomToolbar";
import CustomFooter from "../../Components/Table/components/CustomFooter";
import ButtonField from "../../Components/Button/ButtonField";
import { useAppDispatch, useAppSelector } from "../../Redux/Store/Hooks";
import ResponseDialog from "../../Components/ResponseDialog/ResponseDialog";
import { useLocation, useNavigate } from "react-router-dom";
import { Columns } from "../../Components/Table/ITable";
import {
  GridActionsCellItem,
  GridCellParams,
  GridEventListener,
  GridPreProcessEditCellProps,
  GridRenderCellParams,
  GridRenderEditCellParams,
  GridRowEditStopReasons,
  GridRowId,
  GridRowModel,
  GridRowModes,
  GridRowModesModel,
  GridRowsProp,
  GridValueGetterParams,
} from "@mui/x-data-grid";
import {
  FormProvider,
  SubmitHandler,
  useFieldArray,
  useForm,
} from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import DatePickers from "../../Components/DatePicker/DatePicker";
import { listLocationMaster } from "../../Redux/Slices/LocationMaster/ListLocationMasterSlice";
import Select from "../../Components/Select/AutoComplete";
import { AutocompleteOption } from "../../Components/Select/ISelectProps";
import dayjs from "dayjs";
import { listTransactionRequest } from "../../Redux/Slices/TransactionRequest/ListTransactionRequestSlice";
import {
  clearStockRequestDetails,
  listStockRequestDetails,
} from "../../Redux/Slices/StockTransfer/ListStockRequestDetailsSlice";
import {
  addStockTransfer,
  clearAddStockTransferResponse,
} from "../../Redux/Slices/StockTransfer/AddStockTransferSlice";
import TextInputFieldC from "../../Components/TextInput/TextInputFieldC";
import {
  clearGetoneStockTransferResponse,
  getoneStockTransfer,
} from "../../Redux/Slices/StockTransfer/GetoneStockTransferSlice";
import {
  clearEditStockTransferResponse,
  editStockTransfer,
} from "../../Redux/Slices/StockTransfer/EditStockTransferSlice";
import { DevTool } from "@hookform/devtools";
import {
  clearGetCode,
  getCodeStart,
} from "../../Redux/Slices/SequenceSlicer/GetCode";
import Dialog from "../../Components/Dialog/Dialog";
import ItemForm from "../TransactionRequest/ItemForm";
import { IDeleteDialog } from "../../Components/DeleteDialog/IDeleteDialog";
import { IItemDetails } from "../TransactionRequest/ITransactionRequest";
import IconButtonField from "../../Components/Button/IconButtonField";
import { FormHelperText, SvgIcon } from "@mui/material";
import DeleteDialog from "../../Components/DeleteDialog/DeleteDialog";
import { ReactComponent as Delete } from "../../Assets/Images/delete.svg";
import { ReactComponent as Edit } from "../../Assets/Images/edit.svg";
import TableAggregation from "../../Components/Table/components/TableAggregation ";
import {
  TotalAvailQuantity,
  totalItemQuantity,
  TotalReqQuantity,
} from "../../Utils/TableAggregations";
import { ReactComponent as ViewIcon } from "../../Assets/Images/solar_eye-linear.svg";
import PickQuantityForm from "./PickQuantityForm";
import { getOnePickList } from "../../Redux/Slices/PickList/GetOnePickItemSlice";
import debounce from "lodash.debounce";
import { generateUniqueId } from "../../Utils/UniqIDGenerater";
import api from "../../Redux/Axios/middleware";
import SelectWC from "../../Components/withoutControllerComponents/Select/Select";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Cancel";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { listItemMaster } from "../../Redux/Slices/ItemMaster/ListItemMasterSlice";
import { IItemMaster } from "../ItemMaster/IItemMaster";

interface StockTransferFormProps {}

const StockTransferForm: FunctionComponent<StockTransferFormProps> = () => {
  const dispatch = useAppDispatch();
  // const location = useLocation();
  const nav = useNavigate();
  const requestFormRef = useRef<HTMLFormElement>(null);
  const { state: requestUpdateId, pathname } = useLocation();

  const { data: locationsList } = useAppSelector(
    (state) => state.ListLocationMaster
  );
  const { data: requestList } = useAppSelector(
    (state) => state.ListTransactionRequest
  );
  const { data: requestDataById } = useAppSelector(
    (state) => state.GetoneStockTransfer
  );
  const { data: editRequest } = useAppSelector(
    (state) => state.EditStockTransfer
  );
  const { data: requestDetails } = useAppSelector(
    (state) => state.ListStockRequestDetails
  );
  const { data: addRequest } = useAppSelector(
    (state) => state.AddStockTransfer
  );
  const { data: entryCode } = useAppSelector((state) => state.GetCode);
  const { data: getOnePick } = useAppSelector((state) => state.GetOnePickList);

  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [pickQuantityDialogOpen, setPickQuantityDialogOpen] =
    useState<boolean>(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState<boolean>(false);
  const [deleteDialogData, setDeleteData] = useState<IDeleteDialog>({});
  const [itemValue, setItemValue] = useState<IItemDetails>({});

  const formRef = useRef<HTMLFormElement>(null);

  // item schema
  const itemSchema = z.object({
    id: z.number().optional(),
    item_id: z.number({ message: "Please Select Item" }),
    req_item_qnty: z.number({ message: "Please enter Quantity" }).optional(),
    item_qnty: z
      .number({ message: "Please enter Quantity" })
      .optional()
      .nullable(),
    item_code: z.string().optional(),
    items: z.any().optional(),
  });
  // .refine((data) => data.req_item_qnty >= data.item_qnty, {
  //   message: "asfd",
  //   path: ["item_qnty"],
  // });

  // form schema
  const stockTransferSchema = z
    .object({
      entry_no: z
        .string({ message: "Please enter the Entry No" })
        .min(1, { message: "Please enter the Entry No" }),
      entry_date: z.custom((val) => val && dayjs(val).isValid(), {
        message: "Please select the date",
      }),
      request_id: z
        .number({
          message: "Please select the Request No",
        })
        .nullable(),
      transfer_from: z.number({
        message: "Please select the Transfer from location",
      }),
      transfer_to: z.number({
        message: "Please select the Transfer to location",
      }),

      item_details: z.array(itemSchema).min(1, "Please Add the items"),
    })
    .refine(
      (data) => data.item_details.filter((item) => !!item.item_qnty).length,
      {
        message: "enter minimum one item quantity",
        path: ["item_details"],
      }
    );

  // react hook form init
  const methods = useForm<IStockTransfer>({
    defaultValues: {
      request_id: requestUpdateId?.req_id ?? null,
      transfer_from: Number(localStorage.getItem("location_id")),
      entry_date: dayjs(),
    },
    resolver: zodResolver(stockTransferSchema),
  });

  const { replace, update, append, remove } = useFieldArray({
    control: methods.control,
    name: "item_details",
  });

  // Inline Edit
  const [enterKey, setEnterKey] = useState<boolean>(true);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const isCellEditable = (params: GridCellParams) => {
    return rowModesModel[params.id]?.mode === GridRowModes.Edit;
  };
  const fetchOptions = async (query: string): Promise<AutocompleteOption[]> => {
    const response = await api.get("/item-master", {
      params: {
        searchkey: query, // Include the search query in the API request
      },
    });
    const data = await response?.data?.data?.listitem_master;
    setEnterKey(true);

    return data?.map((item: any) => ({
      label: item.item_name,
      value: item.id,
    }));
  };
  const [options, setOptions] = useState<AutocompleteOption[]>([]);
  const [inputValue, setInputValue] = useState("");

  const { data: items } = useAppSelector((state) => state.ListItemMaster);

  const item_list = Array.isArray(items?.data)
    ? items?.data.map((item) => ({
        label: item.item_name!,
        value: item.id!,
      }))
    : [];

  const handleInputChange = (event: React.ChangeEvent<{}>, value: string) => {
    setEnterKey(true);
    setInputValue(value);
  };
  const debouncedFetchOptions = debounce(async (query: string) => {
    if (query.length >= 3) {
      // Optional: Set a minimum character length for the search
      const results = await fetchOptions(query);
      setOptions(results);
    }
  }, 100); // Adjust the debounce time as needed
  useEffect(() => {
    if (inputValue && enterKey) {
      debouncedFetchOptions(inputValue);
    } else {
      setOptions([]);
    }
  }, [inputValue]);
  const [rows, setRows] = useState<GridRowsProp>([]);
  const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});
  // Inline Edit Functions
  const handleRowEditStop: GridEventListener<"rowEditStop"> = (
    params,
    event
  ) => {
    if (
      params.reason === GridRowEditStopReasons.rowFocusOut ||
      params.reason === GridRowEditStopReasons.enterKeyDown
    ) {
      setEnterKey(false);

      event.defaultMuiPrevented = true;
    }
  };
  const handleEditClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
    setIsEdit(true);
  };
  const handleSaveClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });

    // replace([...rows]);
    if (!isEdit) {
      handleAddClick();
    }
    setIsEdit(false);
  };
  const handleDeleteClick = (id: GridRowId) => () => {
    setRows(rows.filter((row) => row.id !== id));
  };
  const handleCancelClick = (id: GridRowId) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = rows.find((row) => row.id === id);
    if (editedRow!.isNew) {
      setRows(rows.filter((row) => row.id !== id));
    }
  };
  const processRowUpdate = (newRow: GridRowModel) => {
    const updatedRow = { ...newRow, isNew: false };
    setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
    return updatedRow;
  };
  const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };
  const handleAddClick = () => {
    const id = generateUniqueId();
    setRows((oldRows) => [
      ...oldRows,
      {
        id,
        item_id: "",
        item_code: "",
        item_qnty: null,
        isNew: true,
      },
    ]);
    setRowModesModel((oldModel) => ({
      ...oldModel,
      [id]: { mode: GridRowModes.Edit, fieldToFocus: "item_id" },
    }));
  };
  useEffect(() => {
    replace([]);
    if (rows.length > 0) {
      const checkValidArray = rows.filter(
        (items) =>
          items.item_id !== null &&
          items.item_qnty !== null &&
          items.cus_serial_no !== null &&
          items.cus_serial_no !== ""
      );
      console.log("Valid Arrays", checkValidArray);

      replace([...checkValidArray]);
    }
  }, [rows]);

  // form submit
  const onSubmit: SubmitHandler<IStockTransfer> = (val) => {
    const transType = pathname === "/transaction/transfer/add" ? "MT" : "RT";
    if (requestUpdateId?.update_id) {
      const processedValue = {
        ...methods.getValues(),
        item_details: methods.getValues("item_details")?.map((items) => ({
          item_id: items.item_id,
          item_code: items.item_code,
          item_qnty: items.item_qnty,
        })),
      };
      dispatch(
        editStockTransfer({
          ...processedValue,
          id: requestUpdateId?.update_id,
          transfer_type: transType,
          status: "PICD",
          // requestDataById?.data?.status === "PICD"
          //   ? "TRANS"
          //   : requestDataById?.data?.status,
        })
      );
    } else {
      console.log(val);
      const processedValue = {
        ...methods.getValues(),
        item_details: methods.getValues("item_details")?.map((items) => ({
          item_id: items.item_id,
          item_code: items.item_code,
          item_qnty: items.item_qnty,
        })),
      };
      console.log(processedValue);

      dispatch(
        addStockTransfer({
          ...processedValue,
          transfer_type: transType,
          status: "PCKINT",
        })
      );
    }
  };

  useEffect(() => {
    // initial render
    dispatch(listLocationMaster());
    dispatch(listTransactionRequest(null));

    !requestUpdateId?.update_id && dispatch(getCodeStart("Transfer"));
    if (requestUpdateId?.update_id) {
      dispatch(getoneStockTransfer(requestUpdateId?.update_id));
    }
    if (requestUpdateId?.req_id) {
      dispatch(listStockRequestDetails(requestUpdateId.req_id));
    } else {
      replace([]);
      methods.setValue("transfer_to", null);
    }

    return () => {
      dispatch(clearGetCode());
      dispatch(clearStockRequestDetails());
      dispatch(clearAddStockTransferResponse());
      dispatch(clearGetoneStockTransferResponse());
      dispatch(clearEditStockTransferResponse());
    };
  }, [
    dispatch,
    methods,
    replace,
    requestUpdateId?.req_id,
    requestUpdateId?.update_id,
    // updatePickItem, //for pick item list update
  ]);

  // iniline edit
  useEffect(() => {
    if (
      !!(
        pathname === "/transaction/transfer/add" ||
        pathname === "/transaction/transfer/edit"
      )
    ) {
      dispatch(listItemMaster({}));
    }
    if (!!(pathname === "/transaction/transfer/add")) {
      handleAddClick();
    }
  }, [dispatch, pathname]);

  useEffect(() => {
    // update the item details list and transfer_to by request no
    const stockDetailsItemList = requestDetails?.data?.req_items?.map(
      (row, index) => ({
        ...row,
        id: index,
        index: index + 1,
        req_item_qnty: row?.item_qnty,
        item_qnty: null,
      })
    );
    if (Array.isArray(stockDetailsItemList)) {
      replace(stockDetailsItemList);
    }

    methods.reset((val) => ({
      ...val,
      transfer_to: Number(requestDetails?.data?.request_from),
      // entry_no: requestDetails?.data?.Transfer?.entry_no ?? entryCode?.data,
    }));
  }, [requestDetails, methods, replace]);

  useLayoutEffect(() => {
    // get data by request id (update_id)
    if (requestUpdateId?.update_id) {
      methods.reset({ ...requestDataById?.data, entry_date: dayjs() });

      const updateItemList = requestDataById?.data?.item_details?.map(
        (item, index) => ({
          ...item,
          id: index,
          stock_transfer_item_id: item.id,
          index: index + 1,
          req_item_qnty: (requestDataById.data?.request?.item_details ?? [])[
            index
          ]?.item_qnty,
        })
      );
      if (Array.isArray(updateItemList)) {
        setRows([...updateItemList]);
        replace(updateItemList);
      }
      // if (pathname === "/transaction/transfer/edit") {
      //   handleAddClick();
      // }
    }

    // entry no field ( sequance number )
    if (entryCode?.data && !requestUpdateId?.update_id) {
      methods.setValue("entry_no", entryCode.data);
    }
  }, [
    requestUpdateId?.update_id,
    requestDataById,
    entryCode,
    methods,
    replace,
  ]);

  // dropdown lists
  const locations = Array.isArray(locationsList?.data)
    ? locationsList?.data.map((item) => ({ label: item.name!, value: item.id }))
    : [];
  const requests = Array.isArray(requestList?.data)
    ? requestList?.data.map((item) => ({
        label: item.entry_no!,
        value: item.id,
      }))
    : [];

  // update table rows
  const updateRow = (newRow: IStockTransferItemDetails) => {
    const updatedRow = { ...newRow };
    const findIndex = methods.getValues("item_details")?.findIndex((val) => {
      return newRow.id === val.id;
    });

    update(findIndex!, newRow);
    return updatedRow;
  };

  // table rows
  const tableRows = methods
    .getValues("item_details")
    ?.map((row, index) => ({ ...row, index: index + 1, id: row.id ?? index }));

  // show status
  // const show_status = (key?: TStatus | null) => {
  //   switch (key) {
  //     case "PICD":
  //       return "Transferred";
  //     default:
  //       return "Pick Initiated";
  //   }
  // };
  console.log(tableRows);

  const columns: Columns = [
    {
      field: "index",
      renderHeader: () => <b>S.No</b>,
      align: "center",
      headerAlign: "center",
      width: 90,
    },
    {
      field: "item_id",
      renderHeader: () => <b>Item Name</b>,
      flex: 0.8,
      editable: true,
      type: "singleSelect",
      valueOptions: item_list,
      renderEditCell: (params: GridRenderEditCellParams) => {
        return (
          <SelectWC
            placeholder="Search Items"
            name="item_id"
            filterOptions={(options) => options}
            sx={{ width: "100%" }}
            options={options}
            onInputChange={handleInputChange}
            value={
              item_list?.find((item) => item.value === params.value)?.label
            }
            onChange={(event, newValue) => {
              if (newValue && typeof newValue !== "string") {
                const getValue: AutocompleteOption =
                  newValue as AutocompleteOption;
                params.api.setEditCellValue({
                  id: params.id,
                  field: params.field,
                  value: getValue.value,
                });
                // Set the item code based on the selected item
                const itemDetail = items?.data?.find(
                  (item) => item.id === getValue.value
                )?.item_code;
                if (itemDetail) {
                  // Update the item_code field
                  setRows((prevRows) =>
                    prevRows.map((row) =>
                      row.id === params.id
                        ? {
                            ...row,
                            item_id: getValue.value,
                            item_code:
                              items?.data?.find(
                                (item) => item.id === getValue.value
                              )?.item_code || "",
                            base_uom: items?.data?.find(
                              (item) => item.id === getValue.value
                            )?.base_uom,
                          }
                        : row
                    )
                  );
                }
              } else {
                setRows((prevRows) =>
                  prevRows.map((row) =>
                    row.id === params.id
                      ? {
                          ...row,
                          item_code: "",
                          cus_serial_no: "",
                        }
                      : row
                  )
                );
              }
            }}
          />
        );
      },
    },
    {
      field: "item_code",
      renderHeader: () => <b>Item Code</b>,
      flex: 1,
      align: "left",
      headerAlign: "left",
      type: "string",
    },
    {
      field: "available_quantity",
      renderHeader: () => <b>Available Quantity</b>,
      flex: 1,
      align: "right",
      headerAlign: "right",
      type: "string",
    },
    {
      field: "req_item_qnty",
      renderHeader: () => <b>Requested Quantity</b>,
      flex: 1,
      align: "right",
      headerAlign: "right",
      type: "number",
    },
    {
      field: "item_qnty",
      renderHeader: () => <b>Transfer Quantity</b>,
      flex: 1,
      align: "right",
      headerAlign: "right",
      type: "number",
      editable: requestUpdateId?.req_id
        ? true
        : !!(
            pathname === "/transaction/transfer/add" ||
            pathname === "/transaction/transfer/edit"
          )
        ? true
        : false,
      cellClassName: requestUpdateId?.req_id ? "inputCell" : "",
      preProcessEditCellProps: (params: GridPreProcessEditCellProps) => {
        const hasError = params.props.value! > params.row.req_item_qnty!;
        if (hasError) {
          alert("Item Quantity is more than Requested Quantity");
          // throw new Error("Item Quantity is more than Requested Quantity");
        }
        return { ...params.props, error: hasError };
      },
      // renderEditCell: (params) => (
      //   <GridEditInputCell
      //     {...params}
      //     error={true}
      //     value={10}
      //     inputProps={{
      //       max: 10,
      //       min: 0,
      //     }}
      //   />
      // ),
    },
    // for temprory ( until android scan )
    {
      field: "picked_item_qnty",
      renderHeader: () => <b>Picked Quantity</b>,
      flex: 1,
      align: "right",
      headerAlign: "right",
      type: "number",
      valueGetter: (params: GridValueGetterParams<IStockTransferItemDetails>) =>
        params.row.picked_qnty?.item_quantity,
    },
    {
      field: "pick_action",
      renderHeader: () => <b>Action</b>,
      type: "actions",
      renderCell: (params) => {
        return (
          <IconButtonField
            onClick={() => {
              setPickQuantityDialogOpen(true);
              dispatch(getOnePickList(params.row.stock_transfer_item_id));
            }}
          >
            <SvgIcon component={ViewIcon} inheritViewBox />
          </IconButtonField>
        );
      },
    },
    //

    {
      field: "actions",
      type: "actions",
      renderHeader: () => <b>Actions</b>,
      width: 100,
      cellClassName: "actions",
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"
              sx={{ color: "primary.main" }}
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
              disabled={rows.length === 1 ? true : false}
            />,
          ];
        }

        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(id)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            onClick={handleDeleteClick(id)}
            color="inherit"
          />,
        ];
      },
    },
  ];
  const list_items_row = rows.map((item, i) => ({
    ...item,
    index: i + 1,
  }));

  return (
    <>
      <FormProvider {...methods}>
        <Table
          processRowUpdate={
            !!(
              pathname === "/transaction/transfer/add" ||
              pathname === "/transaction/transfer/edit"
            )
              ? processRowUpdate
              : updateRow
          }
          rows={
            !!(
              pathname === "/transaction/transfer/add" ||
              pathname === "/transaction/transfer/edit"
            )
              ? list_items_row
              : tableRows ?? []
          }
          columns={columns}
          initialPageSize={100}
          rowModesModel={rowModesModel}
          onRowModesModelChange={handleRowModesModelChange}
          onRowEditStop={handleRowEditStop}
          editMode={
            !!(
              pathname === "/transaction/transfer/add" ||
              pathname === "/transaction/transfer/edit"
            )
              ? "row"
              : "cell"
          }
          isCellEditable={
            !!(
              pathname === "/transaction/transfer/add" ||
              pathname === "/transaction/transfer/edit"
            )
              ? isCellEditable
              : () => true
          }
          columnVisibilityModel={{
            req_item_qnty: !!!(
              pathname === "/transaction/transfer/add" ||
              pathname === "/transaction/transfer/edit"
            ),
            actions: !!(pathname === "/transaction/transfer/add"),
            available_quantity: !!!(pathname === "/transaction/transfer/add"),
            picked_item_qnty:
              !!requestUpdateId?.update_id &&
              !!!(pathname === "/transaction/transfer/edit"),
            pick_action: !!requestUpdateId?.update_id,
          }}
        >
          <CustomToolbar>
            <h6 style={{ width: "100%" }}>
              {pathname === "/transaction/transfer/add"
                ? "Add Stock Transfer"
                : pathname === "/transaction/request/stock-transfer/add"
                ? "Add Request Stock Transfer"
                : `${
                    pathname === "/transaction/request/stock-transfer/edit"
                      ? "Request Stock Transfer"
                      : "Edit Stock Transfer"
                  }`}
            </h6>
            <form
              ref={requestFormRef}
              onSubmit={methods.handleSubmit(onSubmit)}
              className="toolbar_form"
            >
              <div className="left_div">
                <Select
                  size="small"
                  options={locations ?? []}
                  name="transfer_from"
                  label="Transfer From"
                  disabled
                  value={
                    locations?.find(
                      (location) =>
                        location.value === methods.getValues("transfer_from")
                    ) ?? null
                  }
                  onChange={(_, val) => {
                    methods.setValue(
                      "transfer_from",
                      (val as AutocompleteOption)?.value ?? null,
                      { shouldValidate: true }
                    );
                  }}
                />
                <Select
                  size="small"
                  options={locations ?? []}
                  name="transfer_to"
                  label="Transfer To"
                  hidden={
                    pathname !== "/transaction/request/stock-transfer/add"
                  }
                  disabled={pathname !== "/transaction/transfer/add"}
                  placeholder={
                    pathname === "/transaction/transfer/add" ||
                    pathname === "/transaction/transfer/edit"
                      ? ""
                      : "Please select Request No"
                  }
                  value={
                    locations?.find(
                      (location) =>
                        location.value === methods.getValues("transfer_to")
                    ) ?? null
                  }
                  onChange={(_, val) => {
                    methods.setValue(
                      "transfer_to",
                      (val as AutocompleteOption)?.value ?? null,
                      { shouldValidate: true }
                    );
                  }}
                />
                <Select
                  size="small"
                  hidden={
                    pathname === "/transaction/request/stock-transfer/add"
                  }
                  options={locations ?? []}
                  name="transfer_to"
                  label="Transfer To"
                  disabled={pathname !== "/transaction/transfer/add"}
                  placeholder={
                    pathname === "/transaction/transfer/add" ||
                    pathname === "/transaction/transfer/edit"
                      ? ""
                      : "Please select Request No"
                  }
                  value={
                    locations?.find(
                      (location) =>
                        location.value === methods.getValues("transfer_to")
                    ) ?? null
                  }
                  onChange={(_, val) => {
                    methods.setValue(
                      "transfer_to",
                      (val as AutocompleteOption)?.value ?? null,
                      { shouldValidate: true }
                    );
                  }}
                />
                <Select
                  size="small"
                  hidden={
                    pathname === "/transaction/transfer/add" ||
                    pathname === "/transaction/transfer/edit"
                  }
                  options={requests ?? []}
                  name="request_id"
                  label="Request No"
                  disabled={!!requestUpdateId}
                  value={
                    requests?.find(
                      (request) =>
                        request.value === methods.getValues("request_id")
                    ) ?? null
                  }
                  onChange={(_, val) => {
                    const value = val as AutocompleteOption;
                    methods.setValue("request_id", value?.value ?? null, {
                      shouldValidate: true,
                    });
                  }}
                />
              </div>
              <div className="right_div">
                <TextInputFieldC
                  size="small"
                  name="entry_no"
                  label="Entry No"
                  disabled
                />

                <DatePickers
                  size="small"
                  name="entry_date"
                  label="Entry Date"
                  disabled
                />

                {/* <ButtonField
                  disableRipple
                  sx={{ fontSize: "1rem" }}
                  className={
                    requestDataById?.data?.status === "PICD"
                      ? "transferd"
                      : "pick_init"
                  }
                >
                  {show_status(requestDataById?.data?.status)}
                </ButtonField> */}
                {!!(
                  (pathname === "/transaction/transfer/add")
                  // ||
                  // pathname === "/transaction/transfer/edit"
                ) && (
                  <div className="add_new_with_error">
                    <ButtonField
                      className="tabel_add_new_button"
                      // onClick={() => setDialogOpen(true)}
                      onClick={() => handleAddClick()}
                    >
                      Add new
                    </ButtonField>
                    {methods.formState.errors.item_details && (
                      <FormHelperText error style={{ textAlign: "end" }}>
                        {methods.formState.errors.item_details.message}
                      </FormHelperText>
                    )}
                  </div>
                )}
              </div>
            </form>
          </CustomToolbar>

          <CustomFooter className="item_footer">
            <TableAggregation
              style={{
                display:
                  pathname === "/transaction/request/stock-transfer/edit"
                    ? "flex"
                    : "none",
              }}
              name="Total Available Quantities :"
              value={TotalAvailQuantity(tableRows!)}
            />
            <TableAggregation
              style={{
                display:
                  pathname === "/transaction/request/stock-transfer/edit"
                    ? "flex"
                    : "none",
              }}
              name="Total Requested Quantities :"
              value={TotalReqQuantity(tableRows!)}
            />
            <TableAggregation
              name="Total Transfer Quantities :"
              value={totalItemQuantity(tableRows!)}
            />
            {requestUpdateId?.update_id && (
              <TableAggregation
                name="Picked Quantity :"
                value={
                  (tableRows?.length &&
                    tableRows
                      ?.map((row) => row.picked_qnty?.item_quantity)
                      ?.reduce((pre, cur) => (pre ?? 0) + (cur ?? 0))) ??
                  0
                }
              />
            )}
            <div className="button_navigations">
              <ButtonField
                className="form_cancel_button"
                //   onClick={() => setDialogOpen(false)}
                onClick={() => nav(-1)}
              >
                Cancel
              </ButtonField>

              <ButtonField
                className="form_save_button"
                onClick={() => requestFormRef.current?.requestSubmit()}
              >
                Save
              </ButtonField>
            </div>
          </CustomFooter>
        </Table>
      </FormProvider>

      {/* item form */}
      <Dialog
        open={dialogOpen}
        dialogClassName="dialog"
        Dialog_Title={`${
          Number.isInteger(itemValue.id) ? "Update" : "Add"
        } Item`}
        Dialog_Content={
          <ItemForm
            formRef={formRef}
            initialValues={itemValue}
            setInitialValues={setItemValue}
            itemSchema={itemSchema}
            append={append}
            update={update}
            setDialogOpen={setDialogOpen}
          />
        }
        Dialog_Actions={
          <>
            <ButtonField
              className="form_cancel_button"
              onClick={() => setDialogOpen(false)}
            >
              Cancel
            </ButtonField>

            <ButtonField
              className="form_save_button"
              onClick={() => formRef.current?.requestSubmit()}
            >
              Save
            </ButtonField>
          </>
        }
      />

      {/* <DevTool control={methods.control} /> */}

      <DeleteDialog
        deleteableName={deleteDialogData.name ?? ""}
        open={deleteDialogOpen}
        setOpen={setDeleteDialogOpen}
        deleteFunction={() => {
          remove(deleteDialogData.id as number);
          setDeleteDialogOpen(false);
        }}
      />

      <ResponseDialog response={addRequest} okFunction={() => nav(-1)} />
      <ResponseDialog response={editRequest} okFunction={() => nav(-1)} />

      {/* pick quantity form */}
      <Dialog
        fullWidth
        maxWidth="md"
        open={pickQuantityDialogOpen}
        Dialog_Title="Pick Items"
        Dialog_Content={
          <PickQuantityForm
            list={getOnePick?.data}
            setPickQuantityDialogOpen={setPickQuantityDialogOpen}
            update_id={requestUpdateId?.update_id}
            // formRef={formRef}
            // initialValues={itemValue}
            // setInitialValues={setItemValue}
            // itemSchema={itemSchema}
            // append={append}
            // update={update}
            // setDialogOpen={setDialogOpen}
          />
        }
        // Dialog_Actions={
        //   <>
        //     <ButtonField
        //       className="form_cancel_button"
        //       onClick={() => setPickQuantityDialogOpen(false)}
        //     >
        //       Cancel
        //     </ButtonField>

        //     <ButtonField
        //       className="form_save_button"
        //       onClick={() => {
        //         setPickQuantityDialogOpen(false);
        //         // formRef.current?.requestSubmit();
        //       }}
        //     >
        //       Save
        //     </ButtonField>
        //   </>
        // }
      />
    </>
  );
};

export default StockTransferForm;
