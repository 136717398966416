import {
  ChangeEvent,
  FunctionComponent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import "./ItemMaster.scss";
import { IItemMaster, ItemMasterProps, ItemTypeOptions } from "./IItemMaster";
import CustomToolbar from "../../Components/Table/components/CustomToolbar";
import ButtonField from "../../Components/Button/ButtonField";
import { Columns } from "../../Components/Table/ITable";
import ResponseDialog from "../../Components/ResponseDialog/ResponseDialog";
import Dialog from "../../Components/Dialog/Dialog";
import ItemMasterForm from "./ItemMasterForm";
import { useAppDispatch, useAppSelector } from "../../Redux/Store/Hooks";
import { clearAddItemMasterResponse } from "../../Redux/Slices/ItemMaster/AddItemMasterSlice";
import { GridRenderCellParams } from "@mui/x-data-grid";
import { clearEditItemMasterResponse } from "../../Redux/Slices/ItemMaster/EditItemMasterSlice";
import IconButtonField from "../../Components/Button/IconButtonField";
import DeleteDialog from "../../Components/DeleteDialog/DeleteDialog";
import {
  clearDeleteItemMasterResponse,
  deleteItemMaster,
} from "../../Redux/Slices/ItemMaster/DeleteItemMasterSlice";
import { IDeleteDialog } from "../../Components/DeleteDialog/IDeleteDialog";
import { SvgIcon } from "@mui/material";
import { ReactComponent as Delete } from "../../Assets/Images/delete.svg";
import { ReactComponent as Edit } from "../../Assets/Images/edit.svg";
import ImportItems from "./ImportItems";
import TableAPI from "../../Components/Table/TableAPI";
import api from "../../Redux/Axios/middleware";
import TextInputField from "../../Components/TextInput/TextInputField";
import debounce from "lodash.debounce";
import SearchIcon from "@mui/icons-material/Search";
import Select from "../../Components/withoutControllerComponents/Select/Select";
import { AutocompleteOption } from "../../Components/Select/ISelectProps";
import { clearImportItemMasterResponse } from "../../Redux/Slices/ItemMaster/ImportItemSlicer";
import { getItemTypeStart } from "../../Redux/Slices/GeneralSetting/ItemType/GetItemType";

const ItemMaster: FunctionComponent<ItemMasterProps> = () => {
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);

  const [importDialogOpen, setImportDialogOpen] = useState<boolean>(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState<boolean>(false);
  const [deleteDialogData, setDeleteData] = useState<IDeleteDialog>({});
  const [initialValues, setInitialValues] = useState<IItemMaster>({});

  const [data, setData] = useState([]);
  const [rowCount, setRowCount] = useState(100);
  const [search, setSearch] = useState("");
  const [itemType, setItemType] = useState<string | null>(null);
  const [searchKey, setSearchKey] = useState("");
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 100,
  });

  const [files, setFiles] = useState<ChangeEvent<HTMLInputElement> | null>(
    null
  );
  const dispatch = useAppDispatch();
  const formRef = useRef<HTMLFormElement>(null);
  const { data: addItem } = useAppSelector((state) => state.AddItemMaster);
  const { data: importItem } = useAppSelector(
    (state) => state.ImportItemMaster
  );
  const { data: editItem } = useAppSelector((state) => state.EditItemMaster);
  const { data: deleteItem } = useAppSelector(
    (state) => state.DeleteItemMaster
  );

  const { data: ImportItem } = useAppSelector(
    (state) => state.ImportItemMaster
  );
  const { data: ItemTypeList } = useAppSelector((state) => state.GetItemType);
  const ItemTypeLists = Array.isArray(ItemTypeList?.data)
    ? ItemTypeList?.data.map((type) => ({
        label: type.name!,
        value: type.description!,
      }))
    : [];

  const column: Columns = [
    {
      field: "index",
      renderHeader: () => <b>S.No</b>,
      align: "center",
      headerAlign: "center",
      width: 90,
    },
    {
      field: "item_name",
      renderHeader: () => <b>Name</b>,
      flex: 1,
      align: "left",
      headerAlign: "left",
      type: "string",
    },
    {
      field: "item_code",
      renderHeader: () => <b>Code</b>,
      flex: 1,
      align: "left",
      headerAlign: "left",
      type: "string",
    },
    {
      field: "item_type",
      renderHeader: () => <b>Item Type</b>,
      flex: 1,
      align: "left",
      headerAlign: "left",
      type: "singleSelect",
      valueOptions: ItemTypeOptions,
    },
    {
      field: "base_uom",
      renderHeader: () => <b>UOM</b>,
      flex: 1,
      align: "left",
      headerAlign: "left",
    },
    {
      field: "description",
      renderHeader: () => <b>Description</b>,
      flex: 1,
      align: "left",
      headerAlign: "left",
      type: "string",
    },
    {
      field: "actions",
      renderHeader: () => <b>Action</b>,
      type: "actions",
      renderCell: (params: GridRenderCellParams<IItemMaster>) => {
        return (
          <>
            <IconButtonField
              onClick={() => {
                setInitialValues({ ...params.row });
                setDialogOpen(true);
              }}
            >
              <SvgIcon sx={{ fill: "none" }} component={Edit} inheritViewBox />
            </IconButtonField>
            <IconButtonField
              onClick={() => {
                setDeleteData({ id: params.id, name: params.row.item_name });
                setDeleteDialogOpen(true);
              }}
            >
              <SvgIcon component={Delete} inheritViewBox />
            </IconButtonField>
          </>
        );
      },
    },
  ];

  // useEffect(() => {
  //   dispatch(listItemMaster());
  // }, [addItem, editItem, deleteItem, importItem?.status, dispatch]);
  useEffect(() => {
    dispatch(getItemTypeStart("IT"));
    return () => {
      dispatch(clearAddItemMasterResponse());
      dispatch(clearEditItemMasterResponse());
      dispatch(clearDeleteItemMasterResponse());
      dispatch(clearImportItemMasterResponse());
    };
  }, [dispatch]);

  const fetchData = async () => {
    try {
      const response = await api.get("/item-master", {
        params: {
          page: paginationModel.page + 1, // API usually expects 1-based index for pages
          limit: paginationModel.pageSize,
          searchkey: searchKey, // Include the search query in the API request
          item_type: itemType,
        },
      });
      console.log(response);
      const count = response.data.data.page - 1;
      const itemList = Array.isArray(response.data.data.listitem_master)
        ? response.data.data.listitem_master.map((item: any, index: any) => ({
            ...item,
            index: count
              ? response.data.data.limit * count + index + 1
              : count + index + 1,
          }))
        : [];
      setData(itemList);
      setRowCount(response.data.data.totalRecords); // Ensure this reflects the total number of records on the server
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
    }
  };

  const onSearchChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setSearch(event.target.value);
    debouncedSearch(event.target.value);
  };
  // Debounced search handler using lodash.debounce
  const debouncedSearch = useCallback(
    debounce((searchTerm: string) => {
      setSearchKey(searchTerm);
      setPaginationModel((prev) => ({ ...prev, page: 0 })); // Reset to the first page on search
    }, 800), // Adjust debounce delay as needed
    []
  );

  useEffect(() => {
    fetchData();
  }, [
    paginationModel,
    itemType,
    addItem,
    editItem,
    deleteItem,
    importItem?.status,
    dispatch,
  ]);
  console.log(data);

  return (
    <>
      <TableAPI
        rows={data ?? []}
        columns={column}
        pagination
        rowCount={rowCount}
        paginationModel={paginationModel}
        onPaginationModelChange={setPaginationModel}
        pageSizeOptions={[25, 50, 100]}
      >
        <CustomToolbar className="custom_toolbar_search_add_new">
          <h6>Items</h6>
          <Select
            size="small"
            className="item_type_search_filter"
            label="Filter By Item Type"
            options={ItemTypeLists}
            onChange={(_, val) => {
              const value = val as AutocompleteOption;
              if (value) {
                setItemType(value.value);
              } else {
                setItemType(null);
              }
            }}
          />
          <TextInputField
            placeholder="Search"
            variant="outlined"
            size="small"
            value={search}
            onChange={(e) => onSearchChange(e)}
            sx={{ flexGrow: 0.02 }}
            InputProps={{
              endAdornment: <SearchIcon />,
            }}
          />
          <ButtonField
            className="tabel_import_item_button"
            onClick={() => setImportDialogOpen(true)}
          >
            Import Items
          </ButtonField>
          <ButtonField
            className="tabel_import_item_button"
            onClick={() => setDialogOpen(true)}
          >
            Add new
          </ButtonField>
        </CustomToolbar>
      </TableAPI>

      <>
        {/* Add Item Dialog */}
        <Dialog
          open={dialogOpen}
          dialogClassName="dialog"
          Dialog_Title={`${initialValues.id ? "Update" : "Add"} Item`}
          Dialog_Content={
            <ItemMasterForm
              formRef={formRef}
              initialValues={initialValues}
              setInitialValues={setInitialValues}
            />
          }
          Dialog_Actions={
            <>
              <ButtonField
                className="form_cancel_button"
                onClick={() => setDialogOpen(false)}
              >
                Cancel
              </ButtonField>
              <ButtonField
                className="form_save_button"
                onClick={() => formRef.current?.requestSubmit()}
              >
                Save
              </ButtonField>
            </>
          }
        />
        {/* Item import Dialog */}
        <Dialog
          open={importDialogOpen}
          dialogClassName="dialog"
          dialogContentClassName="item_import_dialog_content"
          Dialog_Content={
            <ImportItems
              files={files!}
              setFileSate={setFiles}
              setImportDialogOpen={setImportDialogOpen}
            />
          }
          Dialog_Title={
            <div className="import_dia_header">
              <div>
                <h6>Import Items</h6>
              </div>
            </div>
          }
        />
      </>

      <ResponseDialog
        response={ImportItem}
        parentDialog={setImportDialogOpen}
      />
      <ResponseDialog response={addItem} parentDialog={setDialogOpen} />
      <ResponseDialog response={editItem} parentDialog={setDialogOpen} />
      <DeleteDialog
        deleteableName={deleteDialogData.name ?? ""}
        open={deleteDialogOpen}
        setOpen={setDeleteDialogOpen}
        deleteFunction={() => {
          dispatch(deleteItemMaster(deleteDialogData.id));
        }}
        response={deleteItem}
      />
    </>
  );
};

export default ItemMaster;
