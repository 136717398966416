import React, { FunctionComponent, useEffect, useState } from "react";
import Table from "../../Components/Table/Table";
import { useAppSelector } from "../../Redux/Store/Hooks";
import { IDetails, IItems, IStockItems } from "./IStockRecieve";
import { Columns } from "../../Components/Table/ITable";
import { GridValueFormatterParams } from "@mui/x-data-grid";
import CustomToolbar from "../../Components/Table/components/CustomToolbar";
import TableSearch from "../../Components/Table/components/TableSearch";

const ViewStockItems: FunctionComponent = () => {
  const { data: stockRecieveList } = useAppSelector(
    (state) => state.ListStockRecieve
  );
  const [stockItems, setStockItems] = useState<IStockItems[]>();

  useEffect(() => {
    const getStock = localStorage.getItem("viewStock");
    if (getStock) {
      const stockItemDetails = stockRecieveList?.data?.find(
        (stock) => stock.id == parseInt(getStock)
      );
      //   console.log(stockItemDetails?.details);
      setStockItems(stockItemDetails?.item_details!);
    }
  }, []);
  const stockRecieveItemRow = Array.isArray(stockItems)
    ? stockItems.map((stock, index) => ({
        ...stock,
        index: index + 1,
      }))
    : [];
  const columns: Columns = [
    {
      field: "index",
      renderHeader: () => <b>S.No</b>,
      width: 30,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "item_name",
      renderHeader: () => <b>Item Name</b>,
      flex: 1,
      headerAlign: "left",
      align: "left",
    },
    {
      field: "item_code",
      renderHeader: () => <b>Item Code</b>,
      flex: 1,
      type: "string",
      headerAlign: "left",
      align: "left",
    },
    {
      field: "cus_serial_no",
      renderHeader: () => <b>Custom S.No</b>,
      flex: 1,
      type: "string",
      headerAlign: "left",
      align: "left",
    },
    {
      field: "item_qnty",
      renderHeader: () => <b>Item Quantity</b>,
      flex: 1,
      type: "string",
      headerAlign: "right",
      align: "right",
    },
  ];
  return (
    <>
      <Table columns={columns ?? []} rows={stockRecieveItemRow ?? []}>
        <CustomToolbar>
          <TableSearch className="view_table_left_align_search" />
        </CustomToolbar>
      </Table>
    </>
  );
};

export default ViewStockItems;
