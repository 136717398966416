import { FunctionComponent } from "react";
import Table from "../Table/Table";
import { Columns } from "../Table/ITable";
import CustomToolbar from "../Table/components/CustomToolbar";
import { GetRoleOptionType, UserPermissionProps } from "./IUserPermission";
import "./userPermission.scss";
import CustomFooter from "../Table/components/CustomFooter";
import ButtonField from "../Button/ButtonField";
import Checkbox from "../Checkbox/Checkbox";
import Select from "../withoutControllerComponents/Select/Select";
import { useAppDispatch } from "../../Redux/Store/Hooks";
import { updateUserPermission } from "../../Redux/Slices/UserPermission/UserPermissionRowsSlicer";
import { useSearchParams } from "react-router-dom";
import { AutocompleteOption } from "../Select/ISelectProps";
import { editUserPermission } from "../../Redux/Slices/UserPermission/UpdateUserPermissionSlicer";

const UserPermission: FunctionComponent<UserPermissionProps> = (props) => {
  const { rows, RoleOptions, getRoleOption } = props;
  const dispatch = useAppDispatch();
  const [searchParams, setSearchParams] = useSearchParams();

  // Column
  const column: Columns = [
    {
      field: "menu",
      renderHeader: () => <b>Menus</b>,
      flex: 3,
      type: "string",
      align: "left",
      headerAlign: "center",
      maxWidth: 250,
    },
    {
      field: "view",
      renderHeader: () => <b>View</b>,
      flex: 1,
      type: "boolean",
      sortable: false,
      renderCell(params) {
        return (
          <div>
            <Checkbox
              color="success"
              checked={params.value}
              onChange={(event) => {
                const checked = event.target.checked;
                dispatch(
                  updateUserPermission({
                    id: params.id,
                    row: {
                      ...params.row,
                      view: checked,
                      add: checked && params.row.add,
                      edit: checked && params.row.edit,
                      delete: checked && params.row.delete,
                    },
                  })
                );
                params.api.updateRows([
                  {
                    ...params.row,
                    view: checked,
                    add: checked && params.row.add,
                    edit: checked && params.row.edit,
                    delete: checked && params.row.delete,
                  },
                ]);
              }}
            />
          </div>
        );
      },
      headerAlign: "center",
      align: "center",
    },
    {
      field: "add",
      renderHeader: () => <b>Add</b>,
      flex: 1,
      type: "boolean",
      headerAlign: "center",
      align: "center",
      sortable: false,
      renderCell(params) {
        return (
          <div>
            <Checkbox
              color="success"
              checked={params.value}
              onChange={(event) => {
                const checked = event.target.checked;
                dispatch(
                  updateUserPermission({
                    id: params.id,
                    row: {
                      ...params.row,
                      add: checked,
                      view: checked || params.row.view,
                    },
                  })
                );
                params.api.updateRows([
                  {
                    ...params.row,
                    add: checked,
                    view: checked || params.row.view,
                  },
                ]);
              }}
            />
          </div>
        );
      },
    },
    {
      field: "edit",
      renderHeader: () => <b>Edit</b>,
      flex: 1,
      type: "boolean",
      headerAlign: "center",
      align: "center",
      sortable: false,
      renderCell(params) {
        return (
          <div>
            <Checkbox
              color="success"
              checked={params.value}
              onChange={(event) => {
                const checked = event.target.checked;
                dispatch(
                  updateUserPermission({
                    id: params.id,
                    row: {
                      ...params.row,
                      edit: checked,
                      view: checked || params.row.view,
                    },
                  })
                );
                params.api.updateRows([
                  {
                    ...params.row,
                    edit: checked,
                    view: checked || params.row.view,
                  },
                ]);
              }}
            />
          </div>
        );
      },
    },
    {
      field: "delete",
      renderHeader: () => <b>Delete</b>,
      flex: 1,
      type: "boolean",
      headerAlign: "center",
      align: "center",
      sortable: false,
      renderCell(params) {
        return (
          <div>
            <Checkbox
              color="success"
              checked={params.value}
              onChange={(event) => {
                const checked = event.target.checked;
                dispatch(
                  updateUserPermission({
                    id: params.id,
                    row: {
                      ...params.row,
                      delete: checked,
                      view: checked || params.row.view,
                    },
                  })
                );
                params.api.updateRows([
                  {
                    ...params.row,
                    delete: checked,
                    view: checked || params.row.view,
                  },
                ]);
              }}
            />
          </div>
        );
      },
    },
  ];

  return (
    <Table rows={rows} columns={column}>
      <CustomToolbar className="top_toolbar">
        <h6>User Permission</h6>
        <Select
          className="select_option"
          options={RoleOptions}
          value={
            RoleOptions.find(
              (role) => role.value === Number(searchParams.get("id"))
            ) ?? null
          }
          onChange={(_, v) => {
            getRoleOption(v as GetRoleOptionType);
            setSearchParams(
              (v as AutocompleteOption)?.value
                ? { id: (v as AutocompleteOption)?.value ?? null }
                : {}
            );
          }}
          label="Select Roll"
          size="small"
          sx={{ minWidth: "300px" }}
        />
      </CustomToolbar>
      <CustomFooter className="bottom_toolbar">
        <ButtonField
          variant="contained"
          onClick={() =>
            dispatch(
              editUserPermission({
                role_id: Number(searchParams.get("id")),
                menu: rows,
              })
            )
          }
          disabled={
            !!!RoleOptions.find(
              (role) => role.value === Number(searchParams.get("id"))
            )
          }
        >
          Submit
        </ButtonField>
      </CustomFooter>
    </Table>
  );
};

export default UserPermission;
