import { PayloadAction } from "@reduxjs/toolkit";
import api from "../../Axios/middleware";
import { AxiosResponse } from "axios";
import { call, put } from "redux-saga/effects";
import {
  failureGetOnePickList,
  successGetOnePickList,
} from "./GetOnePickItemSlice";
import { IGetOnePick } from "../../../Pages/StockTransfer/IStockTransfer";
import { failureeditPickItem, successeditPickItem } from "./EditPickItemSlice";

// getone
const GetOnePickListApi = (action: PayloadAction<number>) => {
  return api.get("/picklist/pickone-item", {
    params: { tran_det_id: action.payload },
  });
};

export function* GetOnePickListCall(action: PayloadAction<number>) {
  try {
    const response: AxiosResponse = yield call(GetOnePickListApi, action);
    yield put(successGetOnePickList(response.data));
  } catch (error) {
    yield put(failureGetOnePickList(error));
  }
}

// edit
const EditPickItemApi = (action: PayloadAction<IGetOnePick>) => {
  return api.patch("/picklist/update_many", action.payload.stockDetails, {
    params: { id: action.payload.picklist.id },
  });
};

export function* EditPickItemCall(action: PayloadAction<IGetOnePick>) {
  try {
    const response: AxiosResponse = yield call(EditPickItemApi, action);
    yield put(successeditPickItem(response.data));
  } catch (error) {
    yield put(failureeditPickItem(error));
  }
}
