import React, { FunctionComponent, useEffect } from "react";
import TestArea from "./TestArea";
import TabLists from "../../Components/Tabs/TabLists";
import { ITabPannelProps, ITabsProps } from "../../Components/Tabs/ITabProps";
import CallIcon from "@mui/icons-material/Call";
import HomeIcon from "@mui/icons-material/Home";
import TextInputField from "../../Components/TextInput/TextInputField";
import { FormikProps, useFormik } from "formik";
import * as Yup from "yup";
import { ILoginForm } from "../Login/ILogin";
import { Divider } from "@mui/material";
import ButtonField from "../../Components/Button/ButtonField";

interface IpersonalForm {
  name?: string;
  state?: string;
}
interface IThirdForm {
  city?: string;
  pin?: string;
}

const TabFormDemo = () => {
  const [tabValue, setTabValue] = React.useState("1");

  //   const [consolidateValues, SetConsolidateVaues] = React.useState<any[]>([]);

  // Tab 1 form
  const loginInitialValues: ILoginForm = {
    email: "",
    password: "",
  };

  const loginSchema = Yup.object({
    email: Yup.string()
      .email("Invalid Email")
      .required("User Name is required"),
    password: Yup.string().required("Password is required"),
  });

  const loginForm = useFormik<ILoginForm>({
    initialValues: loginInitialValues,
    validationSchema: loginSchema,
    onSubmit: (value: ILoginForm) => {
      console.log(value);

      //   if (value) setTabValue(String(parseInt(tabValue) + 1));
    },
  });

  //   Tab Form 2
  const personalInitialValues: IpersonalForm = {
    name: "",
    state: "",
  };

  const personalSchema = Yup.object({
    name: Yup.string().required("Name is required"),
    state: Yup.string().required("State is required"),
  });

  const personalForm = useFormik<IpersonalForm>({
    initialValues: personalInitialValues,
    validationSchema: personalSchema,
    onSubmit: (value: IpersonalForm) => {
      console.log(value);

      //   if (value) setTabValue(String(parseInt(tabValue) + 1));
    },
  });

  //  Form 3
  const formThirdInitialValues: IThirdForm = {
    city: "",
    pin: "",
  };

  const thirdsFormSchema = Yup.object({
    city: Yup.string().required("Name is required"),
    pin: Yup.string().required("State is required"),
  });

  const thirdForm = useFormik<IThirdForm>({
    initialValues: formThirdInitialValues,
    validationSchema: thirdsFormSchema,
    onSubmit: (value: IThirdForm) => {
      console.log(value);

      //   if (value) setTabValue(String(parseInt(tabValue) + 1));
    },
  });

  // Tab declaration

  const tabs: ITabsProps[] = [
    { value: "1", label: "Item One", disabled: false, icon: <CallIcon /> },
    { value: "2", label: "Item Two", disabled: false, icon: <HomeIcon /> },
    { value: "3", label: "Item Three", disabled: false },
    { value: "4", label: "Item Four", disabled: false },
  ];
  const handleFinalSubmit = () => {
    const allFormValues = {
      loginData: loginForm.values,
      PersonalData: personalForm.values,
      addressData: thirdForm.values,
    };
    console.log(allFormValues);
  };

  const tabPanels: ITabPannelProps[] = [
    {
      value: "1",
      children: (
        <form onSubmit={loginForm.handleSubmit}>
          <div className="main">
            <div>
              <div className="heading">Login</div>
              {/* <div className="subHeading">
                Don't have an account yet? <Link to={"#"}>Sign Up</Link>
              </div> */}
              <Divider />
            </div>

            <div className="textFields">
              <TextInputField
                className="textField"
                type="text"
                id="email"
                name="email"
                value={loginForm.values.email}
                onChange={loginForm.handleChange}
                onBlur={loginForm.handleBlur}
                placeholder="User Name"
                helperText={loginForm.touched.email && loginForm.errors.email}
                error={
                  loginForm.touched.email && Boolean(loginForm.errors.email)
                }
              />

              <TextInputField
                className="textField"
                id="password"
                name="password"
                value={loginForm.values.password}
                onChange={loginForm.handleChange}
                onBlur={loginForm.handleBlur}
                helperText={
                  loginForm.touched.password && loginForm.errors.password
                }
                error={
                  loginForm.touched.password &&
                  Boolean(loginForm.errors.password)
                }
                placeholder="Password"
              />
            </div>

            <div className="textFields">
              <ButtonField
                className="buttonfield"
                onClick={(event) => {
                  if (
                    !!!Object.keys(loginForm.errors).length &&
                    !!!Object.values(loginForm.values).includes("")
                  ) {
                    console.log(tabValue);
                    handleChange("2");
                  }
                }}
                type="submit"
              >
                Save & Next
              </ButtonField>
            </div>
          </div>
        </form>
      ),
      saveNext:
        !!!Object.keys(loginForm.errors).length &&
        !!!Object.values(loginForm.values).includes(""),
      checkCondition: () => loginForm.handleSubmit(),
    },
    {
      value: "2",
      children: (
        <form onSubmit={personalForm.handleSubmit}>
          <div className="main">
            <div>
              <div className="heading">Personal</div>
              {/* <div className="subHeading">
                Don't have an account yet? <Link to={"#"}>Sign Up</Link>
              </div> */}
              <Divider />
            </div>

            <div className="textFields">
              <TextInputField
                className="textField"
                type="text"
                id="name"
                name="name"
                value={personalForm.values.name}
                onChange={personalForm.handleChange}
                onBlur={personalForm.handleBlur}
                placeholder="Name"
                helperText={
                  personalForm.touched.name && personalForm.errors.name
                }
                error={
                  personalForm.touched.name && Boolean(personalForm.errors.name)
                }
              />

              <TextInputField
                className="textField"
                id="state"
                name="state"
                value={personalForm.values.state}
                onChange={personalForm.handleChange}
                onBlur={personalForm.handleBlur}
                helperText={
                  personalForm.touched.state && personalForm.errors.state
                }
                error={
                  personalForm.touched.state &&
                  Boolean(personalForm.errors.state)
                }
                placeholder="State"
              />
            </div>

            <div className="textFields">
              <ButtonField
                className="buttonfield"
                onClick={(event) => {
                  if (
                    !!!Object.keys(personalForm.errors).length &&
                    !!!Object.values(personalForm.values).includes("")
                  ) {
                    // setTabValue(String(parseInt(tabValue) + 1));
                    handleChange("3");
                  }
                }}
                type="submit"
              >
                Save & Next
              </ButtonField>
            </div>
          </div>
        </form>
      ),
      saveNext:
        !!!Object.keys(personalForm.errors).length &&
        !!!Object.values(personalForm.values).includes(""),
      checkCondition: () => personalForm.handleSubmit(),
    },
    {
      value: "3",
      children: (
        <form onSubmit={thirdForm.handleSubmit}>
          <div className="main">
            <div>
              <div className="heading">Third Form</div>
              {/* <div className="subHeading">
              Don't have an account yet? <Link to={"#"}>Sign Up</Link>
            </div> */}
              <Divider />
            </div>

            <div className="textFields">
              <TextInputField
                className="textField"
                type="text"
                id="city"
                name="city"
                value={thirdForm.values.city}
                onChange={thirdForm.handleChange}
                onBlur={thirdForm.handleBlur}
                placeholder="City"
                helperText={thirdForm.touched.city && thirdForm.errors.city}
                error={thirdForm.touched.city && Boolean(thirdForm.errors.city)}
              />

              <TextInputField
                className="textField"
                id="pin"
                name="pin"
                value={thirdForm.values.pin}
                onChange={thirdForm.handleChange}
                onBlur={thirdForm.handleBlur}
                helperText={thirdForm.touched.pin && thirdForm.errors.pin}
                error={thirdForm.touched.pin && Boolean(thirdForm.errors.pin)}
                placeholder="PIN"
              />
            </div>

            <div className="textFields">
              <ButtonField
                className="buttonfield"
                onClick={(event) => {
                  if (
                    !!!Object.keys(thirdForm.errors).length &&
                    !!!Object.values(thirdForm.values).includes("")
                  ) {
                    handleChange("4");
                    // setTabValue(String(parseInt(tabValue) + 1));
                    // console.log(tabValue);
                  }
                }}
                type="submit"
              >
                Save & Next
              </ButtonField>
            </div>
          </div>
        </form>
      ),
      saveNext:
        !!!Object.keys(thirdForm.errors).length &&
        !!!Object.values(thirdForm.values).includes(""),
      checkCondition: () => thirdForm.handleSubmit(),
    },
    {
      value: "4",
      children: <ButtonField onClick={handleFinalSubmit}>Finish</ButtonField>,
    },
  ];

  const handleChange = (val: string) => {
    const currentTab = tabPanels.find((item) => item.value === tabValue);
    console.log(currentTab);

    if (currentTab?.checkCondition) {
      currentTab?.checkCondition();
      if (currentTab.saveNext) {
        if (parseInt(tabValue) + 1 === parseInt(val))
          setTabValue((parseInt(tabValue) + 1).toString());
      }
    }
    if (parseInt(tabValue) > parseInt(val)) {
      setTabValue(val);
    }
  };
  return (
    <TabLists
      scrollButtons={true}
      variant="scrollable"
      tabs={tabs}
      tabPannels={tabPanels}
      orientation="horizontal"
      tabClassName="Tab_css"
      textColor="primary"
      centered={true}
      onChange={(_, v) => handleChange(v)}
      tabValue={tabValue}
    />
  );
};

export default TabFormDemo;

const FormOne: FunctionComponent = () => {
  return <></>;
};
