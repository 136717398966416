import { createSlice } from "@reduxjs/toolkit";
import { IReduxState } from "../../interfaces/IReduxState";
import { IZoneConfig } from "../../../Pages/ZoneConfig/IZoneConfig";

export const ZoneConfigSlice = createSlice({
  name: "ListZoneConfig",
  initialState: {} as IReduxState<IZoneConfig[]>,
  reducers: {
    listZoneConfig: (state, _) => {
      state.isLoading = true;
    },
    successZoneConfig: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
    failureZoneConfig: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
  },
});

export const { failureZoneConfig, listZoneConfig, successZoneConfig } =
  ZoneConfigSlice.actions;

export default ZoneConfigSlice.reducer;
