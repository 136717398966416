import { Password } from "@mui/icons-material";
import dayjs from "dayjs";
import { DateObject } from "react-multi-date-picker";
import { z } from "zod";

const dayjsSchema = z.custom(
  (val) => {
    return dayjs.isDayjs(val) && val.isValid();
  },
  {
    message: "Time Range is Required",
  }
);

const unixTimestampSchema = z.custom(
  (val) => {
    if (val instanceof DateObject) {
      return val;
    }
  },
  { message: "Select Dates" }
);

// Define allowed image MIME types
const allowedMimeTypes = ["image/jpeg", "image/png", "image/gif"];

// Define the maximum file size (e.g., 5MB)
const MAX_FILE_SIZE = 1 * 1024 * 1024; // 5 MB in bytes

export const schema = z.object({
  name: z
    .string({
      required_error: "Name is required",
      invalid_type_error: "Name must be a string",
    })
    .optional(), //not required field
  age: z
    .string({
      required_error: "Age is required",
    })
    .nonempty({
      message: "Age is required",
    }),
  email: z
    .string({
      required_error: "Email is required",
    })
    .email({
      message: "Email is required",
    }),
  password: z
    .string({
      required_error: "Password is required",
    })
    .min(8, { message: "Password must be at least 8 characters long" })
    .regex(/[a-z]/, {
      message: "Password must contain at least one lowercase letter",
    })
    .regex(/[A-Z]/, {
      message: "Password must contain at least one uppercase letter",
    })
    .regex(/\d/, { message: "Password must contain at least one number" })
    .regex(/[^a-zA-Z0-9]/, {
      message: "Password must contain at least one special character",
    }),
  text_area: z.string().nonempty({ message: "Required" }),
  date_of_birth: z.custom(
    (val) => {
      return dayjs.isDayjs(val) && val.isValid();
    },
    {
      message: "Date is Required",
    }
  ),
  time: z.custom(
    (val) => {
      return dayjs.isDayjs(val) && val.isValid();
    },
    {
      message: "Time is Required",
    }
  ),
  time_range_t: z.object(
    {
      startTime: dayjsSchema,
      endTime: dayjsSchema,
    },
    {
      message: "Time Range is required",
    }
  ),
  date_range: z.object(
    {
      startDate: z.date().optional(),
      endDate: z.date().optional(),
    },
    {
      message: "Date Range Required",
    }
  ),
  multiple_date: z.array(unixTimestampSchema, {
    required_error: "Select Dates",
  }),
  select_option: z
    .number({
      required_error: "Select option is required",
    })
    .nullable(),
  file: z
    .instanceof(File)
    .refine((file) => !!file, {
      message: "File is required.",
    })
    .refine((file) => allowedMimeTypes.includes(file.type), {
      message: "Invalid file type. Only JPEG, PNG, and GIF files are allowed.",
    })
    .refine((file) => file.size <= MAX_FILE_SIZE, {
      message: "File size should not exceed 1MB.",
    }),
});
