import "./NotAuthorised.scss";

import { FunctionComponent } from "react";
import { useNavigate } from "react-router-dom";
import ButtonField from "../../Components/Button/ButtonField";

interface NotAuthorisedProps {}

const NotAuthorised: FunctionComponent<NotAuthorisedProps> = () => {
  const nav = useNavigate();
  const handleClick = () => {
    nav(-1);
  };
  return (
    <div className="notAuthorised">
      <h1> Your not authorised for this page</h1>
      <ButtonField
        className="not_authorised_button"
        type="button"
        onClick={handleClick}
      >
        Go back
      </ButtonField>
    </div>
  );
};

export default NotAuthorised;
