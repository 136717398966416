import { FunctionComponent, useRef, useState } from "react";
import { useForm, FormProvider } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { schema } from "./ZodSchemas"; // Import the schema
import dayjs from "dayjs";
import ButtonField from "../Button/ButtonField";
import { TimeT } from "../TimePicker/ITimePicker";
import { ITimeRange } from "../TimeRangePicker/ITimeRangePicker";
import { DateT } from "../DatePicker/IDatePicker";
import { Range, RangeKeyDict } from "react-date-range";
import { AutocompleteOption } from "../Select/ISelectProps";
import TextInputFieldC from "../TextInput/TextInputFieldC";
import NumberInputField from "../NumberInput/NumberInputField";
import DatePickers from "../DatePicker/DatePicker";
import AutoComplete from "../Select/AutoComplete";
import TimePickerC from "../TimePicker/TimePickerC";
import TimeRangePickerC from "../TimeRangePicker/TimeRangePickerC";
import DateRangePickerC from "../DateRangePicker/DateRangePickerC";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import UploadButtonC from "../Button/UploadButtonC";
import TextAreaInputField from "../TextAreaInput/TextAreaInputField";
import MultipleDatePicker from "../MultipleDatePicker/MultipleDatePicker";
import DatePanel from "react-multi-date-picker/plugins/date_panel";
import { DateObject } from "react-multi-date-picker";

interface IFormProps {
  name?: string | null;
  email?: string | null;
  password?: string | null;
  date_of_birth?: DateT;
  time?: TimeT;
  time_range_t?: ITimeRange;
  date_range?: Range;
  select_option?: string | number | undefined;
  file?: File | null;
  multiple_date?: number[] | null;
}

const MyForm: FunctionComponent = () => {
  const [dateRange1, setDateRange1] = useState<Range | undefined>();
  const selectOptions = [
    { label: "Option 1", value: 1 },
    { label: "Option 2", value: 2 },
  ];
  const dateRangeRef = useRef<{ reset: () => void }>();
  const initialValues = {
    name: "",
    age: "10",
    email: "ajith@gmail.com",
    password: "8098@jiW",
    text_area: "Paragraph",
    date_of_birth: dayjs(new Date()),
    time: dayjs(new Date()),
    time_range_t: {
      startTime: dayjs(new Date()),
      endTime: dayjs(new Date()),
    },
    date_range: {
      startDate: new Date(),
      endDate: new Date(),
    },
    select_option: 2,
    file: null,
    multiple_date: null,
  } as IFormProps;
  const defaultRange = {
    startDate: new Date(),
    endDate: new Date(),
    key: "selection",
  } as Range;

  const methods = useForm({
    resolver: zodResolver(schema),
    defaultValues: initialValues,
  });
  const { trigger } = methods;

  const onSubmit = (data: any) => {
    console.log(data);
  };
  const timeRangePickerRefSec = useRef<{ timeRangeReset: () => void }>(null);
  const onFormReset = () => {
    methods.reset({
      time: null,
      date_of_birth: null,
      select_option: undefined,
      file: null,
      multiple_date: null,
      date_range: undefined,
    });
    if (dateRangeRef.current) {
      dateRangeRef.current.reset();
    }
    if (timeRangePickerRefSec.current) {
      timeRangePickerRefSec.current.timeRangeReset();
    }
  };
  // console.log(methods.watch("time_range")?.startTime);
  // console.log(methods.watch());
  console.log(methods.formState.errors);
  // console.log(dateRange1);

  return (
    <FormProvider {...methods}>
      <form
        onSubmit={methods.handleSubmit(onSubmit)}
        style={{ display: "flex", gap: "10px", flexDirection: "column" }}
      >
        <TextInputFieldC
          name="name"
          label="Enter Name"
          variant="filled"
          placeholder="Name"
          type="text"
        />
        <NumberInputField
          name="age"
          label="Age"
          type="number"
          disabledScrollBar={true}
          variant="outlined"
          placeholder="Enter Age"
        />
        <TextInputFieldC name="email" label="Enter Email" type="email" />
        <TextInputFieldC
          name="password"
          label="Enter Password"
          type="password"
        />
        <TextAreaInputField
          name="text_area"
          size="small"
          label="Text Area"
          rows={4}
        />

        <DatePickers
          name="date_of_birth"
          label="Date oF Birth"
          disablePast={true}
        />
        <TimePickerC name="time" label="Choose Time" />
        <TimeRangePickerC
          resetFunctionRef={timeRangePickerRefSec}
          format="hh:mm A"
          name="time_range_t"
          label="Time Range"
          customErrorMessage="Time range is required"
        />
        <DateRangePickerC
          ranges={methods.watch("date_range")}
          resetRef={dateRangeRef}
          setRanges={setDateRange1}
          onChange={(item: RangeKeyDict) => {
            console.log(item.selection);

            setDateRange1(item.selection);
            methods.setValue("date_range", item.selection, {
              shouldValidate: true,
            });
          }}
          name="date_range"
          label="Date Range"
          value={methods.watch("date_range")}
          handleRangeClear={() => methods.setValue("date_range", undefined)}
        />
        <MultipleDatePicker
          name="multiple_date"
          plugins={[<DatePanel />]}
          sort={true}
          format="DD/MM/YYYY"
          showOtherDays={true}
          customErrorMessage="Select Dates"
          placeholder="Choose Date"
        />
        <AutoComplete
          name="select_option"
          label="Select"
          value={
            methods.watch("select_option")
              ? selectOptions.find(
                  (opt) => opt.value === methods.watch("select_option")
                )
              : null
          }
          onChange={async (_, val) => {
            if (val) {
              const selectOpt = val as AutocompleteOption;
              methods.setValue("select_option", selectOpt.value!);
              await trigger("select_option");
            } else {
              methods.setValue("select_option", undefined);
              await trigger("select_option");
            }
          }}
          options={selectOptions}
        />
        {methods.watch("file") ? <p>{methods.watch("file")?.name}</p> : null}
        <UploadButtonC
          variant="contained"
          name="file"
          startIcon={<CloudUploadIcon />}
          fileAccept="image/jpeg,image/png,image/svg+xml,image/jpg"
          onChange={async (event) => {
            if (event.target.files?.length) {
              const file = event.target.files[0];
              methods.setValue("file", file);
              await trigger("file"); // Trigger validation for the file
            }
          }}
          customErrorMessage="Image is required"
        >
          Upload
        </UploadButtonC>
        <ButtonField type="submit">Submit</ButtonField>
        <ButtonField type="reset" onClick={onFormReset}>
          Reset
        </ButtonField>
      </form>
    </FormProvider>
  );
};

export default MyForm;
