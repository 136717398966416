import { jsPDF } from "jspdf";
import { Columns, Rows } from "../Components/Table/ITable";
import "jspdf-autotable";
import { ColumnMeta } from "../Components/Table/ITableRP";
import { dateFormatter } from "./dateFormatter";

declare module "jspdf" {
  interface jsPDF {
    autoTable: (options: any) => jsPDF;
  }
}

export const exportToPDF = (
  columns: ColumnMeta[],
  rows: Rows,
  fileName: string
) => {
  const currentDate = new Date();
  if (rows.length !== 0) {
    // create jspdf object
    const doc = new jsPDF({
      format: "a4",
      orientation: columns.length <= 6 ? "portrait" : "landscape",
    });
    // header and data
    const header = columns.map((col) => col.header);
    const data = rows.map((row) => columns.map((col) => row[col.field]));

    doc.autoTable({
      head: [header],
      body: data,
    });

    doc.save(`${fileName}_${dateFormatter(currentDate, "DD-MM-YYYY")}.pdf`);
  } else {
    alert("No data found");
  }
};
