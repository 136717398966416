import { createSlice } from "@reduxjs/toolkit";
import { IReduxState } from "../../../interfaces/IReduxState";
import { IGeneralConfig } from "../../../../Pages/GeneralConfig/IGeneralConfig";

export const UpdateGeneralConfig = createSlice({
  name: "UpdateGeneralConfig",
  initialState: {} as IReduxState<IGeneralConfig>,
  reducers: {
    updateGeneralConfig(state, _) {
      state.isLoading = true;
    },
    successUpdateGeneralConfig(state, action) {
      state.data = action.payload;
      state.isLoading = false;
    },
    failureUpdateGeneralConfig(state, action) {
      state.isLoading = false;
      state.data = action.payload;
    },
    clearUpdateGeneralConfig(state) {
      state.data = {};
    },
  },
});

export const {
  updateGeneralConfig,
  clearUpdateGeneralConfig,
  failureUpdateGeneralConfig,
  successUpdateGeneralConfig,
} = UpdateGeneralConfig.actions;
export default UpdateGeneralConfig.reducer;
