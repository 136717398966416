import { createSlice } from "@reduxjs/toolkit";
import { IReduxState } from "../../interfaces/IReduxState";
import { IReportsStockDetails } from "../../../Pages/ReportsStockDetails/IReportsStockDetails";

export const ListReportsStockDetails = createSlice({
  name: "ListReportsStockDetails",
  initialState: {} as IReduxState<IReportsStockDetails[]>,
  reducers: {
    listReportsStockDetails: (state, _) => {
      state.isLoading = true;
    },
    responseReportsStockDetails: (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    },
  },
});

export const { listReportsStockDetails, responseReportsStockDetails } =
  ListReportsStockDetails.actions;
export default ListReportsStockDetails.reducer;
