import React, { FunctionComponent, useEffect, useState } from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import ButtonField from "../../Components/Button/ButtonField";
import {
  CircularProgress,
  Divider,
  InputAdornment,
  SvgIcon,
} from "@mui/material";
import TextInputFieldC from "../../Components/TextInput/TextInputFieldC";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { IResePassword } from "./IResetPassword";
import { ReactComponent as Lock } from "../../Assets/Images/lock.svg";
import { ReactComponent as ClosedEye } from "../../Assets/Images/closedEye.svg";
import IconButtonField from "../../Components/Button/IconButtonField";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useAppDispatch, useAppSelector } from "../../Redux/Store/Hooks";
import {
  resetPasswordStart,
  clearResetPasswordStatus,
} from "../../Redux/Slices/ResetPassword/ResetPassword";
import Snackbar from "../../Components/Snackbar/Snackbar";
import { useLocation, useNavigate } from "react-router-dom";

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const ResetPassword: FunctionComponent = () => {
  const [show1, setShow1] = useState<boolean>(false);
  const [show2, setShow2] = useState<boolean>(false);
  const [email, setEmail] = useState<string>("");
  const dispatch = useAppDispatch();
  const nav = useNavigate();
  const resetPasswordResponse = useAppSelector((state) => state.ResetPassword);
  console.log(resetPasswordResponse);

  const query = useQuery();
  const Getemail = query.get("email");
  // console.log(Getemail);

  const passwordSchema = z
    .object({
      create_password: z
        .string({
          required_error: "Password is required",
        })
        .min(8, { message: "Password must be at least 8 characters long" }),
      confirm_password: z.string({
        required_error: "Password is required",
      }),
    })
    .refine((data) => data.create_password === data.confirm_password, {
      path: ["confirm_password"],
      message: "Passwords must match",
    });
  const methods = useForm<IResePassword>({
    defaultValues: {
      create_password: null,
      confirm_password: null,
    },
    resolver: zodResolver(passwordSchema),
  });
  const onSubmit: SubmitHandler<IResePassword> = (val) => {
    console.log(val);
    if (val.confirm_password) {
      const resetPassData = {
        email: Getemail,
        password: val.confirm_password,
      };
      dispatch(resetPasswordStart(resetPassData));
    }
  };
  useEffect(() => {
    const getEmail = localStorage.getItem("email");
    console.log(getEmail);

    if (getEmail) {
      setEmail(getEmail);
    }
  }, []);
  useEffect(() => {
    if (resetPasswordResponse.data?.status) {
      setTimeout(() => {
        nav("/login");
      }, 2000);
    }
  }, [resetPasswordResponse]);

  useEffect(() => {
    return () => {
      dispatch(clearResetPasswordStatus());
    };
  }, []);

  return (
    <div className="backGround">
      <Snackbar response={resetPasswordResponse.data} />
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <div className="main">
            <div>
              <div className="heading">Reset Password</div>
              <Divider />
            </div>

            <div className="textFields">
              <TextInputFieldC
                className="textField"
                name="create_password"
                placeholder="Password"
                type={show1 ? "text" : "password"}
                variant="outlined"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SvgIcon
                        sx={{
                          width: "20px",
                          height: "20px",
                        }}
                        component={Lock}
                        inheritViewBox
                      />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <IconButtonField onClick={() => setShow1(!show1)}>
                      <SvgIcon
                        sx={{
                          width: "20px",
                          height: "20px",
                        }}
                        component={show1 ? VisibilityIcon : ClosedEye}
                        inheritViewBox
                      />
                    </IconButtonField>
                  ),
                }}
              />
              <TextInputFieldC
                className="textField"
                name="confirm_password"
                placeholder="Password"
                type={show2 ? "text" : "password"}
                variant="outlined"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SvgIcon
                        sx={{
                          width: "20px",
                          height: "20px",
                        }}
                        component={Lock}
                        inheritViewBox
                      />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <IconButtonField onClick={() => setShow2(!show2)}>
                      <SvgIcon
                        sx={{
                          width: "20px",
                          height: "20px",
                        }}
                        component={show2 ? VisibilityIcon : ClosedEye}
                        inheritViewBox
                      />
                    </IconButtonField>
                  ),
                }}
              />
            </div>
            <div className="textFields">
              <ButtonField
                disabled={resetPasswordResponse.isLoading}
                className="buttonfield"
                type="submit"
              >
                Submit
                {resetPasswordResponse.isLoading && (
                  <CircularProgress
                    size={24}
                    sx={{
                      color: "white",
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      marginTop: "-12px",
                      marginLeft: "-12px",
                    }}
                  />
                )}
              </ButtonField>
            </div>
          </div>
        </form>
      </FormProvider>
    </div>
  );
};

export default ResetPassword;
