import { FunctionComponent, memo, useEffect } from "react";
import "./ZoneConfig.scss";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { IZoneConfig } from "./IZoneConfig";
import { useAppDispatch, useAppSelector } from "../../Redux/Store/Hooks";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import TextInputFieldC from "../../Components/TextInput/TextInputFieldC";
import Select from "../../Components/Select/AutoComplete";
import { AutocompleteOption } from "../../Components/Select/ISelectProps";
import NumberInputField from "../../Components/NumberInput/NumberInputField";
import { addZoneConfig } from "../../Redux/Slices/ZoneConfig/AddZoneConfigSlice";
import { editZoneConfig } from "../../Redux/Slices/ZoneConfig/EditZoneConfigSlice";
import { listLocationMaster } from "../../Redux/Slices/LocationMaster/ListLocationMasterSlice";

interface ZoneConfigFormProps {
  formRef: React.RefObject<HTMLFormElement>;
  initialValues: IZoneConfig | {};
  setInitialValues: React.Dispatch<React.SetStateAction<IZoneConfig | {}>>;
}

const ZoneConfigForm: FunctionComponent<ZoneConfigFormProps> = (props) => {
  const dispatch = useAppDispatch();
  const zoneConfigSchema = z.object({
    id: z.number().optional(),
    name: z
      .string({ message: "Please enter Name" })
      .min(1, { message: "Please enter Name" }),
    location_id: z.number({ message: "Please select Location" }),
    code: z
      .string({ message: "Please enter Code" })
      .min(1, { message: "Please enter Code" }),
    row_prefix: z
      .string({ message: "Please enter Row Prefix" })
      .min(1, { message: "Please enter Row Prefix" }),
    row_len: z.number({ message: "Please enter Row Count" }).max(20, {
      message:
        "Maximum limite allowed Row count exceeds the maximum limit of 20.",
    }),
    col_prefix: z
      .string({ message: "Please enter Column Prefix" })
      .min(1, { message: "Please enter Column Prefix" }),
    col_len: z
      .number({ message: "Please enter Column Count" })
      .max(100, {
        message:
          "Maximum limite allowed Column count exceeds the maximum limit of 100.",
      }),
    pallete_prefix: z
      .string({ message: "Please enter Pallete Prefix" })
      .min(1, { message: "Please enter Pallete Prefix" }),
    pallete_len: z
      .preprocess(
        (val) => (val === "" ? undefined : Number(val)),
        z
          .number({
            required_error: "Please enter Pallet Count",
            invalid_type_error: "Pallet Count must be a number",
          })
          .min(0, { message: "Please enter a valid Pallet Count" })
          .max(10, {
            message:
              "Maximum limite allowed Pallet count exceeds the maximum limit of 10.",
          })
      )
      .optional(),
  });
  const methods = useForm<IZoneConfig>({
    defaultValues: props.initialValues,
    resolver: zodResolver(zoneConfigSchema),
  });

  const onSubmit: SubmitHandler<IZoneConfig> = (val) => {
    console.log(val);
    if (val.id) {
      dispatch(editZoneConfig(val));
    } else {
      dispatch(addZoneConfig(val));
    }
  };

  useEffect(() => {
    const LocationId = Number(localStorage.getItem("location_id"));
    if (LocationId) {
      methods.setValue("location_id", LocationId, {
        shouldValidate: true,
      });
    }
    dispatch(listLocationMaster());
    return () => {
      props.setInitialValues({});
    };
  }, [dispatch, props]);

  const { data: locationsList } = useAppSelector(
    (state) => state.ListLocationMaster
  );

  const locations = Array.isArray(locationsList?.data)
    ? locationsList?.data.map((item) => ({ label: item.name!, value: item.id }))
    : [];
  console.log(methods.formState.errors);

  return (
    <FormProvider {...methods}>
      <form
        ref={props.formRef}
        onSubmit={methods.handleSubmit(onSubmit)}
        className="form_double_input"
      >
        <TextInputFieldC name="name" label="Name" type="text" />

        <Select
          name="location_id"
          label="Location"
          options={locations}
          value={
            locations?.find(
              (location) => location.value === methods.getValues("location_id")
            ) ?? null
          }
          onChange={(_, value) => {
            const val = value as AutocompleteOption;
            console.log(val);

            methods.setValue("location_id", val?.value, {
              shouldValidate: true,
            });
          }}
        />

        <TextInputFieldC name="code" label="Code" type="text" />

        <TextInputFieldC name="row_prefix" label="Row Prefix" type="text" />

        <NumberInputField name="row_len" label="Row Count" type="number" />

        <TextInputFieldC name="col_prefix" label="Column Prefix" type="text" />

        <NumberInputField name="col_len" label="Column Count" type="number" />

        <TextInputFieldC
          name="pallete_prefix"
          label="Pallete Prefix"
          type="text"
        />

        <NumberInputField
          name="pallete_len"
          label="Pallete Count"
          type="number"
        />
      </form>
    </FormProvider>
  );
};

export default memo(ZoneConfigForm);
