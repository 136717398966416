import { PayloadAction } from "@reduxjs/toolkit";
import api from "../../Axios/middleware";
import { AxiosResponse } from "axios";
import { call, put } from "redux-saga/effects";

import {
  successUserPermissionRows,
  failureUserPermissionRows,
} from "./UserPermissionRowsSlicer";
import {
  failureeditUserPermission,
  successeditUserPermission,
} from "./UpdateUserPermissionSlicer";
import { IUpdateUserPermission } from "./IUserPermission";

// get user permission
const EditUserPermission = (action: PayloadAction<IUpdateUserPermission>) => {
  return api.post("/menu-permission/create", action.payload);
};

export function* EditUserPermissionCall(
  action: PayloadAction<IUpdateUserPermission>
) {
  try {
    const response: AxiosResponse = yield call(EditUserPermission, action);
    yield put(successeditUserPermission(response.data));
  } catch (error) {
    yield put(failureeditUserPermission(error));
  }
}

// get User permission rows
const UserPermissionRowsApi = (action?: PayloadAction<number>) => {
  const id = action?.payload;
  return api.get(`/menu-permission/role_id`, {
    params: {
      ...(id ? { id: id } : {}),
    },
  });
};

export function* ListUserPermissionRowsCall(action: PayloadAction<number>) {
  try {
    const response: AxiosResponse = yield call(UserPermissionRowsApi, action);
    yield put(successUserPermissionRows(response.data));
  } catch (error) {
    yield put(failureUserPermissionRows(error));
  }
}
