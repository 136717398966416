import * as XLSX from "xlsx";
import { Columns, Rows } from "../Components/Table/ITable";
import { ColumnMeta } from "../Components/Table/ITableRP";
import { dateFormatter } from "./dateFormatter";

export const exportToExcel = (
  columns: ColumnMeta[],
  rows: Rows,
  fileName: string
) => {
  const currentDate = new Date();
  if (rows.length !== 0) {
    // file type, name, extension
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";

    const columnHeaders = [
      "S.No",
      ...columns
        .filter((column) => column.header !== "S.No")
        .map((column) => column.header),
    ];
    const fields = Object.keys(rows[0]);
    const sheetData = rows.map((row) =>
      fields.map((field) => (row[field] ? row[field] : ""))
    );
    sheetData.unshift(columnHeaders);
    console.log(columnHeaders);
    console.log(fields);
    console.log(sheetData);

    // create data to excel format
    const ws = XLSX.utils.json_to_sheet(sheetData, { skipHeader: true });
    // create workbook
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    // convert workbook to binary excel buffer
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    // create blob object
    const data = new Blob([excelBuffer], { type: fileType });

    // Create download link
    const url = window.URL.createObjectURL(data);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute(
      "download",
      `${fileName}_${dateFormatter(currentDate, "DD-MM-YYYY")}` + fileExtension
    );
    // Append link to body and trigger click event
    document.body.appendChild(link);
    link.click();
  } else {
    alert("No data found");
  }
};
