import { FunctionComponent, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../Redux/Store/Hooks";
import { listLocationReceive } from "../../Redux/Slices/LocationReceive/ListLocationReceiveSlice";
import { ColumnMeta } from "../../Components/Table/ITableRP";
import { AutocompleteOption } from "../../Components/Select/ISelectProps";
import TableRP from "../../Components/Table/TableRP";
import { listLocationMaster } from "../../Redux/Slices/LocationMaster/ListLocationMasterSlice";
import Select from "../../Components/withoutControllerComponents/Select/Select";
import { statusOptionsFD } from "../StockRecieve/IStockRecieve";
import { dateFormatter } from "../../Utils/dateFormatter";
import Dialog from "../../Components/Dialog/Dialog";
import {
  ILocationReceive,
  ILocationReceiveItems,
} from "../LocationReceive/ILocationReceive";
import IconButtonField from "../../Components/Button/IconButtonField";
import { FormControlLabel, Radio, RadioGroup, SvgIcon } from "@mui/material";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import LocationReceiveView from "../LocationReceive/LocationReceiveView";
import DateRangePicker from "../../Components/DateRangePicker/DateRangePicker";
import { Range, RangeKeyDict } from "react-date-range";
import { addTimeToDate } from "../../Utils/AddTimeToDate";

const ReportLocationreceive: FunctionComponent = () => {
  const dispatch = useAppDispatch();
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [dateRange, setDateRange] = useState<Range | undefined>();
  const [locationFilterId, SetLocationFilterId] = useState<number>();
  const [detailedReport, setDetailedReport] = useState<boolean>(false);

  const [viewInitialValues, setViewInitialValues] = useState<
    ILocationReceiveItems[]
  >([]);
  const { data: ListLocationReceiveReport } = useAppSelector(
    (state) => state.ListLocationReceive
  );
  const locationReportList = ListLocationReceiveReport?.data?.map(
    (stock, index) => ({
      index: index + 1,
      entry_no: stock.entry_no,
      entry_date: dateFormatter(stock.entry_date),
      receiveFrom:
        stock.receivefrom?.name?.length! > 1 ? stock.receivefrom?.name : "",
      total_items: stock.item_details?.length,
      status: statusOptionsFD.find((status) => status.value === stock.status)
        ?.label,
      // receiveto:
      // stock?.receiveto?.name?.length! > 1 ? stock.receiveto?.name : "",
    })
  );

  console.log(ListLocationReceiveReport);

  const DeatiledReport = ListLocationReceiveReport?.data?.flatMap((stock) => {
    const itemsDetails = stock.item_details?.flatMap((items) => ({
      entry_no: stock.entry_no,
      entry_date: dateFormatter(stock.entry_date),
      receiveFrom:
        stock.receivefrom?.name?.length! > 1 ? stock.receivefrom?.name : "",
      item_name: items.item_name,
      item_code: items.item_code,
      item_qnty: items.item_qnty,
      status: statusOptionsFD.find((status) => status.value === stock.status)
        ?.label,
    }));
    return itemsDetails;
  });
  const DetailedReportList = DeatiledReport?.map((stocks, i) => ({
    index: i + 1,
    ...stocks,
  }));
  const [value, setValue] = useState("summary");

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue((event.target as HTMLInputElement).value);
    setDetailedReport(
      (event.target as HTMLInputElement).value === "detail" ? true : false
    );
  };

  const { data: location, isLoading: locationLoading } = useAppSelector(
    (state) => state.ListLocationMaster
  );
  const columns: ColumnMeta[] = detailedReport
    ? [
        {
          field: "index",
          header: "S.No",
          align: "center",
          alignHeader: "center",
        },
        {
          field: "entry_no",
          header: "Entry No",
          align: "left",
          alignHeader: "left",
        },
        {
          field: "entry_date",
          header: "Entry Date",
          align: "left",
          alignHeader: "left",
        },
        {
          field: "receiveFrom",
          header: "Receive From",
          align: "left",
          alignHeader: "left",
        },
        {
          field: "item_name",
          header: "Item Name",
          align: "left",
          alignHeader: "left",
        },
        {
          field: "item_code",
          header: "Item Code",
          align: "left",
          alignHeader: "left",
        },
        {
          field: "item_qnty",
          header: "Item Quantity",
          align: "right",
          alignHeader: "right",
        },
        {
          field: "status",
          header: "Status",
          align: "left",
          alignHeader: "left",
        },
      ]
    : [
        {
          field: "index",
          header: "S.No",
          align: "center",
          alignHeader: "center",
        },
        {
          field: "entry_no",
          header: "Entry No",
          align: "left",
          alignHeader: "left",
        },
        {
          field: "entry_date",
          header: "Entry Date",
          align: "left",
          alignHeader: "left",
        },
        {
          field: "receiveFrom",
          header: "Receive From",
          align: "left",
          alignHeader: "left",
        },
        {
          field: "total_items",
          header: "Total Items",
          align: "right",
          alignHeader: "right",
        },
        {
          field: "status",
          header: "Status",
          align: "left",
          alignHeader: "left",
        },
      ];
  const locationList = location?.data?.map((location) => ({
    label: location.name!,
    value: location.id,
  }));

  useEffect(() => {
    dispatch(listLocationReceive({ key: "SRE" }));
    dispatch(listLocationMaster());
  }, [dispatch]);
  return (
    <>
      <TableRP
        columns={columns}
        size="small"
        rows={
          detailedReport ? DetailedReportList ?? [] : locationReportList ?? []
        }
        CSVExport
        ExcelExport
        PdfExport
        ExportName="location_receive_report"
        headerStartComponent={
          <div className="report_start_component">
            <fieldset className="report_fieldset">
              <legend className="reports_label">Report Type</legend>
              <RadioGroup
                name="controlled-radio-buttons-group"
                value={value}
                onChange={handleChange}
                sx={{
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <FormControlLabel
                  value="summary"
                  control={<Radio size="small" />}
                  label="Summary"
                />
                <FormControlLabel
                  value="detail"
                  control={<Radio size="small" />}
                  label="Detail"
                />
              </RadioGroup>
            </fieldset>
            <div className="report_filters_container">
              <DateRangePicker
                setRanges={setDateRange}
                showMonthAndYearPickers={false}
                size="small"
                placeholder="Choose Report Period"
                ranges={dateRange}
                direction="horizontal"
                handleOkBtn={() =>
                  dispatch(
                    listLocationReceive({
                      key: "SRE",
                      startDate: addTimeToDate(dateRange?.startDate!, 5, 30),
                      endDate: addTimeToDate(dateRange?.endDate!, 5, 30),
                      from: locationFilterId && locationFilterId,
                    })
                  )
                }
                onChange={(item: RangeKeyDict) => {
                  setDateRange(item.selection);
                }}
                handleRangeClear={() =>
                  dispatch(
                    listLocationReceive({
                      key: "SRE",
                      from: locationFilterId && locationFilterId,
                    })
                  )
                }
              />
              <Select
                size="small"
                placeholder="Receive From"
                options={locationList ?? []}
                loading={locationLoading}
                onChange={(_, val) => {
                  if (val) {
                    const value = val as AutocompleteOption;
                    SetLocationFilterId(value.value);
                    dispatch(
                      listLocationReceive({
                        from: value.value,
                        key: "SRE",
                        startDate:
                          dateRange?.startDate &&
                          addTimeToDate(dateRange?.startDate!, 5, 30),
                        endDate:
                          dateRange?.endDate &&
                          addTimeToDate(dateRange?.endDate!, 5, 30),
                      })
                    );
                  } else {
                    SetLocationFilterId(undefined);
                    dispatch(
                      listLocationReceive({
                        key: "SRE",
                        startDate:
                          dateRange?.startDate &&
                          addTimeToDate(dateRange?.startDate!, 5, 30),
                        endDate:
                          dateRange?.endDate &&
                          addTimeToDate(dateRange?.endDate!, 5, 30),
                      })
                    );
                  }
                }}
              />
            </div>
          </div>
        }
        viewRowData={(data: ILocationReceive) => {
          console.log(data);

          const itemDetails = data.item_details?.map((items, index) => ({
            ...items,
            index: index + 1,
          }));
          setDialogOpen(true);
          setViewInitialValues(itemDetails ?? []);
        }}
        headerWithSearch
        headerTitle="Location Receive Report"
      />
      <Dialog
        open={dialogOpen}
        dialogClassName="dialog_double_input"
        Dialog_Title={
          <>
            Items
            <IconButtonField
              onClick={() => {
                setDialogOpen(false);
              }}
            >
              <SvgIcon
                component={CloseOutlinedIcon}
                inheritViewBox
                sx={{ height: "20px", width: "20px" }}
              />
            </IconButtonField>
          </>
        }
        Dialog_Content={
          <>
            <LocationReceiveView tableRows={viewInitialValues} />
          </>
        }
      />
    </>
  );
};

export default ReportLocationreceive;
