import { createBrowserRouter } from "react-router-dom";
import ErrorPage from "../Pages/ErrorPage/ErrorPage";
import App from "../App";

import PrivateRoute from "./PrivateRoute";
import Login from "../Pages/Login/Login";
import NotAuthorised from "../Pages/NotAuthorised/NotAuthorised";
import UserPermissions from "../Pages/UserPermission/UserPermissions";
import Dashboard from "../Pages/Dashboard/Dashboard";
import Setting from "../Pages/Settings/Setting";
import ForgotPassword from "../Pages/ForgotPassword/ForgotPassword";
import Product from "../Pages/Product/Product";
import TestArea from "../Pages/Demo/TestArea";
import UserMaster from "../Pages/UserMaster/UserMaster";
import StorageArea from "../Pages/StorageArea/StorageArea";
import ZoneConfig from "../Pages/ZoneConfig/ZoneConfig";
import LocationMaster from "../Pages/LocationMaster/LocationMaster";
import DevisionMaster from "../Pages/DivisionMaster/DivisionMaster";
import ItemMaster from "../Pages/ItemMaster/ItemMaster";
import ResetPassword from "../Pages/ResetPassword/ResetPassword";
import StockRecieve from "../Pages/StockRecieve/StockRecieve";
import StockRecieveForm from "../Pages/StockRecieve/StockRecieveForm";
import TransactionDetails from "../Pages/TransactionDetails/TransactionDetails";
import TransactionReceive from "../Pages/TransactionRequest/TransactionRequest";
import TransactionReceiveForm from "../Pages/TransactionRequest/TransactionRequestForm";
import StockTransfer from "../Pages/StockTransfer/StockTransfer";
import StockTransferForm from "../Pages/StockTransfer/StockTransferForm";
import StockRecieveEdit from "../Pages/StockRecieve/StockRecieveEdit";
import ReportsStockReceive from "../Pages/ReportsStockReceive/ReportsStockReceive";
import ReportsStockRequest from "../Pages/ReportsStockRequest/ReportsStockRequest";
import ReportsStockTransfer from "../Pages/ReportsStockTransfer/ReportsStockTransfer";
import ReportsStockDetails from "../Pages/ReportsStockDetails/ReportsStockDetails";
import GeneralConfig from "../Pages/GeneralConfig/GeneralConfig";
import SequenceTable from "../Pages/Sequence/SequenceTable";
import ColorCode from "../Pages/ColorCode/ColorCode";
import LocationReceive from "../Pages/LocationReceive/LocationReceive";
import LocationReceiveForm from "../Pages/LocationReceive/LocationReceiveForm";
import ReportLocationreceive from "../Pages/ReportLocationReceive/ReportLocationreceive";
import ManualStockAdjustment from "../Pages/StockAdjustment/StockAdjustment";
import ManualStockAdjustmentForm from "../Pages/StockAdjustment/StockAdjustmentForm";
import StockAdjustmentForm from "../Pages/StockAdjustment/StockAdjustmentForm";
import StockAdjustment from "../Pages/StockAdjustment/StockAdjustment";
import ReportStockAdjustment from "../Pages/ReportStockAdjustment/ReportStockAdjustment";
import StorageAreaDashboard from "../Pages/StorageAreaDashboard/StorageAreaDashboard";

export const router = createBrowserRouter([
  { path: "/login", element: <Login /> },
  { path: "/forgotPassword", element: <ForgotPassword /> },
  { path: "/resetPassword", element: <ResetPassword /> },

  {
    path: "/",
    errorElement: <ErrorPage />,
    // loader:,
    element: <PrivateRoute element={<App />} />,
    children: [
      {
        path: "dashboard",
        element: <Dashboard />,
      },
      {
        path: "masters",
        children: [
          {
            path: "division",
            element: <DevisionMaster />,
          },
          {
            path: "location",
            element: <LocationMaster />,
          },
          {
            path: "zone",
            children: [
              {
                index: true,
                element: <ZoneConfig />,
              },
              {
                path: "storage-area",
                element: <StorageArea />,
              },
            ],
          },
          {
            path: "items",
            element: <ItemMaster />,
          },
        ],
      },
      // {
      //   path: "stocks",
      //   children: [
      //     {
      //       path: "stock-receive",
      //       children: [
      //         {
      //           index: true,
      //           element: <StockRecieve />,
      //         },
      //         {
      //           path: "add",
      //           element: <StockRecieveForm />,
      //         },
      //         {
      //           path: "edit",
      //           element: <StockRecieveEdit />,
      //         },
      //       ],
      //     },
      //     {
      //       path: "stock-details",
      //       element: <StockDetails />,
      //     },
      //   ],
      // },
      {
        path: "transaction",
        children: [
          {
            path: "details",
            element: <TransactionDetails />,
          },
          {
            path: "request",
            children: [
              {
                index: true,
                element: <TransactionReceive />,
              },
              {
                path: "add",
                element: <TransactionReceiveForm />,
              },
              {
                path: "edit",
                element: <TransactionReceiveForm />,
              },
              {
                path: "stock-transfer",
                children: [
                  {
                    index: true,
                    element: <StockTransfer />,
                  },
                  {
                    path: "add",
                    element: <StockTransferForm />,
                  },
                  {
                    path: "edit",
                    element: <StockTransferForm />,
                  },
                ],
              },
            ],
          },
          {
            path: "transfer",
            children: [
              {
                index: true,
                element: <StockTransfer />,
              },
              {
                path: "add",
                element: <StockTransferForm />,
              },
              {
                path: "edit",
                element: <StockTransferForm />,
              },
            ],
          },

          {
            path: "stock-receive",
            children: [
              {
                index: true,
                element: <StockRecieve />,
              },
              {
                path: "add",
                element: <StockRecieveForm />,
              },
              {
                path: "edit",
                element: <StockRecieveForm />,
              },
            ],
          },
          {
            path: "location-receive",
            children: [
              {
                index: true,
                element: <LocationReceive />,
              },
              {
                path: "edit",
                element: <LocationReceiveForm />,
              },
            ],
          },
          {
            path: "stock-adjustment",
            children: [
              {
                index: true,
                element: <StockAdjustment />,
              },
              {
                path: "add",
                element: <StockAdjustmentForm />,
              },
            ],
          },
        ],
      },
      {
        path: "reports",
        children: [
          {
            path: "storage-area-dashboard",
            element: <StorageAreaDashboard />,
          },
          {
            path: "stock-receive",
            element: <ReportsStockReceive />,
          },
          {
            path: "stock-request",
            element: <ReportsStockRequest />,
          },
          {
            path: "stock-transfer",
            element: <ReportsStockTransfer />,
          },
          {
            path: "stock-adjustment-report",
            element: <ReportStockAdjustment />,
          },
          {
            path: "location-receive",
            element: <ReportLocationreceive />,
          },
          {
            path: "stock-details",
            element: <ReportsStockDetails />,
          },
        ],
      },
      {
        path: "settings",
        children: [
          {
            path: "userPermission",
            element: <UserPermissions />,
          },
          {
            path: "users",
            element: <UserMaster />,
          },
          {
            path: "general-config",
            element: <GeneralConfig />,
          },
          {
            path: "sequence-config",
            element: <SequenceTable />,
          },
          {
            path: "color-code",
            element: <ColorCode />,
          },
        ],
      },
      {
        path: "product",
        children: [
          {
            path: "list",
            children: [
              {
                index: true,
                element: <Product />,
              },
            ],
          },
          {
            path: "fun",
            element: <TestArea />,
          },
        ],
      },
      {
        path: "NotAuthorised",
        element: <NotAuthorised />,
      },
      {
        path: "setting",
        element: <Setting />,
      },
    ],
  },
]);
