import { IMenuItem } from "./ISidnavbar";
import { IconButton, SvgIcon, Typography } from "@mui/material";
import { ReactComponent as Dashboard } from "../../Assets/Images/Dashboard.svg";
import { ReactComponent as Zone } from "../../Assets/Images/Zone.svg";
import { ReactComponent as Master } from "../../Assets/Images/Master.svg";
import { ReactComponent as Location } from "../../Assets/Images/Location.svg";
import { ReactComponent as Items } from "../../Assets/Images/Items.svg";
import { ReactComponent as Division } from "../../Assets/Images/Division.svg";
import { ReactComponent as Transaction } from "../../Assets/Images/Transaction.svg";
import { ReactComponent as StockReceive } from "../../Assets/Images/StockReceive.svg";
import { ReactComponent as StockRequest } from "../../Assets/Images/StockRequest.svg";
import { ReactComponent as StockTransfer } from "../../Assets/Images/StockTransfer.svg";
import { ReactComponent as LocationReceive } from "../../Assets/Images/LocationReceive.svg";
import { ReactComponent as Settings } from "../../Assets/Images/Settings.svg";
import { ReactComponent as UserPermission } from "../../Assets/Images/UserPermission.svg";
import { ReactComponent as UserMaster } from "../../Assets/Images/UserMaster.svg";
import { ReactComponent as GeneralConfig } from "../../Assets/Images/GeneralConfig.svg";
import { ReactComponent as SequenceConfig } from "../../Assets/Images/SequenceConfig.svg";
import { ReactComponent as ColorCode } from "../../Assets/Images/ColorCode.svg";
import { ReactComponent as Reports } from "../../Assets/Images/Reports.svg";
import { ReactComponent as StockDetails } from "../../Assets/Images/StockDetails.svg";
import { ReactComponent as StockAdjustment } from "../../Assets/Images/StockAdjustment.svg";
import Avatars from "../Avatar/Avatars";
import CloseIcon from "@mui/icons-material/Close";

// managment

/*    dummy menu     */
export const menu: { [key: string]: IMenuItem[] } = {
  Dashboard: [
    {
      icon: <SvgIcon component={Dashboard} inheritViewBox />,
      title: "Dashboard",
      to: "dashboard",
      permissions: {
        add: true,
        view: true,
        show: true,
        edit: true,
        delete: true,
      },
    },
    {
      icon: <SvgIcon component={Master} inheritViewBox />,
      title: "Masters",
      to: "masters",
      permissions: {
        add: true,
        view: true,
        show: true,
        edit: true,
        delete: true,
      },
      items: [
        {
          icon: <SvgIcon component={Division} inheritViewBox />,
          title: "Division",
          to: "masters/division",
          permissions: {
            add: true,
            view: true,
            show: true,
            edit: false,
            delete: true,
          },
        },
        {
          icon: <SvgIcon component={Location} inheritViewBox />,
          title: "Location",
          to: "masters/location",
          permissions: {
            add: false,
            view: true,
            show: true,
            edit: true,
            delete: true,
          },
        },
        {
          icon: <SvgIcon component={Zone} inheritViewBox />,
          title: "Zone",
          to: "masters/zone",
          permissions: {
            add: true,
            view: true,
            show: true,
            edit: true,
            delete: true,
          },
        },
        {
          icon: <SvgIcon component={Dashboard} inheritViewBox />,
          title: "Storage Area",
          to: "masters/zone/storage-area",
          permissions: {
            add: true,
            view: true,
            edit: true,
            delete: true,
            show: false,
          },
        },
        {
          icon: <SvgIcon component={Items} inheritViewBox />,
          title: "Items",
          to: "masters/items",
          permissions: {
            add: true,
            view: true,
            show: true,
            edit: true,
            delete: true,
          },
        },
      ],
    },
    {
      icon: <SvgIcon component={Dashboard} inheritViewBox />,
      title: "Stocks",
      to: "stocks",
      permissions: {
        add: true,
        delete: true,
        edit: true,
        view: true,
        show: true,
      },
      items: [
        {
          icon: <SvgIcon component={Dashboard} inheritViewBox />,
          title: "Stock Receive",
          to: "stocks/stock-receive",
          permissions: {
            add: true,
            view: true,
            show: true,
            edit: true,
            delete: true,
          },
        },
        {
          icon: <SvgIcon component={Dashboard} inheritViewBox />,
          title: "Stock Receive Details",
          to: "stocks/stock-receive/add",
          permissions: {
            add: true,
            view: true,
            show: false,
            edit: true,
            delete: true,
          },
        },
        {
          icon: <SvgIcon component={Dashboard} inheritViewBox />,
          title: "Stock Receive Details",
          to: "stocks/stock-receive/edit",
          permissions: {
            add: true,
            view: true,
            show: false,
            edit: true,
            delete: true,
          },
        },
        {
          icon: <SvgIcon component={Dashboard} inheritViewBox />,
          title: "Stock Details",
          to: "stocks/stock-details",
          permissions: {
            add: true,
            view: false,
            show: true,
            edit: true,
            delete: true,
          },
        },
      ],
    },
    {
      icon: <SvgIcon component={Transaction} inheritViewBox />,
      title: "Transaction",
      to: "transaction",
      permissions: {
        add: true,
        view: true,
        show: true,
        edit: true,
        delete: true,
      },
      items: [
        {
          icon: <SvgIcon component={StockReceive} inheritViewBox />,
          title: "Stock Receive",
          to: "transaction/stock-receive",
          permissions: {
            add: true,
            view: true,
            show: true,
            edit: true,
            delete: true,
          },
        },
        {
          icon: <SvgIcon component={StockRequest} inheritViewBox />,
          title: "Stock Receive Details",
          to: "transaction/stock-receive/add",
          permissions: {
            add: true,
            view: true,
            show: false,
            edit: true,
            delete: true,
          },
        },
        {
          icon: <SvgIcon component={StockTransfer} inheritViewBox />,
          title: "Stock Receive Details",
          to: "transaction/stock-receive/edit",
          permissions: {
            add: true,
            view: true,
            show: false,
            edit: true,
            delete: true,
          },
        },

        {
          icon: <SvgIcon component={StockRequest} inheritViewBox />,
          title: "Stock Request",
          to: "transaction/request",
          permissions: {
            add: true,
            view: true,
            show: true,
            edit: true,
            delete: true,
          },
        },
        {
          icon: <SvgIcon component={Dashboard} inheritViewBox />,
          title: "Transaction Requiest Add",
          to: "transaction/request/add",
          permissions: {
            add: true,
            view: true,
            show: false,
            edit: true,
            delete: true,
          },
        },
        {
          icon: <SvgIcon component={Dashboard} inheritViewBox />,
          title: "Transaction Requiest Edit",
          to: "transaction/request/edit",
          permissions: {
            add: true,
            view: true,
            show: false,
            edit: true,
            delete: true,
          },
        },
        {
          icon: <SvgIcon component={StockTransfer} inheritViewBox />,
          title: "Request Stock Transfer",
          to: "transaction/request/stock-transfer",
          permissions: {
            add: true,
            view: true,
            show: false,
            edit: true,
            delete: true,
          },
        },
        {
          icon: <SvgIcon component={Dashboard} inheritViewBox />,
          title: "Stock Transfer Details",
          to: "transaction/request/stock-transfer/add",
          permissions: {
            add: true,
            view: true,
            show: false,
            edit: true,
            delete: true,
          },
        },
        {
          icon: <SvgIcon component={Dashboard} inheritViewBox />,
          title: "Stock Transfer Details",
          to: "transaction/request/stock-transfer/edit",
          permissions: {
            add: true,
            view: true,
            show: false,
            edit: true,
            delete: true,
          },
        },
        {
          icon: <SvgIcon component={StockTransfer} inheritViewBox />,
          title: "Stock Transfer",
          to: "transaction/transfer",
          permissions: {
            add: true,
            view: true,
            show: true,
            edit: true,
            delete: true,
          },
        },
        {
          icon: <SvgIcon component={Dashboard} inheritViewBox />,
          title: "Stock Transfer Add",
          to: "transaction/transfer/add",
          permissions: {
            add: true,
            view: true,
            show: false,
            edit: true,
            delete: true,
          },
        },
        {
          icon: <SvgIcon component={Dashboard} inheritViewBox />,
          title: "Stock Transfer Edit",
          to: "transaction/transfer/edit",
          permissions: {
            add: true,
            view: true,
            show: false,
            edit: true,
            delete: true,
          },
        },
        {
          icon: <SvgIcon component={Dashboard} inheritViewBox />,
          title: "Transaction Receive Add",
          to: "transaction/receive/add",
          permissions: {
            add: true,
            view: true,
            show: false,
            edit: true,
            delete: true,
          },
        },
        {
          icon: <SvgIcon component={LocationReceive} inheritViewBox />,
          title: "Location Receive",
          to: "transaction/location-receive",
          permissions: {
            add: true,
            view: true,
            show: true,
            edit: true,
            delete: true,
          },
        },
        {
          icon: <SvgIcon component={Dashboard} inheritViewBox />,
          title: "Location Receive Edit",
          to: "transaction/location-receive/edit",
          permissions: {
            add: true,
            view: true,
            show: false,
            edit: true,
            delete: true,
          },
        },
        {
          icon: <SvgIcon component={StockAdjustment} inheritViewBox />,
          title: "Stock Adjustment",
          to: "transaction/stock-adjustment",
          permissions: {
            add: true,
            view: true,
            show: true,
            edit: true,
            delete: true,
          },
        },
        {
          title: "Stock Adjustment Add",
          to: "transaction/stock-adjustment/add",
          permissions: {
            add: true,
            view: true,
            show: false,
            edit: true,
            delete: true,
          },
        },
      ],
    },

    {
      icon: <SvgIcon component={Settings} inheritViewBox />,
      title: "Settings",
      to: "settings",
      permissions: {
        add: true,
        delete: true,
        edit: true,
        view: true,
        show: true,
      },
      items: [
        {
          // icon: (
          //   <SvgIcon
          //     sx={{
          //       width: "20px",
          //       height: "20px",
          //     }}
          //     component={Booking}
          //     inheritViewBox
          //   />
          // ),
          icon: <SvgIcon component={UserPermission} inheritViewBox />,
          title: "User Permission",
          to: "settings/userPermission",
          permissions: {
            add: true,
            view: true,
            show: true,
            edit: true,
            delete: true,
          },
        },
        {
          icon: <SvgIcon component={UserMaster} inheritViewBox />,
          title: "User Master",
          to: "settings/users",
          permissions: {
            add: true,
            view: true,
            show: true,
            edit: true,
            delete: true,
          },
        },
        {
          icon: <SvgIcon component={GeneralConfig} inheritViewBox />,
          title: "General Config",
          to: "settings/general-config",
          permissions: {
            add: true,
            view: true,
            show: true,
            edit: true,
            delete: true,
          },
        },
        {
          icon: <SvgIcon component={SequenceConfig} inheritViewBox />,
          title: "Sequence Config",
          to: "settings/sequence-config",
          permissions: {
            add: true,
            view: true,
            show: true,
            edit: true,
            delete: true,
          },
        },
        {
          icon: <SvgIcon component={ColorCode} inheritViewBox />,
          title: "Color code",
          to: "settings/color-code",
          permissions: {
            add: true,
            view: true,
            show: true,
            edit: true,
            delete: true,
          },
        },
      ],
    },
    {
      icon: <SvgIcon component={Reports} inheritViewBox />,
      title: "Reports",
      to: "reports",
      permissions: {
        add: true,
        delete: true,
        edit: true,
        view: true,
        show: true,
      },
      items: [
        {
          icon: <SvgIcon component={Dashboard} inheritViewBox />,
          title: "Storage Area Dashboard",
          to: "reports/storage-area-dashboard",
          permissions: {
            add: true,
            delete: true,
            edit: true,
            view: true,
            show: true,
          },
        },
        {
          icon: <SvgIcon component={StockReceive} inheritViewBox />,
          title: "Stock Receive",
          to: "reports/stock-receive",
          permissions: {
            add: true,
            delete: true,
            edit: true,
            view: true,
            show: true,
          },
        },
        {
          icon: <SvgIcon component={StockRequest} inheritViewBox />,
          title: "Stock Request",
          to: "reports/stock-request",
          permissions: {
            add: true,
            delete: true,
            edit: true,
            view: true,
            show: true,
          },
        },
        {
          icon: <SvgIcon component={StockTransfer} inheritViewBox />,
          title: "Stock Transfer",
          to: "reports/stock-transfer",
          permissions: {
            add: true,
            delete: true,
            edit: true,
            view: true,
            show: true,
          },
        },
        {
          icon: <SvgIcon component={LocationReceive} inheritViewBox />,
          title: "Location Receive",
          to: "reports/location-receive",
          permissions: {
            add: true,
            delete: true,
            edit: true,
            view: true,
            show: true,
          },
        },
        {
          icon: <SvgIcon component={StockAdjustment} inheritViewBox />,
          title: "Stock Adjustment",
          to: "reports/stock-adjustment-report",
          permissions: {
            add: true,
            delete: true,
            edit: true,
            view: true,
            show: true,
          },
        },
        {
          icon: <SvgIcon component={StockDetails} inheritViewBox />,
          title: "Stock Details",
          to: "reports/stock-details",
          permissions: {
            add: true,
            delete: true,
            edit: true,
            view: true,
            show: true,
          },
        },
      ],
    },
  ],
};

export const testMenu: IMenuItem[] = [
  {
    // icon: <SvgIcon component={StockReceive} inheritViewBox />,
    title: "Storage Area Dashboard",
    to: "reports/storage-area-dashboard",
    permissions: {
      add: true,
      delete: true,
      edit: true,
      view: true,
      show: true,
    },
  },
  {
    title: "Color code",
    to: "settings/color-code",
    permissions: {
      add: true,
      view: true,
      show: true,
      edit: true,
      delete: true,
    },
  },
  {
    title: "Stock Transfer",
    to: "transaction/transfer",
    permissions: {
      add: true,
      view: true,
      show: true,
      edit: true,
      delete: true,
    },
  },
  {
    title: "Stock Transfer Add",
    to: "transaction/transfer/add",
    permissions: {
      add: true,
      view: true,
      show: false,
      edit: true,
      delete: true,
    },
  },
  {
    title: "Stock Transfer Edit",
    to: "transaction/transfer/edit",
    permissions: {
      add: true,
      view: true,
      show: false,
      edit: true,
      delete: true,
    },
  },
  {
    title: "Stock Details",
    to: "reports/stock-details",
    permissions: {
      add: true,
      delete: true,
      edit: true,
      view: true,
      show: true,
    },
  },
  {
    title: "Stock Transfer",
    to: "reports/stock-transfer",
    permissions: {
      add: true,
      delete: true,
      edit: true,
      view: true,
      show: true,
    },
  },
  {
    title: "Stock Receive",
    to: "reports/stock-receive",
    permissions: {
      add: true,
      delete: true,
      edit: true,
      view: true,
      show: true,
    },
  },
  {
    title: "Stock Request",
    to: "reports/stock-request",
    permissions: {
      add: true,
      delete: true,
      edit: true,
      view: true,
      show: true,
    },
  },
  {
    title: "Stock Adjustment",
    to: "reports/stock-adjustment-report",
    permissions: {
      add: true,
      delete: true,
      edit: true,
      view: true,
      show: true,
    },
  },
  {
    title: "Location Receive",
    to: "reports/location-receive",
    permissions: {
      add: true,
      delete: true,
      edit: true,
      view: true,
      show: true,
    },
  },
  {
    title: "Transaction Requiest Edit",
    to: "transaction/request/edit",
    permissions: {
      add: true,
      view: true,
      show: false,
      edit: true,
      delete: true,
    },
  },
  {
    title: "Stock Transfer Edit",
    to: "transaction/request/stock-transfer/edit",
    permissions: {
      add: true,
      view: true,
      show: false,
      edit: true,
      delete: true,
    },
  },
  {
    title: "Dashboard",
    to: "dashboard",
    permissions: {
      add: true,
      view: true,
      edit: true,
      delete: true,
    },
  },
  {
    title: "Division",
    to: "masters/division",
    permissions: {
      add: true,
      view: true,
      edit: false,
      delete: true,
    },
  },
  {
    title: "Location",
    to: "masters/location",
    permissions: {
      add: false,
      view: true,
      edit: true,
      delete: true,
    },
  },
  {
    title: "Zone",
    to: "masters/zone",
    permissions: {
      add: true,
      view: true,
      edit: true,
      delete: true,
    },
  },
  {
    title: "Storage Area",
    to: "masters/zone/storage-area",
    permissions: {
      add: true,
      view: true,
      edit: true,
      delete: true,
    },
  },
  {
    title: "Items",
    to: "masters/items",
    permissions: {
      add: true,
      view: true,
      edit: true,
      delete: true,
    },
  },

  {
    title: "Stock Receive",
    to: "transaction/stock-receive",
    permissions: {
      add: true,
      view: true,
      edit: true,
      delete: true,
    },
  },
  {
    title: "Stock Receive Details",
    to: "transaction/stock-receive/add",
    permissions: {
      add: true,
      view: true,
      edit: true,
      delete: true,
    },
  },
  {
    title: "Stock Receive Details",
    to: "transaction/stock-receive/edit",
    permissions: {
      add: true,
      view: true,
      edit: true,
      delete: true,
    },
  },
  // {
  //   title: "Stock Details",
  //   to: "stocks/stock-details",
  //   permissions: {
  //     add: true,
  //     view: true,
  //     edit: true,
  //     delete: true,
  //   },
  // },
  {
    title: "Transaction Request",
    to: "transaction/request",
    permissions: {
      add: true,
      view: true,
      edit: true,
      delete: true,
    },
  },
  {
    title: "Transaction Requiest Add",
    to: "transaction/request/add",
    permissions: {
      add: true,
      view: true,
      edit: true,
      delete: true,
    },
  },
  {
    title: "Stock Transfer",
    to: "transaction/request/stock-transfer",
    permissions: {
      add: true,
      view: true,
      edit: true,
      delete: true,
    },
  },
  {
    title: "Stock Transfer Add",
    to: "transaction/request/stock-transfer/add",
    permissions: {
      add: true,
      view: true,
      edit: true,
      delete: true,
    },
  },
  {
    title: "Transaction Receive Add",
    to: "transaction/receive/add",
    permissions: {
      add: true,
      view: true,
      edit: true,
      delete: true,
    },
  },
  {
    title: "Stock Adjustment",
    to: "transaction/stock-adjustment",
    permissions: {
      add: true,
      view: true,
      show: true,
      edit: true,
      delete: true,
    },
  },
  {
    title: "Stock Adjustment Add",
    to: "transaction/stock-adjustment/add",
    permissions: {
      add: true,
      view: true,
      show: false,
      edit: true,
      delete: true,
    },
  },
  {
    // icon: (
    //   <SvgIcon
    //     sx={{
    //       width: "20px",
    //       height: "20px",
    //     }}
    //     component={Booking}
    //     inheritViewBox
    //   />
    // ),
    title: "User Permission",
    to: "settings/userPermission",
    permissions: {
      add: true,
      view: true,
      edit: true,
      delete: true,
    },
  },
  {
    title: "User Master",
    to: "settings/users",
    permissions: {
      add: true,
      view: true,
      edit: true,
      delete: true,
    },
  },
  {
    title: "General Config",
    to: "settings/general-config",
    permissions: {
      add: true,
      view: true,
      show: true,
      edit: true,
      delete: true,
    },
  },
  {
    title: "Sequence Config",
    to: "settings/sequence-config",
    permissions: {
      add: true,
      view: true,
      show: true,
      edit: true,
      delete: true,
    },
  },
  {
    title: "Location Receive",
    to: "transaction/location-receive",
    permissions: {
      add: true,
      view: true,
      show: true,
      edit: true,
      delete: true,
    },
  },
  {
    title: "Location Receive Edit",
    to: "transaction/location-receive/edit",
    permissions: {
      add: true,
      view: true,
      edit: true,
      delete: true,
    },
  },
];

export const skipedMenu = [""];

export const notificationMenu = [
  {
    content: (
      <div
        style={{
          display: "flex",
          gap: "10px",
          alignItems: "center",
        }}
      >
        <Avatars></Avatars>
        <div style={{}}>
          <Typography component={"div"}>New Message from A</Typography>
          <Typography component={"p"} variant="body2">
            Feb 28 10:50 am
          </Typography>
        </div>
        <IconButton>
          <SvgIcon
            sx={{
              height: "20px",
              width: "20px",
            }}
            component={CloseIcon}
          />
        </IconButton>
      </div>
    ),
  },
  {
    content: (
      <div
        style={{
          display: "flex",
          gap: "10px",
          alignItems: "center",
        }}
      >
        <Avatars></Avatars>
        <div style={{}}>
          <Typography component={"div"}>New Message from A</Typography>
          <Typography component={"p"} variant="body2">
            Feb 28 10:50 am
          </Typography>
        </div>
        <IconButton>
          <SvgIcon
            sx={{
              height: "20px",
              width: "20px",
            }}
            component={CloseIcon}
          />
        </IconButton>
      </div>
    ),
  },
  {
    content: (
      <div
        style={{
          display: "flex",
          gap: "10px",
          alignItems: "center",
        }}
      >
        <Avatars></Avatars>
        <div style={{}}>
          <Typography component={"div"}>New Message from A</Typography>
          <Typography component={"p"} variant="body2">
            Feb 28 10:50 am
          </Typography>
        </div>
        <IconButton>
          <SvgIcon
            sx={{
              height: "20px",
              width: "20px",
            }}
            component={CloseIcon}
          />
        </IconButton>
      </div>
    ),
  },
  {
    content: (
      <div
        style={{
          display: "flex",
          gap: "10px",
          alignItems: "center",
        }}
      >
        <Avatars></Avatars>
        <div style={{}}>
          <Typography component={"div"}>New Message from A</Typography>
          <Typography component={"p"} variant="body2">
            Feb 28 10:50 am
          </Typography>
        </div>
        <IconButton>
          <SvgIcon
            sx={{
              height: "20px",
              width: "20px",
            }}
            component={CloseIcon}
          />
        </IconButton>
      </div>
    ),
  },
  {
    content: (
      <div
        style={{
          display: "flex",
          gap: "10px",
          alignItems: "center",
        }}
      >
        <Avatars></Avatars>
        <div style={{}}>
          <Typography component={"div"}>New Message from A</Typography>
          <Typography component={"p"} variant="body2">
            Feb 28 10:50 am
          </Typography>
        </div>
        <IconButton>
          <SvgIcon
            sx={{
              height: "20px",
              width: "20px",
            }}
            component={CloseIcon}
          />
        </IconButton>
      </div>
    ),
  },
  {
    content: (
      <div
        style={{
          display: "flex",
          gap: "10px",
          alignItems: "center",
        }}
      >
        <Avatars></Avatars>
        <div style={{}}>
          <Typography component={"div"}>New Message from A</Typography>
          <Typography component={"p"} variant="body2">
            Feb 28 10:50 am
          </Typography>
        </div>
        <IconButton>
          <SvgIcon
            sx={{
              height: "20px",
              width: "20px",
            }}
            component={CloseIcon}
          />
        </IconButton>
      </div>
    ),
  },
  {
    content: (
      <div
        style={{
          display: "flex",
          gap: "10px",
          alignItems: "center",
        }}
      >
        <Avatars></Avatars>
        <div style={{}}>
          <Typography component={"div"}>New Message from A</Typography>
          <Typography component={"p"} variant="body2">
            Feb 28 10:50 am
          </Typography>
        </div>
        <IconButton>
          <SvgIcon
            sx={{
              height: "20px",
              width: "20px",
            }}
            component={CloseIcon}
          />
        </IconButton>
      </div>
    ),
  },
  {
    content: (
      <div
        style={{
          display: "flex",
          gap: "10px",
          alignItems: "center",
        }}
      >
        <Avatars></Avatars>
        <div style={{}}>
          <Typography component={"div"}>New Message from A</Typography>
          <Typography component={"p"} variant="body2">
            Feb 28 10:50 am
          </Typography>
        </div>
        <IconButton>
          <SvgIcon
            sx={{
              height: "20px",
              width: "20px",
            }}
            component={CloseIcon}
          />
        </IconButton>
      </div>
    ),
  },
];
