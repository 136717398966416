import { takeEvery, takeLatest } from "redux-saga/effects";
import { TestCall } from "../Slices/TestSlicers/TestApi";
import { EditUserPermissionCall } from "../Slices/UserPermission/UserPermissionAPI";
import { ListUserPermissionRowsCall } from "../Slices/UserPermission/UserPermissionAPI";
import { ForgotPasswordCall, LoginCall } from "../Slices/LoginSlicer/LoginApi";
import {
  CreateSequenceCall,
  DeleteSequenceConfigCall,
  GenerateSequenceCall,
  GetCodeCall,
  GetSequenceListCall,
  UpdateSequenceConfigCall,
} from "../Slices/SequenceSlicer/SequenceAPI";
import {
  AddUserMasterCall,
  DeleteUserMasterCall,
  EditUserMasterCall,
  ListUserMasterCall,
} from "../Slices/UserMaster/ApiUserMaster";
import { ListRoleCall } from "../Slices/Role/ApiRole";
import { ListStorageAreaCall } from "../Slices/StorageArea/ApiStorageArea";
import {
  AddZoneConfigCall,
  DeleteZoneConfigCall,
  EditZoneConfigCall,
  ListZoneConfigCall,
} from "../Slices/ZoneConfig/ApiZoneConfig";
import { ListLocationTypeCall } from "../Slices/LocationType/ApiLocationType";
import {
  AddLocationMasterCall,
  DeleteLocationMasterCall,
  EditLocationMasterCall,
  ListLocationMasterCall,
} from "../Slices/LocationMaster/ApiLocationMaster";
import {
  AddDivisionMasterCall,
  DeleteDivisionMasterCall,
  EditDivisionMasterCall,
  ListDivisionMasterCall,
} from "../Slices/DivisionMaster/ApiDivisionMaster";
import {
  AddItemMasterCall,
  DeleteItemMasterCall,
  EditItemMasterCall,
  ImportItemsCall,
  ListItemMasterCall,
} from "../Slices/ItemMaster/ApiItemMaster";
import { ResetPasswordCall } from "../Slices/ResetPassword/ResetPasswordAPI";
import {
  CreateStockCall,
  DeleteStockReceiveCall,
  FilterStockRecieveCall,
  FindOneStockReceiveCall,
  StockRecieveStatusUpdateCall,
  UpdateStockReceiveCall,
} from "../Slices/StockRecieve/StockRecieveAPI";
import {
  AddTransactionRequestCall,
  DeleteTransactionRequestCall,
  EditTransactionRequestCall,
  GetoneTransactionRequestCall,
  ListTransactionRequestCall,
} from "../Slices/TransactionRequest/ApiTransactionRequest";
import { ListStockDetailsCall } from "../Slices/StockDetails/StockDetailsAPI";
import {
  AddStockTransferCall,
  EditStockTransferCall,
  GetoneStockTransferCall,
  ListStockRequestDetailsCall,
  ListStockTransferCall,
} from "../Slices/StockTransfer/ApiStockTransfer";
import { GetItemTypeCall } from "../Slices/GeneralSetting/ItemType/ItemTypeAPI";
import { SideMenuPermissionCall } from "../Slices/SideMenuPermission/ApiSideMenuPermission";
import { ReportsStockReceiveCall } from "../Slices/ReportsStockReceive/ApiReportsStockReceive";
import { ReportsStockRequestCall } from "../Slices/ReportsStockRequest/ApiReportsStockRequest";
import { ReportsStockTransferCall } from "../Slices/ReportStockTransfer/ApiReportsStockTransfer";
import { ReportsStockDetailsCall } from "../Slices/ReportStockDetails/ApiReportsStockDetails";
import {
  GetGenerateSerialNoCall,
  UpdateGeneralConfig,
} from "../Slices/GeneralSetting/GenerateSerialNo/GenerateSerialNoAPI";
import { ListTransactionTypeCall } from "../Slices/GeneralSetting/TransactionType/TranscationTypeAPI";
import {
  AddColorCodeCall,
  EditColorCodeCall,
  ListColorCodeCall,
} from "../Slices/ColorCode/ApiColorCode";
import {
  EditLocationReceiveCall,
  GetoneLocationReceiveCall,
  ListLocationReceiveCall,
} from "../Slices/LocationReceive/ApiLocationReceive";
import {
  createStockAdjustmentCall,
  ListScanItemCall,
  ListStockAdjustmentCall,
} from "../Slices/StockAdjustment/StockAdjustmentAPI";
import {
  DashboardCardCall,
  GRNNoCall,
  ListDashboardStorageAreaCall,
} from "../Slices/Dashboard/ApiDashboard";
import {
  EditPickItemCall,
  GetOnePickListCall,
} from "../Slices/PickList/APIPickItem";

export default function* rootSage() {
  yield takeLatest("Test/testCall", TestCall);
  // yield takeLatest("pattern",worker)

  // Dashboard
  yield takeLatest(
    "ListDashboardStorageArea/listDashboardStorageArea",
    ListDashboardStorageAreaCall
  );
  yield takeLatest("DashboardCard/dashboardCard", DashboardCardCall);
  yield takeLatest("ListGRNNo/listGRNNo", GRNNoCall);
  // User Permission
  yield takeLatest(
    "EditUserPermission/editUserPermission",
    EditUserPermissionCall
  );
  yield takeLatest(
    "UserPermissionRows/userPermissionRowsList",
    ListUserPermissionRowsCall
  );
  // SideMenu Permission
  yield takeLatest(
    "SideMenuPermission/listSideMenuPermission",
    SideMenuPermissionCall
  );
  // Login
  yield takeLatest("LoginSlicer/loginStart", LoginCall);
  yield takeLatest("ForgotPassword/ForgotPasswordStart", ForgotPasswordCall);
  // Reset Password
  yield takeLatest("ResetPassword/resetPasswordStart", ResetPasswordCall);
  // Sequence
  yield takeLatest("CreateSequence/createSequenceStart", CreateSequenceCall);
  yield takeLatest("GetSequenceList/getSequenceListStart", GetSequenceListCall);
  yield takeLatest(
    "DeleteSequenceConfig/DeleteSequenceConfigStart",
    DeleteSequenceConfigCall
  );
  yield takeLatest(
    "UpdateSequenceConfig/UpdateSequenceConfigStart",
    UpdateSequenceConfigCall
  );

  // User Master
  yield takeLatest("ListUserMaster/listUserMaster", ListUserMasterCall);
  yield takeEvery("AddUserMasterSlice/addUserMaster", AddUserMasterCall);
  yield takeEvery("EditUserMaster/editUserMaster", EditUserMasterCall);
  yield takeEvery("DeleteUserMaster/deleteUserMaster", DeleteUserMasterCall);

  // ZoneConfig
  yield takeLatest("ListZoneConfig/listZoneConfig", ListZoneConfigCall);
  yield takeEvery("AddZoneConfig/addZoneConfig", AddZoneConfigCall);
  yield takeEvery("EditZoneConfig/editZoneConfig", EditZoneConfigCall);
  yield takeEvery("DeleteZoneConfig/deleteZoneConfig", DeleteZoneConfigCall);

  // Location Master
  yield takeLatest(
    "ListLocationMaster/listLocationMaster",
    ListLocationMasterCall
  );
  yield takeEvery("AddLocationMaster/addLocationMaster", AddLocationMasterCall);
  yield takeEvery(
    "EditLocationMaster/editLocationMaster",
    EditLocationMasterCall
  );
  yield takeEvery(
    "DeleteLocationMaster/deleteLocationMaster",
    DeleteLocationMasterCall
  );

  // Division Master
  yield takeLatest(
    "ListDivisionMaster/listDivisionMaster",
    ListDivisionMasterCall
  );
  yield takeEvery("AddDivisionMaster/addDivisionMaster", AddDivisionMasterCall);
  yield takeEvery(
    "EditDivisionMaster/editDivisionMaster",
    EditDivisionMasterCall
  );
  yield takeEvery(
    "DeleteDivisionMaster/deleteDivisionMaster",
    DeleteDivisionMasterCall
  );

  // Transaction Request
  yield takeLatest(
    "ListTransactionRequest/listTransactionRequest",
    ListTransactionRequestCall
  );
  yield takeEvery(
    "AddTransactionRequest/addTransactionRequest",
    AddTransactionRequestCall
  );
  yield takeEvery(
    "EditTransactionRequest/editTransactionRequest",
    EditTransactionRequestCall
  );
  yield takeEvery(
    "DeleteTransactionRequest/deleteTransactionRequest",
    DeleteTransactionRequestCall
  );
  yield takeEvery(
    "GetoneTransactionRequest/getoneTransactionRequest",
    GetoneTransactionRequestCall
  );

  // Stock Transfer
  yield takeLatest(
    "ListStockTransfer/listStockTransfer",
    ListStockTransferCall
  );
  yield takeEvery("AddStockTransfer/addStockTransfer", AddStockTransferCall);
  yield takeEvery(
    "GetoneStockTransfer/getoneStockTransfer",
    GetoneStockTransferCall
  );
  yield takeEvery("EditStockTransfer/editStockTransfer", EditStockTransferCall);
  yield takeEvery(
    "ListStockRequestDetails/listStockRequestDetails",
    ListStockRequestDetailsCall
  );

  // Item Master
  yield takeLatest("ListItemMaster/listItemMaster", ListItemMasterCall);
  yield takeEvery("AddItemMaster/addItemMaster", AddItemMasterCall);
  yield takeEvery("EditItemMaster/editItemMaster", EditItemMasterCall);
  yield takeEvery("DeleteItemMaster/deleteItemMaster", DeleteItemMasterCall);
  yield takeLatest("ImportItemMaster/importItemMasterStart", ImportItemsCall);

  // Role
  yield takeLatest("RoleSlice/listRoleSlice", ListRoleCall);
  // LocationType
  yield takeLatest("ListLocationType/listLocationType", ListLocationTypeCall);
  // StorageArea
  yield takeLatest("ListStorageArea/listStorageArea", ListStorageAreaCall);

  // Stock recieve
  yield takeLatest("CreateStockRecieve/createStockStart", CreateStockCall);
  yield takeLatest(
    "ListStockRecieve/listStockRecieveStart",
    FilterStockRecieveCall
  );
  yield takeLatest(
    "UpdateStockReceive/UpdateStockReceiveStart",
    UpdateStockReceiveCall
  );
  yield takeLatest(
    "DeleteStockRecieve/deleteStockReceiveStart",
    DeleteStockReceiveCall
  );
  yield takeLatest(
    "StockRecieveStatusUpdate/startStockRecieveStatusUpdate",
    StockRecieveStatusUpdateCall
  );
  yield takeLatest(
    "FindOneStockReceive/findOneStockReceiveStart",
    FindOneStockReceiveCall
  );

  // Stock Details
  yield takeLatest(
    "ListStockDetails/ListStockDetailsStart",
    ListStockDetailsCall
  );
  // General Settings
  yield takeLatest("GetItemType/getItemTypeStart", GetItemTypeCall);
  // Get Code
  yield takeLatest("GetCode/getCodeStart", GetCodeCall);

  // Generate Sequence
  yield takeLatest(
    "GenerateSequence/GenerateSequenceStart",
    GenerateSequenceCall
  );

  yield takeLatest(
    "GetGenerateSerialNo/getGenerateSerialNoStart",
    GetGenerateSerialNoCall
  );

  // Color Code
  yield takeLatest("ListColorCode/listColorCode", ListColorCodeCall);
  yield takeEvery("AddColorCode/addColorCode", AddColorCodeCall);
  yield takeEvery("EditColorCode/editColorCode", EditColorCodeCall);

  // Location Receiver
  yield takeEvery(
    "ListLocationReceive/listLocationReceive",
    ListLocationReceiveCall
  );
  yield takeEvery(
    "GetoneLocationReceive/getoneLocationReceive",
    GetoneLocationReceiveCall
  );
  yield takeEvery(
    "EditLocationReceive/editLocationReceive",
    EditLocationReceiveCall
  );

  // Reports
  // Stock Receive
  yield takeLatest(
    "ListReportsStockReceive/listReportsStockReceive",
    ReportsStockReceiveCall
  );
  yield takeLatest(
    "ListReportsStockRequest/listReportsStockRequest",
    ReportsStockRequestCall
  );
  yield takeLatest(
    "ListReportsStockTransfer/listReportsStockTransfer",
    ReportsStockTransferCall
  );
  yield takeLatest(
    "ListReportsStockDetails/listReportsStockDetails",
    ReportsStockDetailsCall
  );
  // Update Customer Serial No
  yield takeLatest(
    "UpdateGeneralConfig/updateGeneralConfig",
    UpdateGeneralConfig
  );

  // List TransactionType
  yield takeLatest(
    "ListTransactionType/ListTranscationTypeStart",
    ListTransactionTypeCall
  );

  // Stock Adjustment
  yield takeLatest(
    "ListStockAdjustment/listStockAdjustment",
    ListStockAdjustmentCall
  );

  yield takeLatest("ListScanItems/listScanItemsStart", ListScanItemCall);
  yield takeLatest(
    "CreateStockAdjustment/createStockAdjustmentStart",
    createStockAdjustmentCall
  );

  // GetOnePickList
  yield takeLatest("GetOnePickList/getOnePickList", GetOnePickListCall);
  yield takeLatest("EditPickItem/editPickItem", EditPickItemCall);
}
