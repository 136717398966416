import { AxiosResponse } from "axios";
import { call, put } from "redux-saga/effects";
import api from "../../Axios/middleware";
import {
  successStockTransfer,
  failureStockTransfer,
} from "./ListStockTransferSlice";
import {
  IStockTransfer,
  ITransferApiCall,
} from "../../../Pages/StockTransfer/IStockTransfer";
import { PayloadAction } from "@reduxjs/toolkit";
import {
  failureaddStockTransfer,
  successaddStockTransfer,
} from "./AddStockTransferSlice";
import {
  failureStockRequestDetails,
  successStockRequestDetails,
} from "./ListStockRequestDetailsSlice";
import {
  failureeditStockTransfer,
  successeditStockTransfer,
} from "./EditStockTransferSlice";
import {
  failureGetoneStockTransfer,
  successGetoneStockTransfer,
} from "./GetoneStockTransferSlice";
import { GenerateSequenceStart } from "../SequenceSlicer/GenerateSequence";

// list
const ListStockTransferApi = (action: PayloadAction<ITransferApiCall>) => {
  return api.get("/trans-stock-transfer-mas/getall", {
    params: action.payload,
  });
};

export function* ListStockTransferCall(
  action: PayloadAction<ITransferApiCall>
) {
  try {
    const response: AxiosResponse = yield call(ListStockTransferApi, action);
    yield put(successStockTransfer(response.data));
  } catch (error) {
    yield put(failureStockTransfer(error));
  }
}

// Stock Request Details list
const ListStockRequestDetailsApi = (action: PayloadAction<number>) => {
  return api.get("/trans-stock-request-det/getid", {
    params: { id: action.payload },
  });
};

export function* ListStockRequestDetailsCall(action: PayloadAction<number>) {
  try {
    const response: AxiosResponse = yield call(
      ListStockRequestDetailsApi,
      action
    );
    yield put(successStockRequestDetails(response.data));
  } catch (error) {
    yield put(failureStockRequestDetails(error));
  }
}

// add
const AddStockTransferApi = (action: PayloadAction<IStockTransfer>) => {
  return api.post("/trans-stock-transfer-mas/create", action.payload);
};

export function* AddStockTransferCall(action: PayloadAction<IStockTransfer>) {
  try {
    const response: AxiosResponse = yield call(AddStockTransferApi, action);
    yield put(successaddStockTransfer(response.data));
    yield put(GenerateSequenceStart("Transfer"));
  } catch (error) {
    yield put(failureaddStockTransfer(error));
  }
}

// edit
const EditStockTransferApi = (action: PayloadAction<IStockTransfer>) => {
  return api.patch("/trans-stock-transfer-mas/updatestatus", action.payload, {
    params: { id: action.payload.id },
  });
};

export function* EditStockTransferCall(action: PayloadAction<IStockTransfer>) {
  try {
    const response: AxiosResponse = yield call(EditStockTransferApi, action);
    yield put(successeditStockTransfer(response.data));
  } catch (error) {
    yield put(failureeditStockTransfer(error));
  }
}

// getone
const GetoneStockTransferApi = (action: PayloadAction<number>) => {
  return api.get("/trans-stock-transfer-mas/getone", {
    params: { id: action.payload },
  });
};

export function* GetoneStockTransferCall(action: PayloadAction<number>) {
  try {
    const response: AxiosResponse = yield call(GetoneStockTransferApi, action);
    yield put(successGetoneStockTransfer(response.data));
  } catch (error) {
    yield put(failureGetoneStockTransfer(error));
  }
}
