import { FunctionComponent } from "react";
import { IStockTransferItemDetails } from "./IStockTransfer";
import { Columns } from "../../Components/Table/ITable";
import Table from "../../Components/Table/Table";
import TableSearch from "../../Components/Table/components/TableSearch";
import CustomToolbar from "../../Components/Table/components/CustomToolbar";
import { GridValueGetterParams } from "@mui/x-data-grid";
import { IItemDetails } from "../TransactionRequest/ITransactionRequest";

interface StockTransferItemsViewProps {
  tableRows: IStockTransferItemDetails[];
}

const StockTransferItemsView: FunctionComponent<StockTransferItemsViewProps> = (
  props
) => {
  const columns: Columns = [
    {
      field: "index",
      renderHeader: () => <b>S.No</b>,
      align: "center",
      headerAlign: "center",
      width: 90,
    },
    {
      field: "items",
      renderHeader: () => <b>Item Name</b>,
      flex: 1,
      align: "left",
      headerAlign: "left",
      type: "string",
      valueGetter: (params: GridValueGetterParams<IItemDetails>) =>
        params.row.items?.item_name,
    },
    {
      field: "item_code",
      renderHeader: () => <b>Item Code</b>,
      flex: 1,
      align: "left",
      headerAlign: "left",
      type: "string",
    },
    {
      field: "item_qnty",
      renderHeader: () => <b>Item Quantity</b>,
      flex: 1,
      align: "right",
      headerAlign: "right",
      type: "string",
    },
  ];
  console.log(props.tableRows);

  return (
    <>
      <Table rows={props.tableRows ?? []} columns={columns}>
        <CustomToolbar>
          <TableSearch className="view_table_left_align_search" />
        </CustomToolbar>
      </Table>
    </>
  );
};

export default StockTransferItemsView;
