import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IReduxState } from "../../interfaces/IReduxState";
import { IItemAdjustList } from "../../../Pages/StockAdjustment/IStockAdjustment";
import { IGetScanItems } from "./StockAdjustmentAPI";

export const ListScanItems = createSlice({
  name: "ListScanItems",
  initialState: {} as IReduxState<IItemAdjustList[]>,
  reducers: {
    listScanItemsStart: (state, _: PayloadAction<IGetScanItems>) => {
      state.isLoading = true;
    },
    successScanItem: (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    },
    failureScanItem: (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    },
    clearScanItem: (state) => {
      state.data = {};
    },
  },
});

export const {
  listScanItemsStart,
  successScanItem,
  clearScanItem,
  failureScanItem,
} = ListScanItems.actions;

export default ListScanItems.reducer;
