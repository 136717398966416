import { createSlice } from "@reduxjs/toolkit";
import { IReduxState } from "../../interfaces/IReduxState";

export const CreateStockRecieve = createSlice({
  name: "CreateStockRecieve",
  initialState: {
    isLoading: false,
    state: {},
  } as IReduxState<{}>,
  reducers: {
    createStockStart: (state, _) => {
      state.isLoading = true;
    },
    successStockCreate: (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    },
    failureCreateStock: (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    },
    clearCreateStock: (state) => {
      state.data = {};
    },
  },
});
export const {
  createStockStart,
  failureCreateStock,
  successStockCreate,
  clearCreateStock,
} = CreateStockRecieve.actions;
export default CreateStockRecieve.reducer;
