import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IReduxState } from "../../interfaces/IReduxState";
import {
  Filter,
  IcreateStock,
  IListStockRecieve,
} from "../../../Pages/StockRecieve/IStockRecieve";

export const ListStockRecieve = createSlice({
  name: "ListStockRecieve",
  initialState: {
    isLoading: false,
    state: {},
  } as IReduxState<IcreateStock[]>,
  reducers: {
    listStockRecieveStart: (state, _:PayloadAction<Filter>) => {
      state.isLoading = true;
    },
    successStockRecieveList: (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    },
    failureStockRecieveList: (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    },
  },
});
export const {
  failureStockRecieveList,
  listStockRecieveStart,
  successStockRecieveList,
} = ListStockRecieve.actions;
export default ListStockRecieve.reducer;
