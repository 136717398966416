import {
  LocalizationProvider,
  TimeField,
  TimePicker,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import React, { FunctionComponent } from "react";
import { ITimePickerProps } from "./ITimePicker";
import "./Timepicker.scss";

const TimePickers: FunctionComponent<ITimePickerProps> = (props) => {
  const {
    ampm,
    label,
    ampmInClock,
    autoFocus,
    closeOnSelect,
    desktopModeMediaQuery,
    disabled,
    disableFuture,
    disableIgnoringDatePartForTimeValidation,
    disableOpenPicker,
    disablePast,
    format,
    formatDensity,
    inputRef,
    maxTime,
    minTime,
    localeText,
    minutesStep,
    className,
    onChange,
    onAccept,
    onClose,
    onError,
    onOpen,
    onSelectedSectionsChange,
    onViewChange,
    open,
    openTo,
    orientation,
    referenceDate,
    selectedSections,
    shouldDisableTime,
    skipDisabled,
    slotProps,
    slots,
    sx,
    timeSteps,
    thresholdToRenderTimeInASingleColumn,
    timezone,
    value,
    view,
    // viewRenderers,
    views,
    name,
    helperText,
    error,
    onBlur,
    onFocus,
    required,
    InputProps,
    inputLableStyle,
    labelSize,
    shrink,
    variant,
    readOnly,
  } = props;
  return (
    <div>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <TimePicker
          {...props}
          name={name}
          ampm={ampm}
          label={label}
          ampmInClock={ampmInClock}
          closeOnSelect={closeOnSelect}
          autoFocus={autoFocus}
          desktopModeMediaQuery={desktopModeMediaQuery}
          disabled={disabled}
          disableFuture={disableFuture}
          disableIgnoringDatePartForTimeValidation={
            disableIgnoringDatePartForTimeValidation
          }
          disableOpenPicker={disableOpenPicker}
          disablePast={disablePast}
          format={format}
          formatDensity={formatDensity}
          inputRef={inputRef}
          localeText={localeText}
          maxTime={maxTime}
          minTime={minTime}
          minutesStep={minutesStep}
          className={`global_css_timepicker ${className}`}
          onAccept={onAccept}
          onChange={onChange}
          onClose={onClose}
          onError={onError}
          onOpen={onOpen}
          onSelectedSectionsChange={onSelectedSectionsChange}
          onViewChange={onViewChange}
          open={open}
          openTo={openTo}
          orientation={orientation}
          referenceDate={referenceDate}
          selectedSections={selectedSections}
          shouldDisableTime={shouldDisableTime}
          skipDisabled={skipDisabled}
          slotProps={{
            textField: {
              helperText: helperText,
              onFocus: onFocus,
              onBlur: onBlur,
              error: error,
              required: required,
              variant: variant,
              InputProps: {
                ...InputProps,
                disableUnderline: true,
                readOnly: readOnly,
                // className: `GlobalInputPropsStyle ${inputClassName}`,
              },
              InputLabelProps: {
                shrink: shrink,
                size: labelSize,
                // className: `GlobalInputLableStyle ${inputLableStyle}`,
              },
            },
          }}
          slots={slots}
          sx={sx}
          thresholdToRenderTimeInASingleColumn={
            thresholdToRenderTimeInASingleColumn
          }
          timeSteps={timeSteps}
          timezone={timezone}
          value={value}
          view={view}
          // viewRenderers={viewRenderers}
          views={views}
        />
      </LocalizationProvider>
    </div>
  );
};

export default TimePickers;
