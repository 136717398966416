import { createSlice } from "@reduxjs/toolkit";
import { IReduxState } from "../../interfaces/IReduxState";
import { IUserMaster } from "../../../Pages/UserMaster/IUserMaster";

export const UserMasterSlice = createSlice({
  name: "ListUserMaster",
  initialState: {} as IReduxState<IUserMaster[]>,
  reducers: {
    listUserMaster: (state) => {
      state.isLoading = true;
    },
    successUserMaster: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
    failureUserMaster: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
  },
});

export const { failureUserMaster, listUserMaster, successUserMaster } =
  UserMasterSlice.actions;

export default UserMasterSlice.reducer;
