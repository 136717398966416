import "./Checkbox.scss";

import { FormControlLabel } from "@mui/material";
import { FunctionComponent } from "react";
import Checkbox from "./Checkbox";
import { LabeledCheckboxProps } from "./ICheckbox";

const LabeledCheckbox: FunctionComponent<LabeledCheckboxProps> = (props) => {
  const {
    label,
    labelSx,
    required,
    name,
    labelPlacement,
    disabled,
    checked,
    onChange,
    labelClassName,
    children,

    id,
    defaultChecked,
    color,
    icon,
    checkedIcon,
    disableRipple,
    size,
    sx,
    checkboxCalssName,
    indeterminate,
    indeterminateIcon,
  } = props;

  return (
    <>
      <FormControlLabel
        id={id}
        className={`labelClassName ${labelClassName}`}
        label={label}
        checked={checked}
        disabled={disabled}
        onChange={onChange}
        labelPlacement={labelPlacement}
        name={name}
        required={required}
        sx={labelSx}
        control={
          <Checkbox
            id={id}
            defaultChecked={defaultChecked}
            color={color}
            icon={icon}
            checkedIcon={checkedIcon}
            disableRipple={disableRipple}
            size={size}
            sx={sx}
            checkboxCalssName={`checkbox ${checkboxCalssName}`}
            indeterminate={indeterminate}
            indeterminateIcon={indeterminateIcon}
          />
        }
      />
      {children}
    </>
  );
};

export default LabeledCheckbox;
