import { createSlice } from "@reduxjs/toolkit";
import { IReduxState } from "../../interfaces/IReduxState";
import { ITransactionRequest } from "../../../Pages/TransactionRequest/ITransactionRequest";

export const TransactionRequestSlice = createSlice({
  name: "GetoneTransactionRequest",
  initialState: {} as IReduxState<ITransactionRequest>,
  reducers: {
    getoneTransactionRequest: (state, _) => {
      state.isLoading = true;
    },
    successGetoneTransactionRequest: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
    failureGetoneTransactionRequest: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
    clearGetoneTransactionRequest: (state) => {
      state.data = {};
    },
  },
});

export const {
  failureGetoneTransactionRequest,
  getoneTransactionRequest,
  successGetoneTransactionRequest,
  clearGetoneTransactionRequest,
} = TransactionRequestSlice.actions;

export default TransactionRequestSlice.reducer;
