import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IReduxState } from "../../interfaces/IReduxState";
import { ITransactionRequest } from "../../../Pages/TransactionRequest/ITransactionRequest";

export const DeleteTransactionRequestSlice = createSlice({
  name: "DeleteTransactionRequest",
  initialState: {} as IReduxState<ITransactionRequest>,
  reducers: {
    deleteTransactionRequest: (state, _) => {
      state.isLoading = true;
    },
    successdeleteTransactionRequest: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
    failuredeleteTransactionRequest: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
    clearDeleteTransactionRequestResponse: (state) => {
      state.data = {};
    },
  },
});

export const {
  deleteTransactionRequest,
  failuredeleteTransactionRequest,
  successdeleteTransactionRequest,
  clearDeleteTransactionRequestResponse,
} = DeleteTransactionRequestSlice.actions;

export default DeleteTransactionRequestSlice.reducer;
