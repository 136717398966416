import React from "react";
import ReactDOM from "react-dom/client";
import "./index.scss";
import reportWebVitals from "./reportWebVitals";
import createCache from "@emotion/cache";
import { CacheProvider } from "@emotion/react";
import { RouterProvider } from "react-router-dom";
import { router } from "./Routers/Routes";
import { Provider } from "react-redux";
import { store } from "./Redux/Store/Store";
import palette from "./Scss/Variables.module.scss";
import { ThemeProvider, createTheme } from "@mui/material";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";
import { PrimeReactProvider } from "primereact/api";

// In mui takes first prefrence on css file instead of default
const cache = createCache({
  key: "css",
  prepend: true,
});

// replace console.* for disable log on production
if (
  process.env.NODE_ENV === "production" ||
  process.env.REACT_APP_ENV === "LIVE"
) {
  console.log = () => {};
  console.error = () => {};
  console.debug = () => {};
}

const theme = createTheme({
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: 8,
          // "& .MuiOutlinedInput-notchedOutline": {
          //   borderColor: "blue",
          // },
          // "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
          //   borderColor: "red",
          // },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 8,
        },
      },
    },
  },
  palette: {
    primary: {
      main: palette.primary,
      light: "#F5EBFF",
      dark: "#000000",
    },
    secondary: {
      main: palette.secondary,
    },
    success: {
      main: palette.success,
    },
    error: {
      main: palette.error,
    },
    warning: {
      main: palette.warning,
    },
    info: {
      main: palette.info,
    },
  },
  // background: {
  //   paper: '#fff',
  // },
  // text: {
  //   primary: '#173A5E',
  //   secondary: '#46505A',
  // },
  // action: {
  //   active: '#001E3C',
  // },
  // typography: {
  //   button: {
  //     textTransform: "none",
  //   },
  // },
  shape: {
    borderRadius: 8,
  },
});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <>
    <PrimeReactProvider>
      <CacheProvider value={cache}>
        <Provider store={store}>
          <ThemeProvider theme={theme}>
            <RouterProvider router={router} />
          </ThemeProvider>
        </Provider>
      </CacheProvider>
    </PrimeReactProvider>
  </>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
