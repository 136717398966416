import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IReduxState } from "../../interfaces/IReduxState";
import {
  ICreateStockAdjustment,
  IListManualStockAdjustment,
} from "../../../Pages/StockAdjustment/IStockAdjustment";
import { Filter } from "../../../Pages/StockRecieve/IStockRecieve";

export const CreateStockAdjustment = createSlice({
  name: "CreateStockAdjustment",
  initialState: {} as IReduxState<{}>,
  reducers: {
    createStockAdjustmentStart: (state, _) => {
      state.isLoading = true;
    },
    successCreateStockAdjustment: (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    },
    failureCreateStockAdjustment: (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    },
    clearCreateStockAdjustment: (state) => {
      state.data = {};
    },
  },
});

export const {
  createStockAdjustmentStart,
  failureCreateStockAdjustment,
  successCreateStockAdjustment,
  clearCreateStockAdjustment,
} = CreateStockAdjustment.actions;

export default CreateStockAdjustment.reducer;
