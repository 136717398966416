import { createSlice } from "@reduxjs/toolkit";
import { IReduxState } from "../../interfaces/IReduxState";

export const StockRecieveStatusUpdate = createSlice({
  name: "StockRecieveStatusUpdate",
  initialState: {} as IReduxState<null>,
  reducers: {
    startStockRecieveStatusUpdate: (state, _) => {
      state.isLoading = true;
    },
    successStockRecieveStatusUpdate: (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    },
    failureStockRecieveStatusUpdate: (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    },
    clearStockRecieveStatusUpdate: (state) => {
      console.log("Clear Called>>>>");

      state.data = {};
    },
  },
});

export const {
  clearStockRecieveStatusUpdate,
  failureStockRecieveStatusUpdate,
  startStockRecieveStatusUpdate,
  successStockRecieveStatusUpdate,
} = StockRecieveStatusUpdate.actions;

export default StockRecieveStatusUpdate.reducer;
