import { GridCsvExportMenuItem } from "@mui/x-data-grid";
import React, { FunctionComponent } from "react";
import { ButtonFieldProps } from "../../Button/IButtonField";
import { ITableCSVExportProps } from "../ITable";

const TableCSVExportButton: FunctionComponent<ITableCSVExportProps> = (
  props
) => {
  const {
    delimiter,
    fileName,
    includeHeaders,
    includeColumnGroupsHeaders,
    className,
  } = props;
  return (
    <div className={className}>
      <GridCsvExportMenuItem
        options={{
          fileName: fileName,
          includeHeaders: includeHeaders,
          includeColumnGroupsHeaders: includeColumnGroupsHeaders,
        }}
      />
    </div>
  );
};

export default TableCSVExportButton;
