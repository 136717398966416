import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IReduxState } from "../../interfaces/IReduxState";
import { ILocationReceive } from "../../../Pages/LocationReceive/ILocationReceive";
import { key } from "../../../Pages/TransactionRequest/ITransactionRequest";
import { IAPICall } from "./ApiLocationReceive";
import { Filter } from "../../../Pages/StockRecieve/IStockRecieve";

export const LocationReceiveSlice = createSlice({
  name: "ListLocationReceive",
  initialState: {} as IReduxState<ILocationReceive[]>,
  reducers: {
    listLocationReceive: (state, _: PayloadAction<Filter>) => {
      state.isLoading = true;
    },
    successLocationReceive: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
    failureLocationReceive: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
    clearLocationReceive: (state) => {
      state.data = {};
    },
  },
});

export const {
  failureLocationReceive,
  listLocationReceive,
  successLocationReceive,
  clearLocationReceive,
} = LocationReceiveSlice.actions;

export default LocationReceiveSlice.reducer;
