import { createSlice } from "@reduxjs/toolkit";
import { IReduxState } from "../../interfaces/IReduxState";

export const CreateSequence = createSlice({
  name: "CreateSequence",
  initialState: {
    isLoading: false,
    date: {},
  } as IReduxState<null>,
  reducers: {
    createSequenceStart: (state, _) => {
      state.isLoading = true;
    },
    successCreateSequence: (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    },
    failureCreateSequence: (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    },
    clearCreateSequence: (state) => {
      state.data = {};
    },
  },
});

export const {
  createSequenceStart,
  successCreateSequence,
  failureCreateSequence,
  clearCreateSequence,
} = CreateSequence.actions;
export default CreateSequence.reducer;
